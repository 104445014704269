import { useSearchParams } from "react-router-dom";
import { Tab, Tabs } from "@mui/material";
import {
    faGlobe,
    faGraduationCap,
    faPlayCircle,
} from "@fortawesome/free-solid-svg-icons";

import { searchResultsContainer } from "./SearchResult.module.scss";
import { tracker } from "utils/analytics";

import SingleTopicResult from "./TopicSearchResults/SingleTopicSearchResult";
import TopicSearchResult from "./TopicSearchResults/TopicSearchResults";
import WebPageResults from "./WebPageResults/WebPageResults";
import CurriculumNavBar from "components/NavBars/CurriculumNavBar";
import TabTitle from "components/Tabs/TabTitle/TabTitle";

const SearchResults = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const queryParamsString = searchParams.get("queryParams");
    const queryParams = queryParamsString ? JSON.parse(queryParamsString) : {};
    const tabKey = queryParams?.resultType ?? "topics";

    const setTabKey = (
        _event: React.SyntheticEvent,
        newTabKey: "topics" | "pages" | "videos"
    ) => {
        tracker("Search Tab Changed", { newTab: newTabKey });
        const newQueryParams = {
            ...(queryParams ? queryParams : null),
            startPage: 1,
            resultType: newTabKey,
        };

        setSearchParams({
            ...Object.fromEntries([...searchParams]),
            queryParams: JSON.stringify(newQueryParams),
        });
    };

    return (
        <>
            <CurriculumNavBar />
            <div className={searchResultsContainer}>
                <Tabs
                    value={tabKey}
                    variant="fullWidth"
                    onChange={setTabKey}
                    centered
                >
                    <Tab
                        label={
                            <TabTitle
                                label="Learning objectives"
                                styleOverrides={{ textTransform: "capitalize" }}
                                icon={faGraduationCap}
                                isTabSelected={tabKey === "topics"}
                            />
                        }
                        value="topics"
                    />
                    <Tab
                        label={
                            <TabTitle
                                label="Web Pages"
                                icon={faGlobe}
                                isTabSelected={tabKey === "pages"}
                            />
                        }
                        value="pages"
                    />
                    <Tab
                        label={
                            <TabTitle
                                label="Videos"
                                icon={faPlayCircle}
                                isTabSelected={tabKey === "videos"}
                            />
                        }
                        value="videos"
                    />
                </Tabs>
                <div hidden={tabKey !== "topics"}>
                    {searchParams.get("queryResult") ? (
                        <SingleTopicResult />
                    ) : (
                        <TopicSearchResult />
                    )}
                </div>
                <div hidden={tabKey !== "pages"}>
                    <WebPageResults />
                </div>
                <div hidden={tabKey !== "videos"}>
                    <WebPageResults />
                </div>
            </div>
        </>
    );
};

export default SearchResults;
