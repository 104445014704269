import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { axiosAPI } from "./axiosAPI";

import { ResourcesCollection } from "types";

export const useCreateResourcesCollectionMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async ({
            ids,
            title,
            parentId,
        }: {
            ids: string[];
            title: string;
            parentId: number | undefined;
        }) => {
            return await axiosAPI
                .getInstance()
                ?.post("/resource/collection", { ids, title, parentId });
        },
        {
            onSettled: (result) =>
                queryClient.invalidateQueries({
                    queryKey: [
                        "resource-collection",
                        result?.data.collection.title,
                    ],
                }),
        }
    );
};

const useResourcesCollectionQuery = (ids: string[], title: string) => {
    return useQuery<ResourcesCollection, AxiosError>(
        ["resource-collection", title],
        async () => {
            const { data } = await axiosAPI
                .getInstance()
                .post("/resource/collection", { ids, title });
            return data;
        },
        { enabled: !!(ids && ids.length > 0 && title) }
    );
};

export default useResourcesCollectionQuery;
