@use "../../../styles/variables.module";

.numberOfResultsStyle {
    border: 1px solid variables.$background-colors-color-background-light-gray;
    height: 24px;
    width: 41px;
    border-radius: 5px;
    position: relative;
    text-align: center;
    font-size: 14px;
    font-weight: 300;
    outline: none;
}

.numberOfResultsStyle::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
}
