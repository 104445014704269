import { createContext, useContext, useEffect, useRef } from "react";
import Zendesk, { ZendeskAPI } from "react-zendesk";

import { useAppContext } from "./AppContext";
import { isAppMode } from "utils/newtools";

type ZendeskContext = {
    hideZendesk: () => void;
    showZendesk: () => void;
};

const ZendeskContext = createContext<ZendeskContext | null>(null);

export const useZendeskContext = () => {
    const context = useContext(ZendeskContext);
    if (!context) {
        throw new Error(
            "useZendeskContext must be used within a ZendeskContextProvider",
        );
    }
    return context;
};

const ZendeskContextProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const {
        config: { language },
    } = useAppContext();
    const previousLanguage = useRef(language);
    const isVisible = useRef(true);
    const zendeskKey = process.env.REACT_APP_ZENDESK_KEY;
    const isEnabled = isAppMode && typeof zendeskKey === "string";
    const hideZendesk = () => {
        if (isEnabled) {
            isVisible.current = false;
            ZendeskAPI("webWidget", "hide");
        }
    };
    const showZendesk = () => {
        if (isEnabled) {
            isVisible.current = true;
            if (language === "en") {
                ZendeskAPI("webWidget", "show");
            }
        }
    };

    useEffect(() => {
        if (previousLanguage.current === language) return;
        if (language !== "en") {
            ZendeskAPI("webWidget", "hide");
        } else {
            if (isVisible.current) {
                ZendeskAPI("webWidget", "show");
            }
        }
        previousLanguage.current = language;
    }, [language]);

    return (
        <ZendeskContext.Provider value={{ hideZendesk, showZendesk }}>
            {children}
            {isEnabled && (
                <Zendesk
                    defer
                    zendeskKey={zendeskKey}
                    onLoaded={() => {
                        if (language !== "en") ZendeskAPI("webWidget", "hide");
                    }}
                />
            )}
        </ZendeskContext.Provider>
    );
};

export default ZendeskContextProvider;
