import { useNavigate, useParams } from "react-router-dom";
import { Divider, Theme, useMediaQuery } from "@mui/material";
import { faGraduationCap, faListUl } from "@fortawesome/free-solid-svg-icons";
import { has } from "lodash";

import {
    headerContainer,
    logoContainer,
    buttonsContainer,
} from "./Header.module.scss";
import { useAppContext } from "contexts/AppContext";
import useCurriculumParams from "hooks/useCurriculumParams";
import { useUserCurrentLayerQuery } from "hooks/api/useUserSettingsQuery";
import { EmbedLibrary } from "types";
import { tracker } from "utils/analytics";
import { isAppMode, isShareMode } from "utils/newtools";

import UserMenu from "./UserMenu/UserMenu";
import Logo from "components/Logo/Logo";
import TransparentButton from "components/TransparentButton/TransparentButton";
import SearchInput from "features/Search/SearchInput/SearchInput";

interface Props {
    collectionView?: boolean;
}

const Header = ({ collectionView }: Props) => {
    const { shareType } = useParams();
    const isMobileScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("md"),
    );
    const { queryId } = useCurriculumParams();
    const { data: userCurrentLayer } = useUserCurrentLayerQuery();
    const curriculumId = userCurrentLayer?.curriculumId;
    const gradeId = userCurrentLayer?.gradeId;
    const { embedDetails } = useAppContext();
    const navigate = useNavigate();
    if (isShareMode && !embedDetails && !collectionView) return <></>;
    const isEmbedMode = shareType === "embed" || (isShareMode && !shareType); // consortium has old tokens missing shareType but are embeds
    const isMainHeaderEnabled =
        !isEmbedMode ||
        (!embedDetails?.topicId &&
            !embedDetails?.queryId &&
            !embedDetails?.ids) ||
        collectionView;
    const isSearchEnabled =
        (isAppMode && !collectionView) ||
        (!collectionView && has(embedDetails, ["details", "curriculumId"]));
    const handleLogoClick = () => {
        tracker("Home logo clicked");
        if (isShareMode) {
            if (!embedDetails) return; // TODO collection share needs handling
            if (!embedDetails?.topicId && !embedDetails?.queryId) {
                const libraryEmbedDetails = embedDetails as EmbedLibrary;
                navigate(
                    `/library/embed/curriculum/${libraryEmbedDetails.details.curriculumId}`,
                );
            } else if (embedDetails.topicId && queryId) {
                navigate(
                    `/curriculum-share-viewer/${
                        embedDetails?.shareMode +
                        "/topic/" +
                        embedDetails.topicId
                    }`,
                );
            }
        } else {
            navigate("/");
        }
    };
    return (
        <div
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
        >
            {isMainHeaderEnabled && (
                <div className={headerContainer}>
                    <div className={logoContainer} onClick={handleLogoClick}>
                        <Logo />
                    </div>
                    <div className={buttonsContainer}>
                        {!isMobileScreen && !collectionView && isAppMode && (
                            <>
                                <TransparentButton
                                    faIcon={faGraduationCap}
                                    text={"Curriculum"}
                                    clickHandler={() => {
                                        tracker("Curriculum button clicked");
                                        navigate(
                                            `/curriculum/${
                                                curriculumId +
                                                (gradeId
                                                    ? "/grade/" + gradeId
                                                    : "")
                                            }`,
                                        );
                                    }}
                                />
                                <Divider orientation="vertical" />
                                <TransparentButton
                                    faIcon={faListUl}
                                    text={"My collections"}
                                    clickHandler={() => {
                                        tracker(
                                            "My collections button clicked",
                                        );
                                        navigate("/my-collections");
                                    }}
                                />
                                <Divider orientation="vertical" />
                            </>
                        )}
                        {isSearchEnabled && <SearchInput />}
                        {isAppMode && !collectionView && <UserMenu />}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Header;
