import { subheaderWrapper } from "./NavBar.module.scss";
import useCurriculumQuery from "hooks/api/useCurriculumQuery";
import { useNodeLayersQuery } from "hooks/api/useNodeNextQuery";
import useCurriculumParams from "hooks/useCurriculumParams";

import BackButton from "./BackButton/BackButton";

const QueryResultsNavBar = () => {
    const { curriculumId, gradeId, subjectId, unitId } = useCurriculumParams();

    const { data: curriculumData } = useCurriculumQuery();
    const topicListId = unitId
        ? unitId.toString()
        : subjectId
        ? subjectId?.toString()
        : gradeId
        ? gradeId?.toString()
        : curriculumId?.toString();
    const willDisplayUnit =
        curriculumData?.structure.includes("UNIT") &&
        curriculumData?.structure.includes("SUBJECT");
    const layersString = willDisplayUnit ? "UNIT/TOPIC/QUERY" : "TOPIC/QUERY";
    const { data: screenData } = useNodeLayersQuery(topicListId, layersString);

    const subjectTitle = screenData?.name?.toLowerCase() ?? "";

    return (
        <div className={subheaderWrapper} data-cardbackground={subjectTitle}>
            <BackButton />
        </div>
    );
};

export default QueryResultsNavBar;
