import { CSSProperties, useState } from "react";
import Menu from "@mui/material/Menu";
import { MenuItem, Typography } from "@mui/material";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import variables from "styles/_variables.module.scss";

import Translate, { TranslatableText } from "components/Translate/Translate";

const ContextMenu = ({
    children,
    options,
    onClose,
    disabled,
    styleOverrides,
}: {
    children: React.ReactNode;
    options: {
        label: TranslatableText;
        icon?: IconProp;
        disabled?: boolean;
        clickHandler: () => void;
    }[];
    onClose?: () => void;
    disabled?: boolean;
    styleOverrides?: CSSProperties;
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const openHandler = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const closeHandler = () => {
        setAnchorEl(null);
        onClose ? onClose() : undefined;
    };

    return (
        <div
            style={{ ...styleOverrides }}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            <div onClick={openHandler}>{children}</div>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={closeHandler}
                sx={{
                    zIndex: variables["zindex-context-menu"],
                    ".MuiMenu-paper": {
                        borderRadius: "10px",
                        borderWidth: 1,
                        borderStyle: "solid",
                        borderColor: "$border-colors-color-border-light.main",
                        boxShadow: variables["box-shadow-dropdown"],
                        ...styleOverrides,
                    },
                    ".MuiMenu-list": {
                        padding: 0,
                    },
                }}
            >
                {options.map((option, index) => (
                    <MenuItem
                        data-testid={"context-menu-item-" + index}
                        key={index}
                        onClick={() => {
                            option.clickHandler();
                            closeHandler();
                        }}
                        style={{
                            padding: "10px 20px",
                            boxShadow:
                                index !== options.length - 1
                                    ? "0px -1px 0px 0px #CBCBCC inset"
                                    : "unset",
                        }}
                        disabled={disabled || option.disabled}
                    >
                        <Typography
                            variant="text-size-Base"
                            color="$text-colors-color-text-dark.main"
                        >
                            {option.icon && (
                                <FontAwesomeIcon
                                    icon={option.icon}
                                    style={{
                                        marginRight: "10px",
                                    }}
                                    color={
                                        variables["text-colors-color-text-dark"]
                                    }
                                />
                            )}
                            <Translate text={option.label} />
                        </Typography>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default ContextMenu;
