@use "../../styles/variables.module";

.subheaderWrapper {
    padding: 10px 40px;
    position: sticky;
    top: 0;
    align-self: center;
    display: flex;
    align-items: center;
    height: 40px;
    width: 100%;
    gap: 20px;
    color: variables.$text-colors-color-text-gray;
    background-color: white;
    opacity: 0.9;
    z-index: variables.$zindex-navigators;
}

@media (max-width: variables.$bp-md) {
    .subheaderWrapper {
        padding: 10px 10px;
    }
}
