import { selectReason, mandatory } from "./RequiredAction.module.scss";

export const ShowEmptyOption = ({
    feedbackAction,
}: {
    feedbackAction: number | undefined;
}) => {
    if (feedbackAction) return null;
    return <option value="">Select an action</option>;
};

export const RequiredActionOption = ({ action }: { action: Action }) => (
    <option value={action.id}>{action.name}</option>
);

type Action = { id: number; name: string };

interface Props {
    value: number | undefined;
    optionsValues: Array<Action> | undefined;
    changeHandler: (arg0: number) => void;
}

export const RequiredAction = ({
    value,
    optionsValues,
    changeHandler,
}: Props) => {
    if (!optionsValues) return <></>;
    return (
        <div>
            <label style={{ display: "flex", flexDirection: "row" }}>
                Required action<span className={mandatory}>*</span>
            </label>
            <select
                className={selectReason}
                onChange={(e) => changeHandler(parseFloat(e.target.value))}
                name="action"
                value={value}
            >
                <ShowEmptyOption feedbackAction={value} />
                {optionsValues.map((action) => (
                    <RequiredActionOption key={action.id} action={action} />
                ))}
            </select>
        </div>
    );
};

export default RequiredAction;
