import { Typography } from "@mui/material";

import * as styles from "./NumberButton.module.scss";
import variables from "styles/_variables.module.scss";

interface Props {
    value: number;
    isSelected: boolean;
    clickHandler: () => void;
    disabled: boolean;
}

const NumberButton = ({ value, isSelected, clickHandler, disabled }: Props) => {
    return (
        <div
            className={styles.numberButton}
            style={{
                background: disabled
                    ? variables["background-colors-color-background-light-off"]
                    : isSelected
                      ? variables["icon-colors-blue-100"]
                      : undefined,
            }}
            onClick={() => {
                if (disabled) return;
                clickHandler();
            }}
        >
            <Typography
                variant="text-size-Base-bold"
                color={
                    disabled
                        ? "$text-colors-color-text-light-gray.main"
                        : isSelected
                          ? "$border-colors-color-border-white.main"
                          : "$text-colors-color-text-blue.main"
                }
            >
                {value}
            </Typography>
        </div>
    );
};

export default NumberButton;
