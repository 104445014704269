import { wizeupApp } from "./AppLayout.module.scss";
import variables from "styles/_variables.module.scss";

import Header from "features/Header/Header";

interface Props {
    children: JSX.Element | JSX.Element[];
}

const AppLayout = ({ children }: Props) => {
    return (
        <div className={wizeupApp}>
            <Header />
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "100%",
                    width: "100%",
                    overflowY: "auto",
                }}
            >
                <div
                    data-name="app-layout-children"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        minHeight: "100%",
                        height: "fit-content",
                        width: "100%",
                        maxWidth: "1440px",
                        background:
                            variables[
                                "background-colors-color-background-light"
                            ],
                        boxShadow: "0px 0px 30px 0px rgba(52, 58, 64, 0.20)",
                    }}
                >
                    {children}
                </div>
            </div>
        </div>
    );
};

export default AppLayout;
