import { useNavigate } from "react-router-dom";
import { Theme, useMediaQuery } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import { buttonStyle, labelStyle } from "./NextTopicButton.module.scss";
import { useAppContext } from "contexts/AppContext";
import useNextTopicQuery from "hooks/api/useNextTopic";
import { useNodeLayersQuery } from "hooks/api/useNodeNextQuery";
import { tracker } from "utils/analytics";

import Translate, { translate } from "components/Translate/Translate";

interface Props {
    topicId: number;
    unitId: number | null;
}

const NextTopicButton = (props: Props) => {
    const isDesktopScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.up("lg"),
    );
    const navigate = useNavigate();
    const {
        config: { language },
    } = useAppContext();

    const { data: nextTopic, status: nextTopicStatus } = useNextTopicQuery(
        props.topicId,
    );
    const { data: nextTopicData, status: nextTopicDataStatus } =
        useNodeLayersQuery(nextTopic?.node?.id, "QUERY");
    if (
        nextTopicStatus !== "success" ||
        nextTopicDataStatus !== "success" ||
        nextTopic?.isLast ||
        !nextTopicData?.queries?.length
    )
        return null;

    const getNextTopic = () => {
        navigate("../../../" + nextTopic.node.id, {
            relative: "path",
        });
        tracker("Next button");
    };

    return (
        <div
            style={{
                paddingTop: isDesktopScreen ? "15px" : "0px",
                position: "relative",
                height: isDesktopScreen ? "" : "55px",
                flex: isDesktopScreen ? "0 0 325px" : "",
            }}
        >
            <button
                className={buttonStyle}
                onClick={getNextTopic}
                data-testid="nextTopicButton"
                aria-label={translate(language, "Next learning objective")}
            >
                <label className={labelStyle}>
                    <Translate text="Next learning objective" />
                </label>
                <FontAwesomeIcon icon={faArrowRight} />
            </button>
        </div>
    );
};

export default NextTopicButton;
