import { useEffect, useState } from "react";
import { SelectChangeEvent } from "@mui/material";
import { isEqual } from "lodash";

import {
    profileContainer,
    profileFormGroup,
    changeButton,
    supportText,
    formContainer,
} from "./ProfileTab.module.scss";
import usePreventNavigation from "hooks/usePreventNavigation";
import useUserQuery, { useUserUpdateMutation } from "hooks/api/useUserQuery";
import { UserBase } from "types";
import { useMessagingContext } from "contexts/MessagingContext";

import ChangePasswordModal from "./ChangePasswordModal/ChangePasswordModal";
import RegionSelect from "./RegionSelect/RegionSelect";
import DialogFooter from "components/DialogBox/DialogFooter/DialogFooter";
import InputField from "components/InputField/InputField";
import Loading from "components/Loading/Loading";
import Translate from "components/Translate/Translate";

const ProfileForm = ({
    userData,
    setUnsavedChanges,
    hasUnsavedChanges,
}: {
    userData: UserBase;
    setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
    hasUnsavedChanges: boolean;
}) => {
    const { openAlertMessage } = useMessagingContext();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [formData, setFormData] = useState<UserBase>(userData);

    const userRoles = userData?.roles;
    const isNotAccountAdmin = Boolean(!userRoles.includes("ACCOUNT_ADMIN"));

    const userUpdateMutation = useUserUpdateMutation();

    useEffect(() => {
        if (userData) setFormData(userData);
    }, [userData]);

    useEffect(() => {
        setUnsavedChanges(!isEqual(userData, formData));
    }, [userData, formData, setUnsavedChanges]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleChangeRegion = (e: SelectChangeEvent<string>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const resetForm = () => {
        setFormData(userData);
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        const finalData = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            region: formData.region,
            companyName: formData.companyName,
        };
        userUpdateMutation.mutate(finalData, {
            onError: () =>
                openAlertMessage({
                    open: true,
                    type: "error",
                    message: "Your changes have not been saved.",
                }),

            onSuccess: () =>
                openAlertMessage({
                    open: true,
                    type: "success",
                    message: "Your changes have been saved.",
                }),
        });
    };

    usePreventNavigation({
        data: userData,
        formData,
        messageBody: {
            title: "Changes are not saved",
            body: "The changes you made will be lost if you navigate away from this page. Are you sure you want to discard the changes?",
            type: "Yes, leave the page",
        },
    });

    return (
        <div className={formContainer}>
            <form className={profileFormGroup} onSubmit={handleSubmit}>
                <InputField
                    fullWidth
                    required
                    value={formData?.firstName}
                    testId="firstName"
                    name="firstName"
                    type="text"
                    label="First name"
                    handleChange={handleChange}
                />
                <InputField
                    fullWidth
                    required
                    testId="lastName"
                    name="lastName"
                    value={formData?.lastName}
                    type="text"
                    label="Last name"
                    handleChange={handleChange}
                />
                <InputField
                    fullWidth
                    name="email"
                    type="email"
                    testId="email"
                    value={formData?.email}
                    disabled
                    label="Email"
                />
                <InputField
                    fullWidth
                    name="companyName"
                    testId="companyName"
                    disabled={isNotAccountAdmin}
                    value={formData?.companyName}
                    type="text"
                    label="My school or Institution"
                    handleChange={handleChange}
                />
                <RegionSelect
                    value={formData?.region}
                    disabled={isNotAccountAdmin}
                    handleChange={handleChangeRegion}
                />
                <InputField
                    fullWidth
                    name="password"
                    type="password"
                    label="Password"
                    value={"abcdefgh"}
                />
                <div
                    className={changeButton}
                    onClick={() => {
                        setIsModalVisible(true);
                    }}
                >
                    <Translate text="Change password" />
                </div>
                <DialogFooter
                    secondaryButton={{
                        clickHandler: resetForm,
                        label: "Cancel",
                        disabled: !hasUnsavedChanges,
                    }}
                    primaryButton={{
                        label: "Save changes",
                        disabled: !hasUnsavedChanges,
                    }}
                />
            </form>

            <ChangePasswordModal
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
            />
            <p className={supportText}>
                <Translate text="If you wish to" />{" "}
                <span>
                    <Translate text="delete " />
                </span>
                <Translate text="your account contact us at" />{" "}
                <a href="mailto:support@wizenoze.com">support@wizenoze.com</a>
            </p>
        </div>
    );
};

const UserProfile = ({
    setUnsavedChanges,
    hasUnsavedChanges,
}: {
    setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
    hasUnsavedChanges: boolean;
}) => {
    const { data: userData } = useUserQuery();
    return (
        <div className={profileContainer}>
            {userData?.me ? (
                <ProfileForm
                    userData={userData?.me}
                    setUnsavedChanges={setUnsavedChanges}
                    hasUnsavedChanges={hasUnsavedChanges}
                />
            ) : (
                <Loading isCentered={true} />
            )}
        </div>
    );
};

export default UserProfile;
