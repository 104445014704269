/**
 * Determine if the given source is a youtube link or not.
 * @param {String | URL } url The url string or object to be checked
 * @returns Boolean
 */
export const isSrcYouTube = function (url) {
    if (typeof url === "string" && url.length > 0) {
        url = new URL(url);
    }

    if (!(url instanceof URL)) {
        throw new Error(
            "The url seems invalid, it should be an instance of URL"
        );
    }
    return Boolean(/youtube/i.test(url.hostname));
};

export const formatYoutubeEmbedUrl = function (url) {
    // Regular expression to match YouTube URLs
    var regExp =
        /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})(\S*)$/;

    // Check if the provided URL matches the YouTube URL pattern
    var match = url.match(regExp);

    // If there's a match, generate the embed URL
    if (match) {
        var videoId = match[4];
        var params = match[5] || ""; // Additional parameters, if any

        // Replace the first character with "?" if params exist
        if (params.length > 0) {
            params = "?" + params.substr(1);
        }

        var embedUrl = "https://www.youtube.com/embed/" + videoId + params;
        return embedUrl;
    } else {
        // console.error("Invalid Youtube URL");
        return url;
    }
};
