import { Button, Typography } from "@mui/material";

import * as styles from "../ForgotPassword.module.scss";
import { useAppContext } from "contexts/AppContext";
import { usePasswordResetMutation } from "hooks/api/authentication";
import { tracker } from "utils/analytics";

import Translate, { translate } from "components/Translate/Translate";

interface Props {
    email: string;
    setError: React.Dispatch<React.SetStateAction<string | undefined>>;
    setSecondEmailSent: React.Dispatch<React.SetStateAction<boolean>>;
}

const CheckEmailMessage = ({ email, setError, setSecondEmailSent }: Props) => {
    const { config } = useAppContext();
    const language = config.language;
    const passwordResetMutation = usePasswordResetMutation();
    const clickResendHandler = async (e) => {
        e.preventDefault();
        passwordResetMutation.mutate(
            { email: email },
            {
                onSuccess: () => {
                    setSecondEmailSent(true);
                },
                onError: (_error) => {
                    tracker(
                        "Forgot password failed - email address does not exist in our system",
                    );
                    setError(
                        translate(
                            language,
                            "That email address does not exist on our system. Please register.",
                        ),
                    );
                },
            },
        );
        tracker("Forgot password resend email requested");
    };
    return (
        <>
            <Typography variant="text-size-Base">
                <Translate text="Please check your inbox and follow the link in the email to reset your password. Did not receive the email?" />
            </Typography>
            <Button
                variant="outlined"
                className={styles.buttonStyle}
                onClick={clickResendHandler}
            >
                <Typography variant="text-size-Base-bold">
                    <Translate text="Resend email" />
                </Typography>
            </Button>
        </>
    );
};

export default CheckEmailMessage;
