import { useContext, useState } from "react";
import { Typography } from "@mui/material";
import { faRectangleList, faSearch } from "@fortawesome/free-solid-svg-icons";

import * as styles from "./AddResourcePanel.module.scss";
import { useResultModalContext } from "contexts/ResultModalContext";
import { CollectionContext } from "features/CollectionEditor/state/CollectionContext";
import useScreenSizes from "hooks/useScreenSizes";

import AddExternalResources from "./AddExternalResources/AddExternalResources";
import AddFromSearch from "./AddFromSearch/AddFromSearch";
import DialogBox from "components/DialogBox/DialogBox";
import Translate from "components/Translate/Translate";
import TransparentButton from "components/TransparentButton/TransparentButton";
import AddFromMyCollections from "features/MyCollections/AddFromMyCollections";

const AppResourcePanel = () => {
    const { isMobileScreen } = useScreenSizes();
    const { setIsResultModalEnabled } = useResultModalContext();
    const { collection } = useContext(CollectionContext);
    const [openModal, setOpenModal] = useState<
        "Search" | "AddCustom" | "AddCollection" | null
    >(null);
    const clearOpenModal = () => {
        setOpenModal(null);
        setIsResultModalEnabled(collection.resultModal); // When clearing modal restore previous resultModal
    };
    return (
        <div className={styles.outerContainer}>
            <div className={styles.innerContainer}>
                <div className={styles.title}>
                    <Typography variant="text-size-Small-bold">
                        <Translate text="Add Resources" />
                    </Typography>
                </div>
                <div className={styles.buttonContainer}>
                    <TransparentButton
                        faIcon={faSearch}
                        text="Search Wizenoze"
                        clickHandler={() => {
                            setOpenModal("Search");
                            setIsResultModalEnabled(false); // While adding resources ALWAYS open in new tab
                        }}
                    />
                    <TransparentButton
                        faIcon={faRectangleList}
                        text="Add my resources"
                        clickHandler={() => {
                            setOpenModal("AddCustom");
                            setIsResultModalEnabled(false); // While adding resources ALWAYS open in new tab
                        }}
                    />
                    <TransparentButton
                        faIcon={faSearch}
                        text="Add from collections"
                        clickHandler={() => {
                            setOpenModal("AddCollection");
                            setIsResultModalEnabled(false); // While adding resources ALWAYS open in new tab
                        }}
                    />
                </div>
            </div>
            <DialogBox
                title="Find resource by searching the Wizenoze library"
                isOpen={openModal === "Search"}
                closeHandler={clearOpenModal}
                withCloseButton
                styleOverrides={{
                    position: isMobileScreen ? "absolute" : undefined,
                    margin: isMobileScreen ? "0px" : undefined,
                    maxHeight: isMobileScreen
                        ? "-webkit-fill-available"
                        : undefined,
                    top: isMobileScreen ? "71px" : undefined,
                    borderRadius: isMobileScreen ? "0px" : undefined,
                }}
                primaryButton={{
                    label: "Done",
                    clickHandler: clearOpenModal,
                }}
                footerStyleOverrides={{ justifyContent: "flex-end" }}
            >
                <AddFromSearch />
            </DialogBox>
            <DialogBox
                title="My resources"
                isOpen={openModal === "AddCustom"}
                closeHandler={clearOpenModal}
                withCloseButton
                styleOverrides={{
                    position: isMobileScreen ? "absolute" : undefined,
                    margin: isMobileScreen ? "0px" : undefined,
                    maxHeight: isMobileScreen
                        ? "-webkit-fill-available"
                        : undefined,
                    top: isMobileScreen ? "71px" : undefined,
                    borderRadius: isMobileScreen ? "0px" : undefined,
                }}
            >
                <AddExternalResources closeHandler={clearOpenModal} />
            </DialogBox>
            <DialogBox
                title="Add resources from other collections"
                isOpen={openModal === "AddCollection"}
                closeHandler={clearOpenModal}
                withCloseButton
                styleOverrides={{
                    position: isMobileScreen ? "absolute" : undefined,
                    margin: isMobileScreen ? "0px" : undefined,
                    maxHeight: isMobileScreen
                        ? "-webkit-fill-available"
                        : undefined,
                    top: isMobileScreen ? "71px" : undefined,
                    borderRadius: isMobileScreen ? "0px" : undefined,
                }}
                primaryButton={{
                    label: "Done",
                    clickHandler: clearOpenModal,
                }}
                footerStyleOverrides={{ justifyContent: "flex-end" }}
            >
                <AddFromMyCollections />
            </DialogBox>
        </div>
    );
};

export default AppResourcePanel;
