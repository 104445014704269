import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Divider, TextField, Typography } from "@mui/material";

import * as styles from "../ForgotPassword.module.scss";
import { useAppContext } from "contexts/AppContext";
import { usePasswordResetMutation } from "hooks/api/authentication";
import { tracker } from "utils/analytics";

import Translate, { translate } from "components/Translate/Translate";

interface Props {
    email: string;
    setEmail: (email: string) => void;
    setShowForgotPasswordForm: (showForgotPasswordForm: boolean) => void;
    setTitle: (title: string) => void;
    setError: (error: string | undefined) => void;
}

const ForgotPasswordForm = ({
    email,
    setEmail,
    setShowForgotPasswordForm,
    setTitle,
    setError,
}: Props) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { config } = useAppContext();
    const language = config.language;
    const passwordResetMutation = usePasswordResetMutation();
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (passwordResetMutation.isLoading) return;
        const { email } = e.currentTarget;
        if (!email.value) {
            throw new Error(translate(language, "Missing email"));
        }
        passwordResetMutation.mutate(
            { email: email.value },
            {
                onSuccess: () => {
                    setShowForgotPasswordForm(false);
                    setTitle("Password recovery sent!");
                    setError(undefined);
                },
                onError: (_error) => {
                    tracker(
                        "Forgot password failed - email address does not exist in our system",
                    );
                    setError(
                        translate(
                            language,
                            "That email address does not exist on our system. Please register.",
                        ),
                    );
                },
            },
        );
        tracker("Forgot password email requested");
    };
    return (
        <>
            <form className={styles.formContainer} onSubmit={handleSubmit}>
                <TextField
                    name="email"
                    type="email"
                    label={translate(language, "Email")}
                    placeholder={translate(language, "Enter your email")}
                    value={email}
                    fullWidth
                    required
                    InputLabelProps={{ required: false }}
                    onChange={handleChange}
                />
                <Button
                    variant="contained"
                    type="submit"
                    className={styles.buttonStyle}
                >
                    <Typography variant="text-size-Base-bold">
                        <Translate text="Send password recovery" />
                    </Typography>
                </Button>
            </form>
            <Divider style={{ margin: "12px 0px" }}>
                <Typography
                    variant="text-size-xSmall-regular"
                    color="$text-colors-color-text-gray.main"
                >
                    <Translate text="OR" />
                </Typography>
            </Divider>
            <Button
                variant="outlined"
                className={styles.buttonStyle}
                onClick={() =>
                    navigate(
                        {
                            pathname: "/login",
                            search: searchParams.toString(),
                        },
                        { relative: "path" },
                    )
                }
            >
                <Typography variant="text-size-Base-bold">
                    <Translate text="Back to login" />
                </Typography>
            </Button>
        </>
    );
};

export default ForgotPasswordForm;
