import { useState } from "react";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

import useScreenSizes from "hooks/useScreenSizes";
import variables from "styles/_variables.module.scss";
import { tracker } from "utils/analytics";

import OrganicSearchFilters from "./OrganicSearchFilters";
import MenuButton from "components/MenuButton/MenuButton";
import MenuContainer from "components/MenuContainer/MenuContainer";

const MobileOrganicSearchFilters = (
    props: React.ComponentProps<typeof OrganicSearchFilters>
) => {
    const { isMobileScreen } = useScreenSizes();
    const [isCollectionPanelOpen, setIsCollectionPanelOpen] = useState(false);
    return (
        <>
            <MenuButton
                faIcon={faFilter}
                iconColor={variables["icon-colors-blue-100"]}
                primaryColor={variables["icon-colors-blue-40"]}
                secondaryColor={variables["icon-colors-blue-60"]}
                isOpen={isCollectionPanelOpen}
                styleOverrides={{
                    width: "30px",
                    minWidth: "30px",
                    height: "30px",
                    fontSize: "12px",
                }}
                onClick={() => {
                    tracker("Organic search filters panel menu open");
                    setIsCollectionPanelOpen(true);
                }}
            />
            <MenuContainer
                isOpen={isCollectionPanelOpen}
                closeMenu={() => setIsCollectionPanelOpen(false)}
                containerRelativePosition={
                    isMobileScreen
                        ? "bottom-right"
                        : "left-full-height-half-width"
                }
                inDialogBox
                fullWidthBreakpoint="xl"
                fullHeightBreakpoint="xl"
            >
                <OrganicSearchFilters {...props} />
            </MenuContainer>
        </>
    );
};

export default MobileOrganicSearchFilters;
