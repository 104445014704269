import { useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAngleRight,
    faAngleDoubleRight,
    faAngleLeft,
    faAngleDoubleLeft,
    IconDefinition,
} from "@fortawesome/free-solid-svg-icons";

import {
    paginationContainer,
    innerContainer,
    label,
    button,
    buttonFeatured,
    icon,
    pageList,
    pageListItem,
} from "./PaginationControls.module.scss";
import { MetadataNameAndId, StructureNode } from "types";
import { tracker } from "utils/analytics";

import Translate from "components/Translate/Translate";

type PaginationButtonProps = {
    action: () => void;
    title: string;
    icon: IconDefinition;
};
type KeyAndCount = {
    key: string;
    count: number;
};
export type Pagination = {
    totalPages: number;
    count: number;
    pageSize: number;
    startPage: number;
    totalResults: number;
    results: Array<{
        type: StructureNode;
        qualifier: StructureNode;
        curriculumId: number;
        nodeId: number;
        queryId: number;
        name: string;
        path: string;
        curriculumMetadata: {
            CURRICULUM: MetadataNameAndId;
            UNIT: MetadataNameAndId;
            QUERY: MetadataNameAndId;
            GRADE: MetadataNameAndId;
            SUBJECT: MetadataNameAndId;
            TOPIC: MetadataNameAndId & {
                logoUrl: string;
            };
        };
        logoUrl: string | null;
    }>;
    facets: {
        curriculums: Array<KeyAndCount>;
        subjects: Array<KeyAndCount>;
        grades: Array<KeyAndCount>;
        layers: Array<StructureNode>;
    };
};

const NavButton = ({ buttonProps }: { buttonProps: PaginationButtonProps }) => (
    <button
        className={button}
        onClick={buttonProps.action}
        title={buttonProps.title}
    >
        <FontAwesomeIcon className={icon} icon={buttonProps.icon} />
    </button>
);

const ListPages = ({
    pagination,
    changePage,
}: {
    pagination: Pagination;
    changePage: (arg0: number) => void;
}) => {
    const { startPage } = pagination;

    const backwardNumberbuttons = [-4, -3, -2, -1].map((val) =>
        makeButtons(val, startPage)
    );
    const forwardNumberButtons = [1, 2, 3, 4].map((val) =>
        makeButtons(val, startPage)
    );

    const currentPageButton = (
        <li className={pageListItem}>
            <button className={buttonFeatured}>{pagination.startPage}</button>
        </li>
    );

    return (
        <ul className={pageList}>
            {backwardNumberbuttons.map((button) => (
                <NumberedButton
                    key={button.page}
                    page={button.page}
                    changePage={changePage}
                    pagination={pagination}
                />
            ))}
            {currentPageButton}
            {forwardNumberButtons.map((button) => (
                <NumberedButton
                    key={button.page}
                    page={button.page}
                    changePage={changePage}
                    pagination={pagination}
                />
            ))}
        </ul>
    );
};

/**
 * A simple helper to create the button
 * objects.
 */
const makeButtons = (val: number, startPage: number) => ({
    page: startPage + val,
});

/**
 * Make buttons depending on the users current position
 * in the page list.
 */
const NumberedButton = ({
    page,
    changePage,
    pagination,
}: {
    page: number;
    changePage: (arg0: number) => void;
    pagination: Pagination;
}) => {
    if (isInvalidButton(page, pagination)) {
        return <></>;
    }
    return (
        <li className={pageListItem}>
            <button
                className={button}
                onClick={(e) => {
                    e.preventDefault();
                    changePage(page);
                }}
            >
                {page}
            </button>
        </li>
    );
};

const isInvalidButton = (
    nextPage: number,
    {
        startPage: currentPage,
        totalPages,
    }: { startPage: number; totalPages: number }
) =>
    nextPage < 1 ||
    (currentPage > 1 && nextPage - currentPage > 2) ||
    nextPage > totalPages ||
    (totalPages - currentPage > 2 && nextPage - currentPage < -2);

interface Props {
    pagination: Pagination;
    resultType: string;
}

const PaginationControls = ({ pagination, resultType }: Props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const queryParams = searchParams.get("queryParams");
    const params = queryParams ? JSON.parse(queryParams) : null;

    const changePage = (page: number) => {
        tracker("Paginate Number");
        const allParams = Object.fromEntries([...searchParams]);
        const updatedParams = {
            ...allParams,
            queryParams: JSON.stringify({
                ...params,
                resultType,
                startPage: page,
            }),
        };
        setSearchParams(updatedParams);
    };
    const paginatePrevious = () => {
        if (pagination.startPage <= 1) {
            return;
        }
        tracker("Paginate Previous");
        const page = pagination.startPage - 1;
        changePage(page);
    };

    const paginateFirst = () => {
        if (pagination.startPage <= 1) {
            return;
        }
        tracker("Paginate First");
        changePage(1);
    };

    const paginateNext = () => {
        if (pagination.startPage > pagination.totalPages - 1) {
            return;
        }
        tracker("Paginate Next");
        const page = pagination.startPage + 1;
        changePage(page);
    };

    const paginateLast = () => {
        if (pagination.startPage >= pagination.totalPages - 1) {
            return;
        }
        tracker("Paginate Last");
        changePage(pagination.totalPages - 1);
    };

    if (!pagination?.totalResults || pagination?.totalPages < 2) {
        return null;
    }

    const beforeButtons: [PaginationButtonProps, PaginationButtonProps] = [
        {
            action: () => paginateFirst(),
            title: "First page",
            icon: faAngleDoubleLeft,
        },
        {
            action: paginatePrevious,
            title: "Previous page",
            icon: faAngleLeft,
        },
    ];
    const nextButtons: [PaginationButtonProps, PaginationButtonProps] = [
        {
            action: paginateNext,
            title: "Next page",
            icon: faAngleRight,
        },
        {
            action: () => paginateLast(),
            title: "Last page",
            icon: faAngleDoubleRight,
        },
    ];

    return (
        <div className={paginationContainer}>
            <div className={label}>{<Translate text="Choose page" />}</div>
            <div className={innerContainer}>
                {beforeButtons.map((el) => (
                    <NavButton key={el.title} buttonProps={el} />
                ))}
                <ListPages pagination={pagination} changePage={changePage} />
                {nextButtons.map((el) => (
                    <NavButton key={el.title} buttonProps={el} />
                ))}
            </div>
            <div className={label}>
                {<Translate text="Total pages" />}: {pagination?.totalPages}
            </div>
        </div>
    );
};

export default PaginationControls;
