import { useContext } from "react";
import { Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faInfo,
    faSliders,
    faWandMagicSparkles,
} from "@fortawesome/free-solid-svg-icons";

import * as styles from "./QuizSteps.module.scss";
import { QuizContext } from "../../state/QuizContext";
import useScreenSizes from "hooks/useScreenSizes";
import variables from "styles/_variables.module.scss";
import { tracker } from "utils/analytics";

import Translate from "components/Translate/Translate";

const QuizSteps = () => {
    const { isDesktopScreen } = useScreenSizes();
    const { setShowQuizDetails } = useContext(QuizContext);
    return (
        <div className={styles.container}>
            <div className={styles.background}>
                <div className={styles.stepsContainer}>
                    <div className={styles.mainTitle}>
                        <FontAwesomeIcon
                            icon={faWandMagicSparkles}
                            color="#770db8"
                            fontSize={24}
                            className={styles.logo}
                        />
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 5,
                            }}
                        >
                            <Typography
                                variant="text-size-Base"
                                color="#770db8"
                            >
                                <Translate text="Use the quiz options to set up the quiz." />
                            </Typography>
                        </div>
                    </div>
                    <div className={styles.section}>
                        <Typography
                            variant="text-size-Medium"
                            color="text-colors-color-text-dark.main"
                        >
                            <Translate text="Create a quiz in two easy steps:" />
                        </Typography>
                        <div className={styles.steps}>
                            {/* <Typography
                                variant="text-size-Base"
                                color="text-colors-color-text-gray.main"
                            >
                                <Translate text="1. Select the type of questions" />
                            </Typography> */}
                            <Typography
                                variant="text-size-Base"
                                color="text-colors-color-text-gray.main"
                            >
                                <Translate text="1. Choose the number of questions" />
                                {/* <Translate text="2. Choose the number of questions" /> */}
                            </Typography>
                            <Typography
                                variant="text-size-Base"
                                color="text-colors-color-text-gray.main"
                                style={{ marginTop: "5px" }}
                            >
                                <Translate text="2. Click the Generate Questions button, and we will prepare the quiz." />
                            </Typography>
                        </div>
                    </div>
                    <div className={styles.section}>
                        <Typography
                            variant="text-size-Medium"
                            color="text-colors-color-text-dark.main"
                        >
                            <Translate text="Edit the quiz" />
                        </Typography>
                        <Typography
                            variant="text-size-Base"
                            color="text-colors-color-text-gray.main"
                        >
                            <Translate text="You can reorder, delete and generate more quiz questions." />
                        </Typography>
                    </div>
                    <div className={styles.section}>
                        <Typography
                            variant="text-size-Medium"
                            color="text-colors-color-text-dark.main"
                        >
                            <Translate text="Save and share" />
                        </Typography>
                        <Typography
                            variant="text-size-Base"
                            color="text-colors-color-text-gray.main"
                        >
                            <Translate text="Once you save the quiz, it is added to the collection. The quiz is shared together with the collection." />
                        </Typography>
                    </div>
                    <div className={styles.mainTitle}>
                        <FontAwesomeIcon
                            icon={faInfo}
                            color="#770db8"
                            fontSize={24}
                            className={styles.logo}
                            style={{
                                padding: "16px 22px",
                            }}
                        />
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 5,
                            }}
                        >
                            <Typography
                                variant="text-size-Base"
                                color="#770db8"
                            >
                                <Translate text="Please be aware that this is an early beta version and as such the quality of results may vary." />
                            </Typography>
                            <Typography
                                variant="text-size-Base"
                                color="#770db8"
                            >
                                <Translate text="Like (or dislike) your experience with our new tool? Please reach out to us at support@wizenoze.com!" />
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
            {!isDesktopScreen && (
                <div
                    className={styles.footer}
                    onClick={() => {
                        tracker("Quiz Details Opened");
                        setShowQuizDetails(true);
                    }}
                >
                    <FontAwesomeIcon
                        icon={faSliders}
                        fontSize={15}
                        color={variables["text-colors-color-text-white"]}
                    />
                    <Typography
                        variant="text-size-Small"
                        color={variables["text-colors-color-text-white"]}
                    >
                        <Translate text="Quiz options" />
                    </Typography>
                </div>
            )}
        </div>
    );
};

export default QuizSteps;
