import wizenozePlaceholder from "images/wizenoze-placeholder.webp";
import { setBlankImage } from "utils/helpers";

const HeaderImage = ({
    logoUrl,
    name: altName,
}: {
    logoUrl: string | undefined;
    name: string | undefined;
}) => {
    return (
        <span
            style={{
                flex: "0 0 80px",
                height: "80px",
                display: "flex",
                alignItems: "center",
            }}
        >
            <img
                onError={setBlankImage}
                src={logoUrl || wizenozePlaceholder}
                alt={altName}
                style={{
                    objectFit: "contain",
                    width: "100%",
                    height: "55px",
                }}
            />
        </span>
    );
};

export default HeaderImage;
