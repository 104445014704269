import { resultContainer } from "./TopicSearchResults.module.scss";
import useCurriculumSearchQuery from "hooks/api/useSearchQuery";

import SearchTopicNoResults from "./SearchTopicNoResults/SearchTopicNoResults";
import Filters from "../Filters/Filters";
import TopicResultList from "./TopicResultList";
import Loading from "components/Loading/Loading";
import PaginationControls from "components/PaginationControls/PaginationControls";

const TopicSearchResult = () => {
    const { data: searchResponse, status: searchResponseStatus } =
        useCurriculumSearchQuery();
    if (searchResponseStatus === "loading") return <Loading />;
    if (searchResponseStatus === "success") {
        return (
            <div className={resultContainer}>
                <Filters />
                {searchResponse?.results.length === 0 ? (
                    <SearchTopicNoResults />
                ) : (
                    <>
                        <TopicResultList results={searchResponse?.results} />
                        {searchResponse?.totalPages &&
                            searchResponse.totalPages > 1 && (
                                <PaginationControls
                                    pagination={searchResponse}
                                    resultType="topics"
                                />
                            )}
                    </>
                )}
            </div>
        );
    }
    return <></>;
};

export default TopicSearchResult;
