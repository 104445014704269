import { useParams } from "react-router-dom";
import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import { axiosAPI } from "./axiosAPI";
import { useUserCurrentLayerQuery } from "./useUserSettingsQuery";
import { useAppContext } from "contexts/AppContext";
import useCurriculumParams from "hooks/useCurriculumParams";
import { Curriculum, Grade, Query, Subject, Topic, Unit } from "types";

export const curriculumsURL = "/curriculum";
export const useCurriculumsQuery = () => {
    const { config } = useAppContext();
    const { hash } = useParams();
    const showDrafts = !!config?.showDrafts;
    return useQuery<Array<Curriculum>, AxiosError>(
        ["curriculums", showDrafts],
        async () => {
            const { data } = await axiosAPI.getInstance().get(curriculumsURL, {
                params: {
                    allPublicationStatus: showDrafts,
                },
            });
            if (typeof data === "string") throw new Error("Returned HTML Page");
            return data;
        },
        { enabled: !hash, staleTime: 1000 * 60 * 60 * 12 },
    );
};

export const useCurriculumNextQuery = (curriculumId?: string | null) => {
    const { config } = useAppContext();
    const { showDrafts } = config;
    return useQuery<Array<Grade | Subject | Unit | Topic>, AxiosError>(
        ["curriculumNext", curriculumId, showDrafts],
        async () => {
            const { data } = await axiosAPI
                .getInstance()
                .get(`/curriculum/${curriculumId}/layers/NEXT`, {
                    params: {
                        allPublicationStatus: showDrafts,
                    },
                });
            return data;
        },
        { enabled: !!curriculumId },
    );
};

export const useCurriculumLayersQuery = (
    targetCurriculumId?: number,
    layersString?: string,
) => {
    const curriculumParams = useCurriculumParams();
    const { data: userCurrentLayer } = useUserCurrentLayerQuery();
    const curriculumId = targetCurriculumId
        ? targetCurriculumId
        : curriculumParams.curriculumId
          ? curriculumParams.curriculumId
          : userCurrentLayer?.curriculumId;
    const { config } = useAppContext();
    const { showDrafts } = config;
    const updateLogoUrl = (
        node: Grade | Subject | Unit | Topic,
        parentLogoUrl: string,
    ) => {
        if (node.children) {
            node.children.forEach((child: Subject | Unit | Topic) => {
                if (!child.logoUrl) {
                    child.logoUrl = parentLogoUrl;
                }
                updateLogoUrl(child, child.logoUrl || parentLogoUrl);
            });
        }
        if (node.queries) {
            node.queries.forEach((query: Query) => {
                if (!query.logoUrl) {
                    query.logoUrl = parentLogoUrl;
                }
            });
        }
    };
    return useQuery<Array<{ id: number; name: string }>, AxiosError>(
        ["curriculumLayers", curriculumId, showDrafts, layersString],
        async () => {
            const { data } = await axiosAPI
                .getInstance()
                .get(`/curriculum/${curriculumId}/layers/${layersString}`, {
                    params: {
                        allPublicationStatus: showDrafts,
                    },
                });
            data.forEach((node: Grade | Subject | Unit | Topic | Query) => {
                updateLogoUrl(node, node.logoUrl);
            });
            return data;
        },
        {
            enabled: !!curriculumId && !!layersString,
            staleTime: 1000 * 60 * 60 * 12,
        },
    );
};

const useCurriculumQuery = (targetCurriculumId?: string | number) => {
    const curriculumParams = useCurriculumParams();
    const { data: userCurrentLayer } = useUserCurrentLayerQuery();
    const curriculaQuery = useCurriculumsQuery();
    const curriculumId = targetCurriculumId
        ? targetCurriculumId
        : curriculumParams.curriculumId
          ? curriculumParams.curriculumId
          : userCurrentLayer?.curriculumId; // targetCurriculmId is only used by the curriculum menu
    return {
        ...curriculaQuery,
        data: curriculaQuery?.data?.find(
            (el) => el.id === Number(curriculumId),
        ),
    };
};

export default useCurriculumQuery;
