import { useState } from "react";
import { TextField } from "@mui/material";

import {
    passwordFormContainer,
    passwordError,
} from "./ChangePasswordModal.module.scss";
import { useUserPasswordUpdateMutation } from "hooks/api/useUserQuery";
import { PasswordConfirmData } from "types";

import Translate from "components/Translate/Translate";
import DialogBox from "components/DialogBox/DialogBox";
import { useMessagingContext } from "contexts/MessagingContext";

interface Props {
    isModalVisible: boolean;
    setIsModalVisible: (state: boolean) => void;
}

const ChangePasswordModal = ({ isModalVisible, setIsModalVisible }: Props) => {
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorState, setError] = useState(false);

    const { openAlertMessage } = useMessagingContext();
    const userPasswordUpdateMutation = useUserPasswordUpdateMutation();
    const changePassword = () => {
        const passwordData: PasswordConfirmData = {
            password: newPassword,
            passwordConfirmation: confirmPassword,
            currentPassword,
        };
        userPasswordUpdateMutation.mutate(passwordData, {
            onError: () =>
                openAlertMessage({
                    open: true,
                    type: "error",
                    message: "Password update failed.",
                }),

            onSuccess: () =>
                openAlertMessage({
                    open: true,
                    type: "success",
                    message: "Your password has been changed.",
                }),
        });
        setIsModalVisible(false);
    };

    const resetForm = () => {
        setNewPassword("");
        setConfirmPassword("");
        setCurrentPassword("");
        setIsModalVisible(false);
    };

    const handleSubmit = () => {
        if (newPassword !== confirmPassword) return setError(true);
        changePassword();
    };

    return (
        <DialogBox
            title="Change password"
            isOpen={isModalVisible}
            withCloseButton
            closeHandler={resetForm}
            styleOverrides={{ width: "435px", height: "unset" }}
            secondaryButton={{
                clickHandler: resetForm,
                label: "Cancel",
            }}
            primaryButton={{
                clickHandler: handleSubmit,
                label: "Change password",
            }}
        >
            <div className={passwordFormContainer}>
                <TextField
                    fullWidth
                    required
                    name="currentPassword"
                    type="password"
                    label="Current password"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    inputProps={{
                        testId: "password",
                    }}
                    style={{ backgroundColor: "white" }}
                />
                <TextField
                    fullWidth
                    required
                    inputProps={{ minlength: 8, testId: "newPassword" }}
                    name="password"
                    type="password"
                    label="New password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    style={{ backgroundColor: "white" }}
                />
                <TextField
                    fullWidth
                    required
                    inputProps={{ minlength: 8, testId: "confirmPassword" }}
                    name="passwordConfirmation"
                    type="password"
                    label="Confirm new password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    style={{ backgroundColor: "white" }}
                />
            </div>
            {errorState && (
                <div className={passwordError} data-testid="passwordMatchError">
                    <Translate text="Your password and confirmation password do not match." />
                </div>
            )}
        </DialogBox>
    );
};

export default ChangePasswordModal;
