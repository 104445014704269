@use "../../styles/variables.module";

.containerV2 {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    border-radius: 15px;
    border: 1px solid variables.$border-colors-color-border-primary;
    background: variables.$background-colors-color-background-white;
    @media (max-width: variables.$bp-lg) {
        border-radius: 0px;
    }
}

.container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    border-radius: 15px;
    border: 1px solid variables.$border-colors-color-border-primary;
    background: variables.$background-colors-color-background-white;
}

.sectionTitle {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5px;
    margin-bottom: 20px;
    align-self: stretch;
    border-bottom: 1px solid variables.$border-colors-color-border-light;
}

.title {
    align-self: stretch;
    border-bottom: 1px solid variables.$border-colors-color-border-light;
}

.footerContainer {
    width: 100%;
    min-height: 40px;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.footer {
    display: flex;
    gap: 10px;
    color: variables.$text-colors-color-text-blue;
    cursor: pointer;
}
