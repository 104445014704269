import { useLocation, useNavigate } from "react-router-dom";
import {
    faChevronDown,
    faChevronRight,
    faCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Theme, Typography, useMediaQuery } from "@mui/material";
import { TreeItem, TreeView } from "@mui/x-tree-view";

import variables from "styles/_variables.module.scss";
import useCurriculumParams from "hooks/useCurriculumParams";

const TreeNode = ({ data, path }: { data: TreeData; path: string }) => {
    const isTabletScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.between("md", "lg")
    );
    const isMobileScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("md")
    );
    const navigate = useNavigate();
    const cleanData =
        data.qualifier !== "QUERY" && data.queries
            ? {
                  ...data,
                  children: data.queries.map((el) => ({
                      ...el,
                      qualifier: "QUERY",
                  })),
              }
            : data;
    return (
        <TreeItem
            nodeId={cleanData.id.toString()}
            label={
                <Typography
                    variant="text-size-Small"
                    color="$text-colors-color-text-dark.main"
                >
                    {cleanData.name}
                </Typography>
            }
            style={{
                paddingTop: isMobileScreen
                    ? "15px"
                    : isTabletScreen
                    ? "10px"
                    : "0px",
            }}
            onClick={() => navigate(path)}
        >
            {cleanData.qualifier !== "QUERY"
                ? cleanData.children?.map((el) => (
                      <TreeNode
                          key={el.id}
                          data={el}
                          path={[path, el.qualifier.toLowerCase(), el.id].join(
                              "/"
                          )}
                      />
                  ))
                : undefined}
        </TreeItem>
    );
};

type TreeData = {
    id: number;
    name: string;
    qualifier: "GRADE" | "SUBJECT" | "UNIT" | "TOPIC" | "QUERY";
    children?: TreeData[] | { id: number; name: string }[];
    queries?: { id: number; name: string }[];
};

const TreeExplorer = ({ data }: { data: TreeData }) => {
    const isTabletScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.between("md", "lg")
    );
    const isMobileScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("md")
    );
    const location = useLocation();
    const curriculumParams = useCurriculumParams();
    const selectedArray: string[] = [];
    if (curriculumParams.unitId) {
        selectedArray.push(curriculumParams.unitId.toString());
    }
    if (curriculumParams.topicId) {
        selectedArray.push(curriculumParams.topicId.toString());
    }
    if (curriculumParams.queryId) {
        selectedArray.push(curriculumParams.queryId.toString());
    }
    return (
        <TreeView
            aria-label="curriculum tree explorer"
            defaultCollapseIcon={
                <FontAwesomeIcon
                    icon={faChevronDown}
                    color={variables["text-colors-color-text-gray"]}
                    style={{ fontSize: "12px" }}
                />
            }
            defaultExpandIcon={
                <FontAwesomeIcon
                    icon={faChevronRight}
                    color={variables["text-colors-color-text-gray"]}
                    style={{ fontSize: "12px" }}
                />
            }
            defaultEndIcon={
                <FontAwesomeIcon
                    icon={faCircle}
                    color={variables["text-colors-color-text-gray"]}
                    style={{ fontSize: "4px", marginTop: "4px" }}
                />
            }
            sx={{
                marginTop: isMobileScreen
                    ? "-20px"
                    : isTabletScreen
                    ? "-15px"
                    : "-5px",
                flexGrow: 1,
                maxWidth: 400,
                overflowY: "auto",
                ".MuiTreeItem-iconContainer": {
                    alignSelf: "flex-start",
                    marginTop: "7px",
                },
            }}
            expanded={selectedArray}
        >
            {data.children?.map((el) => (
                <TreeNode
                    key={el.id}
                    data={el}
                    path={[
                        location.pathname.split(
                            "/" + el.qualifier.toLowerCase()
                        )[0],
                        el.qualifier.toLowerCase(),
                        el.id,
                    ].join("/")}
                />
            ))}
            {data.queries?.map((el) => (
                <TreeNode
                    key={el.id}
                    data={{ ...el, qualifier: "QUERY" }}
                    path={[
                        location.pathname.split("/query")[0],
                        "query",
                        el.id,
                    ].join("/")}
                />
            ))}
        </TreeView>
    );
};

export default TreeExplorer;
