import { useLocation } from "react-router-dom";

import { subheaderWrapper } from "./NavBar.module.scss";
import useCurriculumQuery from "hooks/api/useCurriculumQuery";
import useCurriculumSelectedNodes from "hooks/useCurriculumSelectedNodes";
import variables from "styles/_variables.module.scss";

import BackButton from "./BackButton/BackButton";
import Breadcrumbs from "./Breadcrumbs/Breadcrumbs";

const CurriculumNavBar = () => {
    const location = useLocation();
    const { data: curriculumData } = useCurriculumQuery();
    const selectedCurriculumNodes = useCurriculumSelectedNodes();

    const newBreadcrumbs = [];
    newBreadcrumbs.push({
        label: "Home",
    });
    if (curriculumData) {
        let buildingPathname =
            location.pathname.split("/curriculum/")[0] +
            "/curriculum/" +
            curriculumData.id;
        newBreadcrumbs.push({
            label: curriculumData?.name,
            pathname: buildingPathname,
        });
        selectedCurriculumNodes.forEach((node) => {
            if (node.qualifier) {
                // cant do this for query/last node
                buildingPathname +=
                    "/" + node.qualifier.toLowerCase() + "/" + node.id;
            }
            newBreadcrumbs.push({
                label: node.name,
                pathname: buildingPathname,
            });
        });
    }

    return (
        <div className={subheaderWrapper}>
            <>
                <BackButton
                    styleOverrride={{
                        color: variables["text-colors-color-text-white"],
                        backgroundColor:
                            variables["color-background-light-gray"],
                    }}
                />
                <Breadcrumbs breadcrumbs={newBreadcrumbs} />
            </>
        </div>
    );
};

export default CurriculumNavBar;
