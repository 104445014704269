@use "../../../../../../styles/variables.module";

.cardContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 445px;
    height: 100%;
    background: white;
    border-radius: 15px;
    box-shadow: 0px 5px 20px rgba(108, 117, 125, 0.2);
    overflow: visible;
    cursor: pointer;
}

.innerCard {
    display: flex;
    flex-direction: column;
    border-radius: 15px 15px 0 0;
}

.cardBody {
    border-radius: 0 0 16px 16px;
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
}

.cardTitle {
    text-align: center;
}

[data-card-expanded="true"] .innerCard {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: calc(100% - 45px);
    overflow: auto;
}
