import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import {
    searchResultsDisplay,
    searchResultsContainer,
    readingLevelFilterContainer,
} from "./WebPageResults.module.scss";
import { useOrganicSearchQuery } from "hooks/api/useSearchQuery";
import { ReadingLevel, Result } from "types";
import { tracker } from "utils/analytics";

import SearchTotalResultDisplay from "./SearchTotalResultDisplay";
import Loading from "components/Loading/Loading";
import ReadingLevelFilter, {
    allReadingLevels,
    allReadingLevelsSelected,
} from "components/ReadingLevelFilter/ReadingLevelFilter";
import PaginationControls from "components/PaginationControls/PaginationControls";
import Translate from "components/Translate/Translate";
import ResultCard from "features/QueryResults/ResultsGrid/ResultCard/ResultCard";

export const structureResult = (result) => {
    if (!result?.subResults) return { ...result, hasMoreResults: false };
    return {
        ...result.subResults[0],
        totalResults: result.totalResults,
        showAllQueryString: result.showAllQueryString,
        hasMoreResults: true,
    };
};

const WebPageResults = () => {
    const {
        data: searchResponse,
        status: searchResponseStatus,
        isFetching: isFetchingSearch,
    } = useOrganicSearchQuery();
    const [searchParams, setSearchParams] = useSearchParams();
    const queryParamsString = searchParams.get("queryParams");
    const queryParams = queryParamsString
        ? JSON.parse(queryParamsString)
        : null;
    const resultType = queryParams?.resultType;

    const cleanedResults = useMemo<Result[]>(
        () =>
            searchResponseStatus === "success" && searchResponse?.results
                ? searchResponse?.results?.map((result) =>
                      structureResult(result)
                  )
                : [],
        [searchResponse?.results, searchResponseStatus]
    );
    const [readingLevels, setReadingLevels] =
        useState<ReadingLevel[]>(allReadingLevels);
    const updateReadingLevels = (level: ReadingLevel) => {
        const newReadingLevel = [...readingLevels];
        if (allReadingLevelsSelected(readingLevels)) {
            tracker("Change reading level", { readingLevel: [level] });
            setReadingLevels([level]);
        } else {
            if (newReadingLevel.includes(level)) {
                newReadingLevel.splice(newReadingLevel.indexOf(level), 1);
            } else {
                newReadingLevel.push(level);
                newReadingLevel.sort();
            }
            if (newReadingLevel.length != 0) {
                tracker("Changed reading level", {
                    readingLevel: newReadingLevel,
                });
                setReadingLevels(newReadingLevel);
            } else {
                tracker("Changed reading level", {
                    readingLevel: ["1", "2", "3", "4", "5"],
                });
                setReadingLevels(allReadingLevels);
            }
        }
        const allParams = Object.fromEntries([...searchParams]);
        const updatedParams = {
            ...allParams,
            queryParams: JSON.stringify({
                ...queryParams,
                readabilityLevel: allReadingLevelsSelected(readingLevels)
                    ? level
                    : newReadingLevel.length != 0
                    ? newReadingLevel.join(",")
                    : allReadingLevels.join(","),
                startPage: 1,
            }),
        };
        setSearchParams(updatedParams);
    };
    if (isFetchingSearch) return <Loading />;
    const pagination = searchResponse?.pagination;
    return (
        <div className="resultContainer">
            <div className={searchResultsDisplay} data-result-type={resultType}>
                <SearchTotalResultDisplay
                    searchResults={pagination}
                    query={searchResponse?.query}
                />
                {resultType === "pages" && (
                    <div className={readingLevelFilterContainer}>
                        <Typography
                            variant="text-size-Base-regular"
                            color="$text-colors-color-text-gray.main"
                        >
                            <Translate text="Choose your reading level" />
                        </Typography>
                        <ReadingLevelFilter
                            readingLevels={readingLevels}
                            updateReadingLevels={updateReadingLevels}
                        />
                    </div>
                )}
            </div>
            <div className={searchResultsContainer}>
                <Grid container spacing={2}>
                    {cleanedResults.map((result, index) => (
                        <Grid
                            xl={3}
                            lg={4}
                            md={6}
                            xs={12}
                            key={result.id + "grid"}
                        >
                            <ResultCard
                                results={cleanedResults}
                                resultIndex={index}
                                type={resultType}
                                stripSummaryText={false}
                                isSearchResult={true}
                            />
                        </Grid>
                    ))}
                </Grid>
            </div>
            <PaginationControls
                pagination={pagination}
                resultType={resultType}
            />
        </div>
    );
};

export default WebPageResults;
