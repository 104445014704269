@use "../../../../styles/variables.module";

.selections {
    padding: 10px 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
    background: variables.$background-colors-color-background-white;
    border-radius: 15px;
    box-shadow: 0px 5px 20px rgba(108, 117, 125, 0.5);
    z-index: variables.$zindex-filter-input;
}

.selector {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    height: 40px;

    /* Background colors/$color-background-white */

    background: variables.$background-colors-color-background-white;
    /* Border colors/$color-border-primary */

    border: 1px solid variables.$border-colors-color-border-primary;
    border-radius: 5px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.optionItem {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;

    width: 98%;
    height: 40px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    border-bottom: 1px solid variables.$border-colors-color-border-light;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

input[type="checkbox"] {
    height: 20px;
    width: 20px;
    left: 0px;
    top: 0px;
    border-radius: 5px;

    box-sizing: border-box;

    /* Background colors/$color-background-white */

    background: variables.$background-colors-color-background-white;
    /* Border colors/$color-border-primary */

    border: 1px solid variables.$border-colors-color-border-primary;
    border-radius: 5px;
}

.defaultLabel {
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    display: flex;
    align-items: center;
    text-align: right;

    color: #535150;

    opacity: 0.3;

    /* Inside auto layout */

    flex: none;
    order: 2;
    flex-grow: 1;
}

.checkBoxLabel {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    display: flex;
    align-items: center;

    color: #505153;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 1;
    gap: 10px;
    padding: 3px;
}

.selectContainer {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 16px;
    gap: 10px;

    width: 210px;
    height: 36px;

    background: variables.$background-colors-color-background-white;
    border: 1px solid #3b657d;
    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.filterLabel {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    /* identical to box height, or 156% */

    /* Text colors/$color-text-gray */

    color: variables.$text-colors-color-text-gray;
}

.filterValue {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */

    display: flex;
    justify-content: space-between;

    /* Text colors/$color-text-dark */

    color: variables.$text-colors-color-text-dark;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 1;
    padding: 10px;
    width: 100%;
}

.valueText {
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.inputContainer {
    padding-bottom: 10px;
    max-width: 410px;
}

.selector[data-is-disabled="true"] {
    pointer-events: none;
    background-color: variables.$border-colors-color-border-primary;
}

/* Apply button */

.applyButtonContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.applyButton {
    gap: 10px;
    height: 30px;

    /* Background colors/$color-background-blue */

    background: variables.$background-colors-color-background-blue;
    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    color: variables.$text-colors-color-text-white;
    border-color: transparent;
    display: inline-block;
    margin-left: 5%;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
}

.clearButton {
    height: 30px;
    text-align: right;
    color: variables.$text-colors-color-text-blue;
    order: 1;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    display: flex;
    align-items: center;
    margin-right: 5%;
    cursor: pointer;
}

@media (min-width: 640px) {
    .inputContainer {
        padding-right: 16px;
    }
}
