import * as styles from "./ResultCardFooter.module.scss";

import SourceDescriptor from "./SourceDescriptor/SourceDescriptor";

const ResultCardFooter = (
    props: React.ComponentProps<typeof SourceDescriptor>
) => {
    return (
        <footer className={styles.resultsFooter}>
            <SourceDescriptor {...props} />
        </footer>
    );
};

export default ResultCardFooter;
