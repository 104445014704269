import { AxiosError } from "axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { PasswordConfirmData, UserWithOrders } from "types";
import { axiosAPI } from "./axiosAPI";
import { isShareMode } from "utils/newtools";

export const userProfileURL = "/user/me";
interface ProfileData {
    firstName: string | null;
    lastName: string | null;
    region: string;
    companyName: string;
}

export const useUserPasswordUpdateMutation = () => {
    return useMutation(async (newData: PasswordConfirmData) => {
        return await axiosAPI.getInstance()?.put(`/user/password`, newData);
    });
};

export const useUserUpdateMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(async (newData: ProfileData) => {
        const response = await axiosAPI
            .getInstance()
            ?.put(`/user/me/profile`, newData);

        if (response?.data) {
            queryClient.setQueryData(["user-me"], response.data);
        }
    });
};

const useUserQuery = (isDisabled?: boolean) => {
    const queryClient = useQueryClient();
    const isEnabled =
        !isDisabled && !isShareMode && !!queryClient.getQueryData(["user-me"]);

    return useQuery<UserWithOrders, AxiosError>(
        ["user-me"],
        async () => {
            const { data } = await axiosAPI.getInstance().get(userProfileURL);
            return data;
        },
        {
            enabled: isEnabled,
            cacheTime: Infinity,
            staleTime: Infinity,
            keepPreviousData: true,
        }
    );
};

export default useUserQuery;
