import useLTIDeepLinkSubmit from "hooks/api/useLTISubmitLink";
import { DeepLink } from "types";
import LTIFormRedirector from "./LTIFormRedirector";

const LTIRender = ({ deeplink }: { deeplink: DeepLink }) => {
    const { data } = useLTIDeepLinkSubmit(deeplink);
    if (!data) return null;

    return <LTIFormRedirector htmlString={data} />;
};

export default LTIRender;
