@use "../../../../styles/variables.module";

.mainContainer {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.headerContainer {
    width: 100%;
    height: 71px;
    padding: 15px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: variables.$background-colors-color-background-light-off;
    border-bottom: 1px solid variables.$border-colors-color-border-primary;
    @media (max-width: variables.$bp-lg) {
        padding: 15px 20px;
    }
    @media (max-width: variables.$bp-md) {
        padding: 15px 10px;
    }
}

.logoContainer {
    height: 40px;
}

.buttonsContainer {
    display: flex;
    align-items: center;
    gap: 20px;
}

.contentContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    overflow-y: auto;
}

.childrenContainer {
    padding: 40px;
    width: 520px;
    min-width: 520px;
    height: fit-content;
    min-height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    background: variables.$background-colors-color-background-white;
    @media (max-width: variables.$bp-lg) {
        padding: 20px;
        width: 466px;
        min-width: 466px;
    }
    @media (max-width: variables.$bp-md) {
        padding: 20px 10px;
        width: 100%;
        min-width: 100%;
    }
}

.backgroundContainer {
    position: sticky;
    top: 0;
    padding: 0;
    // padding-top: 40px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    background-image: url(./assets/laptop-classroom.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.backgroundContainerGradient {
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.42) 100%
    );
}

.footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 40px;
    @media screen and (max-width: variables.$bp-max) {
        padding-bottom: 60px;
    }
}
