@use "../../styles/variables.module";

.container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-width: 128px;
}

.text {
    font-size: 10px;
    line-height: 12px;
    color: variables.$text-colors-color-text-gray;
}

.url {
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    color: variables.$text-colors-color-text-blue;
    text-decoration: underline;
    cursor: pointer;
}
