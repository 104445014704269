@use "../../../styles/variables.module";

.mainContainer {
    width: 900px;
    box-shadow: 0px 20px 30px
        variables.$background-colors-color-background-overlay;
    border-radius: 10px;
    background-color: variables.$background-colors-color-background-white;
    display: flex;
    flex-direction: column;
}

.headerContainer {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: variables.$text-colors-color-text-gray;
    border-bottom: 1px solid variables.$border-colors-color-border-primary;
    background: variables.$background-colors-color-background-light-off;
}

.tabsContainer {
    display: flex;
    flex-direction: column;
    padding: 30px 40px 10px;
}

.shareContainer {
    display: flex;
    gap: 20px;
}

.textContainer {
    padding: 16px 20px 24px;
    max-height: 120px;
    background: variables.$background-colors-color-background-light;
    border: 1px solid variables.$border-colors-color-border-light;
    border-radius: 5px;
    overflow-y: scroll;
}

.text {
    width: 100%;
    font-family: monospace;
    font-size: 14px;
    line-height: 24px;
    color: variables.$text-colors-color-text-gray;
    word-wrap: break-word;
}

.iframeContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.iframeTitle {
    padding-bottom: 5px;
    font-size: 16px;
    font-weight: 700;
    line-height: 25px;
    color: variables.$text-colors-color-text-dark;
}

.sizeContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 115px;
}

.styleLabel {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    color: variables.$text-colors-color-text-dark;
}

.sizeInput {
    padding: 5px;
    width: 50px;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    text-align: center;
    color: variables.$text-colors-color-text-dark;
    border: 1px solid variables.$border-colors-color-border-primary;
    border-radius: 4px;
}

.sizeInput::-webkit-inner-spin-button {
    appearance: none;
}

.previewButton {
    padding: 0px;
    align-self: flex-start;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-decoration: underline;
}

.copyContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-top: 1px solid variables.$border-colors-color-border-primary;
}

.copyText {
    padding-left: 10px;
    font-weight: 700;
    font-size: 14px;
}

.copyCorrect {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: variables.$message-colors-color-message-success;
}

.copyButton {
    padding: 10px 20px;
    font-size: 16px;
}

@media (max-width: variables.$bp-lg) {
    .mainContainer {
        width: 500px;
    }
    .headerContainer {
        padding: 10px 20px;
    }
    .tabsContainer {
        padding: 30px 20px 10px;
    }
    .textContainer {
        max-height: 210px;
    }
    .copyContainer {
        padding: 20px 20px;
    }
}

@media (max-width: variables.$bp-md) {
    .mainContainer {
        width: 320px;
    }
    .headerContainer {
        padding: 7.5px 15px;
    }
    .headerTitle {
        font-size: 18px;
        line-height: 25px;
    }
    .headerCloseButton {
        padding: 0px;
    }
    .tabsContainer {
        padding: 30px 15px 10px;
    }
    .shareContainer {
        flex-direction: column;
    }
    .textContainer {
        max-height: 230px;
    }
    .iframeContainer {
        padding-bottom: 10px;
    }
    .copyContainer {
        padding: 15px 15px;
        flex-direction: column;
        align-items: baseline;
        justify-content: space-between;
    }
    .copyCorrect {
        margin-bottom: 10px;
    }
    .copyButton {
        width: 100%;
    }
}
