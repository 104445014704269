import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { Result } from "types";

import CollectionListResultCard from "components/CollectionViewer/components/CollectionListView/components/CollectionListResultCard";

interface Props {
    id: number;
    result: Result;
    results: Result[];
    resultIndex: number;
    deleteItemHandler: () => void;
    isHighlighted: boolean;
}

const SortableListResult = ({
    id,
    result,
    results,
    resultIndex,
    deleteItemHandler,
    isHighlighted,
}: Props) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({ id: id });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        touchAction: "none",
    };
    return (
        <div ref={setNodeRef} style={style}>
            <CollectionListResultCard
                result={result}
                results={results}
                resultIndex={resultIndex}
                deleteItemHandler={deleteItemHandler}
                isHighlighted={isHighlighted}
                draggable={{ attributes: attributes, listeners: listeners }}
            />
        </div>
    );
};

export default SortableListResult;
