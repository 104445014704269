import { useState } from "react";
import { Box, TextField } from "@mui/material";

import { useMessagingContext } from "contexts/MessagingContext";
import useCollectionsParams from "hooks/useCollectionsParams";
import { useEditFolderMutation } from "hooks/api/folders";
import { Folder } from "types";
import { tracker } from "utils/analytics";

import DialogBox from "components/DialogBox/DialogBox";
import Translate from "components/Translate/Translate";

const EditFolderDialog = ({
    folder,
    isVisible,
    setIsVisible,
}: {
    folder: Folder;
    isVisible: boolean;
    setIsVisible: (arg0: boolean) => void;
}) => {
    const { openAlertMessage } = useMessagingContext();
    const collectionsParams = useCollectionsParams();
    const editFolderMutation = useEditFolderMutation();
    const [folderName, setFolderName] = useState(folder.title);
    const editFolderHandler = () => {
        if (collectionsParams.length === 0) return;

        editFolderMutation.mutate(
            {
                ...folder,
                title: folderName,
            },
            {
                onSuccess: () => {
                    openAlertMessage({
                        message: "Folder renamed successfully.",
                        type: "success",
                        open: true,
                    });
                    tracker("Created folder");
                    setFolderName("");
                    setIsVisible(false);
                },
                onError: (error) => {
                    openAlertMessage({
                        message: "Error",
                        type: "error",
                        open: true,
                    });
                    console.error(error);
                    setFolderName("");
                    setIsVisible(false);
                },
            }
        );
        return;
    };
    return (
        <DialogBox
            isOpen={isVisible}
            closeHandler={() => setIsVisible(false)}
            title={
                <>
                    <Translate text="Rename " />
                    &quot;{folder.title}&quot;
                    <Translate text=" folder" />
                </>
            }
            withCloseButton
            styleOverrides={{ width: "435px", height: "unset" }}
            secondaryButton={{
                label: "Cancel",
                clickHandler: () => setIsVisible(false),
            }}
            primaryButton={{
                label: "Save",
                clickHandler: editFolderHandler,
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    paddingTop: "30px",
                    paddingBottom: "30px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    backgroundColor:
                        "$background-colors-color-background-light.main",
                }}
            >
                <TextField
                    label={<Translate text="Insert new name" />}
                    placeholder="Insert new name"
                    style={{
                        width: "100%",
                        backgroundColor: "white",
                    }}
                    autoFocus
                    value={folderName}
                    onChange={(e) => setFolderName(e.target.value)}
                />
            </Box>
        </DialogBox>
    );
};

export default EditFolderDialog;
