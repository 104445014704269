@use "../../../../styles/variables.module";

.autocompleteMenu {
    color: #323335;
    overflow-y: auto;
    z-index: variables.$zindex-search-autocomplete;
    position: relative;
    transform: translateZ(0);
}

.listButton {
    width: 100%;
    padding: 10px 20px;
    font-size: 16px;
    line-height: 25px;
    text-align: left;
    color: variables.$text-colors-color-text-blue;
    background-color: transparent;
    border: none;
    border-top: 1px solid variables.$border-colors-color-border-primary;
}

.listButton:hover {
    outline: none;
    background: rgba(variables.$background-colors-color-background-blue, 0.1);
}

.listButton[data-focus="true"] {
    border-left: 4px solid #504691;
}
