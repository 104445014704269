import { useCallback, useEffect, useState } from "react";
import {
    useBlocker,
    useBeforeUnload,
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";

import {
    CollectionContext,
    NewCollection,
    organizeArrayBToMatchArrayA,
} from "./state/CollectionContext";
import { translate } from "components/Translate/Translate";
import { useAppContext } from "contexts/AppContext";
import { useMessagingContext } from "contexts/MessagingContext";
import { useResultModalContext } from "contexts/ResultModalContext";
import {
    useAddItemToCollectionMutation,
    useAddTagToCollectionMutation,
    useCollectionQuery,
    useCreateCollectionMutation,
    useItemsInCollectionQuery,
    useCreateTagMutation,
    useDeleteItemInCollectionMutation,
    useDeleteTagFromCollectionMutation,
    useDeleteCollectionMutation,
    useUpdateCollectionMutation,
    useUpdateOrderOfItemsMutation,
} from "hooks/api/collections";
import useScreenSizes from "hooks/useScreenSizes";
import {
    Collection,
    CollectionChangeQueue,
    Item,
    Mutable,
    Tag,
    UnsavedCollection,
} from "types";
import { tracker } from "utils/analytics";
import { objectsAreEqual } from "utils/newtools";

import CollectionDetailsPanel from "./DetailsPanel/CollectionDetailsPanel";
import EditableTitle from "./EditableTitle/EditableTitle";
import QuizPanel from "./QuizPanel/QuizPanel";
import ResourcesContainer from "./ResourcesContainer/ResourcesContainer";
import QuizEditor from "./Subheader/GenerateQuizButton/QuizEditor/QuizEditor";
import Subheader from "./Subheader/Subheader";
import Loading from "components/Loading/Loading";
import CollectionNavBar from "components/NavBars/CollectionNavBar";

const CollectionEditor = () => {
    const { isDesktopScreen, isMobileScreen } = useScreenSizes();
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [localStateReset, setLocalStateReset] = useState(location.state);
    const { openAlertMessage, openMessageModal } = useMessagingContext();
    const { config } = useAppContext();
    const collectionId = params.collectionId as string;
    const { data: collectionData, status: collectionStatus } =
        useCollectionQuery(collectionId);
    const { data: collectionItemsData, status: collectionItemsStatus } =
        useItemsInCollectionQuery(collectionId);
    const [isInitialised, setIsInitialised] = useState(!collectionId);
    const [isSaving, setIsSaving] = useState(false);
    const { setIsResultModalEnabled } = useResultModalContext();

    useEffect(() => {
        // Set loaded data when in Edit Mode, rather than Create New Mode
        if (isInitialised) return;
        if (
            collectionStatus === "success" &&
            collectionItemsStatus === "success"
        ) {
            setLocalCollection(collectionData);
            setSelectedItems(collectionItemsData);
            setIsInitialised(true);
            setIsResultModalEnabled(collectionData.resultModal);
        }
    }, [
        collectionData,
        collectionItemsData,
        collectionItemsStatus,
        collectionStatus,
        isInitialised,
        setIsResultModalEnabled,
    ]);

    const createCollectionMutation = useCreateCollectionMutation();
    const updateCollectionMutation = useUpdateCollectionMutation();
    const addItemToCollectionMutation = useAddItemToCollectionMutation();
    const deleteItemInCollectionMutation = useDeleteItemInCollectionMutation();
    const updateOrderOfItemsMutation = useUpdateOrderOfItemsMutation();
    const createTagMutation = useCreateTagMutation();
    const addTagToCollectionMutation = useAddTagToCollectionMutation();
    const deleteTagFromCollectionMutation =
        useDeleteTagFromCollectionMutation();
    const [selectedItems, setSelectedItems] = useState<Array<Item>>([]);
    const [highlightedItems, setHighlightedItems] = useState<Array<number>>([]);
    const deleteCollectionMutation = useDeleteCollectionMutation();

    const [changeQueue, setChangeQueue] = useState<CollectionChangeQueue>([]);

    const [localCollection, setLocalCollection] = useState<
        UnsavedCollection | Collection
    >({
        ...NewCollection,
        title: translate(config.language, "Insert collection title"),
        language: config.language,
        resultModal: config.resultModal,
    });
    const updateCollection = (key: keyof Collection, value: unknown) => {
        tracker("Collection Updated", { property: key });
        setLocalCollection({ ...localCollection, [key]: value });
        if (key === "title" && !value) return;
        setChangeQueue([
            ...changeQueue,
            {
                updateType: "update-collection",
                updateFunction: async (
                    latestCollection: Collection,
                    _latestItems: Item[],
                ) => {
                    return await updateCollectionMutation.mutateAsync({
                        collection: {
                            ...latestCollection,
                            language: config.language,
                            [key]: value,
                        },
                        collectionsParams: location.state?.collectionsParams,
                    });
                },
            },
        ]);
    };

    const addItem = (item: Item) => {
        tracker("Add Item to Collection", item);
        openAlertMessage({
            message: "Resource has been added to the collection.",
            open: true,
            type: "success",
        });
        setSelectedItems((oldSelectedItems) => [item, ...oldSelectedItems]);
        setHighlightedItems((oldHighlightedItems) => [
            item.id,
            ...oldHighlightedItems,
        ]);
        setTimeout(() => {
            removeHighlightedItem(item);
        }, 5000);
        setChangeQueue((oldChangeQueue) => [
            ...oldChangeQueue,
            {
                updateType: "add-item",
                updateFunction: async (
                    latestCollection: Collection,
                    _latestItems: Item[],
                ) => {
                    return await addItemToCollectionMutation.mutateAsync({
                        item,
                        collectionId: latestCollection.id,
                    });
                },
            },
        ]);
    };

    const removeHighlightedItem = (item: Item) => {
        setHighlightedItems((oldHighlightedItems) =>
            oldHighlightedItems.filter((id) => id !== item.id),
        );
    };

    const removeItem = (item: Item) => {
        tracker("Remove Item from Collection", item);
        setSelectedItems(
            selectedItems.filter((el) =>
                item.id
                    ? el.id !== item.id
                    : item.indexId
                      ? el.indexId !== item.indexId
                      : el.url !== item.url,
            ),
        );
        setChangeQueue([
            ...changeQueue,
            {
                updateType: "delete-item",
                updateFunction: async (
                    latestCollection: Collection,
                    latestItems: Item[],
                ) => {
                    const foundItem = latestItems.find(
                        (el) =>
                            el.id === item?.id ||
                            el.indexId === item?.indexId ||
                            el.url === item?.url,
                    );

                    return await deleteItemInCollectionMutation.mutateAsync({
                        itemId: foundItem?.id as number,
                        collectionId: latestCollection.id,
                    });
                },
            },
        ]);
    };

    const reorderItems = (items: Array<Item>) => {
        tracker("Reorder Items in Collection");
        setSelectedItems(items);
        setChangeQueue([
            ...changeQueue,
            {
                updateType: "reorder-items",
                updateFunction: async (
                    latestCollection: Collection,
                    latestItems: Item[],
                ) => {
                    const newlyOrderedItems = organizeArrayBToMatchArrayA(
                        items,
                        latestItems,
                        true,
                    ).map((item, index) => ({
                        id: item.id.toString(),
                        item_order: index,
                    }));
                    return await updateOrderOfItemsMutation.mutateAsync({
                        items: newlyOrderedItems,
                        collectionId: latestCollection.id,
                    });
                },
            },
        ]);
    };

    const addTag = async (value: string) => {
        if (!value) return;
        tracker("Add Tag to Collection", { tagValue: value });
        setLocalCollection({
            ...localCollection,
            tags: [...localCollection.tags, { id: 0, value }],
        });
        setChangeQueue([
            ...changeQueue,
            {
                updateType: "add-tag",
                updateFunction: async (
                    latestCollection: Collection,
                    _latestItems: Item[],
                ) => {
                    const createStepResult =
                        await createTagMutation.mutateAsync({
                            tag: value,
                        });
                    if (createStepResult?.status === 200) {
                        const result =
                            await addTagToCollectionMutation.mutateAsync({
                                collectionId: latestCollection.id,
                                tagId: createStepResult.data.id,
                            });
                        return result;
                    } else {
                        return createStepResult;
                    }
                },
            },
        ]);
    };

    const removeTag = (value: string) => {
        tracker("Remove Tag from Collection");
        setLocalCollection({
            ...localCollection,
            tags: localCollection.tags.filter((el) => el.value !== value),
        });
        setChangeQueue([
            ...changeQueue,
            {
                updateType: "remove-tag",
                updateFunction: async (latestCollection: Collection) => {
                    const foundTag = latestCollection.tags.find(
                        (el) => el.value.toLowerCase() === value.toLowerCase(),
                    );
                    return await deleteTagFromCollectionMutation.mutateAsync({
                        tagId: foundTag?.id as number,
                        collectionId: latestCollection.id,
                    });
                },
            },
        ]);
    };

    const changesProcessor = useCallback(async () => {
        if (changeQueue.length === 0) return;
        if (isSaving) return; // Already saving
        tracker("Saving Collection");
        setIsSaving(true);
        let saveCollection =
            localCollection.id === 0
                ? ({ ...NewCollection } as Mutable<Collection>)
                : collectionData
                  ? ({ ...collectionData } as Mutable<Collection>)
                  : (localCollection as Mutable<Collection>);
        let saveItems = Array.isArray(collectionItemsData)
            ? [...collectionItemsData]
            : ([] as Mutable<Item[]>);
        const saveChangeQueue = [...changeQueue];
        if (!localCollection.title) {
            setIsSaving(false);
            return openAlertMessage({
                message: "Failed to create collection.",
                open: true,
                type: "error",
            });
        }
        if (saveCollection.id === 0) {
            const result = await createCollectionMutation.mutateAsync({
                collectionsParams: location.state?.collectionsParams,
                title: localCollection.title,
                language: config.language,
            });
            if (result?.status === 200) {
                saveCollection = result.data;
            } else {
                openAlertMessage({
                    message: "Failed to create collection.",
                    open: true,
                    type: "error",
                });
                console.error("Failed to create collection");
                return;
            }
        }
        while (saveChangeQueue.length > 0) {
            const result = await saveChangeQueue[0].updateFunction(
                saveCollection,
                saveItems,
            );
            switch (saveChangeQueue[0].updateType) {
                case "update-collection": {
                    saveCollection = result.data as Collection;
                    break;
                }
                case "add-item": {
                    const addedItem = result.data as Item;
                    saveItems.unshift(addedItem);
                    break;
                }
                case "delete-item": {
                    const removedItem = result.data as Item;
                    saveItems = saveItems.filter(
                        (el) => el.id !== removedItem.id,
                    );
                    break;
                }
                case "reorder-items": {
                    const newItemOrder = result.data as Array<Item>;
                    const reorderedItems = organizeArrayBToMatchArrayA(
                        newItemOrder,
                        saveItems,
                        false,
                    );
                    saveItems = reorderedItems;
                    break;
                }
                case "add-tag": {
                    const addedTag = result.data as Tag;
                    const filteredTags = saveCollection.tags.filter(
                        (tag) =>
                            tag.value.toLowerCase() !==
                            addedTag.value.toLowerCase(),
                    );
                    saveCollection.tags = [...filteredTags, addedTag];
                    break;
                }
                case "remove-tag": {
                    const removedTag = result.data as Tag;
                    const newTags = saveCollection.tags.filter(
                        (el) => el.id !== removedTag.id,
                    );
                    saveCollection.tags = newTags;
                    break;
                }
            }
            saveChangeQueue.shift();
        }
        if (saveChangeQueue.length === 0) {
            // Update local states
            setLocalCollection(saveCollection);
            setSelectedItems(saveItems);
            openAlertMessage({
                message: "Collection has been saved.",
                open: true,
                type: "success",
            });
            tracker("Collection Saved Successfully", {
                collectionId: saveCollection.id,
            });
        } else {
            throw new Error("Failed to process change queue");
        }
        setIsSaving(false);
        setChangeQueue(saveChangeQueue); // empty or remaining items
    }, [
        changeQueue,
        collectionData,
        collectionItemsData,
        config.language,
        createCollectionMutation,
        isSaving,
        localCollection,
        location.state?.collectionsParams,
        openAlertMessage,
    ]);

    const deleteHandler = async () => {
        tracker("Deleting Collection");
        const collectionId = localCollection.id;
        if (collectionId !== 0) {
            const result = await deleteCollectionMutation.mutateAsync({
                collectionId,
                collectionsParams: location.state?.collectionsParams,
            });
            if (result?.status === 200) {
                // wait for the alert to close and onclosing the alert.
                const navIds = location?.state?.collectionsParams
                    ? location?.state?.collectionsParams
                    : [];
                navigate(["/my-collections", ...navIds].join("/"));
            } else {
                console.error("Failed to delete collection");
                return;
            }
        }
    };

    const [isDisplayingUnloadMessage, setIsDisplayingUnloadMessage] =
        useState<boolean>(false);
    const unloadHandlerRouter = useCallback(
        ({
            currentLocation,
            nextLocation,
        }: {
            currentLocation?: { pathname: string; state?: string };
            nextLocation?: { pathname: string; state?: string };
        }) => {
            if (changeQueue.length === 0) return false;
            if (!currentLocation || !nextLocation) return false;
            if (
                currentLocation.state &&
                currentLocation.state === nextLocation.state
            )
                return false;
            return true;
        },
        [changeQueue.length],
    );

    const blocker = useBlocker(unloadHandlerRouter);
    if (!isDisplayingUnloadMessage && blocker.state === "blocked") {
        openMessageModal({
            isModalVisible: true,
            title: "Collection is not saved",
            body: "Your work will be lost if you navigate away from this page. Are you sure you want to discard the changes?",
            type: "Yes, leave the page",
            primaryActionHandler: blocker.proceed,
            secondaryButtonTextOverride: "Keep editing",
            secondaryActionHandler: () => {
                blocker.reset();
                setIsDisplayingUnloadMessage(false);
            },
        });
        setIsDisplayingUnloadMessage(true); // This shouldn't be here, but in a useEffect of similar. Works for now, but does warn in the console.
    }
    const unloadHandlerBrowser = useCallback(
        (e: BeforeUnloadEvent) => {
            if (changeQueue.length === 0) return false;
            tracker("Collection Editor Prevent Unsaved Browser Navigation");
            e.preventDefault();
        },
        [changeQueue.length],
    );
    useBeforeUnload(unloadHandlerBrowser);

    useEffect(() => {
        if (location.pathname !== "/create-collection") return;

        if (localStateReset !== location.state) {
            // This is an onload check which looks for any state on the location object. This indicates an 'add collection' button click.
            // As this may be from the edit-collection path, we have to do a manual reset of states
            setLocalStateReset(location.state);
            setLocalCollection({
                ...NewCollection,
                title: translate(config.language, "Insert collection title"),
                language: config.language,
                resultModal: config.resultModal,
            });
            setSelectedItems([]);
            setChangeQueue([]);
            setIsDisplayingUnloadMessage(false);
            setIsSaving(false);
            setIsInitialised(false);
        } else if (
            changeQueue.length === 0 &&
            localCollection.id !== 0 &&
            blocker.state === "unblocked"
        ) {
            setTimeout(
                () =>
                    navigate(`/edit-collection/${localCollection.id}`, {
                        state: location.state,
                        replace: true,
                    }),
                200, // Timeout to stop the blocker from blocking in some cases
            );
        }
    }, [
        blocker.state,
        changeQueue.length,
        config.language,
        config.resultModal,
        localCollection.id,
        localStateReset,
        location.pathname,
        location.state,
        navigate,
    ]);

    useEffect(() => {
        if (changeQueue.length > 0) return;
        if (localCollection.id === 0 || !collectionData) return;
        if (!objectsAreEqual(localCollection, collectionData, ["quiz"])) return;
        if (objectsAreEqual(localCollection?.quiz, collectionData?.quiz))
            return;
        setLocalCollection(collectionData);
    }, [changeQueue.length, collectionData, localCollection]);

    const [isQuizEditorOpen, setIsQuizEditorOpen] = useState(
        location.state?.openQuizEditor ?? false,
    );

    return (
        <CollectionContext.Provider
            value={{
                collection: localCollection,
                updateCollection,
                selectedItems,
                highlightedItems,
                addItem,
                removeItem,
                reorderItems,
                addTag,
                removeTag,
                changeQueue,
                saveHandler: changesProcessor,
                isSaving,
                deleteHandler,
            }}
        >
            <CollectionNavBar
                pageTitle={localCollection.title}
                previousCrumbs={[
                    {
                        label: translate(config.language, "Editor"),
                        pathname: location.pathname,
                    },
                ]}
            />
            {collectionId && localCollection.id.toString() !== collectionId ? (
                <Loading />
            ) : (
                <>
                    <Subheader setIsQuizEditorOpen={setIsQuizEditorOpen} />
                    <EditableTitle
                        title={localCollection.title}
                        titleChangeHandler={(value) =>
                            updateCollection("title", value)
                        }
                        editDisabled={
                            !!(collectionId && collectionStatus !== "success")
                        }
                    />
                    <div
                        style={{
                            padding: isMobileScreen ? "20px 10px" : "20px 40px",
                            marginBottom: 10,
                        }}
                    >
                        <Grid container spacing={"20px"}>
                            {isDesktopScreen && (
                                <Grid lg={3} xs={0}>
                                    <CollectionDetailsPanel />
                                </Grid>
                            )}
                            {!isDesktopScreen && collectionData && (
                                <Grid xs={12}>
                                    <QuizPanel
                                        collection={collectionData}
                                        editClickHandler={() =>
                                            setIsQuizEditorOpen(true)
                                        }
                                    />
                                </Grid>
                            )}
                            <Grid lg={6} xs={12}>
                                <ResourcesContainer />
                            </Grid>
                            {isDesktopScreen && collectionData && (
                                <Grid lg={3}>
                                    <QuizPanel
                                        collection={collectionData}
                                        editClickHandler={() =>
                                            setIsQuizEditorOpen(true)
                                        }
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </div>
                    <QuizEditor
                        isEditorOpen={isQuizEditorOpen}
                        setIsEditorOpen={setIsQuizEditorOpen}
                    />
                </>
            )}
        </CollectionContext.Provider>
    );
};

export default CollectionEditor;
