import { CSSProperties } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";

import { menuButton } from "./MenuButton.module.scss";

interface Props {
    dataTestId?: string;
    faIcon: IconDefinition;
    iconColor: string;
    primaryColor: string;
    secondaryColor: string;
    isOpen: boolean;
    onClick: () => void;
    isHidden?: boolean;
    styleOverrides?: CSSProperties;
}

export const MenuButton = ({
    dataTestId,
    faIcon,
    iconColor,
    primaryColor,
    secondaryColor,
    isOpen,
    onClick,
    isHidden,
    styleOverrides,
}: Props) => {
    return (
        <button
            data-testid={dataTestId}
            className={menuButton}
            style={{
                color: iconColor,
                background: !isOpen ? primaryColor : secondaryColor,
                borderColor: !isOpen ? secondaryColor : iconColor,
                display: isHidden ? "none" : "flex",
                ...styleOverrides,
            }}
            onClick={onClick}
        >
            <FontAwesomeIcon icon={faIcon} />
        </button>
    );
};

export default MenuButton;
