import { useNavigate, useSearchParams } from "react-router-dom";
import { Typography } from "@mui/material";
import {
    faArrowRightToBracket,
    faUserPlus,
} from "@fortawesome/free-solid-svg-icons";

import * as styles from "./PublicPageContainer.module.scss";
import useScreenSizes from "hooks/useScreenSizes";

import Footer from "./components/Footer/Footer";
import HeaderButton from "./components/HeaderButton/HeaderButton";
import LanguageControl from "./components/LanguageControl/LanguageControl";
import Logo from "components/Logo/Logo";
import Translate from "components/Translate/Translate";

interface Props {
    page:
        | "login"
        | "signup"
        | "verifyEmail"
        | "forgotPassword"
        | "passwordReset";
    children: JSX.Element | Array<JSX.Element>;
}

const PublicPageContainer = ({ page, children }: Props) => {
    const { isMobileScreen } = useScreenSizes();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    return (
        <div className={styles.mainContainer}>
            <div className={styles.headerContainer}>
                <div
                    className={styles.logoContainer}
                    onClick={() =>
                        navigate(
                            {
                                pathname: "/login",
                                search: searchParams.toString(),
                            },
                            { relative: "path" },
                        )
                    }
                >
                    <Logo />
                </div>
                <div className={styles.buttonsContainer}>
                    {page === "login" && (
                        <HeaderButton
                            faIcon={faUserPlus}
                            text="Create account"
                            clickHandler={() =>
                                navigate(
                                    {
                                        pathname: "/signup",
                                        search: searchParams.toString(),
                                    },
                                    { relative: "path" },
                                )
                            }
                        />
                    )}
                    {page === "signup" && (
                        <HeaderButton
                            faIcon={faArrowRightToBracket}
                            text="Login"
                            clickHandler={() =>
                                navigate(
                                    {
                                        pathname: "/login",
                                        search: searchParams.toString(),
                                    },
                                    { relative: "path" },
                                )
                            }
                        />
                    )}
                    <LanguageControl />
                </div>
            </div>
            <div className={styles.contentContainer}>
                <div
                    style={{
                        width: "100%",
                        maxWidth: "1440px",
                        minHeight: "100%",
                        display: "flex",
                        boxShadow: "0px 0px 30px 0px rgba(52, 58, 64, 0.20)",
                        overflowY: "auto",
                    }}
                >
                    <div className={styles.childrenContainer}>
                        {children}
                        <Footer />
                    </div>
                    {!isMobileScreen && (
                        <div className={styles.backgroundContainer}>
                            <div className={styles.backgroundContainerGradient}>
                                <div className={styles.footer}>
                                    <Typography
                                        variant="text-size-Small"
                                        color="$background-colors-color-background-light-blue.main"
                                        style={{ textTransform: "uppercase" }}
                                        component="div"
                                    >
                                        <Translate text="Unlock the future of education" />
                                    </Typography>
                                    <Typography
                                        variant="text-size-xLarge"
                                        color="$text-colors-color-text-white.main"
                                        component="div"
                                    >
                                        <Translate text="Advanced tools and curated educational resources for enhanced teaching and enriched learning experiences" />
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PublicPageContainer;
