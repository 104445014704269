import { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faMagnifyingGlass,
    faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";

import {
    desktopContainer,
    searchForm,
    searchButton,
    baseContainer,
} from "./SearchInput.module.scss";
import variables from "styles/_variables.module.scss";
import { useAppContext } from "contexts/AppContext";

import Autocomplete from "./Autocomplete/CollectionSearchAutocomplete";
import { translate } from "components/Translate/Translate";

interface Props {
    displayTerm: string;
    changeHandler: (arg0: string) => void;
    submitHandler: (arg0: string) => void;
    resetTerm: () => void;
}

const NewSearchInput = ({
    displayTerm,
    changeHandler,
    submitHandler,
    resetTerm,
}: Props) => {
    const {
        config: { language },
    } = useAppContext();
    const [showAutoComplete, setShowAutoComplete] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const closeSearch = () => {
        setShowAutoComplete(false);
    };
    const handleChange = (e: React.KeyboardEvent) => {
        e.preventDefault();
        e.code !== "Enter"
            ? setShowAutoComplete(true)
            : setShowAutoComplete(false);
    };
    return (
        <div
            data-testid="baseContainer"
            className={baseContainer}
            style={{ width: "100%" }}
        >
            <div
                data-testid="collection-search-input"
                className={desktopContainer}
            >
                <form
                    className={searchForm}
                    style={{ zIndex: "unset" }}
                    onSubmit={(e) => {
                        e.preventDefault();
                        submitHandler(displayTerm);
                    }}
                >
                    <input
                        data-testid="search-input"
                        ref={inputRef}
                        name="search"
                        autoComplete="off"
                        onKeyUp={handleChange}
                        placeholder={translate(language, "Search")}
                        onChange={(e) => changeHandler(e.target.value)}
                        value={displayTerm}
                        onBlur={closeSearch}
                    />
                    {displayTerm != null && displayTerm.length > 0 && (
                        <div
                            style={{
                                color: variables["text-colors-color-text-gray"],
                                width: 40,
                                borderRight: "2px",
                                borderColor:
                                    variables[
                                        "border-colors-color-border-light"
                                    ],
                            }}
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={resetTerm}
                        >
                            <FontAwesomeIcon icon={faCircleXmark} />
                        </div>
                    )}
                    <button
                        className={searchButton}
                        aria-label={translate(language, "Search icon")}
                    >
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </button>
                </form>
                {showAutoComplete && (
                    <Autocomplete
                        searchTerm={displayTerm}
                        updateSearchTerm={submitHandler}
                        resetAutoCompleteDisplay={() => {
                            setShowAutoComplete(false);
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default NewSearchInput;
