@use "../../../../../../../../styles/variables.module";

.container {
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    align-self: stretch;
    border-radius: 15px;
    background: variables.$background-colors-color-background-white;
    box-shadow:
        0px 4px 8px 0px rgba(0, 0, 0, 0.08),
        0px 1px 2px 0px rgba(108, 117, 125, 0.24);
}

.title {
    position: relative;
    padding-bottom: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    border-bottom: 1px solid variables.$border-colors-color-border-primary;
}

.buttonsContainer {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    gap: 5px;
}

.saveButton {
    height: 25px;
    padding: 5px 10px;
    border-radius: 5px;
}

.cancelButton {
    height: 25px;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid variables.$border-colors-color-border-blue;
}

.questionContainer {
    margin-top: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    align-self: stretch;
    border-radius: 15px;
    background: variables.$background-colors-color-background-light-off;
}

.typeButtonsContainer {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    align-self: stretch;
}

.typeButton {
    height: 30px;
    padding: 10px 20px;
    background: variables.$background-colors-color-background-light-blue;
}
