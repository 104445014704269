import {
    EmbedLibrary,
    EmbedQuery,
    EmbedResults,
    EmbedTopic,
    EmbedType,
} from "./share";

// Type guards
export const isEmbedLibrary = (
    embed: EmbedType | undefined
): embed is EmbedLibrary => {
    return (embed as EmbedLibrary)?.embedType !== undefined;
};

export const isEmbedTopic = (
    embed: EmbedType | undefined
): embed is EmbedTopic => {
    return (embed as EmbedTopic)?.topicId !== undefined;
};

export const isEmbedQuery = (
    embed: EmbedType | undefined
): embed is EmbedQuery => {
    return (embed as EmbedQuery)?.queryId !== undefined;
};

export const isEmbedResults = (
    embed: EmbedType | undefined
): embed is EmbedResults => {
    return (embed as EmbedResults)?.resourceRequestType !== undefined;
};
