@use "../../styles/variables.module";

.menuButton {
    justify-content: center;
    align-items: center;
    width: 40px;
    min-width: 40px;
    height: 40px;
    font-size: 15px;
    border: 1px solid;
    border-radius: 20px;
    cursor: pointer;
}
