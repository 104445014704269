@use "../../../styles/variables.module";

.openButton {
    height: 30px;
    padding: 0px;
    border-radius: 15px;
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.icon {
    padding: 0px 8px;
}

.text {
    padding-right: 8px;
}

.newDesignShareButton {
    padding: 10px 20px;
    height: 30px;
    gap: 10px;
}
