import { useLocation } from "react-router-dom";

import { subheaderWrapper } from "./NavBar.module.scss";
import {
    useSelectedShareFolders,
    useShareParams,
} from "hooks/api/useShareQuery";
import variables from "styles/_variables.module.scss";

import BackButton from "./BackButton/BackButton";
import Breadcrumbs, { Breadcrumb } from "./Breadcrumbs/Breadcrumbs";

const FolderViewerNavBar = () => {
    const shareParams = useShareParams();
    const rootFolderParams = shareParams[0];
    const selectedShareFolders = useSelectedShareFolders();
    const location = useLocation();
    const pathParams = location.pathname.split("/"); // First value is "" as string is "/embed/folder/etc"
    const homePath = [
        "/" + pathParams[1],
        pathParams[2],
        rootFolderParams.id,
        rootFolderParams.hash,
    ].join("/");
    const newBreadcrumbs: Breadcrumb[] = [];
    newBreadcrumbs.push({
        label: "Home",
        pathname: homePath,
    });
    if (selectedShareFolders.length > 0) {
        let buildingPathname = homePath;
        selectedShareFolders.forEach((folder) => {
            buildingPathname += "/" + folder.id + "/" + folder.hash;
            newBreadcrumbs.push({
                label: folder.title,
                pathname: buildingPathname,
            });
        });
    }
    return (
        <div className={subheaderWrapper}>
            <BackButton
                styleOverrride={{
                    color: variables["text-colors-color-text-white"],
                    backgroundColor: variables["color-background-light-gray"],
                }}
            />
            <Breadcrumbs breadcrumbs={newBreadcrumbs} />
        </div>
    );
};

export default FolderViewerNavBar;
