export const isValidNumericalId = function (id, name = "id value") {
    if (typeof id !== "number") {
        throw new Error(`Invalid ${name} provided. Expected number. Found ${typeof id}`);
    }
    return true;
};

export const isValidCurriculumId = function (curriculumId) {
    return isValidNumericalId(curriculumId, "curriculumId");
};

export const isValidTopicId = function (topicId) {
    return isValidNumericalId(topicId, "topic id");
}
