import wizenozePlaceholder from "images/wizenoze-placeholder.webp";

import { calcFontSize } from "./helpers/calcFontSize";
import { capitaliseFirstLetter } from "./helpers/capitaliseFirstLetter";
import {
    isValidCurriculumId,
    isValidTopicId,
} from "./helpers/validateCurriculumId";
import { isFilledArray } from "./helpers/isFilledArray";
import { isSelectedLayerItem } from "./helpers/isSelectedLayerItem";
import { getDefaultCurriculumId } from "./helpers/getDefaultCurriculumId";
import { isSrcYouTube, formatYoutubeEmbedUrl } from "./helpers/youtube";
import { isModalElement } from "./helpers/isModalElement";
import { underscoreLink } from "./helpers/underscoreLink";
import { closeModalSetting } from "./helpers/closeModalSetting";
import { getUniqueList } from "./helpers/getUniqueList";

export {
    calcFontSize,
    capitaliseFirstLetter,
    isValidCurriculumId,
    isFilledArray,
    isSelectedLayerItem,
    getDefaultCurriculumId,
    isSrcYouTube,
    formatYoutubeEmbedUrl,
    isModalElement,
    isValidTopicId,
    closeModalSetting,
    underscoreLink,
    getUniqueList,
};

/**
 * There are multiple different images to fall back to
 * The sequence is defined in multiple places:
 * https://github.com/wizenoze/wizeup-ui/blob/master/src/components/result_thumbnail.tag
 * // TODO: optimize (also the API results)
 * @param {*} param0
 * @returns
 */
export const getImageWithSize = ({ imgUrl, fallbacks = [] }) => {
    if (imgUrl) {
        return imgUrl + "&height=" + 1200 + "&width=" + 1200;
    }
    if (Array.isArray(fallbacks) && fallbacks.length > 0) {
        const fallbackImg = fallbacks.find((_imgUrl) => Boolean(_imgUrl));
        if (fallbackImg) {
            return fallbackImg + "&height=" + 1200 + "&width=" + 1200;
        }
    }
    return wizenozePlaceholder;
};

export const setBlankImage = (e, src) => {
    e.target.src = src ?? wizenozePlaceholder;
    e.target.onError = null;
};

export const getResultType = (searchParams) => {
    const defaultType = "topics";

    if (!searchParams) return defaultType;
    const allParams = Object.fromEntries([...searchParams]);
    const queryParams = allParams?.queryParams
        ? JSON.parse(allParams?.queryParams)
        : null;

    if (!queryParams || !queryParams?.resultType) return defaultType;
    return queryParams?.resultType;
};
