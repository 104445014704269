import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons";

import * as styles from "./CurriculumLogo.module.scss";

interface Props {
    name: string | undefined;
    logoUrl: string | undefined | null;
}

const CurriculumLogo = ({ name, logoUrl }: Props) => {
    return (
        <div className={styles.logoContainer}>
            {name && logoUrl ? (
                <img src={logoUrl} className={styles.curriculumLogo} />
            ) : (
                <div className={styles.curriculumMissingLogo}>
                    <FontAwesomeIcon icon={faBook} color="white" />
                </div>
            )}
        </div>
    );
};

export default CurriculumLogo;
