import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronDown,
    faChevronUp,
    faCirclePlus,
} from "@fortawesome/free-solid-svg-icons";

import * as styles from "./Subheader.module.scss";
import useCollectionsParams from "hooks/useCollectionsParams";
import useScreenSizes from "hooks/useScreenSizes";
import { tracker } from "utils/analytics";

import ContextMenu from "components/ContextMenu/ContextMenu";
import Translate from "components/Translate/Translate";

const NewElementContextMenu = ({
    isVisible,
    setIsVisible,
    setIsNewFolderModalVisible,
}: {
    isVisible: boolean;
    setIsVisible: (open: boolean) => void;
    setIsNewFolderModalVisible: (visible: boolean) => void;
}) => {
    const { isMobileScreen } = useScreenSizes();
    const navigate = useNavigate();
    const collectionsParams = useCollectionsParams();
    return (
        <ContextMenu
            options={[
                {
                    label: "Folder",
                    clickHandler: () => setIsNewFolderModalVisible(true),
                },
                {
                    label: "Collection",
                    clickHandler: () => {
                        tracker("Add collection button clicked");
                        navigate("/create-collection", {
                            state: {
                                collectionsParams,
                            },
                        });
                    },
                },
            ]}
            onClose={() => setIsVisible(false)}
            disabled={collectionsParams && collectionsParams.length === 0}
            styleOverrides={{ width: isMobileScreen ? "100%" : undefined }}
        >
            <Button
                variant="outlined"
                className={styles.newButton}
                onClick={() => setIsVisible(true)}
            >
                <FontAwesomeIcon icon={faCirclePlus} fontSize={12} />
                <Typography variant="text-size-xSmall-bold">
                    <Translate text="New" />
                </Typography>
                <FontAwesomeIcon
                    icon={isVisible ? faChevronUp : faChevronDown}
                    fontSize={12}
                />
            </Button>
        </ContextMenu>
    );
};

export default NewElementContextMenu;
