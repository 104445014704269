@use "../../../styles/variables.module";

.backButton {
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    min-width: 20px;
    height: 20px;
    color: variables.$text-colors-color-text-white;
    font-size: 12px;
    background-color: variables.$background-colors-color-background-light-gray;
    border-radius: 20px;
    border: none;
    cursor: pointer;
}
