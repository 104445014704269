import {
    DraggableAttributes,
    DraggableSyntheticListeners,
} from "@dnd-kit/core";

import { Result } from "types";

import ResultCard from "components/ResultCard/ResultCard";

interface Props {
    result: Result;
    results: Result[];
    resultIndex: number;
    deleteItemHandler?: () => void;
    isSelectedResult?: boolean;
    addResourceHandler?: () => void;
    removeResourceHandler?: () => void;
    isHighlighted?: boolean;
    draggable?: {
        attributes: DraggableAttributes;
        listeners: DraggableSyntheticListeners;
    };
}

const CollectionListResultCard = ({
    result,
    results,
    resultIndex,
    deleteItemHandler,
    isSelectedResult,
    addResourceHandler,
    removeResourceHandler,
    isHighlighted,
    draggable,
}: Props) => {
    return (
        <ResultCard
            result={result}
            results={results}
            resultIndex={resultIndex}
            deleteItemHandler={deleteItemHandler}
            isSelectedResult={isSelectedResult}
            addResourceHandler={addResourceHandler}
            removeResourceHandler={removeResourceHandler}
            isHighlighted={isHighlighted}
            draggable={draggable}
        />
    );
};

export default CollectionListResultCard;
