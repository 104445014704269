import { useSearchParams } from "react-router-dom";

import noResultImage from "./no-results.webp";
import * as styles from "./SearchTopicNoResults.module.scss";
import { capitaliseFirstLetter } from "utils/helpers";
import { tracker } from "utils/analytics";

import Translate from "components/Translate/Translate";

const SearchTopicNoResults = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const searchTerm = searchParams.get("searchTerm");
    const queryParams = searchParams.get("queryParams");
    const setTabKey = (newTabKey: "topics" | "pages" | "videos") => {
        const newQueryParams = {
            ...(queryParams
                ? { ...JSON.parse(queryParams), startPage: 1 }
                : { startPage: 1 }),
            resultType: newTabKey,
        };
        setSearchParams({
            ...Object.fromEntries([...searchParams]),
            queryParams: JSON.stringify(newQueryParams),
        });
    };

    return (
        <div className={styles.noResultsContainer}>
            <div className={styles.helperList}>
                <p>
                    <strong>
                        <Translate text="Search Help" />
                    </strong>
                </p>
                <ul role="helpList">
                    <li role="itemList">
                        <Translate text="Check your search for typos" />
                    </li>
                    <li role="itemList">
                        <Translate text="Use more generic term" />
                    </li>
                    <li role="itemList">
                        <Translate text="Clear all filters" />
                    </li>
                    <li role="itemList">
                        <Translate text="Check for results in" />
                        <span
                            onClick={() => {
                                tracker(
                                    "Topic without results - change to pages"
                                );
                                setTabKey("pages");
                            }}
                        >
                            <Translate text="Web Pages" />
                        </span>
                        <Translate text=" or" />
                        <span
                            onClick={() => {
                                tracker(
                                    "Topic without results - change to videos"
                                );
                                setTabKey("videos");
                            }}
                        >
                            <Translate text="Videos" />
                        </span>
                        <Translate text=" tabs" />
                    </li>
                </ul>
            </div>
            <div className={styles.imageWrapper}>
                <img src={noResultImage} alt="No result image " width={100} />
                <p>
                    <Translate text="Sorry we couldn’t find any learning objective matches for " />
                    <strong>{capitaliseFirstLetter(searchTerm)}</strong>
                </p>
            </div>
        </div>
    );
};

export default SearchTopicNoResults;
