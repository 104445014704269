@use "../../../styles/variables.module";

.info {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.appearance {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
