import { useEffect, useState } from "react";

import { useAppContext } from "./contexts/AppContext";
import { axiosAPI } from "./hooks/api/axiosAPI";
import useUserQuery from "./hooks/api/useUserQuery";

// Does the initialisation of axiosAPI, but within the React Query provider, to make sure cached user value is provided
const AxiosInitialiser = ({ children }: { children: JSX.Element }) => {
    const { embedDetails, config } = useAppContext();
    const [isInitialised, setIsInitialised] = useState(false);
    const { data: cachedUser } = useUserQuery();

    useEffect(() => {
        if (!config) return;
        const setupAxiosAPI = async () => {
            try {
                await axiosAPI.initialise(
                    { ...embedDetails, ...config },
                    cachedUser,
                );
                setIsInitialised(true);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        setupAxiosAPI();
    }, [cachedUser, config, embedDetails]);
    if (!isInitialised) return <></>;
    return children;
};

export default AxiosInitialiser;
