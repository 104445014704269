import { Result } from "types";
import { isSrcYouTube } from "utils/helpers";

export const isScreenSmallerThan640 = function () {
    return (
        (window.screen.height < 640 &&
            window.matchMedia("(orientation: landscape)").matches) ||
        (window.screen.width < 640 &&
            window.matchMedia("(orientation: portrait)").matches)
    );
};

/**
 * Helper function to allow for easy unit testing
 */
export const isVideoAndSmall = function (isValidScreenSize: () => boolean) {
    return function (activeUrlData: Result) {
        return (
            isSrcYouTube(activeUrlData?.clickUrl || activeUrlData?.url) &&
            isValidScreenSize()
        );
    };
};

/**
 * Helps determine if the source is a video
 * so different styling can be applied.
 * @param {Object} activeUrlData
 * @returns boolean
 */
export const isVideoSource = function (activeUrlData: Result) {
    return isVideoAndSmall(isScreenSmallerThan640)(activeUrlData);
};
