import {
    useContext,
    // useState
} from "react";
import { Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowLeft,
    faClipboardList,
    faClipboardQuestion,
    faSpinner,
    faWandMagicSparkles,
} from "@fortawesome/free-solid-svg-icons";

import * as styles from "./QuizDetails.module.scss";
import { QuizContext } from "../../state/QuizContext";
import useScreenSizes from "hooks/useScreenSizes";
import variables from "styles/_variables.module.scss";
import { tracker } from "utils/analytics";

import NumberButton from "./components/NumberButton";
import InputField from "components/InputField/InputField";
// import LabeledSwitch from "components/InputFields/LabeledSwitch/LabeledSwitch";
import Translate from "components/Translate/Translate";

const QuizDetails = () => {
    const { isDesktopScreen } = useScreenSizes();
    const {
        title,
        setTitle,
        // multipleChoice,
        // setMultipleChoice,
        // trueOrFalse,
        // setTrueOrFalse,
        numberOfQuestions,
        setNumberOfQuestions,
        // questions,
        // setQuestions,
        isGenerating,
        setIsGenerating,
        setTimeStamp,
        setShowQuizDetails,
        setIsDirty,
    } = useContext(QuizContext);
    // const [showMultipleChoiceTooltip, setShowMultipleChoiceTooltip] =
    //     useState(false);
    // const [showTrueOrFalseTooltip, setShowTrueOrFalseTooltip] = useState(false);
    const clickHandler = () => {
        tracker("Quiz Generate Questions Clicked");
        setIsGenerating(true);
        setTimeStamp(Date.now().toString());
        setIsDirty(true);
        setShowQuizDetails(false);
    };
    return (
        <div className={styles.container}>
            {!isDesktopScreen && (
                <div
                    className={styles.back}
                    onClick={() => {
                        tracker("Quiz Details Closed");
                        setShowQuizDetails(false);
                    }}
                >
                    <FontAwesomeIcon
                        icon={faArrowLeft}
                        color={variables["text-colors-color-text-gray"]}
                        fontSize={15}
                    />
                    <Typography
                        variant="text-size-xSmall-bold"
                        color="$text-colors-color-text-gray.main"
                    >
                        <Translate text="Back" />
                    </Typography>
                </div>
            )}
            <div className={styles.quizName}>
                <div className={styles.logoAndTitle}>
                    <FontAwesomeIcon
                        icon={faClipboardList}
                        color={variables["text-colors-color-text-gray"]}
                        fontSize={15}
                    />
                    <Typography
                        variant="text-size-Medium"
                        color="$text-colors-color-text-dark.main"
                    >
                        <Translate text="Quiz name" />
                    </Typography>
                </div>
                <InputField
                    fullWidth
                    required
                    value={title}
                    name="quizName"
                    type="text"
                    handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const newQuizName = e.target.value;
                        tracker("Quiz Name Changed", { newQuizName });
                        setIsDirty(true);
                        setTitle(newQuizName);
                    }}
                    disabled={isGenerating}
                />
            </div>
            <div className={styles.quizOptions}>
                <div className={styles.logoAndTitle}>
                    <FontAwesomeIcon
                        icon={faClipboardQuestion}
                        color={variables["text-colors-color-text-gray"]}
                        fontSize={15}
                    />
                    <Typography
                        variant="text-size-Medium"
                        color="$text-colors-color-text-dark.main"
                    >
                        <Translate text="Quiz options" />
                    </Typography>
                </div>
                {/* <div className={styles.questionTypes}>
                    <Typography
                        variant="text-size-Base-bold"
                        color="$text-colors-color-text-dark.main"
                    >
                        <Translate text="Question types" />
                    </Typography>
                    <LabeledSwitch
                        label="Multiple choice"
                        keyname="multipleChoice"
                        value={multipleChoice}
                        updateHandler={() => {
                            if (multipleChoice && !trueOrFalse) {
                                setShowMultipleChoiceTooltip(true);
                            } else {
                                setMultipleChoice(!multipleChoice);
                            }
                        }}
                        switchTooltip={
                            "At least one question type must be selected."
                        }
                        showSwitchTooltip={showMultipleChoiceTooltip}
                        hideSwitchTooltipHandler={() =>
                            setShowMultipleChoiceTooltip(false)
                        }
                        disabled={isGenerating}
                    />
                    <LabeledSwitch
                        label="True or false"
                        keyname="trueOrFalse"
                        value={trueOrFalse}
                        updateHandler={() => {
                            if (!multipleChoice && trueOrFalse) {
                                setShowTrueOrFalseTooltip(true);
                            } else {
                                setTrueOrFalse(!trueOrFalse);
                            }
                        }}
                        switchTooltip={
                            "At least one question type must be selected."
                        }
                        showSwitchTooltip={showTrueOrFalseTooltip}
                        hideSwitchTooltipHandler={() =>
                            setShowTrueOrFalseTooltip(false)
                        }
                        disabled={isGenerating}
                    />
                </div> */}
                <div className={styles.numberOfQuestions}>
                    <Typography
                        variant="text-size-Base-bold"
                        color="$text-colors-color-text-dark.main"
                    >
                        <Translate text="Number of questions" />
                    </Typography>
                    <div className={styles.numberButtons}>
                        {new Array(10).fill(null).map((value, index) => (
                            <NumberButton
                                key={index}
                                value={index + 1}
                                isSelected={index + 1 === numberOfQuestions}
                                clickHandler={() => {
                                    const newNumberOfQuestions = index + 1;
                                    tracker(
                                        "Quiz Number of Questions Changed",
                                        { newNumberOfQuestions },
                                    );
                                    setNumberOfQuestions(newNumberOfQuestions);
                                }}
                                disabled={isGenerating}
                            />
                        ))}
                    </div>
                </div>
                <div
                    className={styles.generateButton}
                    style={{
                        background: isGenerating
                            ? "white"
                            : "linear-gradient(180deg, #ba29b5 0%, #9b1de8 100%)",
                        border: isGenerating ? "1px solid #770DB8" : undefined,
                    }}
                    onClick={clickHandler}
                >
                    {isGenerating ? (
                        <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            color="#770DB8"
                            fontSize={12}
                        />
                    ) : (
                        <FontAwesomeIcon
                            icon={faWandMagicSparkles}
                            color={
                                variables["border-colors-color-border-white"]
                            }
                            fontSize={12}
                        />
                    )}
                    <Typography
                        variant="button-text-size-xSmall-bold"
                        color={
                            isGenerating
                                ? "#770DB8"
                                : "$border-colors-color-border-white.main"
                        }
                    >
                        <Translate
                            text={
                                isGenerating
                                    ? "Generating questions"
                                    : "Generate questions"
                            }
                        />
                    </Typography>
                </div>
            </div>
        </div>
    );
};

export default QuizDetails;
