@use "../../styles/variables.module";

.wizeupApp {
    padding: 0;
    margin: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
}

/* reset the style and avoid overwrite */
.wizeupApp img,
.wizeupApp p {
    padding: 0;
    margin: 0;
}
