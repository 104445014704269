@use "../../styles/variables.module";

.navigation {
    margin-top: -40px;
    position: sticky;
    top: 0;
    z-index: variables.$zindex-navigators;
}

.container {
    // margin-bottom: 10px;
    padding: 20px 40px;
    height: 100%;
}

@media (max-width: variables.$bp-md) {
    .container {
        padding: 20px 10px;
    }
}
