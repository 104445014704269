@use "../../../../styles/variables.module";

.container {
    padding: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    background: url(./assets/curriculum-background-default.webp);
    background-position: center;
    border: 1px solid variables.$border-colors-color-border-light;
    border-radius: 15px;
    cursor: pointer;
}

.curiculumAndGrade {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    overflow: hidden;
}

.curriculum {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    overflow: hidden;
}
