import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";

import {
    selector,
    selections,
    optionItem,
    checkBoxLabel,
    filterLabel,
    inputContainer,
    filterValue,
    applyButtonContainer,
    applyButton,
    valueText,
    clearButton,
} from "./FilterInput.module.scss";
import { formatValues } from "./Filters.helpers";
import { capitaliseFirstLetter } from "utils/helpers";
import { tracker } from "utils/analytics";

import Translate, { TranslatableText } from "components/Translate/Translate";

interface Props {
    label: TranslatableText;
    curriculumSelectorDisabled: boolean;
    isVisible: boolean;
}

const FilterInput = ({
    label,
    curriculumSelectorDisabled,
    isVisible,
    ...props
}: Props) => {
    const [toggleState, setToggle] = useState(false);
    const selectRef = useRef(null);

    if (
        !isVisible ||
        (curriculumSelectorDisabled &&
            (label?.toLowerCase() === "grade" ||
                label?.toLowerCase() === "curriculum"))
    )
        return null;

    return (
        <div className={inputContainer}>
            <div className={filterLabel}>
                <Translate text={label} />
            </div>
            <InputField
                label={label}
                selectRef={selectRef}
                toggleState={toggleState}
                setToggle={setToggle}
                {...props}
            />
        </div>
    );
};

const getValueLabel = (data) =>
    !_.isEmpty(data) &&
    data.map((item, index) => {
        return (
            <React.Fragment key={index}>{`${
                Boolean(item) &&
                capitaliseFirstLetter(
                    typeof item === "string" ? item : item?.key
                )
            }${index !== data.length - 1 ? ", " : ""}`}</React.Fragment>
        );
    });

export const updateOptions = (props, options) =>
    Boolean(props.options?.length !== options?.length || options?.length !== 0);

const InputField = ({
    label,
    value,
    selectRef,
    toggleState,
    setToggle,
    ...props
}) => {
    const [allSelected, allSelection] = useState(0);
    const [options, setOptions] = useState<{ name: string; value: unknown }[]>(
        []
    );

    const isDisabled = () =>
        label?.toLowerCase() === "grade" ||
        label?.toLowerCase() === "curriculum" ||
        props?.totalResults === 0;

    const applyFilter = () => {
        tracker("Subject filter applied", { options });
        let subjectFilters = [];

        if (allSelected) {
            // everything is selected
            subjectFilters = options?.map((item) => {
                return item?.name?.toLowerCase();
            });
        } else {
            for (let v of options)
                if (v.value === 1 || v.value === true)
                    subjectFilters.push(v?.name?.toLowerCase());
        }

        props.applySubjectFilter(subjectFilters);
    };

    const selectAll = () => allSelection(!allSelected);

    useEffect(() => {
        allSelection(checkSelection());
        const firstFormat = Boolean(
            props.appliedFilters?.length === props?.options
        );
        setOptions(formatValues(props.options, value, firstFormat));
    }, []);

    useEffect(() => {
        updateOptions(props, options) &&
            setOptions(formatValues(props.options, value));
    }, [props.options]);

    const checkSelection = () =>
        Boolean(
            !_.isEmpty(value) &&
                !_.isEmpty(options) &&
                value?.length === options?.length
        );

    const handleSelection = (name) => {
        if (allSelected) {
            // unselect when all selected
            selectAll();
            setOptions(
                [...options]?.map((item) =>
                    name === item?.name
                        ? { ...item, value: 0 }
                        : { ...item, value: 1 }
                )
            );
        } else {
            setOptions(
                [...options]?.map((item) =>
                    name === item?.name
                        ? { ...item, value: !item?.value }
                        : item
                )
            );
        }
    };

    return (
        <div
            className={selector}
            id="multi-select"
            ref={selectRef}
            data-is-disabled={isDisabled()}
        >
            <div
                className={filterValue}
                onClick={() => setToggle(!toggleState)}
            >
                <div className={valueText}>{getValueLabel(value)}</div>
                <div>
                    <FontAwesomeIcon icon={faAngleDown} />
                </div>
            </div>
            {toggleState && (
                <div id="selections" className={selections}>
                    <div className={optionItem}>
                        <label className={checkBoxLabel}>
                            <input
                                type="checkbox"
                                name={""}
                                value={allSelected}
                                checked={allSelected}
                                onChange={selectAll}
                            />{" "}
                            <Translate text="Select All" />
                        </label>
                        <br />
                    </div>
                    {options?.map((item, key) => {
                        return (
                            <div key={key} className={optionItem}>
                                <label className={checkBoxLabel}>
                                    <input
                                        type="checkbox"
                                        name={item.name}
                                        value={
                                            allSelected
                                                ? allSelected
                                                : item.value
                                        }
                                        checked={
                                            allSelected
                                                ? allSelected
                                                : item.value
                                        }
                                        onChange={() => {
                                            handleSelection(item.name);
                                        }}
                                    />{" "}
                                    {Boolean(item.name) &&
                                        capitaliseFirstLetter(item.name)}
                                </label>
                                <br />
                            </div>
                        );
                    })}
                    <div className={applyButtonContainer}>
                        <button className={applyButton} onClick={applyFilter}>
                            <Translate text="Apply" />
                        </button>
                        <div
                            className={clearButton}
                            onClick={props.clearFilter}
                        >
                            <Translate text="Clear" />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FilterInput;
