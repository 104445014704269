import { Theme, useMediaQuery } from "@mui/material";

import logoSrc from "./assets/wizenoze-logo-green.webp";
import iconSrc from "./assets/wizenoze-icon-green.webp";

const Logo = () => {
    const isDesktopScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.up("lg")
    );

    return (
        <div>
            {isDesktopScreen ? (
                <img
                    src={logoSrc}
                    alt="Wizenoze Learning Resources"
                    width={158}
                />
            ) : (
                <img
                    src={iconSrc}
                    alt="Wizenoze Learning Resources"
                    width={40}
                />
            )}
        </div>
    );
};

export default Logo;
