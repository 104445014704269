@use "../../../../styles/variables.module";

.searchResultsDisplay {
    padding: 20px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 90px;
}

.searchResultsContainer {
    padding: 0px 40px;
}

.readingLevelFilterContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: -10px;
}

@media (max-width: variables.$bp-md) {
    .searchResultsDisplay {
        padding: 20px 10px;
        flex-direction: column;
        height: fit-content;
    }
    .searchResultsContainer {
        padding: 0px 10px;
    }
}
