import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";

import { topicResultContainer } from "./TopicSearchResults.module.scss";
import { CurriculumSearchResultsType } from "hooks/api/useSearchQuery";
import { tracker } from "utils/analytics";

import TopicSearchCard from "./TopicSearchCard";

const transformQueryObjectToSearchParams = (
    obj: Record<
        string,
        {
            id: number;
            name: string;
        }
    >
) => {
    let path = "";
    if (obj.CURRICULUM?.id !== undefined)
        path = path + "/curriculum/" + obj.CURRICULUM.id;
    if (obj.GRADE?.id !== undefined) path = path + "/grade/" + obj.GRADE.id;
    if (obj.SUBJECT?.id !== undefined)
        path = path + "/subject/" + obj.SUBJECT.id;
    if (obj.UNIT?.id !== undefined) path = path + "/unit/" + obj.UNIT.id;
    if (obj.TOPIC?.id !== undefined) path = path + "/topic/" + obj.TOPIC.id;
    if (obj.QUERY?.id !== undefined) path = path + "/query/" + obj.QUERY.id;
    return path;
};

const TopicResultItem = ({
    result,
}: {
    result: CurriculumSearchResultsType["results"][number];
}) => {
    const navigate = useNavigate();
    const { curriculumMetadata } = result;
    const topicNode = { ...curriculumMetadata?.TOPIC };
    return (
        <TopicSearchCard
            nodeData={topicNode}
            suggestedQueries={[
                {
                    id: result?.queryId,
                    name: result?.name,
                },
            ]}
            path={result?.path}
            showQueryResult={() => {
                tracker("Open query from search results");
                navigate(
                    transformQueryObjectToSearchParams(curriculumMetadata)
                );
            }}
            subjectTitle={curriculumMetadata?.SUBJECT?.name}
        />
    );
};

interface Props {
    results: CurriculumSearchResultsType["results"];
}

const TopicResultList = ({ results }: Props) => {
    if (!results) return null;
    return (
        <div className={topicResultContainer}>
            <Grid container spacing={2}>
                {results.map((result) => {
                    return (
                        <Grid
                            xl={3}
                            lg={4}
                            md={6}
                            xs={12}
                            key={result.name}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <TopicResultItem result={result} />
                        </Grid>
                    );
                })}
            </Grid>
        </div>
    );
};

export default TopicResultList;
