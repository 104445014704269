import { createContext } from "react";

export const CurriculumContext = createContext<{
    selectedResultsArray: string[];
    setSelectedResultsArray: (_value: string[]) => void;
    embedUrl?: string;
    setEmbedUrl: (_value: string) => void;
    linkUrl?: string;
    setLinkUrl: (_value: string) => void;
}>({
    selectedResultsArray: [],
    setSelectedResultsArray: (_value) => {
        return;
    },
    setEmbedUrl: (_value) => {
        return;
    },
    setLinkUrl: (_value) => {
        return;
    },
});
