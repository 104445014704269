import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";

import { useAppContext } from "contexts/AppContext";
import useScreenSizes from "hooks/useScreenSizes";
import {
    useCollectionQuery,
    useViewItemsInCollectionQuery,
} from "hooks/api/collections";
import { Collection, Item, UnsavedCollection } from "types";
import { tracker } from "utils/analytics";

import CollectionViewer from "components/CollectionViewer/CollectionViewer";
import Loading from "components/Loading/Loading";
import ShareLoadFailed from "components/ShareLoadFailed/ShareLoadFailed";
import TitleHeader from "components/TitleHeader/TitleHeader";
import Header from "features/Header/Header";
import QuizAvailableFooter from "./QuizAvailablePanel/QuizAvailableFooter";
import QuizAvailablePanel from "./QuizAvailablePanel/QuizAvailablePanel";
import QuizTaker from "./QuizTaker/QuizTaker";

const CollectionShareViewer = ({
    collection,
    items,
}: {
    collection?: UnsavedCollection | Collection;
    items?: Item[];
}) => {
    const quizAvailableFooterRef = useRef<HTMLDivElement>(null);
    const quizAvailableFooterHeight = quizAvailableFooterRef.current
        ?.clientHeight
        ? `0 0 ${quizAvailableFooterRef.current?.clientHeight}px 0`
        : undefined;

    const { isDesktopScreen } = useScreenSizes();
    const { collectionId, hash } = useParams();
    const location = useLocation();
    const { data: collectionData } = useCollectionQuery(collectionId, hash);
    const { config, updateConfig } = useAppContext();
    useEffect(() => {
        if (
            !!collectionData &&
            collectionData.language &&
            config.language !== collectionData.language.toLowerCase()
        ) {
            updateConfig("language", collectionData.language.toLowerCase());
        }
    }, [collectionData, config.language, updateConfig]);
    const shareParam =
        collectionId && hash ? { id: collectionId, hash } : undefined;
    const { data: collectionItems, status: collectionItemsStatus } =
        useViewItemsInCollectionQuery(shareParam);
    const embedMode =
        collectionId && hash && location.pathname?.includes("embed");
    const gridItems = items || collectionItems;
    const collectionName = collectionData?.title || collection?.title;
    const [tracked, setTracked] = useState(false);
    useEffect(() => {
        if (tracked || (!items && collectionItemsStatus !== "success")) return;
        tracker("Viewed Collection", { collectionId });
        setTracked(true);
    }, [collectionId, collectionItemsStatus, items, tracked]);
    const quizData = collection?.quiz ?? collectionData?.quiz;
    const [isQuizTakerOpen, setIsQuizTakerOpen] = useState(false);
    if (!items && collectionItemsStatus === "loading") return <Loading />;
    const openQuiz = () => {
        tracker("Open Quiz Taker");
        setIsQuizTakerOpen(true);
    };
    const closeQuiz = () => {
        tracker("Close Quiz Taker");
        setIsQuizTakerOpen(false);
    };
    return (
        <>
            <Header collectionView={!embedMode} />
            <TitleHeader collectionName={collectionName} />
            {!gridItems || !gridItems.length ? (
                <ShareLoadFailed collectionExists={Boolean(collectionId)} />
            ) : (
                <Grid
                    container
                    spacing={2}
                    margin={"20px 40px"}
                    padding={quizAvailableFooterHeight}
                >
                    <Grid lg={6} xs={12} padding={"0px"}>
                        <CollectionViewer
                            items={gridItems ?? []}
                            isFetched={
                                !!items || collectionItemsStatus !== "loading"
                            }
                            viewAs={collectionData?.view ?? "GRID"}
                            collectionResultsModalEnabled={
                                !!collectionData?.resultModal
                            }
                        />
                    </Grid>
                    {isDesktopScreen && (
                        <>
                            <Grid xs={0} sm={3}></Grid>
                            <Grid sm={3}>
                                {quizData && (
                                    <QuizAvailablePanel
                                        openHandler={() =>
                                            setIsQuizTakerOpen(true)
                                        }
                                        quizTitle={quizData.title}
                                    />
                                )}
                            </Grid>
                        </>
                    )}
                </Grid>
            )}
            {quizData && collectionId && hash && (
                <QuizTaker
                    isOpen={isQuizTakerOpen}
                    closeHandler={closeQuiz}
                    collectionId={collectionId}
                    collectionHash={hash}
                    quizData={quizData}
                />
            )}
            {!isDesktopScreen && quizData && (
                <QuizAvailableFooter
                    ref={quizAvailableFooterRef}
                    quizName={quizData?.title}
                    openHandler={openQuiz}
                />
            )}
        </>
    );
};

export default CollectionShareViewer;
