import { useState } from "react";

import { useMessagingContext } from "contexts/MessagingContext";
import { useMoveCollectionMutation } from "hooks/api/collections";
import {
    useAllFoldersEnhancedQuery,
    useFolderTreeQuery,
} from "hooks/api/folders";
import { Collection, Folder } from "types";
import { tracker } from "utils/analytics";

import DialogBox from "components/DialogBox/DialogBox";
import TreeExplorer from "components/TreeExplorer/TreeExplorer";
import { Box } from "@mui/material";

const recursivelyFilterCollections = (
    folders: Array<Folder | Collection>,
    currentFolderId: number
): Folder[] => {
    return folders.reduce((filtered: Folder[], folder) => {
        if (
            "type" in folder &&
            folder.type === "FOLDER" &&
            folder.id !== currentFolderId
        ) {
            filtered.push({
                ...folder,
                children: recursivelyFilterCollections(
                    folder.children,
                    currentFolderId
                ),
            });
        }
        return filtered;
    }, []);
};

const MoveCollectionDialog = ({
    collection,
    isVisible,
    setIsVisible,
}: {
    collection: Collection;
    isVisible: boolean;
    setIsVisible: (arg0: boolean) => void;
}) => {
    const { openAlertMessage } = useMessagingContext();
    const { data: allFoldersAndCollections } = useAllFoldersEnhancedQuery();
    const allFolders = recursivelyFilterCollections(
        allFoldersAndCollections ?? [],
        collection.id
    );

    const moveCollectionMutation = useMoveCollectionMutation();
    const [newPerentPathParams, setNewParentPathParams] = useState<string[]>(
        []
    );
    const [newParentId, setNewParentId] = useState<number>();
    const [newInvalidationIds, setNewInvalidationIds] = useState<number[]>([]);
    const moveFolderHandler = () => {
        if (!newParentId) return;

        moveCollectionMutation.mutate(
            {
                collection,
                newParentId,
                newInvalidationIds,
            },
            {
                onSuccess: () => {
                    openAlertMessage({
                        message: "Collection moved successfully.",
                        type: "success",
                        open: true,
                    });
                    tracker("Moved collection");
                    setNewParentId(undefined);
                    setNewParentPathParams([]);
                    setNewInvalidationIds([]);
                    setIsVisible(false);
                },
                onError: (error) => {
                    openAlertMessage({
                        message: "Error",
                        type: "error",
                        open: true,
                    });
                    console.error(error);
                    setNewParentId(undefined);
                    setNewParentPathParams([]);
                    setNewInvalidationIds([]);
                    setIsVisible(false);
                },
            }
        );
        return;
    };

    const [fetchId, setFetchId] = useState<string>();
    const _sideFetched = useFolderTreeQuery(fetchId);
    const fetchFolderHandler = (folderId: string | string[]) => {
        if (Array.isArray(folderId)) return;
        setFetchId(folderId);
    };
    return (
        <DialogBox
            isOpen={isVisible}
            closeHandler={() => setIsVisible(false)}
            title="Move to"
            withCloseButton
            styleOverrides={{ width: "435px", height: "unset" }}
            secondaryButton={{
                label: "Cancel",
                clickHandler: () => setIsVisible(false),
            }}
            primaryButton={{
                label: "Save",
                clickHandler: moveFolderHandler,
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    paddingTop: "30px",
                    paddingBottom: "30px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    backgroundColor:
                        "$background-colors-color-background-light.main",
                }}
            >
                {allFolders && (
                    <TreeExplorer
                        selectedNodes={newPerentPathParams}
                        pathRoot=""
                        data={allFolders}
                        onNodeClick={(_e, nodeId) => fetchFolderHandler(nodeId)}
                        folderClickHandler={(
                            folder,
                            pathString,
                            invalidationIds
                        ) => {
                            pathString &&
                                setNewParentPathParams(pathString.split("/"));
                            setNewParentId(folder.id);
                            invalidationIds &&
                                setNewInvalidationIds(invalidationIds);
                        }}
                    />
                )}
            </Box>
        </DialogBox>
    );
};

export default MoveCollectionDialog;
