import { Typography } from "@mui/material";

import defaultLandingSvg from "./image-new-tab.webp";
import {
    container,
    image,
    text,
    smallText,
} from "./ShareLoadFailed.module.scss";

import Translate from "components/Translate/Translate";

const ShareLoadFailed = ({
    collectionExists,
}: {
    collectionExists?: boolean;
}) => {
    return (
        <div className={container}>
            <img
                src={defaultLandingSvg}
                className={image}
                alt="default landing image"
            />
            <Typography
                variant="text-size-Large"
                color="$text-colors-color-text-light-gray.main"
                className={text}
            >
                <Translate text="Content cannot be loaded" />
            </Typography>
            {collectionExists && (
                <Typography
                    variant="text-size-Medium"
                    color="$text-colors-color-text-light-gray.main"
                    className={smallText}
                >
                    <Translate text="Please try again later." />
                </Typography>
            )}
        </div>
    );
};

export default ShareLoadFailed;
