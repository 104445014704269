import { Button, Typography } from "@mui/material";

import questionMarkSVG from "./question-mark.svg";

import Translate from "components/Translate/Translate";

interface Props {
    openHandler: () => void;
    quizTitle: string;
}

const QuizAvailablePanel = ({ openHandler, quizTitle }: Props) => {
    const openQuiz = () => {
        openHandler();
    };
    return (
        <div
            style={{
                display: "flex",
                // width: "325px",
                height: "fit-content",
                padding: "15px",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
                borderRadius: "15px",
                border: "1px solid #69BFAC",
                background: "white",
                boxShadow: "0px 5px 20px 0px rgba(108, 117, 125, 0.20)",
            }}
        >
            <img
                src={questionMarkSVG}
                alt="Quiz Icon"
                style={{ maxHeight: "230px", maxWidth: "100%", margin: 20 }}
            />
            <Typography
                variant="text-size-Small"
                color="$text-colors-color-text-gray.main"
                style={{ textAlign: "center" }}
            >
                <Translate text="Take the quiz and see how much you know!" />
            </Typography>
            <Typography
                variant="text-size-xMedium"
                color="$text-colors-color-text-dark.main"
                style={{ textAlign: "center" }}
            >
                {quizTitle}
            </Typography>
            <Button
                style={{
                    display: "flex",
                    padding: "16px 30px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    width: "100%",
                    borderRadius: "5px",
                    border: "1px solid #F7BF6D",
                    background: "#F29103",
                }}
                onClick={openQuiz}
            >
                <Typography
                    variant="text-size-Base-bold"
                    color="$text-colors-color-text-white.main"
                >
                    <Translate text="Start the quiz" />
                </Typography>
            </Button>
        </div>
    );
};

export default QuizAvailablePanel;
