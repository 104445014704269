import { Typography } from "@mui/material";

import { headerContainer } from "./TitleHeader.module.scss";

const TitleHeader = ({ collectionName }: { collectionName?: string }) => {
    if (!collectionName) return <></>;
    return (
        <div className={headerContainer}>
            <Typography
                variant="text-size-xMedium"
                color="$text-colors-color-text-dark.main"
                sx={{ wordBreak: "break-all" }}
            >
                {collectionName}
            </Typography>
        </div>
    );
};

export default TitleHeader;
