import { useContext } from "react";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSliders, faSpinner } from "@fortawesome/free-solid-svg-icons";

import * as styles from "./QuizQuestions.module.scss";
import { QuizContext } from "../../state/QuizContext";
import useScreenSizes from "hooks/useScreenSizes";
import variables from "styles/_variables.module.scss";
import { tracker } from "utils/analytics";

import AddQuestionPanel from "./components/AddQuestionPanel";
import QuestionCard, { QuestionCardSkeleton } from "./components/QuestionCard";
import MenuButton from "components/MenuButton/MenuButton";
import SaveButton from "components/SaveButton/SaveButton";
import Translate from "components/Translate/Translate";

const QuizQuestions = () => {
    const { isDesktopScreen } = useScreenSizes();
    const {
        numberOfQuestions,
        questions,
        setQuestions,
        isGenerating,
        saveHandler,
        isSaving,
        showQuizDetails,
        setShowQuizDetails,
        isDirty,
        setIsDirty,
    } = useContext(QuizContext);
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );
    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            const questionsIds = questions.map((question) => question.id);
            const localQuestions = arrayMove(
                questions,
                questionsIds.indexOf(active.id),
                questionsIds.indexOf(over.id),
            );
            setQuestions(localQuestions);
            setIsDirty(true);
            tracker("Questions Reordered");
        }
    };
    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
        >
            <div className={styles.container}>
                <div className={styles.questions}>
                    <AddQuestionPanel />
                    {isGenerating &&
                        new Array(numberOfQuestions)
                            .fill(1)
                            .map((index) => (
                                <QuestionCardSkeleton key={index} />
                            ))}
                    <SortableContext
                        items={questions}
                        strategy={verticalListSortingStrategy}
                    >
                        {questions.map((question) => (
                            <QuestionCard
                                key={question.id}
                                question={question}
                            />
                        ))}
                    </SortableContext>
                </div>
                <div
                    className={styles.footer}
                    style={{
                        justifyContent: isDesktopScreen
                            ? "flex-end"
                            : "space-between",
                    }}
                >
                    {!isDesktopScreen && (
                        <MenuButton
                            faIcon={faSliders}
                            iconColor={variables["icon-colors-blue-100"]}
                            primaryColor={variables["icon-colors-blue-40"]}
                            secondaryColor={variables["icon-colors-blue-60"]}
                            isOpen={showQuizDetails}
                            styleOverrides={{
                                width: "30px",
                                minWidth: "30px",
                                height: "30px",
                                fontSize: "12px",
                            }}
                            onClick={() => {
                                tracker("Quiz Details Opened");
                                setShowQuizDetails(true);
                            }}
                        />
                    )}
                    <div className={styles.numberAndSave}>
                        <Typography
                            variant="text-size-Small"
                            color="$icon-colors-green-60.main"
                        >
                            <Translate text="Questions in the quiz" />{" "}
                            {isGenerating ? (
                                <FontAwesomeIcon
                                    icon={faSpinner}
                                    spin
                                    color={variables["icon-colors-green-60"]}
                                    fontSize={12}
                                />
                            ) : (
                                questions.length
                            )}
                        </Typography>
                        <SaveButton
                            saveHandler={saveHandler}
                            isSaving={isSaving}
                            isDisabled={isGenerating || !isDirty}
                        />
                    </div>
                </div>
            </div>
        </DndContext>
    );
};

export default QuizQuestions;
