import { Dispatch, SetStateAction } from "react";
import { Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

import useScreenSizes from "hooks/useScreenSizes";
import { Question } from "types";

import { BackgroundPattern } from "./BackgroundPattern";
import ListItem from "components/SelectableList/ListItem";
import Translate from "components/Translate/Translate";

interface Props {
    question: Question | undefined;
    currentIndex: number;
    totalNumberOfQuestions: number | undefined;
    selectedId: number | undefined;
    setSelectedId: Dispatch<SetStateAction<number | undefined>>;
    isAnswerConfirmed: boolean;
}

const QuestionScreen = ({
    question,
    currentIndex,
    totalNumberOfQuestions,
    selectedId,
    setSelectedId,
    isAnswerConfirmed,
}: Props) => {
    const { isDesktopScreen } = useScreenSizes();
    if (!question) return <></>;
    const answerClickHandler = (id: number | undefined) => {
        if (isDesktopScreen) {
            if (selectedId === undefined) setSelectedId(id);
        } else {
            if (!isAnswerConfirmed) setSelectedId(id);
        }
    };

    const conditionState =
        selectedId === undefined || (!isDesktopScreen && !isAnswerConfirmed)
            ? "neutral"
            : selectedId ===
                question.answers.findIndex(
                    (el) => el.id === question.correctAnswerId,
                )
              ? "right"
              : "wrong";
    const instructionText = {
        neutral: "Select one of the answers",
        right: "Correct. Nice job!",
        wrong: "This answer is incorrect.",
    }[conditionState];
    return (
        <div
            style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
                width: "100%",
                paddingTop: "80px",
                paddingLeft: "20px",
                paddingRight: "20px",
            }}
        >
            <BackgroundPattern colourState={conditionState} />
            <div
                style={{
                    zIndex: 1,
                    background: "white",
                    display: "flex",
                    minHeight: "182px",
                    padding: "0px 20px 20px 20px",
                    flexDirection: "column",
                    // alignItems: "center",
                    gap: "40px",
                    flexShrink: 0,
                    boxShadow: "0px 5px 20px 0px rgba(108, 117, 125, 0.20)",
                    borderRadius: 15,
                    alignSelf: "stretch",
                    marginBottom: 40,
                }}
            >
                <div
                    style={{
                        background: "#2B7FF2",
                        borderRadius: 15,
                        padding: "5px 20px",
                        alignSelf: "flex-start",
                        minWidth: 280,
                        marginLeft: -20,
                    }}
                >
                    <Typography variant="text-size-Medium" color="white">
                        Question {currentIndex + 1} / {totalNumberOfQuestions}
                    </Typography>
                </div>
                <div>
                    <Typography
                        variant="text-size-xxLarge"
                        color="$text-colors-color-text-dark.main"
                    >
                        {question.question}
                    </Typography>
                </div>
            </div>
            <div style={{ zIndex: 1 }}>
                <div style={{ marginBottom: "15px" }}>
                    <Typography
                        variant="text-size-Small"
                        color="$text-colors-color-text-gray.main"
                    >
                        <Translate text={instructionText} />
                    </Typography>
                </div>
                <Grid2 container spacing="10px">
                    {question?.answers.map((el, index) => (
                        <Grid2 xs={12} md={6} key={el.id}>
                            <ListItem
                                id={index}
                                name={el.text}
                                selectAction={() => answerClickHandler(index)}
                                isSelected={index === selectedId}
                            />
                        </Grid2>
                    ))}
                </Grid2>
            </div>
        </div>
    );
};

export default QuestionScreen;
