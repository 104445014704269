import { Folder } from "types";

import FolderGridView from "components/FolderViewer/components/FolderGridView/FolderGridView";
import FolderListView from "components/FolderViewer/components/FolderListView/FolderListView";

const FolderViewer = ({
    items,
    isFetched,
    viewAs,
    sortBy,
    folderClickHandler,
    collectionClickHandler,
    disableContextMenu,
}: React.ComponentProps<typeof FolderListView> & {
    viewAs: Folder["view"];
}) => {
    return viewAs === "LIST" ? (
        <FolderListView
            items={items}
            isFetched={isFetched}
            sortBy={sortBy}
            folderClickHandler={folderClickHandler}
            collectionClickHandler={collectionClickHandler}
            disableContextMenu={disableContextMenu}
        />
    ) : (
        <FolderGridView
            items={items}
            isFetched={isFetched}
            sortBy={sortBy}
            folderClickHandler={folderClickHandler}
            collectionClickHandler={collectionClickHandler}
            disableContextMenu={disableContextMenu}
        />
    );
};

export default FolderViewer;
