import { useContext, useState } from "react";
import { Button, Typography } from "@mui/material";

import * as styles from "./AddQuestionPanel.module.scss";
import { QuizContext } from "../../../state/QuizContext";
import { useMessagingContext } from "contexts/MessagingContext";
import { Question } from "types";
import { tracker } from "utils/analytics";

import CreateQuestionCard from "./CreateQuestionCard";
import Translate from "components/Translate/Translate";

const AddQuestionPanel = () => {
    const { openMessageModal } = useMessagingContext();
    const { questions, setQuestions, setIsDirty } = useContext(QuizContext);
    const [newQuestion, setNewQuestion] = useState<Question | undefined>(
        undefined,
    );
    const [newQuestionType, setNewQuestionType] = useState<
        "multipleChoice" | "trueOrFalse" | "fillInTheBlank" | undefined
    >(undefined);
    const addMultipleChoiceQuestion = () => {
        tracker("Add Multiple Choice Question");
        const questionId = Date.now().toString();
        setNewQuestion({
            id: questionId,
            question: "Type your question",
            answers: [
                { id: questionId + "a1", text: "Option 1" },
                { id: questionId + "a2", text: "Option 2" },
                { id: questionId + "a3", text: "Option 3" },
                { id: questionId + "a4", text: "Option 4" },
            ],
            correctAnswerId: questionId + "a1",
        });
        setNewQuestionType("multipleChoice");
    };
    const addTrueOrFalseQuestion = () => {
        tracker("Add True Or False Question");
        const questionId = Date.now().toString();
        setNewQuestion({
            id: questionId,
            question: "Type your question",
            answers: [
                { id: questionId + "a1", text: "True" },
                { id: questionId + "a2", text: "False" },
            ],
            correctAnswerId: questionId + "a1",
        });
        setNewQuestionType("trueOrFalse");
    };
    // const addFillInTheBlankQuestion = () => {
    //     tracker("Add Fill In The Blank Question");
    //     const questionId = Date.now().toString();
    //     setNewQuestion({
    //         id: questionId,
    //         question: "Type your question",
    //         answers: [
    //             { id: questionId + "a1", text: "Option 1" },
    //             { id: questionId + "a2", text: "Option 2" },
    //             { id: questionId + "a3", text: "Option 3" },
    //             { id: questionId + "a4", text: "Option 4" },
    //         ],
    //         correctAnswerId: questionId + "a1",
    //     });
    //     setNewQuestionType("fillInTheBlank");
    // };
    const saveNewQuestionHandler = () => {
        if (newQuestion !== undefined) {
            if (newQuestion.question === "") {
                openMessageModal({
                    isModalVisible: true,
                    title: "Title cannot be empty",
                    body: "You can't save a question with an empty title. Please add text to your title.",
                    type: "OK",
                    primaryActionHandler: () => undefined,
                });
            } else if (
                newQuestion.answers.some((answer) => answer.text === "")
            ) {
                openMessageModal({
                    isModalVisible: true,
                    title: "Option cannot be empty",
                    body: "You can't save a question with an empty option. Please add text to all your options or delete empty options before saving a question.",
                    type: "OK",
                    primaryActionHandler: () => undefined,
                });
            } else {
                tracker("Save New Question");
                setQuestions([newQuestion, ...questions]);
                setIsDirty(true);
                setNewQuestion(undefined);
                setNewQuestionType(undefined);
            }
        }
    };
    const cancelNewQuestionHandler = () => {
        tracker("Cancel New Question");
        setNewQuestion(undefined);
        setNewQuestionType(undefined);
    };
    return (
        <div className={styles.container}>
            <div className={styles.title}>
                <Typography variant="text-size-Small-bold">
                    <Translate text="Manually add a question" />
                </Typography>
                {newQuestion !== undefined && (
                    <div className={styles.buttonsContainer}>
                        <Button
                            variant="contained"
                            className={styles.saveButton}
                            onClick={saveNewQuestionHandler}
                        >
                            <Typography variant="text-size-xSmall-bold">
                                <Translate text="Save" />
                            </Typography>
                        </Button>
                        <Button
                            variant="outlined"
                            className={styles.cancelButton}
                            onClick={cancelNewQuestionHandler}
                        >
                            <Typography variant="text-size-xSmall-bold">
                                <Translate text="Cancel" />
                            </Typography>
                        </Button>
                    </div>
                )}
            </div>
            {newQuestion !== undefined ? (
                <div className={styles.questionContainer}>
                    <CreateQuestionCard
                        question={newQuestion}
                        setNewQuestion={setNewQuestion}
                        type={newQuestionType}
                    />
                </div>
            ) : (
                <div className={styles.typeButtonsContainer}>
                    <Button
                        data-testid="multipleChoiceButton"
                        variant="text"
                        className={styles.typeButton}
                        onClick={addMultipleChoiceQuestion}
                    >
                        <Typography variant="text-size-xSmall-bold">
                            <Translate text="Multiple choice" />
                        </Typography>
                    </Button>
                    <Button
                        data-testid="trueOrFalseButton"
                        variant="text"
                        className={styles.typeButton}
                        onClick={addTrueOrFalseQuestion}
                    >
                        <Typography variant="text-size-xSmall-bold">
                            <Translate text="True / False" />
                        </Typography>
                    </Button>
                    {/* <Button
                        data-testid="fillInTheBlankButton"
                        variant="text"
                        className={styles.typeButton}
                        onClick={addFillInTheBlankQuestion}
                    >
                        <Typography variant="text-size-xSmall-bold">
                            <Translate text="Fill in the blank" />
                        </Typography>
                    </Button> */}
                </div>
            )}
        </div>
    );
};

export default AddQuestionPanel;
