import * as Sentry from "@sentry/react";
import mixpanel from "mixpanel-browser";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";

import "./styles/index.css";
import reactRender from "./reactRender";
import { formatVersionString } from "./utils/strings";

dayjs.extend(duration);
dayjs.extend(relativeTime);

Sentry.init({
    dsn: "https://eaa68bbdc0e7cd698dcb8355baeb073d@o30528.ingest.sentry.io/4505742681505792",
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
                "https://app.wizenoze.com",
                "https://staging-app.wizenoze.com",
                "https://share.wizenoze.com",
                "https://staging-share.wizenoze.com",
                "https://lti.wizeup.world",
            ],
        }),
        new Sentry.Replay(),
    ],
    environment: process.env.NODE_ENV,
    // Performance Monitoring
    tracesSampleRate: 0.01, // Capture 1% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const mixpanelKey = process.env.REACT_APP_SDK_MIXPANEL_KEY;
mixpanelKey &&
    mixpanel.init(mixpanelKey, {
        api_host: "https://mixpanel-proxy.wizenoze.com",
    });

export const wizeupSDK = (window.wizeupSDK = (function () {
    return function () {
        console.info(
            "App Version: ",
            formatVersionString(process.env.REACT_APP_VERSION)
        );
        reactRender();
    };
})());

export default wizeupSDK;
