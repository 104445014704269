import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, TextField, Typography } from "@mui/material";

import * as styles from "./PasswordReset.module.scss";
import { useAppContext } from "contexts/AppContext";
import { useMessagingContext } from "contexts/MessagingContext";
import { usePasswordUpdateMutation } from "hooks/api/authentication";
import { tracker } from "utils/analytics";

import PublicPageContainer from "../components/PublicPageContainer/PublicPageContainer";
import PublicPageTitle from "../components/PublicPageTitle/PublicPageTitle";
import Translate, { translate } from "components/Translate/Translate";

const PasswordReset = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { config } = useAppContext();
    const language = config.language;
    const { openAlertMessage } = useMessagingContext();
    const token = searchParams.get("token");
    const passwordUpdateMutation = usePasswordUpdateMutation();
    const [title, setTitle] = useState("Create new password");
    const [message, setMessage] = useState(
        "Your new password must be at least 8 characters long.",
    );
    const [error, setError] = useState<string>();
    const [showPasswordResetForm, setShowPasswordResetForm] = useState(true);
    const [formData, setFormData] = useState<{
        newPassword: string;
        confirmPassword: string;
    }>({ newPassword: "", confirmPassword: "" });
    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (passwordUpdateMutation.isLoading) return;
        const { newPassword, confirmPassword } = e.currentTarget;
        if (!token) {
            const errMsg = translate(
                language,
                "Password reset failed - missing token",
            );
            tracker(errMsg);
            setError(errMsg);
            throw new Error(errMsg);
        }
        if (newPassword.value !== confirmPassword.value) {
            const errMsg = translate(
                language,
                "Your password and confirmation password do not match.",
            );
            tracker(errMsg);
            setError(errMsg);
            throw new Error(errMsg);
        }
        passwordUpdateMutation.mutate(
            {
                token,
                password: newPassword.value,
                passwordConfirmation: confirmPassword.value,
            },
            {
                onSuccess: () => {
                    tracker("Password reset successful");
                    setTitle("Password set");
                    setMessage("You can now login with your new password.");
                    setShowPasswordResetForm(false);
                },
                onError: () => {
                    tracker("Password update failed.");
                    openAlertMessage({
                        open: true,
                        type: "error",
                        message: "Password update failed.",
                    });
                },
            },
        );
    };
    return (
        <PublicPageContainer page="passwordReset">
            <div className={styles.passwordResetContainer}>
                <PublicPageTitle title={title} />
                {error && <div className={styles.formError}>{error}</div>}
                <Typography variant="text-size-Base">
                    <Translate text={message} />
                </Typography>
                {showPasswordResetForm ? (
                    <form
                        className={styles.formContainer}
                        onSubmit={submitHandler}
                    >
                        <TextField
                            name="newPassword"
                            type="password"
                            label={translate(language, "New password")}
                            placeholder={translate(language, "Enter password")}
                            value={formData.newPassword}
                            fullWidth
                            required
                            InputLabelProps={{ required: false }}
                            onChange={changeHandler}
                        />
                        <TextField
                            name="confirmPassword"
                            type="password"
                            label={translate(language, "Confirm password")}
                            placeholder={translate(
                                language,
                                "Re-enter password",
                            )}
                            value={formData.confirmPassword}
                            fullWidth
                            required
                            InputLabelProps={{ required: false }}
                            onChange={changeHandler}
                        />
                        <Button
                            variant="contained"
                            type="submit"
                            className={styles.formSubmitButton}
                        >
                            <Typography variant="text-size-Base-bold">
                                <Translate text="Reset password" />
                            </Typography>
                        </Button>
                    </form>
                ) : (
                    <Button
                        variant="outlined"
                        className={styles.buttonStyle}
                        onClick={() =>
                            navigate(
                                {
                                    pathname: "/login",
                                    search: searchParams.toString(),
                                },
                                { relative: "path" },
                            )
                        }
                    >
                        <Typography variant="text-size-Base-bold">
                            <Translate text="Back to login" />
                        </Typography>
                    </Button>
                )}
            </div>
        </PublicPageContainer>
    );
};

export default PasswordReset;
