import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import { axiosAPI } from "./axiosAPI";
import useCurriculumQuery from "./useCurriculumQuery";
import { useAppContext } from "contexts/AppContext";

export type NextTopic = {
    isLast: boolean;
    node: {
        id: number;
        logoUrl: string;
        name: string;
        parentId: number;
        parentQualifier: string;
        qualifier: string;
    };
};

const useNextTopicQuery = (topicId: number) => {
    const { data: curriculumData } = useCurriculumQuery();
    const isInStructure = curriculumData?.structure.includes("TOPIC");
    const { config } = useAppContext();
    const { showDrafts } = config;
    return useQuery<NextTopic, AxiosError>(
        ["topicnext", topicId, showDrafts],
        async () => {
            const { data } = await axiosAPI
                .getInstance()
                .get(`curriculum/node/${topicId}/next`, {
                    params: {
                        allPublicationStatus: showDrafts,
                    },
                });
            return data;
        },
        { enabled: !!topicId && !!curriculumData?.id && !!isInStructure },
    );
};

export default useNextTopicQuery;
