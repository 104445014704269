import { useEffect, useState } from "react";

import { useMessagingContext } from "contexts/MessagingContext";
import { useDeleteCollectionMutation } from "hooks/api/collections";
import useCollectionsParams from "hooks/useCollectionsParams";
import { Collection } from "types";
import { tracker } from "utils/analytics";

import { MessageModalProps } from "components/MessageModal/MessageModal";
import { AlertMessageProps } from "components/AlertMessage/AlertMessage";

const DeleteCollectionDialog = ({
    collection,
    isVisible,
    setIsVisible,
}: {
    collection: Collection;
    isVisible: boolean;
    setIsVisible: (arg0: boolean) => void;
}) => {
    const { openAlertMessage, openMessageModal } = useMessagingContext();
    const deleteCollectionMutation = useDeleteCollectionMutation();
    const [isLatched, setIsLatched] = useState(false);
    const collectionsParams = useCollectionsParams();
    useEffect(() => {
        const deleteAlertMessage: AlertMessageProps = {
            message: "Collection has been deleted.",
            type: "error",
            open: true,
        };
        const deleteMessageModal: MessageModalProps = {
            isModalVisible: true,
            title: "Delete the collection",
            body: "Are you sure you want to delete the collection? You can't undo this action.",
            type: "Delete",
            primaryActionHandler: () => {
                tracker("Deleted Colelction");
                deleteCollectionMutation.mutate(
                    { collectionId: collection.id, collectionsParams },
                    {
                        onSuccess: () => openAlertMessage(deleteAlertMessage),
                        onSettled: () => setIsVisible(false),
                    }
                );
            },
        };

        if (isVisible && !isLatched) {
            setIsLatched(true);
            openMessageModal(deleteMessageModal);
        } else if (!isVisible && isLatched) {
            setIsLatched(false);
        }
    }, [
        isVisible,
        isLatched,
        openMessageModal,
        deleteCollectionMutation,
        openAlertMessage,
        setIsVisible,
        collection,
    ]);

    return <></>;
};

export default DeleteCollectionDialog;
