import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCheck } from "@fortawesome/free-solid-svg-icons";
import { Button, Typography } from "@mui/material";

import * as styles from "../ShareModal.module.scss";
import { useAppContext } from "contexts/AppContext";
import { tracker } from "utils/analytics";

import Translate, { translate } from "components/Translate/Translate";

interface SizeInputProps {
    label: string;
    onChange: (e: number) => void;
    onBlur: () => void;
    value: number | undefined;
}

const SizeInput = ({ label, onChange, onBlur, value }: SizeInputProps) => {
    const maxValue = 1440;
    return (
        <div className={styles.sizeContainer}>
            <div className={styles.styleLabel}>{label}</div>
            <input
                className={styles.sizeInput}
                type="number"
                onChange={(e) => {
                    const number = Math.max(
                        0,
                        Math.min(maxValue, parseFloat(e.target.value)),
                    );
                    onChange(number ?? 0);
                }}
                onBlur={onBlur}
                min={1}
                max={maxValue}
                value={value}
            />
        </div>
    );
};

interface Props {
    embedUrlSettings: string;
    copyEmbed: boolean;
    setCopyLink: (arg0: boolean) => void;
    setCopyEmbed: (arg0: boolean) => void;
    width: number | undefined;
    setWidth: (arg0: number | undefined) => void;
    height: number | undefined;
    setHeight: (arg0: number | undefined) => void;
    analyticsOptions?: Record<string, unknown>;
}

const EmbedTabContent = ({
    embedUrlSettings,
    copyEmbed,
    setCopyLink,
    setCopyEmbed,
    width,
    setWidth,
    height,
    setHeight,
    analyticsOptions,
}: Props) => {
    const { config } = useAppContext();
    const defaultHeight = 500;
    const defaultWidth = "100%";
    const [currentWidth, setCurrentWidth] = useState(width);
    const [currentHeight, setCurrentHeight] = useState(height);

    const iframeCode = `<iframe allow="fullscreen" id='SDK in Iframe' width='${
        width ?? defaultWidth
    }' height='${height ?? defaultHeight}' src="${embedUrlSettings}"></iframe>`;

    const previewSettings = encodeURI(embedUrlSettings);
    // const previewSettings = encodeURI(
    //     `${embedUrlSettings}&previewHeight=${
    //         height ?? defaultHeight
    //     }&previewWidth=${width ?? defaultWidth}`
    // );

    return (
        <>
            <div className={styles.tabsContainer}>
                <div className={styles.shareContainer}>
                    <div className={styles.textContainer}>
                        <span className={styles.text}>{iframeCode}</span>
                    </div>
                    <div className={styles.iframeContainer}>
                        <div className={styles.iframeTitle}>
                            <Translate text="Embed size" />
                        </div>
                        <SizeInput
                            label={translate(config.language, "Width")}
                            value={currentWidth}
                            onChange={(number: number) => {
                                setCurrentWidth(number);
                            }}
                            onBlur={() => {
                                setWidth(currentWidth);
                            }}
                        />
                        <SizeInput
                            label={translate(config.language, "Height")}
                            value={currentHeight}
                            onChange={(number: number) => {
                                setCurrentHeight(number);
                            }}
                            onBlur={() => {
                                setHeight(currentHeight);
                            }}
                        />
                    </div>
                </div>
                <Button
                    variant="text"
                    className={styles.previewButton}
                    onClick={() => {
                        window.open(previewSettings)?.focus();
                        tracker("Preview Embed Clicked", analyticsOptions);
                    }}
                >
                    <Translate text="Preview in a new tab" />
                </Button>
            </div>
            <div className={styles.copyContainer}>
                {copyEmbed ? (
                    <div className={styles.copyCorrect}>
                        <FontAwesomeIcon icon={faSquareCheck} />
                        <Typography variant="text-size-Small-bold">
                            <Translate text="Embed code copied to clipboard" />
                        </Typography>
                    </div>
                ) : (
                    <div />
                )}
                <Button
                    variant="contained"
                    className={styles.copyButton}
                    onClick={() => {
                        navigator.clipboard.writeText(iframeCode);
                        setCopyLink(false);
                        setCopyEmbed(true);
                        tracker("Copy Embed Clicked", analyticsOptions);
                    }}
                >
                    <Translate text="Copy to clipboard" />
                </Button>
            </div>
        </>
    );
};

export default EmbedTabContent;
