import * as styles from "./ControlsFooter.module.scss";
import { Result } from "types";
import { tracker } from "utils/analytics";

import Translate from "components/Translate/Translate";
import Feedback from "features/QueryResults/ResultsGrid/ResultCard/Feedback/Feedback";

const ControlsFooter = ({
    isSelectedResult,
    result,
    selectResultHandler,
}: {
    isSelectedResult: boolean | undefined;
    result: Result;
    selectResultHandler: (resultId: string) => void;
}) => {
    return (
        <div className={styles.container}>
            <div className={styles.select}>
                <input
                    type="checkbox"
                    checked={isSelectedResult}
                    onChange={() => {
                        tracker("Query result selected", {
                            result,
                        });
                        selectResultHandler(result.id);
                    }}
                    className={styles.checkbox}
                />
                <Translate text="Select" />
            </div>
            <Feedback result={result} isSearchResult={false} />
        </div>
    );
};

export default ControlsFooter;
