/**
 * The responsive needs to be calculated based
 * on the parent width.
 * If the condition is true, results[1] is returned
 */

export const calcFontSize = ({
    parentWidth,
    containerWidth,
    maxWidth = 1000,
    results = [18, 24],
}) => {
    let width = containerWidth;
    if (!width && parentWidth) {
        width = parentWidth();
    }
    return results[Number(width > maxWidth)];
};

export default calcFontSize;
