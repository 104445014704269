/*
The functions in this file are for supporting old but still active use cases. #
These scenarios are entirely/mostly the old 'widget shares' that customers have deployed and we need to support still.
*/

import { decodeJwt } from "jose";
import { EmbedLibrary, EmbedQuery, EmbedTopic, EmbedType } from "types";
import { isShareMode } from "./newtools";

export const cleanOldPathSearchParams = (pathString: string) => {
    const splitIds = pathString.split("/");
    const objectOfIds: Record<string, string> = splitIds?.reduce(
        (acc: Record<string, string>, curr: string) => {
            const pair = curr.split("=");
            const pairKey = pair[0];
            const pairValue = decodeURIComponent(pair[1]);
            acc[pairKey] = pairValue;
            return acc;
        },
        {}
    );

    if (objectOfIds?.filters) {
        // Rob: This is maybe a bit over engineered, but just incase there's a multi curriculum etc search in use, using old params, this theoretically supports it
        const parsedFilters = JSON.parse(objectOfIds.filters);
        const curriculumFilters = parsedFilters?.curriculums
            ? parsedFilters?.curriculums.split("&&&")
            : null;
        const gradeFilters = parsedFilters?.grades
            ? parsedFilters?.grades.split("&&&")
            : null;
        const layerFilters = parsedFilters?.layers
            ? parsedFilters?.layers.split("&&&")
            : null;
        const subjectFilters = parsedFilters?.subjects
            ? parsedFilters?.subjects.split("&&&")
            : null;

        const preparedFilters = {
            ...(curriculumFilters ? { curriculumFilters } : null),
            ...(gradeFilters ? { gradeFilters } : null),
            ...(layerFilters ? { layerFilters } : null),
            ...{ subjectFilters: subjectFilters ?? [] },
        };
        objectOfIds.filters = JSON.stringify(preparedFilters);
    }

    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete("path");
    Object.entries(objectOfIds).forEach((el) => urlParams.append(el[0], el[1]));
    history.replaceState(null, "", "?" + urlParams.toString());
};

const cleanSlashParams = (token: string) => {
    // Handles legacy urls using '/' divider of params instead of '&'
    const splitToken = token.split("/");
    const urlParams = new URLSearchParams(window.location.search);

    if (splitToken.length <= 1) {
        console.error("Token Clean Failed");
    } else {
        urlParams.set("token", splitToken.shift() as string); // if statement above prevents 0 length array usage here
        splitToken.forEach((el) => {
            const newParam = el.split("=");
            urlParams.set(newParam[0], newParam[1]);
        });
        history.replaceState(null, "", "?" + urlParams.toString());
    }
};

/*
The following url formats are need supporting and converting to current styles:
  - `/?path=queryId={value}/gradeId={value}/...`
  - `/?queryId={value}&gradeId={value}&...`
  - `/?token={jwttoken}` (tokens with and without shareMode (embed/link) value)
  - `/?token={jwttoken}/otherId={value}`
*/
export const sanitiseURL = () => {
    const params = new URLSearchParams(document.location.search);

    const path = params.get("path");
    if (path) {
        // we've got the old path params, remove it and put the params in the url properly
        cleanOldPathSearchParams(path);
    }
    const embedDetails = getEmbedDetails();
    if (isShareMode && embedDetails) {
        // if (token.includes("/")) {
        //     // There's a slash in my token, which is meant to be a param divider like &, so fix it
        //     cleanSlashParams(token);
        // }
        const newURL = new URL(window.location.href);
        if (
            embedDetails.accountId &&
            !(embedDetails.topicId || embedDetails.queryId)
        ) {
            const libraryJWT = embedDetails as EmbedLibrary;
            newURL.pathname = "/library/embed/";
            if (libraryJWT.details.curriculumId)
                newURL.pathname +=
                    "curriculum/" + libraryJWT.details.curriculumId;
        } else if (
            !window.location.pathname.includes("/curriculum-share-viewer/")
        ) {
            const shareModeType = embedDetails.shareMode ?? "embed";
            // Update path
            newURL.pathname = `/curriculum-share-viewer/${shareModeType}`;

            // Push the updated URL to the browser's history
            // Old shares need redirecting to here now. In future this will be to collection viewer
        }
        if (
            embedDetails.topicId &&
            !window.location.pathname.includes("/topic/")
        )
            newURL.pathname += `/topic/${embedDetails.topicId}`;
        if (
            embedDetails.queryId &&
            !window.location.pathname.includes("/query/")
        )
            newURL.pathname += `/query/${embedDetails.queryId}`;
        history.pushState(null, "", newURL.toString());
    }
};

// For pre-token embeds
const embedDetailsFromParams = (
    params: URLSearchParams
): EmbedTopic | EmbedQuery | undefined => {
    const urlAccountId = params.get("accountId") ?? undefined;
    if (!urlAccountId) return undefined;
    const urlUserId = params.get("userId") ?? undefined;
    const urlTopicId = params.get("topicId") ?? undefined;
    if (urlTopicId) {
        return {
            shareMode: "embed",
            creatorUserId: urlUserId,
            accountId: urlAccountId,
            topicId: urlTopicId,
        } as EmbedTopic;
    }
    const urlQueryId = params.get("queryId") ?? undefined;
    return {
        shareMode: "embed",
        creatorUserId: urlUserId,
        accountId: urlAccountId,
        queryId: urlQueryId,
    } as EmbedQuery;
};

export const getEmbedDetails = () => {
    if (!isShareMode) return undefined;
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token")?.split("/")[0];
    if (token) {
        return decodeJwt(token) as EmbedType;
    } else {
        return embedDetailsFromParams(urlParams);
    }
};
