import { Divider, Typography } from "@mui/material";

import * as styles from "./FooterItem.module.scss";
import variables from "styles/_variables.module.scss";
import { tracker } from "utils/analytics";

import WizenozeCopyRight from "./WizenozeCopyRight";
import Translate from "components/Translate/Translate";

const FooterItem = () => {
    return (
        <div className={styles.item}>
            <div className={styles.privacyTerms}>
                <Typography
                    variant="text-size-xSmall-regular"
                    color="$text-colors-color-text-blue.main"
                    onClick={() => {
                        tracker("Privacy Notice Clicked");
                        window.open("https://www.wizenoze.com/cookies/");
                    }}
                >
                    <Translate text="Privacy notice" />
                </Typography>
                <Divider
                    orientation="vertical"
                    style={{
                        height: "12px",
                        borderColor:
                            variables["text-colors-color-text-light-gray"],
                    }}
                />
                <Typography
                    variant="text-size-xSmall-regular"
                    color="$text-colors-color-text-blue.main"
                    onClick={() => {
                        tracker("Terms & Conditions Clicked");
                        window.open(
                            "https://www.wizenoze.com/wp-content/uploads/2021/12/wizenoze-terms-conditions.pdf"
                        );
                    }}
                >
                    <Translate text="Terms & Conditions" />
                </Typography>
            </div>
            <Typography
                variant="text-size-xSmall-regular"
                color="$text-colors-color-text-gray.main"
            >
                <WizenozeCopyRight />
            </Typography>
        </div>
    );
};

export default FooterItem;
