@use "../../../../styles/variables.module";

.resultContainer {
    padding: 20px 40px;
}

.topicResultContainer {
    padding-top: 20px;
}

@media (max-width: variables.$bp-md) {
    .resultContainer {
        padding: 20px 10px;
    }
}
