import quizPatternPNG from "./assets/quiz-pattern.png";

export const BackgroundPattern = ({
    colourState,
    height,
}: {
    colourState: "neutral" | "right" | "wrong";
    height?: string;
}) => {
    const backgroundColor = {
        neutral: "linear-gradient(153deg, #558FDD 0%, #1C539D 100%)",
        right: "green",
        wrong: "red",
    }[colourState];
    return (
        <div
            style={{
                background: backgroundColor,
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                height: height ?? "180px",
            }}
        >
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(${quizPatternPNG})`,
                    backgroundSize: "10%",
                    opacity: 0.25,
                }}
            ></div>
        </div>
    );
};
