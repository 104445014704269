import { useState } from "react";

import * as styles from "./ForgotPassword.module.scss";

import PublicPageContainer from "../components/PublicPageContainer/PublicPageContainer";
import PublicPageTitle from "../components/PublicPageTitle/PublicPageTitle";
import CheckEmailMessage from "./components/CheckEmailMessage";
import ForgotPasswordForm from "./components/ForgotPasswordForm";
import SecondEmailSentMessage from "./components/SecondEmailSentMessage";

const ForgotPassword = () => {
    const [title, setTitle] = useState("Forgot password");
    const [error, setError] = useState<string | undefined>();
    const [email, setEmail] = useState<string>("");
    const [showForgotPasswordForm, setShowForgotPasswordForm] = useState(true);
    const [secondEmailSent, setSecondEmailSent] = useState(false);
    return (
        <PublicPageContainer page="forgotPassword">
            <div className={styles.forgotPasswordContainer}>
                <div className={styles.title}>
                    <PublicPageTitle title={title} />
                </div>
                {error && <div className={styles.formError}>{error}</div>}
                {showForgotPasswordForm ? (
                    <ForgotPasswordForm
                        email={email}
                        setEmail={setEmail}
                        setShowForgotPasswordForm={setShowForgotPasswordForm}
                        setTitle={setTitle}
                        setError={setError}
                    />
                ) : (
                    <div className={styles.messageAndButtonContainer}>
                        {secondEmailSent ? (
                            <SecondEmailSentMessage />
                        ) : (
                            <CheckEmailMessage
                                email={email}
                                setError={setError}
                                setSecondEmailSent={setSecondEmailSent}
                            />
                        )}
                    </div>
                )}
            </div>
        </PublicPageContainer>
    );
};

export default ForgotPassword;
