import _ from "lodash";

export const getValue = (data: Record<string, unknown>, key: string) =>
    _.has(data, [key]) && !_.isEmpty(data[key]) ? data[key] : [];

export const compareArrLength = (arr1: Array<unknown>, arr2: Array<unknown>) =>
    arr1?.length === arr2?.length || _.isEmpty(arr1) || _.isEmpty(arr2);

export const formatValues = (
    data: Array<{ key: string } | string>,
    values: Array<{ key: string }>,
    firstFormat: boolean
) =>
    data.map((item) => {
        const name = typeof item === "string" ? item : item.key;
        return {
            name,
            value: firstFormat
                ? 0
                : !_.isEmpty(
                      values?.filter(
                          (value) =>
                              name?.toLowerCase() === value?.key?.toLowerCase()
                      )
                  )
                ? 1
                : 0,
        };
    });
