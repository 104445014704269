@use "../../../../styles/variables.module";

.subjectCard {
    display: flex;
    padding: 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 15px;
    width: 100%;
    height: 186px;
    box-shadow: variables.$card-box-shadow;
    background: #dededf;
    cursor: pointer;
}

.imageClass {
    max-height: 90px;
}

.subjectTitle {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    text-align: center;
    overflow: hidden;
}

@media (max-width: variables.$bp-sm) {
    .subjectCard {
        height: 155px;
    }
    .subjectTitle {
        -webkit-line-clamp: 1;
    }
}
