import { useState } from "react";
import { Box, TextField } from "@mui/material";

import { useMessagingContext } from "contexts/MessagingContext";
import { useCreateFolderMutation } from "hooks/api/folders";
import useCollectionsParams from "hooks/useCollectionsParams";
import { tracker } from "utils/analytics";

import DialogBox from "components/DialogBox/DialogBox";

const NewFolderDialog = ({
    isVisible,
    setIsVisible,
}: {
    isVisible: boolean;
    setIsVisible: (arg0: boolean) => void;
}) => {
    const { openAlertMessage } = useMessagingContext();
    const collectionsParams = useCollectionsParams();
    const createFolderMutation = useCreateFolderMutation();
    const [newFolderName, setNewFolderName] = useState("");
    const createFolderHandler = () => {
        if (collectionsParams.length === 0) return;

        createFolderMutation.mutate(
            {
                parentId: collectionsParams[collectionsParams.length - 1],
                title: newFolderName,
                description: "",
                imageUrl: "",
                // language: "en",
                // resultModal: false,
                // color: "#000000",
            },
            {
                onSuccess: () => {
                    openAlertMessage({
                        message: "Folder created successfully.",
                        type: "success",
                        open: true,
                    });
                    tracker("Created folder");
                    setNewFolderName("");
                    setIsVisible(false);
                },
                onError: (error) => {
                    openAlertMessage({
                        message: "Error",
                        type: "error",
                        open: true,
                    });
                    console.error(error);
                    setNewFolderName("");
                    setIsVisible(false);
                },
            }
        );
        return;
    };
    return (
        <DialogBox
            isOpen={isVisible}
            closeHandler={() => setIsVisible(false)}
            title="New folder"
            withCloseButton
            styleOverrides={{ width: "435px", height: "unset" }}
            secondaryButton={{
                label: "Cancel",
                clickHandler: () => setIsVisible(false),
            }}
            primaryButton={{
                label: "Save",
                clickHandler: createFolderHandler,
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    paddingTop: "30px",
                    paddingBottom: "30px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    backgroundColor:
                        "$background-colors-color-background-light.main",
                }}
            >
                <TextField
                    label="Folder name"
                    placeholder="insert folder name"
                    style={{
                        width: "100%",
                        backgroundColor: "white",
                    }}
                    autoFocus
                    value={newFolderName}
                    onChange={(e) => setNewFolderName(e.target.value)}
                />
            </Box>
        </DialogBox>
    );
};

export default NewFolderDialog;
