import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChalkboardUser, faUserCog } from "@fortawesome/free-solid-svg-icons";

import * as styles from "./UserItem.module.scss";
import useUserQuery from "hooks/api/useUserQuery";
import variables from "styles/_variables.module.scss";

interface Props {
    closeMenu: () => void;
}

const UserItem = ({ closeMenu }: Props) => {
    const navigate = useNavigate();
    const { data: userData } = useUserQuery();
    return (
        <div
            className={styles.item}
            onClick={() => {
                closeMenu();
                navigate("/settings?tab=profile");
            }}
        >
            <div className={styles.logo}>
                <FontAwesomeIcon
                    icon={faChalkboardUser}
                    color={variables["icon-colors-green-100"]}
                    fontSize={15}
                />
            </div>
            <div className={styles.info}>
                <Typography
                    variant="text-size-Base-regular"
                    color="$text-colors-color-text-dark.main"
                >
                    {userData?.me?.firstName}
                </Typography>
                <Typography
                    variant="text-size-Small-regular"
                    color="$text-colors-color-text-gray.main"
                >
                    {userData?.me?.email}
                </Typography>
            </div>
            <div className={styles.settings}>
                <FontAwesomeIcon
                    icon={faUserCog}
                    color={variables["text-colors-color-text-blue"]}
                    fontSize={15}
                />
            </div>
        </div>
    );
};

export default UserItem;
