import { useEffect, useState } from "react";

import { useMessagingContext } from "contexts/MessagingContext";
import { useDeleteFolderMutation } from "hooks/api/folders";
import { Folder } from "types";
import { tracker } from "utils/analytics";

import { MessageModalProps } from "components/MessageModal/MessageModal";
import { AlertMessageProps } from "components/AlertMessage/AlertMessage";
import Translate from "components/Translate/Translate";

const DeleteFolderDialog = ({
    folder,
    isVisible,
    setIsVisible,
}: {
    folder: Folder;
    isVisible: boolean;
    setIsVisible: (arg0: boolean) => void;
}) => {
    const { openAlertMessage, openMessageModal } = useMessagingContext();
    const deleteFolderMutation = useDeleteFolderMutation();
    const [isLatched, setIsLatched] = useState(false);

    useEffect(() => {
        const deleteAlertMessage: AlertMessageProps = {
            message: "Folder has been deleted.",
            type: "error",
            open: true,
        };
        const deleteMessageModal: MessageModalProps = {
            isModalVisible: true,
            title: "Delete the folder",
            body: (
                <div>
                    <Translate text="Are you sure you want to delete the folder? All collections and sub-folders will be deleted." />
                    <Translate text="You can’t undo this action." />
                </div>
            ),
            type: "Delete",
            primaryActionHandler: () => {
                tracker("Delete Folder");
                deleteFolderMutation.mutate(folder, {
                    onSuccess: () => openAlertMessage(deleteAlertMessage),
                    onSettled: () => setIsVisible(false),
                });
            },
        };

        if (isVisible && !isLatched) {
            setIsLatched(true);
            openMessageModal(deleteMessageModal);
        } else if (!isVisible && isLatched) {
            setIsLatched(false);
        }
    }, [
        isVisible,
        isLatched,
        openMessageModal,
        deleteFolderMutation,
        folder,
        openAlertMessage,
        setIsVisible,
    ]);

    return <></>;
};

export default DeleteFolderDialog;
