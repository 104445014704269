import { useAppContext } from "contexts/AppContext";
import { useParams } from "react-router-dom";
import { useUserCurrentLayerQuery } from "./api/useUserSettingsQuery";

function arrayToObject(arr: string[] | undefined) {
    if (!arr) return [];
    const returnValue = [];
    for (let i = 0; i < arr.length; i += 2) {
        if (arr[i] != "query")
            returnValue.push({
                key: arr[i] + "Id",
                value: parseFloat(arr[i + 1]),
            });
    }
    return returnValue;
}

const getIdValue = (
    paramStrings: string[] | undefined,
    targetParam: string,
) => {
    if (!paramStrings) return undefined;
    return paramStrings?.includes(targetParam)
        ? parseInt(paramStrings[paramStrings.indexOf(targetParam) + 1])
        : undefined;
};

const useCurriculumParams = () => {
    const params = useParams();
    const { data: userCurrentLayer } = useUserCurrentLayerQuery();
    const { libraryCurriculumId } = useAppContext();
    const nodeParams = params["*"]?.split("/");
    let curriculumId = params["curriculumId"]
        ? parseInt(params["curriculumId"])
        : getIdValue(nodeParams, "curriculum");
    if (window.location.pathname.includes("library")) {
        // Library share needs to use raw window path for curriculumId
        if (libraryCurriculumId) {
            curriculumId = libraryCurriculumId as number;
        } else if (!curriculumId) {
            curriculumId = getIdValue(
                window.location.pathname.split("/"),
                "curriculum",
            );
        }
    } else if (!curriculumId && userCurrentLayer?.curriculumId) {
        curriculumId = parseFloat(userCurrentLayer?.curriculumId);
    }
    const gradeId = getIdValue(nodeParams, "grade");
    const subjectId = getIdValue(nodeParams, "subject");
    const unitId = getIdValue(nodeParams, "unit");
    const topicId = getIdValue(nodeParams, "topic");
    const queryId = getIdValue(nodeParams, "query");

    return {
        curriculumId,
        gradeId,
        subjectId,
        unitId,
        topicId,
        queryId,
        orderedNodeIds: arrayToObject(nodeParams),
    };
};

export default useCurriculumParams;
