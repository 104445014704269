import { Button, Divider, Typography } from "@mui/material";

import * as styles from "../VerifyEmail.module.scss";
import { tracker } from "utils/analytics";

import Translate from "components/Translate/Translate";

interface Props {
    setTitle: (title: string) => void;
    setShowSendConfirmationAgainForm: (
        showSendConfirmationAgainForm: boolean
    ) => void;
}

const NotVerifiedMessage = ({
    setTitle,
    setShowSendConfirmationAgainForm,
}: Props) => {
    const sendConfirmationAgainClickHandler = () => {
        tracker("Send Confirmation Again Button Clicked");
        setShowSendConfirmationAgainForm(true);
        setTitle("Send confirmation again");
    };
    const contactSupportClickHandler = () => {
        tracker("Support Team Clicked");
        window.location.href = "mailto:support@wizenoze.com";
    };
    return (
        <div className={styles.notVerifiedButtons}>
            <Button
                variant="contained"
                className={styles.buttonStyle}
                onClick={sendConfirmationAgainClickHandler}
            >
                <Typography variant="text-size-Base-bold">
                    <Translate text="Send confirmation again" />
                </Typography>
            </Button>
            <Divider style={{ width: "100%" }}>
                <Typography
                    variant="text-size-xSmall-regular"
                    color="$text-colors-color-text-gray.main"
                >
                    <Translate text="OR" />
                </Typography>
            </Divider>
            <Button
                variant="outlined"
                className={styles.buttonStyle}
                onClick={contactSupportClickHandler}
            >
                <Typography variant="text-size-Base-bold">
                    <Translate text="Contact support" />
                </Typography>
            </Button>
        </div>
    );
};

export default NotVerifiedMessage;
