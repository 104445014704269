import { useState, useEffect, useRef } from "react";
import {
    createSearchParams,
    useNavigate,
    useSearchParams,
} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faMagnifyingGlass,
    faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";

import {
    baseContainer,
    openContainer,
    openList,
    searchForm,
    searchButton,
} from "./SearchInput.module.scss";
import { useAppContext } from "contexts/AppContext";
import variables from "styles/_variables.module.scss";
import { tracker } from "utils/analytics";

import Autocomplete from "./Autocomplete/Autocomplete";
import MenuButton from "components/MenuButton/MenuButton";
import { translate } from "components/Translate/Translate";

const SearchInput = () => {
    const {
        config: { language },
    } = useAppContext();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const searchTerm = searchParams.get("searchTerm");
    const [term, setTerm] = useState(searchTerm);
    const [isOpen, setIsOpen] = useState(false);
    const [showAutoComplete, setAutoCompleteDisplay] = useState(false);
    useEffect(() => {
        // When the url searchTerm updates, update the input
        setTerm(searchTerm);
    }, [searchTerm]);

    const inputRef = useRef<HTMLInputElement>(null);
    const resetTerm = () => {
        setTerm("");
    };
    const closeSearch = () => {
        tracker("Search bar closed");
        setIsOpen(false);
    };
    const handleChange = (e: React.KeyboardEvent) => {
        e.preventDefault();
        e.code !== "Enter" && setAutoCompleteDisplay(true);
    };
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (term) {
            tracker("Performed Search", { searchTerm: e.target.search.value });
            const allParams = Object.fromEntries([...searchParams]);
            if (searchParams.get("queryResult")) delete allParams.queryResult;

            navigate({
                pathname: "/search",
                search: createSearchParams({
                    ...allParams,
                    searchTerm: term,
                    subjectFilters: [],
                }).toString(),
            });
            setAutoCompleteDisplay(false);
            setIsOpen(false);
        }
    };
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTerm(e.target.value);
    };
    const toggleSearchInput = () => {
        setIsOpen(!isOpen);
    };
    return (
        <div data-testid="baseContainer" className={baseContainer}>
            <MenuButton
                dataTestId={"searchMenuButton"}
                faIcon={faMagnifyingGlass}
                iconColor={variables["text-colors-color-text-gray"]}
                primaryColor={
                    variables["background-colors-color-background-white"]
                }
                secondaryColor={variables["border-colors-color-border-primary"]}
                isOpen={isOpen}
                onClick={toggleSearchInput}
            />
            {isOpen && (
                <div className={openContainer} onClick={closeSearch}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <div
                            className={openList}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <form
                                className={searchForm}
                                onSubmit={handleSubmit}
                            >
                                <input
                                    ref={inputRef}
                                    name="search"
                                    autoComplete="off"
                                    onKeyUp={handleChange}
                                    placeholder={translate(language, "Search")}
                                    autoFocus={true}
                                    onChange={onChange}
                                    value={term ?? ""}
                                />
                                {term != null && term.length > 0 && (
                                    <div
                                        style={{
                                            color: variables[
                                                "text-colors-color-text-gray"
                                            ],
                                            borderRight: "2px",
                                            borderColor:
                                                variables[
                                                    "border-colors-color-border-light"
                                                ],
                                        }}
                                        onMouseDown={(e) => e.preventDefault()}
                                        onClick={resetTerm}
                                    >
                                        <FontAwesomeIcon icon={faCircleXmark} />
                                    </div>
                                )}
                                <button
                                    className={searchButton}
                                    aria-label={translate(
                                        language,
                                        "Search icon",
                                    )}
                                    onMouseDown={(e) => e.preventDefault()}
                                >
                                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                                </button>
                            </form>
                            {showAutoComplete && (
                                <Autocomplete
                                    searchTerm={term ?? ""}
                                    resetAutoCompleteDisplay={() => {
                                        setAutoCompleteDisplay(false);
                                        setIsOpen(false);
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SearchInput;
