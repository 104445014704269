import { useState, useEffect } from "react";

const containerTypeNames = Object.freeze({
    PHONE: "PHONE",
    LARGE_PHONE: "LARGE_PHONE",
    SMALL_TABLET: "SMALL_TABLET",
    TABLET: "TABLET",
    LARGE_TABLET: "LARGE_TABLET",
    LAPTOP: "LAPTOP",
    LARGE: "LARGE",
    EXTRA_LARGE: "EXTRA_LARGE",
    DESKTOP: "DESKTOP",
});

const containerTypes: Array<{
    name: keyof typeof containerTypeNames;
    coarseName: keyof typeof containerTypeNames;
    minmax: [number, number];
}> = [
    {
        name: containerTypeNames.PHONE,
        coarseName: containerTypeNames.PHONE,
        minmax: [0, 320],
    },
    {
        name: containerTypeNames.LARGE_PHONE,
        coarseName: containerTypeNames.PHONE,
        minmax: [320, 414],
    },
    {
        name: containerTypeNames.SMALL_TABLET,
        coarseName: containerTypeNames.TABLET,
        minmax: [414, 640],
    },
    {
        name: containerTypeNames.TABLET,
        coarseName: containerTypeNames.TABLET,
        minmax: [640, 768],
    },
    {
        name: containerTypeNames.LARGE_TABLET,
        coarseName: containerTypeNames.TABLET,
        minmax: [768, 1024],
    },
    {
        name: containerTypeNames.LAPTOP,
        coarseName: containerTypeNames.DESKTOP,
        minmax: [1024, 1280],
    },
    {
        name: containerTypeNames.LARGE,
        coarseName: containerTypeNames.DESKTOP,
        minmax: [1280, 1920],
    },
    {
        name: containerTypeNames.EXTRA_LARGE,
        coarseName: containerTypeNames.DESKTOP,
        minmax: [1920, 25000],
    },
];

const searchMaxWidths = {
    [320]: 225,
    [414]: 319,
    [640]: 545,
    [768]: 245,
    [1024]: 301,
    [1280]: 305,
    [1440]: 440,
};

const calculateWidth = (val: number, percentage: number) => {
    if (!val || !percentage) return;
    return `${Math.round(val * (percentage / 100))}px`;
};

const calcSearchWidth = (containerWidth: number) => {
    if (containerWidth < 768) return calculateWidth(containerWidth, 100);
    if (containerWidth > 1440) return 440;

    const key = Object.keys(searchMaxWidths).find(
        (key) => containerWidth <= Number(key)
    );
    return searchMaxWidths[key];
};

const useContainerSize = () => {
    const getContainerSize = () => {
        const isWidthBetween = ([min, max]: [min: number, max: number]) => {
            return Boolean(width > min && width <= max);
        };
        return (
            containerTypes.find((item) => isWidthBetween(item.minmax)) ??
            containerTypes.at(-1)
        );
    };

    const [width, setWidth] = useState(window.innerWidth);
    const [searchWidth, setSearchWidth] = useState(
        calcSearchWidth(window.innerWidth)
    );
    const [containerSize, setContainerSizeName] = useState(getContainerSize());
    const resize = () => {
        setWidth(window.innerWidth);
        setSearchWidth(window.innerWidth);
        setContainerSizeName(getContainerSize());
    };

    useEffect(() => {
        window.addEventListener("resize", resize);
        return () => window.removeEventListener("resize", resize);
    });

    return { width, searchWidth, containerSize };
};

export default useContainerSize;
