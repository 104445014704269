@use "../../styles/variables.module";

.headerContainer {
    padding: 15px 40px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    background-color: variables.$background-colors-color-background-light-off;
    width: 100%;
    height: 71px;
    border-bottom: 1px solid variables.$border-colors-color-border-primary;
}

.titleText {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    color: variables.$text-colors-color-text-dark;
    display: flex;
    align-items: center;
}

.logoContainer {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: 40px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.buttonsContainer {
    display: flex;
    flex-grow: 1;
    flex-basis: 0%;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 10px;
    padding-left: 20px;
}

@media (max-width: variables.$bp-lg) {
    .titleText {
        display: none;
    }
}

@media (max-width: variables.$bp-md) {
    .headerContainer {
        padding: 15px 10px;
    }
    .buttonsContainer {
        padding-left: 10px;
        gap: 10px;
    }
    .subheaderWrapper {
        padding: 10px 10px;
    }
}
