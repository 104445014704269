import TopicCard from "components/TopicCard/TopicCard";
import { Query, Topic } from "types";

interface Props {
    nodeData: Topic;
    suggestedQueries: Array<Query>;
    showQueryResult: () => void;
    path: string;
    subjectTitle: string;
}

const TopicSearchCard = ({
    nodeData,
    suggestedQueries,
    showQueryResult,
    path,
    subjectTitle,
}: Props) => {
    return (
        <TopicCard
            cardHeading={nodeData.name}
            cardImage={nodeData?.logoUrl}
            listItems={suggestedQueries}
            topicData={nodeData}
            clickAction={showQueryResult}
            path={path}
            subjectTitle={subjectTitle}
        />
    );
};

export default TopicSearchCard;
