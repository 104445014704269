import { useState } from "react";
import { Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-solid-svg-icons";

import * as styles from "./FolderCard.module.scss";
import variables from "styles/_variables.module.scss";
import { Folder } from "types";
import { isAppMode } from "utils/newtools";

import FolderThreeDotContextMenu from "components/ContextMenu/FolderThreeDotContextMenu";
import Translate from "components/Translate/Translate";

interface Props {
    folder: Folder;
    clickHandler: (folder: Folder) => void;
    disableContextMenu?: boolean;
}

const FolderCard = ({ folder, clickHandler, disableContextMenu }: Props) => {
    const [hasImageFetchFailed, setHasImageFetchFailed] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const mouseOverHandler = () => {
        setIsHovered(true);
    };
    const mouseLeaveHandler = () => {
        setIsHovered(false);
    };
    return (
        <div
            className={styles.container}
            onClick={() => clickHandler(folder)}
            onMouseOver={
                isAppMode && !disableContextMenu && !folder.isRootFolder
                    ? mouseOverHandler
                    : undefined
            }
            onMouseLeave={
                isAppMode && !disableContextMenu && !folder.isRootFolder
                    ? mouseLeaveHandler
                    : undefined
            }
        >
            {isAppMode && !disableContextMenu && !folder.isRootFolder && (
                <div style={{ position: "absolute", right: 10, top: 5 }}>
                    <FolderThreeDotContextMenu
                        folder={folder}
                        isHovered={isHovered}
                    />
                </div>
            )}
            <div className={styles.image}>
                {!hasImageFetchFailed && folder.imageUrl ? (
                    <img
                        src={folder.imageUrl}
                        alt={folder.title}
                        aria-hidden="true"
                        style={{
                            objectFit: "contain",
                            width: "100%",
                            height: "100%",
                        }}
                        onError={() => setHasImageFetchFailed(true)}
                    />
                ) : (
                    <FontAwesomeIcon
                        icon={faFolder}
                        color={
                            folder.color ||
                            variables["text-colors-color-text-light-gray"]
                        }
                        fontSize={32}
                    />
                )}
            </div>
            <Typography
                variant="text-size-Base-bold"
                color="$text-colors-color-text-dark.main"
                className={styles.title}
            >
                {folder.isRootFolder ? (
                    <Translate text={folder.title} />
                ) : (
                    folder.title
                )}
            </Typography>
        </div>
    );
};

export default FolderCard;
