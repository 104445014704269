/**
 * A function to check if the given ref
 * is in a modal state or not.
 * The computed values is saved.
 * Subsequent renders might not have the element
 * readily available but the information is still
 * current.
 * @param {Object} myCard The React ref object
 * @param {Object} options Currently only supports reset option
 * @returns Boolean
 */
export const isModalElement = (function () {
    let _isModal;

    return function (myCard, { reset } = {}) {
        reset && (_isModal = undefined); // use the options to reset
        if (myCard?.current) {
            _isModal = ["fixed"].includes(
                window.getComputedStyle(myCard.current).position
            );
        }
        return _isModal;
    };
})();
