import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { axiosAPI } from "./axiosAPI";
import { GeneratedQuestions, Quiz } from "types";

export const useCreateQuizMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async ({
            collectionId,
            quiz,
        }: {
            collectionId: number | string;
            quiz: Quiz;
        }) => {
            return await axiosAPI
                .getInstance()
                .put(`/content/quiz/${collectionId}/quiz/`, {
                    ...quiz,
                });
        },
        {
            onSettled: (_, __, { collectionId }) =>
                queryClient.invalidateQueries([
                    "collection",
                    collectionId.toString(),
                ]),
        },
    );
};

export const useDeleteQuizMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async ({ collectionId }: { collectionId: number | string }) => {
            return await axiosAPI
                .getInstance()
                .delete(`/content/quiz/${collectionId}/`);
        },
        {
            onSettled: (_, __, { collectionId }) =>
                queryClient.invalidateQueries([
                    "collection",
                    collectionId.toString(),
                ]),
        },
    );
};

// const fakeQuizData = {
//     id: 16818,
//     title: "Quiz test",
//     description: "",
//     imageUrl: "",
//     access: "null",
//     type: "QUIZ",
//     lastModifiedDate: "2024-07-03T20:12:02",
//     answerKey: {
//         q1: "q1a4",
//         q2: "q2a2",
//         q1Explanation:
//             "12 is an even number because it is exactly divisible by 2.",
//         q2Explanation:
//             "The numbers in the sequence are increasing by 2. So the next number after 10 is 12.",
//     },
//     questions: [
//         {
//             id: "q1",
//             answers: [
//                 {
//                     id: "q1a1",
//                     text: "5",
//                 },
//                 {
//                     id: "q1a2",
//                     text: "7",
//                 },
//                 {
//                     id: "q1a3",
//                     text: "11",
//                 },
//                 {
//                     id: "q1a4",
//                     text: "12",
//                 },
//             ],
//             question: "Which of the following is an example of an even number?",
//         },
//         {
//             id: "q2",
//             answers: [
//                 {
//                     id: "q2a1",
//                     text: "11",
//                 },
//                 {
//                     id: "q2a2",
//                     text: "12",
//                 },
//                 {
//                     id: "q2a3",
//                     text: "13",
//                 },
//                 {
//                     id: "q2a4",
//                     text: "14",
//                 },
//             ],
//             question:
//                 "What is the next number in the sequence: 2, 4, 6, 8, 10?",
//         },
//     ],
// };

export const useQuizQuery = (collectionId?: number | string) => {
    return useQuery<Quiz, AxiosError>(
        ["quiz", collectionId?.toString()],
        async () => {
            const { data } = await axiosAPI
                .getInstance()
                .get(`/content/quiz/${collectionId}/quiz/`);
            return data;
            // const fakeFunc = async () => fakeQuizData;
            // const result = await fakeFunc();
            // return result;
        },
        { enabled: !!collectionId },
    );
};

export const useShareQuizQuery = (
    collectionId?: number | string,
    hash?: number | string,
) => {
    return useQuery<Quiz, AxiosError>(
        ["share-quiz", collectionId, hash],
        async () => {
            const { data } = await axiosAPI
                .getInstance()
                .get(`/content/share/quiz/`, {
                    params: { collectionId, hash },
                });
            return data;
        },
        { enabled: !!collectionId && !!hash },
    );
};

export const useGenerateQuestionsQuery = (
    collectionId?: number | string,
    numberOfQuestions?: number,
    options?: { timeStamp?: string },
) => {
    return useQuery<GeneratedQuestions, AxiosError>(
        ["generate-questions", collectionId, options?.timeStamp],
        async () => {
            const { data } = await axiosAPI
                .getInstance()
                .get(
                    `/content/quiz/${collectionId}/questions?numberOfQuestions=${numberOfQuestions}`,
                );
            return data;
        },
        {
            enabled: !!collectionId && !!options?.timeStamp,
            staleTime: Infinity,
        },
    );
};

type SendQuizEmailDetails = {
    studentName: string;
    numberOfCorrectAnswers: number;
    questionsFailed: Array<string>;
};

export const useSendQuizEmailMutation = (
    collectionId: number | string,
    hash: string,
) => {
    return useMutation(async (sendQuizEmailDetails: SendQuizEmailDetails) => {
        await axiosAPI
            .getInstance()
            .post(
                `/content/share/${collectionId}/email?shareId=${collectionId}&hash=${hash}`,
                sendQuizEmailDetails,
            );
    });
};
