import { useState } from "react";
import {
    Button,
    Theme,
    Tooltip,
    Typography,
    useMediaQuery,
} from "@mui/material";

import * as styles from "./ReadingLevelFilter.module.scss";
import variables from "styles/_variables.module.scss";
import { ReadingLevel } from "types";
import { tracker } from "utils/analytics";

import ReadingLevelButton from "./components/ReadingLevelButton/ReadingLevelButton";
import ReadingLevelTooltip from "./components/ReadingLevelTooltip/ReadingLevelTooltip";

export const allReadingLevels: ReadingLevel[] = ["1", "2", "3", "4", "5"];

export const allReadingLevelsSelected = (readingLevels: ReadingLevel[]) => {
    return allReadingLevels.every((level) => readingLevels.includes(level));
};
interface Props {
    readingLevels: ReadingLevel[];
    updateReadingLevels: (level: ReadingLevel) => void;
}

const ReadingLevelFilter = ({ readingLevels, updateReadingLevels }: Props) => {
    const isMobileScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("md")
    );
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    return (
        <div className={styles.container}>
            <div className={styles.readingLevels}>
                {allReadingLevels.map((level) => (
                    <ReadingLevelButton
                        key={level}
                        level={level}
                        readingLevel={readingLevels}
                        updateReadingLevel={updateReadingLevels}
                        allReadingLevelsSelected={allReadingLevelsSelected(
                            readingLevels
                        )}
                    />
                ))}
            </div>
            <Tooltip
                title={
                    <ReadingLevelTooltip
                        closeHandler={() => {
                            tracker("Reading level modal off");
                            setIsTooltipOpen(false);
                        }}
                    />
                }
                placement={isMobileScreen ? "bottom" : "right"}
                open={isTooltipOpen}
                slotProps={{
                    tooltip: { style: { maxWidth: "unset" } },
                }}
                onClick={(e) => e.preventDefault()}
            >
                <Button
                    data-testid="open-reading-level-info-button"
                    variant="outlined"
                    color="$border-colors-color-border-primary"
                    style={{
                        width: "25px",
                        height: "25px",
                        borderRadius: "25px",
                    }}
                    onClick={() => {
                        tracker("Reading level modal on");
                        setIsTooltipOpen(true);
                    }}
                >
                    <Typography
                        variant="text-size-xSmall-bold"
                        style={{
                            color: variables["text-colors-color-text-gray"],
                        }}
                    >
                        ?
                    </Typography>
                </Button>
            </Tooltip>
        </div>
    );
};

export default ReadingLevelFilter;
