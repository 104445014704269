@use "../../styles/variables.module";

.paginationContainer {
    color: variables.$text-colors-color-text-dark;
    text-align: center;
    padding-bottom: 16px;
}
.innerContainer {
    display: flex;
    place-items: center;
    justify-content: center;
}

.label {
    color: variables.$text-colors-color-text-gray;
    margin: 16px 0;
}

.button {
    background-color: white;
    color: #0d6efd;
    border: none;
    padding: 7px;
    border: 1px solid #dee2e6;
    padding: 6px 12px;
    text-decoration: none;
    height: 32px;
    cursor: pointer;
}

.buttonFeatured {
    border: none;
    padding: 7px;
    border: 1px solid #dee2e6;
    padding: 6px 12px;
    text-decoration: none;
    height: 32px;
    background-color: #0d6efd;
    color: white;
    border-color: #0d6efd;
}

.icon {
    color: #0d6efd;
}

.pageList {
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0;
    border: none;
}

.pageListItem {
    padding: 0;
    margin: 0;
    border: 0;
    display: inline-block;
}
