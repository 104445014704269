import { useState, useRef, useLayoutEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import * as styles from "./TopicCard.module.scss";
import useContainerSize from "hooks/useContainerSize";
import { Query, Topic } from "types";
import { tracker } from "utils/analytics";

import CardImage from "./components/CardImage";
import CardList from "./components/CardList";
import CardFooter from "./components/CardFooter";
import ShareButton from "components/ShareButton/ShareButton";

interface Props {
    cardHeading: string;
    cardImage: string | undefined;
    listItems: Array<Query>;
    topicData: Topic;
    subjectTitle?: string;
    path?: string;
    clickAction?: () => void;
}

const TopicCard = ({
    cardHeading,
    cardImage,
    listItems,
    topicData,
    subjectTitle,
    path,
    clickAction,
}: Props) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { width } = useContainerSize();
    const [isCardExpanded, setIsCardExpanded] = useState(false);

    const ref = useRef<HTMLDivElement | null>(null);
    const [preExpansionHeight, setPreExpansionHeight] = useState<number>();
    useLayoutEffect(() => {
        if (!preExpansionHeight && ref.current?.clientHeight)
            setPreExpansionHeight(ref.current?.clientHeight);
    }, [preExpansionHeight]);

    const handleClick = (index: number) => {
        tracker("Topic clicked", topicData);
        if (clickAction) return clickAction();
        navigate(
            {
                pathname: "./query/" + index.toString(),
                search: searchParams.toString(),
            },
            {
                relative: "path",
            }
        );
    };

    const expandCollapseCard = () => {
        setIsCardExpanded((current) => !current);
        tracker("Card Show/Hide more");
    };

    return (
        <div
            className={styles.cardContainer}
            data-card-expanded={isCardExpanded}
            ref={ref}
            style={{
                minWidth: width > 470 ? undefined : "95%",
                maxWidth: width > 470 ? undefined : "95%",
                height: isCardExpanded ? preExpansionHeight : undefined,
            }}
        >
            {topicData?.embedUrl && topicData?.linkUrl && (
                <ShareButton
                    embedUrl={topicData.embedUrl}
                    linkUrl={topicData.linkUrl}
                    hasText={false}
                    analyticsOptions={{ topicId: topicData.id }}
                    styleOverrides={{
                        position: "absolute",
                        right: "15px",
                        top: "15px",
                    }}
                />
            )}
            <div
                className={styles.innerCard}
                onClick={() => handleClick(listItems[0]?.id)}
                data-testid="cardContainer"
                tabIndex={0}
            >
                <CardImage
                    cardImage={cardImage}
                    cardHeading={cardHeading}
                    subjectTitle={subjectTitle}
                />

                <div className={styles.cardBody}>
                    <span className={styles.cardTitle}>{cardHeading}</span>
                    {path && (
                        <div className={styles.pathLabel}>
                            {path.replace(">", "|")}
                        </div>
                    )}
                    <CardList
                        listItems={listItems}
                        handleClick={handleClick}
                        isCardExpanded={isCardExpanded}
                    />
                </div>
            </div>
            <CardFooter
                listItemsTotal={listItems?.length}
                expandCollapseCard={expandCollapseCard}
                isCardExpanded={isCardExpanded}
            />
        </div>
    );
};

export default TopicCard;
