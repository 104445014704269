@use "../../../../../styles/variables.module";

.outerContainer {
    margin-bottom: 20px;
    padding: 20px;
    background: variables.$background-colors-color-background-white;
    border-radius: 15px;
    border: 1px solid variables.$border-colors-color-border-primary;
}

.innerContainer {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background: variables.$background-colors-color-background-light-off;
    border-radius: 10px;
    border: 1px solid variables.$border-colors-color-border-light;
}

.title {
    padding: 5px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    border-bottom: 1px solid variables.$border-colors-color-border-light;
}

.buttonContainer {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: variables.$bp-md) {
        flex-direction: column;
        gap: 10px;
    }
}
