import { useState } from "react";
import { faSliders } from "@fortawesome/free-solid-svg-icons";

import useScreenSizes from "hooks/useScreenSizes";
import variables from "styles/_variables.module.scss";
import { tracker } from "utils/analytics";

import CollectionDetailsPanel from "./CollectionDetailsPanel";
import MenuButton from "components/MenuButton/MenuButton";
import MenuContainer from "components/MenuContainer/MenuContainer";

const MobileCollectionDetailsPanel = () => {
    const { isMobileScreen } = useScreenSizes();
    const [isCollectionDetailsPanelOpen, setIsCollectionDetailsPanelOpen] =
        useState(false);
    return (
        <>
            <MenuButton
                faIcon={faSliders}
                iconColor={variables["icon-colors-blue-100"]}
                primaryColor={variables["icon-colors-blue-40"]}
                secondaryColor={variables["icon-colors-blue-60"]}
                isOpen={isCollectionDetailsPanelOpen}
                styleOverrides={{
                    width: "30px",
                    minWidth: "30px",
                    height: "30px",
                    fontSize: "12px",
                }}
                onClick={() => {
                    tracker("Collections details panel menu open");
                    setIsCollectionDetailsPanelOpen(true);
                }}
            />
            <MenuContainer
                isOpen={isCollectionDetailsPanelOpen}
                closeMenu={() => setIsCollectionDetailsPanelOpen(false)}
                containerRelativePosition={
                    isMobileScreen
                        ? "bottom-right"
                        : "left-full-height-half-width"
                }
                fullWidthBreakpoint="xl"
                fullHeightBreakpoint="xl"
            >
                <CollectionDetailsPanel />
            </MenuContainer>
        </>
    );
};

export default MobileCollectionDetailsPanel;
