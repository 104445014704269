import { useContext, useState } from "react";
import { Button, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/free-solid-svg-icons";

import * as styles from "./CollectionShareButtonWrapper.module.scss";
import MessagingContext from "contexts/MessagingContext";
import useScreenSizes from "hooks/useScreenSizes";

import { CollectionContext } from "features/CollectionEditor/state/CollectionContext";
import { MessageModalProps } from "components/MessageModal/MessageModal";
import ShareCollectionDialog from "components/ContextMenu/dialogs/ShareCollectionDialog";
import Translate from "components/Translate/Translate";
import { Collection } from "types";

const CollectionShareButtonWrapper = () => {
    const { isDesktopScreen } = useScreenSizes();
    const { openMessageModal } = useContext(MessagingContext);
    const { collection, changeQueue, selectedItems, saveHandler } =
        useContext(CollectionContext);
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const okMessageModal: MessageModalProps = {
        isModalVisible: true,
        title: "Collection is empty",
        body: "You can't share an empty collection. Please add resources to the collection before sharing.",
        type: "OK",
        primaryActionHandler: () => undefined,
    };
    const saveMessageModal: MessageModalProps = {
        isModalVisible: true,
        title: "Collection is not saved",
        body: "You need to save the collection before sharing. Do you want to save the collection?",
        type: "Save",
        primaryActionHandler: saveHandler,
    };
    const shareHandler = () => {
        if (selectedItems.length === 0) {
            openMessageModal(okMessageModal);
        } else if (changeQueue.length > 0) {
            openMessageModal(saveMessageModal);
        }
    };

    return (
        <div className={styles.wrapper}>
            <Button
                variant="outlined"
                className={styles.shareButton}
                data-testid="share-button"
                disabled={collection.id === 0}
                onClick={() => setIsShareModalOpen(true)}
            >
                <FontAwesomeIcon icon={faShare} fontSize={12} />
                {isDesktopScreen && (
                    <Typography variant="text-size-xSmall-bold">
                        <Translate text="Share" />
                    </Typography>
                )}
            </Button>
            {(collection.id === 0 ||
                selectedItems.length === 0 ||
                changeQueue.length > 0) && (
                <div
                    data-testid="collection-overlay-share-button"
                    className={styles.overlay}
                    onClick={shareHandler}
                ></div>
            )}
            <ShareCollectionDialog
                collection={collection as Collection}
                isVisible={isShareModalOpen}
                setIsVisible={setIsShareModalOpen}
            />
        </div>
    );
};

export default CollectionShareButtonWrapper;
