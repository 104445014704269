import { Typography } from "@mui/material";

import useScreenSizes from "hooks/useScreenSizes";
import { tracker } from "utils/analytics";

import Translate from "components/Translate/Translate";

const Footer = () => {
    const { isMobileScreen } = useScreenSizes();
    return (
        <div
            style={{
                marginTop: "40px",
                marginBottom: isMobileScreen ? "40px" : "20px",
                textAlign: "center",
            }}
        >
            <Typography
                variant="text-size-Small-bold"
                color="$text-colors-color-text-blue.main"
                onClick={() => {
                    tracker("Privacy Notice Clicked");
                    window.open("https://www.wizenoze.com/cookies/");
                }}
            >
                <Translate text="Privacy notice" />
            </Typography>
            {" • "}
            <Typography
                variant="text-size-Small-bold"
                color="$text-colors-color-text-blue.main"
                onClick={() => {
                    tracker("Terms & Conditions Clicked");
                    window.open(
                        "https://www.wizenoze.com/wp-content/uploads/2021/12/wizenoze-terms-conditions.pdf"
                    );
                }}
            >
                <Translate text="Terms & Conditions" />
            </Typography>
            {" • "}
            <Typography
                variant="text-size-Small-bold"
                color="$text-colors-color-text-gray.main"
            >
                {`2013 - ${new Date().getFullYear()} © Wizenoze`}
            </Typography>
        </div>
    );
};

export default Footer;
