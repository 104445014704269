import { MouseEvent } from "react";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as styles from "../TopicCard.module.scss";
import { tracker } from "../../../utils/analytics";

type Item = {
    id: number;
    name: string;
};

const CardList = ({
    listItems,
    handleClick,
    isCardExpanded,
}: {
    listItems: Array<Item>;
    handleClick: (arg0: number) => void;
    isCardExpanded: boolean;
}) => {
    const _handleClick =
        (index: number, name: string) => (e: MouseEvent<HTMLLIElement>) => {
            tracker("Query clicked", { id: index, name: name });
            e.stopPropagation();
            handleClick(index);
        };

    const isExapandListItem = isCardExpanded
        ? listItems
        : listItems?.slice(0, 4);

    return (
        <ul className={styles.cardList}>
            {isExapandListItem?.map((item: Item) => (
                <li
                    key={item.id}
                    className={styles.cardListItem}
                    onClick={_handleClick(item.id, item.name)}
                >
                    <FontAwesomeIcon
                        className={styles.bulletIcon}
                        icon={faCaretRight}
                    />
                    <span className={styles.cardListItemText}>{item.name}</span>
                </li>
            ))}
        </ul>
    );
};

export default CardList;
