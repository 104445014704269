import {
    faCommentDots,
    faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { feedbackButton } from "./FeedBackButton.module.scss";
import { tracker } from "utils/analytics";

import Translate from "components/Translate/Translate";

interface Props {
    feedbackProvided: boolean;
    setIsFormShowing: React.Dispatch<React.SetStateAction<boolean>>;
}

const FeedBackButton = ({ feedbackProvided, setIsFormShowing }: Props) => {
    const buttonStats = feedbackProvided
        ? ({
              icon: faCircleCheck,
              text: "Feedback provided",
          } as const)
        : ({
              icon: faCommentDots,
              text: "Feedback",
          } as const);

    const handleClick = () => {
        tracker("Feedback button");
        setIsFormShowing(true);
    };

    return (
        <button
            className={feedbackButton}
            data-testid="feedBackButton"
            data-feedback-provided={feedbackProvided}
            onClick={handleClick}
        >
            <label>
                <FontAwesomeIcon icon={buttonStats?.icon} />
            </label>
            <label>
                <Translate text={buttonStats?.text}></Translate>
            </label>
        </button>
    );
};

export default FeedBackButton;
