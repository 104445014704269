import { useParams } from "react-router-dom";

const useCollectionsParams = () => {
    const params = useParams();
    const nodeParams =
        params["*"] !== undefined && params["*"] !== ""
            ? params["*"].split("/")
            : [];
    return nodeParams.map((value) => parseInt(value));
};

export default useCollectionsParams;
