import MuiSwitch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";

import variables from "styles/_variables.module.scss";

const AntSwitch = styled(MuiSwitch)(({ theme }) => ({
    width: 40,
    height: 20,
    padding: 0,
    display: "flex",
    "&:active": {
        "& .MuiSwitch-thumb": {
            width: 20,
        },
        "& .MuiSwitch-switchBase.Mui-checked": {
            transform: "translateX(16px)",
        },
    },
    "& .MuiSwitch-switchBase": {
        padding: 2,
        "&.Mui-checked": {
            transform: "translateX(20px)",
            color: variables["background-colors-color-background-white"],
            "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor:
                    theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
            },
        },
    },
    "& .MuiSwitch-thumb": {
        boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
        width: 16,
        height: 16,
        borderRadius: 10,
        transition: theme.transitions.create(["width"], {
            duration: 200,
        }),
    },
    "& .MuiSwitch-track": {
        borderRadius: 20 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === "dark"
                ? "rgba(255,255,255,.35)"
                : "rgba(0,0,0,.25)",
        boxSizing: "border-box",
    },
}));

interface Props {
    checked: boolean;
    onChange: () => void;
    disabled?: boolean;
}

const Switch = ({ checked, onChange, disabled }: Props) => {
    return (
        <AntSwitch checked={checked} onChange={onChange} disabled={disabled} />
    );
};

export default Switch;
