import React from "react";

import * as styles from "./Tooltip.module.scss";

import Translate, { TranslatableText } from "components/Translate/Translate";

interface Props {
    text: TranslatableText;
    disabled?: boolean;
    children: React.ReactElement | React.ReactElement[];
}

const Tooltip = ({ text, disabled, children }: Props) => {
    if (disabled) return <>{children}</>;
    return (
        <div className={styles.tooltipContainer}>
            {children}
            <span className={styles.tooltip}>
                <Translate text={text} />
            </span>
        </div>
    );
};

export default Tooltip;
