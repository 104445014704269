@use "../../styles/variables.module";

.tooltipContainer {
    display: flex;
    justify-content: center;
    position: relative;
}

.tooltipContainer:hover .tooltip {
    display: block;
}

.tooltip {
    display: none;
    position: absolute;
    bottom: 32px;
    padding: 0px 5px;
    width: max-content;
    height: 20px;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: variables.$text-colors-color-text-gray;
    background-color: variables.$background-colors-color-background-light;
    border: 1px solid variables.$border-colors-color-border-primary;
    border-radius: 3px;
    z-index: variables.$zindex-tooltip;
}
