import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import { axiosAPI } from "./axiosAPI";
import useCurriculumQuery from "./useCurriculumQuery";
import { useAppContext } from "contexts/AppContext";
import { Grade } from "types";

const useGradesQuery = (curriculumId?: string | number) => {
    const { data: curriculumData } = useCurriculumQuery(curriculumId);
    const isInStructure = curriculumData?.structure?.includes("GRADE");
    const { config } = useAppContext();
    const showDrafts = config?.showDrafts ?? false;
    return useQuery<Grade[], AxiosError>(
        ["grade", curriculumData?.id, showDrafts],
        async () => {
            const { data } = await axiosAPI
                .getInstance()
                .get(`/curriculum/${curriculumData?.id}/layers/GRADE`, {
                    params: {
                        allPublicationStatus: showDrafts,
                    },
                });
            return data;
        },
        {
            enabled: !!curriculumData && !!isInStructure,
            staleTime: 1000 * 60 * 60 * 12,
        },
    );
};

export default useGradesQuery;
