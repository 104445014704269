@use "../../../../../../styles/variables.module";

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 15px;
    box-shadow: 0px 5px 20px rgba(108, 117, 125, 0.2);
    overflow: hidden;
    cursor: pointer;
}

.image {
    height: 80px;
    width: 100%;
    padding: 20px 0px;
    background: #dededf;
    box-sizing: content-box;
}

.name {
    padding: 20px;
    text-align: center;
}
