import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Divider, Typography } from "@mui/material";
import { faShare, faToolbox } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as styles from "./Subheader.module.scss";
import {
    useAllFoldersEnhancedQuery,
    useEditFolderMutation,
    useSelectedFoldersAndCollections,
} from "hooks/api/folders";
import useCollectionsParams from "hooks/useCollectionsParams";
import useScreenSizes from "hooks/useScreenSizes";
import variables from "styles/_variables.module.scss";
import { Collection, Folder } from "types";
import { tracker } from "utils/analytics";

import EditButton from "./EditButton";
import NewElementContextMenu from "./NewElementContextMenu";
import NewFolderDialog from "./NewFolderDialog";
import SortByContextMenu from "./SortByContextMenu";
import ViewAsContextMenu from "./ViewAsContextMenu";
import MobileCollectionsExplorer from "components/CollectionsExplorerPanel/MobileCollectionsExplorer";
import ShareCollectionDialog from "components/ContextMenu/dialogs/ShareCollectionDialog";
import ShareFolderDialog from "components/ContextMenu/dialogs/ShareFolderDialog";
import MenuButton from "components/MenuButton/MenuButton";
import MenuContainer from "components/MenuContainer/MenuContainer";
import Translate from "components/Translate/Translate";
import { GenerateCollectionQuizButton } from "features/CollectionEditor/Subheader/GenerateQuizButton/GenerateQuizButton";

interface ShareButtonProps {
    isDisabled: boolean;
    clickHandler: () => void;
    showLabel: boolean;
}

const ShareButton = ({
    isDisabled,
    clickHandler,
    showLabel,
}: ShareButtonProps) => (
    <Button
        variant="outlined"
        className={styles.shareButton}
        disabled={isDisabled}
        onClick={clickHandler}
    >
        <FontAwesomeIcon icon={faShare} fontSize={12} />
        {showLabel && (
            <Typography variant="text-size-xSmall-bold">
                <Translate text="Share" />
            </Typography>
        )}
    </Button>
);

interface Props {
    viewAs: Folder["view"];
    setViewAs: (viewAs: Folder["view"]) => void;
    sortBy: Folder["sortBy"];
    setSortBy: (sortBy: Folder["sortBy"]) => void;
    openQuizEditorHandler: () => void;
}

const Subheader = ({
    viewAs,
    setViewAs,
    sortBy,
    setSortBy,
    openQuizEditorHandler,
}: Props) => {
    const { isDesktopScreen, isMobileScreen } = useScreenSizes();
    const navigate = useNavigate();
    const collectionsParams = useCollectionsParams();
    const editFolderMutation = useEditFolderMutation();
    const selectedFoldersAndCollections = useSelectedFoldersAndCollections();
    const selectedFolderOrCollection =
        selectedFoldersAndCollections[selectedFoldersAndCollections.length - 1];
    const [isToolboxPanelOpen, setIsToolboxPanelOpen] = useState(false);
    const [isNewMenuOpen, setIsNewMenuOpen] = useState(false);
    const [isViewMenuOpen, setIsViewMenuOpen] = useState(false);
    const [isSortMenuOpen, setIsSortMenuOpen] = useState(false);
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const [isNewFolderModalVisible, setIsNewFolderModalVisible] =
        useState(false);

    const updateFolder = (key: keyof Folder, value: unknown) => {
        editFolderMutation.mutate({
            ...selectedFolderOrCollection,
            [key]: value,
        });
    };
    const folderClickHandler = (folder: Folder, pathString?: string) => {
        if (!pathString) return;
        tracker("Folder clicked", folder);
        navigate(pathString);
    };
    const collectionClickHandler = (
        collection: Collection,
        pathString?: string,
    ) => {
        if (!pathString) return;
        tracker("Collection clicked", collection);
        navigate(pathString);
    };
    const { data: allRootFolders } = useAllFoldersEnhancedQuery();
    return (
        <div className={styles.subheader}>
            <div className={styles.subheaderGroup}>
                <div className={styles.editAndMobileExplorer}>
                    {selectedFolderOrCollection &&
                        selectedFolderOrCollection.type === "COLLECTION" && (
                            <EditButton />
                        )}
                    {!isDesktopScreen && (
                        <MobileCollectionsExplorer
                            items={allRootFolders}
                            selectedIds={collectionsParams}
                            folderClickHandler={folderClickHandler}
                            collectionClickHandler={collectionClickHandler}
                        />
                    )}
                </div>
                {!isMobileScreen ? (
                    (!selectedFolderOrCollection ||
                        selectedFolderOrCollection.type !== "COLLECTION") && (
                        <>
                            <NewElementContextMenu
                                isVisible={isNewMenuOpen}
                                setIsVisible={setIsNewMenuOpen}
                                setIsNewFolderModalVisible={
                                    setIsNewFolderModalVisible
                                }
                            />
                            <Divider orientation="vertical" />
                            <ViewAsContextMenu
                                viewAs={viewAs}
                                updateViewAs={(viewAs) => {
                                    setViewAs(viewAs);
                                    selectedFolderOrCollection &&
                                        updateFolder("view", viewAs);
                                }}
                                isVisible={isViewMenuOpen}
                                setIsVisible={setIsViewMenuOpen}
                                isDisabled={collectionsParams.length === 0}
                            />
                            <SortByContextMenu
                                sortBy={sortBy}
                                updateSortBy={(sortBy) => {
                                    setSortBy(sortBy);
                                    selectedFolderOrCollection &&
                                        updateFolder("sortBy", sortBy);
                                }}
                                isVisible={isSortMenuOpen}
                                setIsVisible={setIsSortMenuOpen}
                                isDisabled={collectionsParams.length === 0}
                            />
                        </>
                    )
                ) : (
                    <>
                        {(!selectedFolderOrCollection ||
                            selectedFolderOrCollection.type !==
                                "COLLECTION") && (
                            <MenuButton
                                faIcon={faToolbox}
                                iconColor={variables["icon-colors-blue-100"]}
                                primaryColor={variables["icon-colors-blue-40"]}
                                secondaryColor={
                                    variables["icon-colors-blue-60"]
                                }
                                isOpen={isToolboxPanelOpen}
                                styleOverrides={{
                                    width: "30px",
                                    minWidth: "30px",
                                    height: "30px",
                                    fontSize: "12px",
                                }}
                                onClick={() => {
                                    tracker("Toolbox panel menu open");
                                    setIsToolboxPanelOpen(true);
                                }}
                            />
                        )}
                        <div style={{ display: "flex", gap: 20 }}>
                            {selectedFolderOrCollection &&
                                selectedFolderOrCollection.type ===
                                    "COLLECTION" && (
                                    <GenerateCollectionQuizButton
                                        clickHandler={openQuizEditorHandler}
                                    />
                                )}
                            <ShareButton
                                isDisabled={
                                    !selectedFolderOrCollection ||
                                    selectedFolderOrCollection?.isRootFolder
                                }
                                clickHandler={() => setIsShareModalOpen(true)}
                                showLabel={false}
                            />
                        </div>
                        <MenuContainer
                            isOpen={isToolboxPanelOpen}
                            closeMenu={() => setIsToolboxPanelOpen(false)}
                            containerRelativePosition="bottom-right"
                            fullWidthBreakpoint="xl"
                            fullHeightBreakpoint="xl"
                        >
                            <div className={styles.mobileContainer}>
                                <NewElementContextMenu
                                    isVisible={isNewMenuOpen}
                                    setIsVisible={setIsNewMenuOpen}
                                    setIsNewFolderModalVisible={(
                                        open: boolean,
                                    ) => {
                                        setIsToolboxPanelOpen(false);
                                        setIsNewFolderModalVisible(open);
                                    }}
                                />
                                <ViewAsContextMenu
                                    viewAs={viewAs}
                                    updateViewAs={(viewAs) => {
                                        setViewAs(viewAs);
                                        selectedFolderOrCollection &&
                                            updateFolder("view", viewAs);
                                    }}
                                    isVisible={isViewMenuOpen}
                                    setIsVisible={setIsViewMenuOpen}
                                />
                                <SortByContextMenu
                                    sortBy={sortBy}
                                    updateSortBy={(sortBy) => {
                                        setSortBy(sortBy);
                                        selectedFolderOrCollection &&
                                            updateFolder("sortBy", sortBy);
                                    }}
                                    isVisible={isSortMenuOpen}
                                    setIsVisible={setIsSortMenuOpen}
                                />
                            </div>
                        </MenuContainer>
                    </>
                )}
            </div>
            {!isMobileScreen && (
                <div style={{ display: "flex", gap: 20 }}>
                    {selectedFolderOrCollection &&
                        selectedFolderOrCollection.type === "COLLECTION" && (
                            <GenerateCollectionQuizButton
                                clickHandler={openQuizEditorHandler}
                            />
                        )}
                    <ShareButton
                        isDisabled={
                            !selectedFolderOrCollection ||
                            selectedFolderOrCollection?.isRootFolder
                        }
                        clickHandler={() => setIsShareModalOpen(true)}
                        showLabel={isDesktopScreen}
                    />
                </div>
            )}
            <NewFolderDialog
                isVisible={isNewFolderModalVisible}
                setIsVisible={setIsNewFolderModalVisible}
            />
            {selectedFolderOrCollection &&
                (selectedFolderOrCollection.type === "FOLDER" ? (
                    <ShareFolderDialog
                        folder={selectedFolderOrCollection}
                        isVisible={isShareModalOpen}
                        setIsVisible={setIsShareModalOpen}
                    />
                ) : (
                    <ShareCollectionDialog
                        collection={selectedFolderOrCollection}
                        isVisible={isShareModalOpen}
                        setIsVisible={setIsShareModalOpen}
                    />
                ))}
        </div>
    );
};

export default Subheader;
