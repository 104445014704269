import { useContext } from "react";
import { Button, Typography } from "@mui/material";
import { faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import MessagingContext from "contexts/MessagingContext";
import { CollectionContext } from "features/CollectionEditor/state/CollectionContext";
import {
    useCollectionQuery,
    useItemsInCollectionQuery,
} from "hooks/api/collections";
import useCollectionsParams from "hooks/useCollectionsParams";

import { MessageModalProps } from "components/MessageModal/MessageModal";
import Translate from "components/Translate/Translate";

export const GenerateCurriculumQuizButton = (
    props: React.ComponentProps<typeof GenerateQuizButton>,
) => {
    return <GenerateQuizButton {...props} />;
};

export const GenerateCollectionQuizButton = (
    props: React.ComponentProps<typeof GenerateQuizButton>,
) => {
    const { openMessageModal } = useContext(MessagingContext);
    const { collection, changeQueue, saveHandler } =
        useContext(CollectionContext);
    const collectionParams = useCollectionsParams();
    const { data: collectionData } = useCollectionQuery(
        collectionParams.at(-1),
    );
    const { data: selectedItems } = useItemsInCollectionQuery(
        collectionParams.at(-1),
    );
    const okMessageModal: MessageModalProps = {
        isModalVisible: true,
        title: "Collection is empty",
        body: "You can't generate a quiz for an empty collection. Please add resources to the collection before generating a quiz.",
        type: "OK",
        primaryActionHandler: () => undefined,
    };
    const saveMessageModal: MessageModalProps = {
        isModalVisible: true,
        title: "Collection is not saved",
        body: "You need to save the collection before generating a quiz. Do you want to save the collection?",
        type: "Save",
        primaryActionHandler: saveHandler,
    };
    const preventClickHandler = () => {
        if (selectedItems?.length === 0) {
            openMessageModal(okMessageModal);
        } else if (changeQueue.length > 0) {
            openMessageModal(saveMessageModal);
        }
    };
    const isClickPrevented =
        selectedItems?.length === 0 || changeQueue.length > 0;
    return (
        <GenerateQuizButton
            {...props}
            isDisabled={
                props.isDisabled ||
                collection?.quiz?.questions?.length > 0 ||
                collectionData?.quiz?.questions?.length > 0
            }
            preventClickHandler={
                isClickPrevented ? preventClickHandler : undefined
            }
        />
    );
};

const GenerateQuizButton = ({
    clickHandler,
    isDisabled,
    isFullWidth,
    preventClickHandler,
}: {
    clickHandler: () => void;
    isDisabled?: boolean;
    isFullWidth?: boolean;
    preventClickHandler?: () => void;
}) => {
    return (
        <div
            style={{
                position: "relative",
                width: isFullWidth ? "100%" : undefined,
            }}
        >
            <Button
                variant={isDisabled ? "ai-disabled-button" : "ai-button"}
                style={{
                    width: "100%",
                    height: "30px",
                    color: "white",
                }}
                startIcon={
                    <FontAwesomeIcon
                        icon={faWandMagicSparkles}
                        style={{ marginRight: 5, fontSize: 12 }}
                    />
                }
                onClick={clickHandler}
                disabled={isDisabled}
            >
                <Typography variant="button-text-size-xSmall-bold">
                    <Translate text="Generate Quiz" />
                </Typography>
            </Button>
            {!isDisabled && preventClickHandler && (
                <div
                    data-testid="collection-overlay-share-button"
                    style={{
                        height: "100%",
                        width: "100%",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        cursor: "pointer",
                    }}
                    onClick={preventClickHandler}
                ></div>
            )}
        </div>
    );
};

export default GenerateQuizButton;
