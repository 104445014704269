@use "../../styles/variables.module";

.subheader {
    padding: 10px 40px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: variables.$background-colors-color-background-white;
    border-bottom: 1px solid variables.$border-colors-color-border-light;
    width: 100%;
}

.subheaderGroup {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
}

@media (max-width: variables.$bp-md) {
    .subheader {
        padding: 10px;
    }
    .subheaderGroup {
        justify-content: space-between;
    }
}
