@use "../../styles/variables.module";

.button {
    padding: 11px 20px 9px 20px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 45px;
    color: variables.$text-colors-color-text-gray;
    border-radius: 45px;
    border: 1px solid variables.$border-colors-color-border-light;
    background: variables.$background-colors-color-background-white;
    cursor: pointer;
}

.container {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #0000;
    z-index: variables.$zindex-selectable-dropdown;
}

.list {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 22px;
    border: 1px solid variables.$border-colors-color-border-light;
    box-shadow: 0px 10px 10px 0px variables.$text-colors-color-text-gray;
    background: variables.$background-colors-color-background-white;
    cursor: pointer;
    z-index: variables.$zindex-selectable-dropdown + 1;
}

.title {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 45px;
    color: variables.$text-colors-color-text-gray;
}

.item {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 25px;
    color: variables.$text-colors-color-text-dark;
    border-top: 1px solid variables.$border-colors-color-border-primary;
}
