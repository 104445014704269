@use "../../../../styles/variables.module";

.item {
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    gap: 5px;
    background: variables.$background-colors-color-background-light-off;
}

.privacyTerms {
    display: flex;
    gap: 5px;
}
