import { useState } from "react";
import { ClickAwayListener, Tooltip, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";

import variables from "styles/_variables.module.scss";
import { tracker } from "utils/analytics";

import Translate, { TranslatableText } from "components/Translate/Translate";

interface Props {
    tooltip?: {
        title: TranslatableText;
        description: TranslatableText;
    };
}

const NotificationIcon = ({ tooltip }: Props) => {
    const [showTooltip, setShowTooltip] = useState(false);
    return (
        <Tooltip
            title={
                tooltip && (
                    <ClickAwayListener
                        onClickAway={() => setShowTooltip(false)}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Typography variant="text-size-xxSmall-bold">
                                <Translate text={tooltip.title} />
                            </Typography>
                            <Typography variant="text-size-xxSmall-regular">
                                <Translate text={tooltip.description} />
                            </Typography>
                        </div>
                    </ClickAwayListener>
                )
            }
            placement="bottom"
            arrow
            open={showTooltip}
            slotProps={{
                tooltip: { style: { maxWidth: "246px" } },
            }}
        >
            <FontAwesomeIcon
                icon={faBell}
                color={variables["message-colors-color-message-warning"]}
                fontSize={14}
                style={{ cursor: "pointer" }}
                onClick={() => {
                    tracker("Gone resource notification clicked");
                    setShowTooltip(true);
                }}
            />
        </Tooltip>
    );
};

export default NotificationIcon;
