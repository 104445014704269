import { useParams } from "react-router-dom";

import { useAppContext } from "contexts/AppContext";
import useCurriculumParams from "hooks/useCurriculumParams";

import QueryResults from "features/QueryResults/QueryResults";
import SingleTopicCard from "features/SingleTopicCard/SingleTopicCard";
import ShareLoadFailed from "components/ShareLoadFailed/ShareLoadFailed";

const CurriculumShareViewer = () => {
    const { embedDetails } = useAppContext();
    const { shareType } = useParams();
    const { queryId, topicId } = useCurriculumParams();

    if (shareType) {
        if (queryId || embedDetails?.queryId || embedDetails?.ids)
            return <QueryResults />;
        if (topicId || embedDetails?.topicId) return <SingleTopicCard />;
        return <ShareLoadFailed />;
    }

    if (queryId) return <QueryResults />;
    return <></>;
};

export default CurriculumShareViewer;
