import { useSearchAutocompleteQuery } from "hooks/api/useSearchQuery";
import {
    createSearchParams,
    useNavigate,
    useSearchParams,
} from "react-router-dom";

import { tracker } from "utils/analytics";
import { getResultType } from "utils/helpers";

import AutoCompleteList from "./AutoCompleteList";

const Autocomplete = ({
    searchTerm,
    resetAutoCompleteDisplay,
}: {
    searchTerm: string;
    resetAutoCompleteDisplay: () => void;
}) => {
    const { data: autocompleteData } = useSearchAutocompleteQuery(searchTerm);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const navigateToQuery = (index: number) => {
        const completion = autocompleteData?.[index];
        if (!completion) return;

        tracker("Suggestion button");
        const allParams = Object.fromEntries([...searchParams]);
        resetAutoCompleteDisplay();

        navigate({
            pathname: "/search",
            search: createSearchParams({
                ...allParams,
                searchTerm: completion.label,
                ...(getResultType(searchParams) === "topics"
                    ? { queryResult: JSON.stringify(completion) }
                    : null),
            }).toString(),
        });
    };

    if (
        !autocompleteData ||
        (autocompleteData?.length ? "" : Boolean(searchTerm))
    )
        return <></>;
    return (
        <AutoCompleteList
            autocompleteList={autocompleteData}
            completionClickHandler={navigateToQuery}
        />
    );
};

export default Autocomplete;
