import { Theme, useMediaQuery } from "@mui/material";

const useScreenSizes = () => {
    const isDesktopScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.up("lg")
    );
    const isTabletScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.between("md", "lg")
    );
    const isMobileScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("md")
    );
    return { isDesktopScreen, isTabletScreen, isMobileScreen };
};

export default useScreenSizes;
