@use "../../../../styles/variables.module";

.inputLabel {
    color: variables.$text-colors-color-text-gray;
    /* $text-size-xxSmall-regular */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.dropdownIcon {
    color: variables.$text-colors-color-text-gray;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 900;
    line-height: 20px;
    cursor: pointer;
    position: absolute;
    pointer-events: "none";
    right: 15px;
}
