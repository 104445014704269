@use "../../../styles/variables.module";

.container {
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    margin-top: -5px;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: -10px;
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: variables.$background-colors-color-background-light-off;
}

.select {
    border: 1px solid variables.$border-colors-color-border-primary;
    border-radius: 5px;
    background-color: white;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
}

.checkbox {
    width: 20px;
    height: 20px;
    margin: 0px;
    cursor: pointer;
}
