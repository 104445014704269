import {
    DraggableAttributes,
    DraggableSyntheticListeners,
} from "@dnd-kit/core";
import { Tooltip, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle, faWarning } from "@fortawesome/free-solid-svg-icons";

import * as styles from "./ResultCard.module.scss";
import { useResultModalContext } from "contexts/ResultModalContext";
import wizenozePlaceholder from "images/wizenoze-placeholder.webp";
import variables from "styles/_variables.module.scss";
import { Item, Result } from "types";
import { tracker } from "utils/analytics";
import { setBlankImage } from "utils/helpers";
import { isAppMode, isShareMode } from "utils/newtools";

import ControlsFooter from "./ControlsFooter/ControlsFooter";
import DragHandle from "./DragHandle";
import Header from "./Header";
import MetaDataFooter from "./MetaDataFooter";
import Translate from "components/Translate/Translate";

interface Props {
    result: Result;
    results?: Result[];
    resultIndex?: number;
    deleteItemHandler?: () => void;
    isSelectedResult?: boolean;
    selectResultHandler?: (resultId: string) => void;
    selectedItems?: Item[];
    addResourceHandler?: () => void;
    removeResourceHandler?: () => void;
    isHighlighted?: boolean;
    draggable?: {
        attributes: DraggableAttributes;
        listeners: DraggableSyntheticListeners;
    };
}

const ResultCard = ({
    result,
    results,
    resultIndex,
    deleteItemHandler,
    isSelectedResult,
    selectResultHandler,
    addResourceHandler,
    removeResourceHandler,
    isHighlighted,
    draggable,
}: Props) => {
    const { openResultModal, isResultModalEnabled } = useResultModalContext();
    const [page] = result.audiences;
    const readingLevel = page?.level;
    const isResultGone = result.isUserProvided && result.pageState === "GONE";
    const showMetadataFooter =
        result.collectionAccessType === "paidCollection" ||
        result.isUserProvided ||
        result.teacherApproved ||
        readingLevel ||
        (isAppMode && result.options?.embeddable) ||
        (isAppMode && result.videoMetadata?.caption) ||
        (isAppMode && result.videoMetadata?.duration);
    const clickHandler = () => {
        if (isResultModalEnabled) {
            if (results && resultIndex !== undefined) {
                openResultModal({
                    results: results,
                    initialIndex: resultIndex,
                });
            } else {
                openResultModal({ results: [result], initialIndex: 0 });
            }
        } else {
            window.open(result.fullUrl);
        }
        tracker("Link feature clicked", {
            fullUrl: result.fullUrl,
            inIFrame: isResultModalEnabled,
        });
    };
    return (
        <div className={styles.relativeWrapper}>
            <div
                className={styles.container}
                style={{
                    borderColor: isHighlighted
                        ? variables["border-colors-color-border-blue"]
                        : variables["border-colors-color-border-light"],
                    transition: "border-color 5s ease-in-out",
                }}
            >
                {draggable && <DragHandle draggable={draggable} />}
                <div
                    className={styles.card}
                    style={{
                        backgroundColor: isResultGone
                            ? variables[
                                  "background-colors-color-background-light-off"
                              ]
                            : variables[
                                  "background-colors-color-background-white"
                              ],
                    }}
                >
                    <Header
                        clickHandler={clickHandler}
                        result={result}
                        deleteItemHandler={deleteItemHandler}
                        isResultGone={isResultGone}
                        isSelected={isSelectedResult}
                        addResourceHandler={addResourceHandler}
                        removeResourceHandler={removeResourceHandler}
                    />
                    <div className={styles.body} onClick={clickHandler}>
                        {result?.thumbnail && (
                            <div className={styles.bodyVisual}>
                                <img
                                    className={styles.bodyImage}
                                    onError={setBlankImage}
                                    src={
                                        result.thumbnail || wizenozePlaceholder
                                    }
                                    alt={result?.title + " thumbnail"}
                                />
                                {result.documentTypes.includes("video") && (
                                    <FontAwesomeIcon
                                        icon={faPlayCircle}
                                        className={styles.bodyVideoIcon}
                                    />
                                )}
                            </div>
                        )}
                        <div className={styles.bodyTexts}>
                            <Typography
                                variant="text-size-Base-bold"
                                color={
                                    isResultGone
                                        ? "$text-colors-color-text-light-gray.main"
                                        : "$text-colors-color-text-blue.main"
                                }
                            >
                                {result.isUserProvided &&
                                    result.title === result.fullUrl && (
                                        <Tooltip
                                            title={
                                                <Typography variant="text-size-xxSmall-regular">
                                                    <Translate text="We can’t reach the resource." />
                                                </Typography>
                                            }
                                            placement="top-start"
                                            arrow
                                            onClick={(e) => e.preventDefault()}
                                        >
                                            <FontAwesomeIcon
                                                icon={faWarning}
                                                color={
                                                    variables[
                                                        "message-colors-color-message-warning"
                                                    ]
                                                }
                                                style={{ marginRight: 10 }}
                                            />
                                        </Tooltip>
                                    )}
                                {result.title}
                            </Typography>
                            <Typography
                                variant="text-size-Small-regular"
                                color={
                                    isResultGone
                                        ? "$text-colors-color-text-light-gray.main"
                                        : "$text-colors-color-text-dark.main"
                                }
                            >
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: result.summaryText,
                                    }}
                                />
                            </Typography>
                        </div>
                    </div>
                    {showMetadataFooter && (
                        <MetaDataFooter
                            readingLevel={readingLevel}
                            result={result}
                        />
                    )}
                    {selectResultHandler && !isShareMode && (
                        <ControlsFooter
                            isSelectedResult={isSelectedResult}
                            result={result}
                            selectResultHandler={selectResultHandler}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default ResultCard;
