import { Theme, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import { CollectionCardSkeleton } from "components/CollectionCard/CollectionCard";

const MyCollectionsPanelSkeleton = () => {
    const isDesktopScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.up("lg")
    );
    const cardCount = isDesktopScreen ? 3 : 1;
    return (
        <>
            {new Array(cardCount).fill(null).map((index) => (
                <Grid key={index} lg={4} xs={12}>
                    <CollectionCardSkeleton />
                </Grid>
            ))}
        </>
    );
};

export default MyCollectionsPanelSkeleton;
