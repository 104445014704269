import dayjs from "dayjs";
import { createContext } from "react";
import {
    Collection,
    CollectionChangeQueue,
    Item,
    UnsavedCollection,
} from "types";

export const NewCollection: UnsavedCollection = {
    id: 0,
    title: "Insert collection title",
    description: "",
    imageUrl: "",
    tags: [],
    createdDate: dayjs().toISOString(),
    resultModal: false,
    language: "en",
    view: "LIST",
};

export const organizeArrayBToMatchArrayA = (
    arrayA: Array<Item>,
    arrayB: Array<Item>,
    isNewlyOrderedItems: boolean
) => {
    // Create a copy of arrayB to avoid mutating the original array
    const sortedArrayB = [...arrayB];
    sortedArrayB.sort((a, b) => {
        const orderA = arrayA.findIndex((item) =>
            !isNewlyOrderedItems && item.id
                ? item.id === a.id
                : item.indexId
                ? item.indexId === a.indexId
                : item.url === a.url
        );
        const orderB = arrayA.findIndex((item) =>
            !isNewlyOrderedItems && item.id
                ? item.id === b.id
                : item.indexId
                ? item.indexId === b.indexId
                : item.url === b.url
        );
        return orderA - orderB;
    });

    return sortedArrayB;
};

export const CollectionContext = createContext<{
    collection: UnsavedCollection | Collection;
    updateCollection: (key: keyof Collection, value: unknown) => void;
    selectedItems: Item[];
    highlightedItems: number[];
    addItem: (item: Item) => void;
    removeItem: (item: Item) => void;
    reorderItems: (items: Array<Item>) => void;
    addTag: (value: string) => void;
    removeTag: (value: string) => void;
    changeQueue: CollectionChangeQueue;
    saveHandler: () => void;
    isSaving: boolean;
    deleteHandler: () => void;
}>({
    collection: NewCollection,
    updateCollection: (_key, _value) => {
        return;
    },
    selectedItems: [],
    highlightedItems: [],
    addItem: (_item) => {
        return;
    },
    removeItem: (_item) => {
        return;
    },
    reorderItems: (_items) => {
        return;
    },
    addTag: (_value) => {
        return;
    },
    removeTag: (_id) => {
        return;
    },
    changeQueue: [],
    saveHandler: () => {
        return;
    },
    isSaving: false,
    deleteHandler: () => {
        return;
    },
});
