import { reasonList } from "./CommentField.module.scss";

interface Props {
    value: string;
    changeHandler: (arg0: string) => void;
    isRequired: boolean | undefined;
}

export const CommentField = ({ value, changeHandler, isRequired }: Props) => {
    if (isRequired == undefined) return <></>;

    return (
        <div>
            <label>Leave a Comment</label>
            <div className={reasonList}>
                <textarea
                    name="comment"
                    rows={5}
                    cols={30}
                    value={value}
                    onChange={(e) => changeHandler(e.target.value)}
                    required={isRequired}
                ></textarea>
            </div>
        </div>
    );
};
export default CommentField;
