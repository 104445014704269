@use "../../../../styles/variables.module";

.clearButton {
    /* $text-size-xxSmall-bold */
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */

    display: flex;
    align-items: center;

    /* Border colors/$color-border-blue */

    color: variables.$text-colors-color-text-blue;

    /* Inside auto layout */

    flex: none;
    order: 2;
    flex-grow: 0;

    text-align: center;
    cursor: pointer;
}

.filterContainer[data-container-width="TABLET"] {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(279px, 1fr));
}

.filterContainer[data-container-width="LARGE_TABLET"] {
    display: grid;
    grid-template-columns: repeat(2, 50%);
}

.filterContainer[data-container-width="LAPTOP"] {
    display: grid;
    grid-template-columns: repeat(3, 280px) 60px;
}

.filterContainer[data-container-width="LARGE"],
.filterContainer[data-container-width="EXTRA_LARGE"] {
    display: grid;
    grid-template-columns: repeat(3, 380px) 60px;
}
