import { useContext } from "react";

import { CollectionContext } from "features/CollectionEditor/state/CollectionContext";
import { Result } from "types";
import { tracker } from "utils/analytics";
import { convertResultTypeToItemType } from "utils/typeConvertors";

import ResultCard from "components/ResultCard/ResultCard";

interface Props {
    result: Result;
}

const OrganicSearchResultsCard = ({ result }: Props) => {
    const { selectedItems, addItem, removeItem } =
        useContext(CollectionContext);
    const addResourceHandler = () => {
        tracker("Add search resource to collection", result);
        addItem(convertResultTypeToItemType(result, selectedItems.length + 1));
    };
    const removeResourceHandler = () => {
        tracker("Remove search resource from collection", result);
        const foundItem = selectedItems.find((el) => el.indexId === result.id);
        if (!foundItem) {
            console.error("Couldn't find item to remove");
            return;
        }
        removeItem(foundItem);
    };
    const isSelectedResult = selectedItems.some(
        (el) => el.indexId === result.id
    );
    return (
        <ResultCard
            result={result}
            isSelectedResult={isSelectedResult}
            addResourceHandler={addResourceHandler}
            removeResourceHandler={removeResourceHandler}
        />
    );
};

export default OrganicSearchResultsCard;
