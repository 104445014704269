import { useEffect, useState } from "react";

const LTIFormRedirector = ({ htmlString }: { htmlString: string }) => {
    const [processedHTML, setProcessedHTML] = useState("");

    useEffect(() => {
        // First draft by ChatGPT. This finds all the src props in script tags, and inlines the remote code
        // Then when we 'dangerously set inner html' it can run immediately and not fail.
        async function inlineExternalScripts() {
            const dom = new DOMParser().parseFromString(
                htmlString,
                "text/html"
            );
            const scriptTags = dom.querySelectorAll("script[src]");

            for (let i = 0; i < scriptTags.length; i++) {
                const scriptTag = scriptTags[i];
                const scriptSrc = scriptTag.getAttribute("src");
                if (typeof scriptSrc === "string") {
                    const response = await fetch(scriptSrc, {
                        mode: "no-cors",
                    });
                    const scriptText = await response.text();

                    const inlineScript = dom.createElement("script");
                    inlineScript.textContent = scriptText;
                    scriptTag.replaceWith(inlineScript);
                }
            }

            setProcessedHTML(dom.documentElement.outerHTML);
        }

        if (!processedHTML) {
            inlineExternalScripts();
        }
    }, [htmlString, processedHTML]);

    if (processedHTML) {
        document.open("text/html");
        document.write("<html>" + processedHTML + "</html>");
        document.close();
        return <div>Done</div>;
    } else {
        return <div>Processing Submission</div>;
    }
};

export default LTIFormRedirector;
