@use "../../styles/variables.module";

.loadingContainer {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: absolute;
    display: flex;
    place-items: center;
}

.loadingContainer.searchTop {
    top: -30px;
    position: relative;
    width: 100%;
}

.spinner {
    margin: 100px auto;
    width: 60px;
    height: 60px;
    position: relative;
    text-align: center;
    -webkit-animation: sk-rotate 1s infinite linear;
    animation: sk-rotate 1s infinite linear;
    z-index: variables.$zindex-loading;
}

.dot1,
.dot2 {
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: variables.$wizenoze-brand-color-wizenoze-darkgreen;
    border-radius: 100%;

    -webkit-animation: sk-bounce 1s infinite ease-in-out;
    animation: sk-bounce 1s infinite ease-in-out;
}

.dot2 {
    top: auto;
    bottom: 0;
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
}

@-webkit-keyframes sk-rotate {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes sk-rotate {
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes sk-bounce {
    0%,
    100% {
        -webkit-transform: scale(0);
    }

    50% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bounce {
    0%,
    100% {
        transform: scale(0);
        -webkit-transform: scale(0);
    }

    50% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}
