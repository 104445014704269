@use "../../../styles/variables.module";

.section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    max-width: 420px;
    margin-bottom: 10px;
}

.container {
    padding: 20px 0;
    max-width: 670px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    background: variables.$background-colors-color-background-light;
}

.title {
    padding-bottom: 5px;
    border-bottom: 1px solid variables.$border-colors-color-border-light;
}

.select {
    display: flex;
    flex-direction: row;
    max-width: 420px;
    line-height: 45px;
    gap: 20px;
}

.selectInput {
    max-height: 45px;
    flex-grow: 1;
    padding-right: 15px;
    border-radius: 5px;
    border: 1px solid variables.$background-colors-color-background-light-gray;
    background-color: variables.$background-colors-color-background-white;
}

.icon {
    color: variables.$text-colors-color-text-gray;
    font-size: 15px;
}

.footer {
    max-width: 325px;
    margin-top: 10px;
}
