import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { cardBodyFooter } from "../TopicCard.module.scss";

import Translate from "components/Translate/Translate";

interface CardFoorterProps {
    listItemsTotal: number;
    expandCollapseCard: () => void;
    isCardExpanded: boolean;
}

const CardFooter = ({
    listItemsTotal,
    expandCollapseCard,
    isCardExpanded,
}: CardFoorterProps) => {
    if (listItemsTotal < 5) return null;

    return (
        <div className={cardBodyFooter} onClick={expandCollapseCard}>
            {isCardExpanded ? (
                <>
                    <span role="link">{<Translate text="Show Less" />}</span>
                    <FontAwesomeIcon icon={faAngleUp} />
                </>
            ) : (
                <>
                    <span role="link">{<Translate text="Show More" />}</span>
                    <FontAwesomeIcon icon={faAngleDown} />
                </>
            )}
        </div>
    );
};

export default CardFooter;
