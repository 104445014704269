import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import mixpanel from "mixpanel-browser";

import * as styles from "./LogoutItem.module.scss";
import { useAppContext } from "contexts/AppContext";
import { axiosAPI } from "hooks/api/axiosAPI";
import variables from "styles/_variables.module.scss";
import { tracker } from "utils/analytics";

import Translate from "components/Translate/Translate";

const LogoutItem = () => {
    const navigate = useNavigate();
    const { config } = useAppContext();
    const queryClient = useQueryClient();
    const logoutAction = async () => {
        tracker("Log out");
        if (process.env.REACT_APP_SDK_MIXPANEL_KEY) {
            mixpanel.reset();
        }

        await axiosAPI.initialise(config);
        queryClient.invalidateQueries();
        queryClient.removeQueries();
        queryClient.clear();
        navigate("/home");
    };
    return (
        <div className={styles.item} onClick={logoutAction}>
            <FontAwesomeIcon
                icon={faRightFromBracket}
                color={variables["text-colors-color-text-light-gray"]}
                fontSize={20}
            />
            <Typography
                variant="text-size-Base-bold"
                color="$message-colors-color-message-warning.main"
            >
                <Translate text="Log out" />
            </Typography>
        </div>
    );
};

export default LogoutItem;
