export const localStorageKeys = {
    DEVICEUUID: "DEVICEUUID",
    DEVICEUUID_VERSION: "DEVICEUUID_VERSION",
};

export const collectionAccessTypes = {
    paidCollection: "paidCollection",
    privateCollection: "privateCollection",
    publicCollection: "publicCollection",
};

/**
 * Prepare constants object for default exports
 */
const constants = {
    ...localStorageKeys,
    ...collectionAccessTypes,
};

export default constants;
