@use "../../../../../../../../styles/variables.module";

.mandatory {
    color: variables.$message-colors-color-message-danger;
}
.reasonList {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 15px 0px;
    gap: 5px;
    padding: 0;
}
