import { Typography } from "@mui/material";

import Translate from "components/Translate/Translate";

const EmptyFolderMessage = () => {
    return (
        <Typography variant="text-size-Base">
            <Translate text="The folder is currently empty." />
        </Typography>
    );
};

export default EmptyFolderMessage;
