@use "../../../../../../styles/variables.module";

.feedbackButton {
    top: 10px;
    left: 10px;
    border: none;
    border-radius: 5px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    display: flex;
    cursor: pointer;
    background-color: variables.$message-colors-color-message-warning;
    padding: 0px 5px;
}

.feedbackButton > label {
    margin: 5px;
    color: white;
    font-size: 12px;
    cursor: pointer;
}

.feedbackButton[data-feedback-provided="true"] {
    background-color: #19b14d;
}
