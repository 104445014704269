@use "../../../../styles/variables.module";

.passwordFormContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
    margin-bottom: 20px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    background: variables.$background-colors-color-background-light;
}

.title {
    border-bottom: 1px solid variables.$border-colors-color-border-primary;
    background: variables.$background-colors-color-background-light-off;
}

.closeButton {
    color: variables.$text-colors-color-text-gray;
    /* Icons/$text-size-icon-Small */
    font-size: 16px;
    font-style: normal;
    cursor: pointer;
    position: absolute;
    right: 1.25rem;
}

.passwordError {
    color: variables.$message-colors-color-message-danger;
    /* $text-size-xSmall */
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    margin-bottom: 0.625rem;
    gap: 0;
}
