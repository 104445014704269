import { CSSProperties } from "react";
import { useNavigate } from "react-router-dom";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { backButton } from "./BackButton.module.scss";
import { translate } from "components/Translate/Translate";
import { useAppContext } from "contexts/AppContext";
import { tracker } from "utils/analytics";

const BackButton = ({
    styleOverrride,
    customOnClick,
}: {
    styleOverrride?: CSSProperties;
    customOnClick?: () => void;
}) => {
    const {
        config: { language },
    } = useAppContext();
    const navigate = useNavigate();
    return (
        <button
            onClick={() => {
                tracker("Back button clicked");
                customOnClick ? customOnClick() : navigate(-1);
            }}
            className={backButton}
            id="navBackButton"
            aria-label={translate(language, "Navigate Back")}
            style={styleOverrride}
        >
            <FontAwesomeIcon icon={faChevronLeft} />
        </button>
    );
};

export default BackButton;
