import { useState } from "react";
import {
    faAngleLeft,
    faAngleRight,
    faCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as styles from "./WelcomeSlides.module.scss";
import frame1Img from "./assets/welcome-tour-1.webp";
import frame2Img from "./assets/welcome-tour-2.webp";
import frame3Img from "./assets/welcome-tour-3.webp";
import frame4Img from "./assets/welcome-tour-4.webp";
import frameCollectionsImg from "./assets/welcome-tour-collections.webp";
import { useAppContext } from "contexts/AppContext";
import { useUserSettingsMutation } from "hooks/api/useUserSettingsQuery";
import { tracker } from "utils/analytics";

import Slide, { SlideType } from "./Slide";
import Translate from "components/Translate/Translate";

const WelcomeSlides = () => {
    const { config } = useAppContext();
    const userSettingsMutation = useUserSettingsMutation();
    const [currentSlide, updateCurrentSlide] = useState(0);

    const slides: SlideType[] = [
        {
            image: frame1Img,
            title: "Discover, review and share",
            bulletPoints: [
                "Curated content by educators mapped to six national curricula.",
                "STEM subjects for all curricula.",
                "Social science and English language for selected curricula.",
            ],
        },
        {
            image: frame2Img,
            title: "Browse or search",
            text: "Easily navigate through the familiar structure of your curriculum. Or, use the search option to find curated content for any learning objective.",
        },
        {
            image: frame3Img,
            title: "Share a link or embed resources",
            text: "Share a link or embed the selected topic or learning outcome resources with your learners. Your learners will receive the resources in a carefully designed experience to allow them to focus on what is important.",
        },
        {
            image: frameCollectionsImg,
            title: "Discover the Collections",
            text: "Create and share your collections of educational resources with your learners.",
        },
        {
            image: frame4Img,
            title: "Let’s start!",
            text: "Let’s discover and share curated resources today:",
            bulletPoints: ["Choose your curriculum and grade to start."],
        },
    ];

    const skipIntro = () => {
        tracker("Skipped Intro");
        completeOnboarding();
    };
    const finishIntro = () => {
        tracker("Finished Intro");
        completeOnboarding();
    };

    const completeOnboarding = () => {
        userSettingsMutation.mutate({
            ...config,
            completedTours: ["WELCOME"],
        });
    };
    const nextSlide = () => {
        if (currentSlide < slides.length - 1) {
            updateCurrentSlide((currentSlide) => currentSlide + 1);
            tracker("Intro Slide Next");
        }
    };

    const prevSlide = () => {
        if (currentSlide > 0) {
            updateCurrentSlide((currentSlide) => currentSlide - 1);
            tracker("Intro Slide Previous");
        }
    };

    return (
        <div className={styles.introModalDialog} role="document">
            <div className={styles.introContent}>
                <div className={styles.enable}>
                    <Slide slide={slides[currentSlide]} />
                    {currentSlide === slides.length - 1 && (
                        <div
                            className={`${styles.introFooter} ${styles.centerItem}`}
                        >
                            <button
                                type="button"
                                className={`${styles.button} ${styles.endButton}`}
                                onClick={finishIntro}
                            >
                                <Translate text="Let’s start!" />
                            </button>
                        </div>
                    )}
                </div>
                <div className={styles.sliderControlWrapper}>
                    <div className={styles.skipProgressWrapper}>
                        <button
                            data-testid="welcomeSkipButton"
                            type="button"
                            className={`${styles.button} ${styles.skipButton}`}
                            onClick={skipIntro}
                        >
                            <Translate text="Skip" />
                        </button>
                        <div className={styles.modalProgressMarks}>
                            <FontAwesomeIcon
                                icon={faCircle}
                                className={
                                    currentSlide === 0
                                        ? styles.progressActiveMark
                                        : ""
                                }
                            />
                            <FontAwesomeIcon
                                icon={faCircle}
                                className={
                                    currentSlide === 1
                                        ? styles.progressActiveMark
                                        : ""
                                }
                            />
                            <FontAwesomeIcon
                                icon={faCircle}
                                className={
                                    currentSlide === 2
                                        ? styles.progressActiveMark
                                        : ""
                                }
                            />
                            <FontAwesomeIcon
                                icon={faCircle}
                                className={
                                    currentSlide === 3
                                        ? styles.progressActiveMark
                                        : ""
                                }
                            />
                            <FontAwesomeIcon
                                icon={faCircle}
                                className={
                                    currentSlide === 4
                                        ? styles.progressActiveMark
                                        : ""
                                }
                            />
                        </div>
                    </div>
                    <div>
                        <button
                            onClick={prevSlide}
                            style={{ marginRight: "15px" }}
                            disabled={currentSlide === 0}
                            className={`${styles.button} ${styles.nextPrevButton}`}
                        >
                            <FontAwesomeIcon icon={faAngleLeft} />
                        </button>
                        <button
                            data-testid="welcome-next-button"
                            onClick={nextSlide}
                            disabled={currentSlide === slides.length - 1}
                            className={`${styles.button} ${styles.nextPrevButton}`}
                        >
                            <FontAwesomeIcon icon={faAngleRight} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WelcomeSlides;
