import { CSSProperties } from "react";
import { Button, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare, faSpinner } from "@fortawesome/free-solid-svg-icons";

import * as styles from "./ShareButtonUI.module.scss";

import Tooltip from "components/Tooltip/Tooltip";
import Translate from "components/Translate/Translate";

interface Props {
    clickHandler: (e: React.MouseEvent) => void;
    hasText: boolean;
    styleOverrides?: CSSProperties;
    disabled?: boolean;
    isNewDesign?: boolean;
    isProcessing?: boolean;
}

const ShareButtonUI = ({
    clickHandler,
    hasText,
    styleOverrides,
    disabled,
    isNewDesign,
    isProcessing,
}: Props) => {
    return (
        <div style={styleOverrides}>
            <Tooltip text="Share" disabled={hasText}>
                {isNewDesign ? (
                    <Button
                        variant="outlined"
                        className={styles.newDesignShareButton}
                        onClick={clickHandler}
                        disabled={disabled}
                    >
                        <FontAwesomeIcon
                            icon={isProcessing ? faSpinner : faShare}
                            spin={isProcessing}
                            fontSize={12}
                        />
                        {hasText && (
                            <Typography variant="text-size-xSmall-bold">
                                <Translate text="Share" />
                            </Typography>
                        )}
                    </Button>
                ) : (
                    <Button
                        data-testid="share-button"
                        variant="contained"
                        className={styles.openButton}
                        onClick={clickHandler}
                        disabled={disabled}
                    >
                        <FontAwesomeIcon
                            className={styles.icon}
                            icon={faShare}
                        />
                        {hasText && (
                            <span className={styles.text}>
                                <Translate text="Share" />
                            </span>
                        )}
                    </Button>
                )}
            </Tooltip>
        </div>
    );
};

export default ShareButtonUI;
