@use "../../styles/variables.module";

.container {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #0000;
    z-index: variables.$zindex-menu-container;
}

.list {
    position: fixed;
    top: 71px;
    right: 40px;
    overflow: hidden;
    border-radius: 0px 0px 10px 10px;
    box-shadow: 0px 10px 10px 0px rgba(108, 117, 125, 0.5);
    z-index: variables.$zindex-menu-container;
    max-height: 100%;
    @media (max-width: variables.$bp-md) {
        right: 0px;
    }
}

.positionBottomRight {
    top: 71px;
    left: 0;
    bottom: 0;
    right: 0;
}

.positionLeftFullHeightHalfWidth {
    top: 0;
    left: 0;
    bottom: 0;
    right: 50%;
}

.inDialogBoxStyle {
    top: 72px;
    left: 32px;
    bottom: 100px;
    box-shadow: 0px 0px 20px rgba(52, 58, 64, 0.6);
    @media (max-width: variables.$bp-md) {
        top: 71px;
        left: 0px;
        bottom: 0px;
        right: 0px;
    }
}

.closeButtonContainer {
    padding: 10px 20px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: variables.$background-colors-color-background-light-off;
    border-bottom: 1px solid variables.$border-colors-color-border-primary;
}
