import { listButton } from "./Autocomplete.module.scss";

interface Props {
    label: string;
    clickHandler: (arg0: number) => void;
    index: number;
}
const SuggestionButton = ({ label, clickHandler, index }: Props) => {
    return (
        <button
            id={`searchTerm-${index}`}
            className={listButton}
            value={label}
            onMouseDown={(e) => e.preventDefault()}
            onClick={() => {
                clickHandler(index);
            }}
        >
            {label}
        </button>
    );
};

export default SuggestionButton;
