@use "../../../../styles/variables.module";

.item {
    padding: 10px 20px;
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    background: variables.$background-colors-color-background-white;
    box-shadow: 0px -1px 0px 0px variables.$border-colors-color-border-primary inset;
}

.logo {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: variables.$icon-colors-green-40;
    border: 1px solid variables.$icon-colors-green-60;
    border-radius: 50%;
}

.info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.settings {
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
}
