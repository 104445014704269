export const formatVersionString = (version: string | undefined) => {
    if (typeof version !== "string") return; // intentionally silent
    return version.replace("refs/tags/", "").replace("refs/heads/", "");
};

export const formatVersionStringForApm = (version: string | undefined) => {
    if (typeof version !== "string") return; // intentionally silent
    return version?.replaceAll(".", "_");
};

export const getSubjectClass = (subjectName: string) => {
    return `subject-color-${subjectName
        .toLowerCase()
        ?.replace(/&|-/g, "")
        ?.replace(/\s/g, "-")}`;
};
