import { Theme, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import MyCollectionsPanel from "./components/MyCollectionsPanel/MyCollectionsPanel";
import MyCurriculumPanel from "./components/MyCurriculumPanel/MyCurriculumPanel";

const DashboardPage = () => {
    const isMobileScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("md")
    );

    return (
        <div
            style={{
                padding: isMobileScreen ? "30px 10px" : "30px 40px",
                marginBottom: 10,
            }}
        >
            <Grid container spacing={"20px"}>
                <Grid lg={4} md={6} xs={12}>
                    <MyCurriculumPanel />
                </Grid>
                <Grid lg={8} md={6} xs={12}>
                    <MyCollectionsPanel />
                </Grid>
            </Grid>
        </div>
    );
};

export default DashboardPage;
