import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Divider, TextField, Typography } from "@mui/material";

import * as styles from "../VerifyEmail.module.scss";
import { useAppContext } from "contexts/AppContext";
import { useSendConfirmationAgainMutation } from "hooks/api/authentication";
import { tracker } from "utils/analytics";

import Translate, { translate } from "components/Translate/Translate";

interface Props {
    resetDefaultValues: () => void;
}

const SendConfirmationAgainForm = ({ resetDefaultValues }: Props) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { config } = useAppContext();
    const language = config.language;
    const [error, setError] = useState<string | undefined>();
    const [email, setEmail] = useState<string>("");
    const sendConfirmationAgainMutation = useSendConfirmationAgainMutation();
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (sendConfirmationAgainMutation.isLoading) return;
        const { email } = e.currentTarget;
        if (!email.value) {
            throw new Error(translate(language, "Missing email"));
        }
        sendConfirmationAgainMutation.mutate(
            {
                email: email.value,
                application: "WIZEUP",
            },
            {
                onSuccess: () => {
                    tracker("Send confirmation email requested");
                    resetDefaultValues();
                },
                onError: (error) => {
                    if (
                        error.response.data.code === "signup.confirmedAccount"
                    ) {
                        setError(
                            translate(
                                language,
                                "That email address is already verified.",
                            ),
                        );
                    } else {
                        setError(
                            translate(
                                language,
                                "That email address does not exist on our system. Please register.",
                            ),
                        );
                    }
                },
            },
        );
    };
    return (
        <div className={styles.sendConfirmationAgainFormContainer}>
            {error && <div className={styles.formError}>{error}</div>}
            <form className={styles.formContainer} onSubmit={handleSubmit}>
                <TextField
                    name="email"
                    type="email"
                    label={translate(language, "Email")}
                    placeholder={translate(language, "Enter your email")}
                    value={email}
                    fullWidth
                    required
                    InputLabelProps={{ required: false }}
                    onChange={handleChange}
                />
                <Button
                    variant="contained"
                    type="submit"
                    className={styles.buttonStyle}
                >
                    <Typography variant="text-size-Base-bold">
                        <Translate text="Send confirmation again" />
                    </Typography>
                </Button>
            </form>
            <Divider style={{ margin: "12px 0px" }}>
                <Typography
                    variant="text-size-xSmall-regular"
                    color="$text-colors-color-text-gray.main"
                >
                    <Translate text="OR" />
                </Typography>
            </Divider>
            <Button
                variant="outlined"
                className={styles.buttonStyle}
                onClick={() =>
                    navigate(
                        {
                            pathname: "/login",
                            search: searchParams.toString(),
                        },
                        { relative: "path" },
                    )
                }
            >
                <Typography variant="text-size-Base-bold">
                    <Translate text="Back to login" />
                </Typography>
            </Button>
        </div>
    );
};

export default SendConfirmationAgainForm;
