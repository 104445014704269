@use "../../../styles/variables.module";

.container {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    @media (max-width: variables.$bp-md) {
        padding: 10px;
    }
}

.button {
    padding: 16px 30px;
    font-size: 16px;
    line-height: 1;
}
