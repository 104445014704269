import { createContext } from "react";

import { AnswerKey, Question } from "types";

export const QuizContext = createContext<{
    title: string;
    setTitle: (_value: string) => void;
    multipleChoice: boolean;
    setMultipleChoice: (_value: boolean) => void;
    trueOrFalse: boolean;
    setTrueOrFalse: (_value: boolean) => void;
    numberOfQuestions: number;
    setNumberOfQuestions: (_value: number) => void;
    questions: Array<Question>;
    setQuestions: (_value: Array<Question>) => void;
    answerKey: Array<AnswerKey>;
    setAnswerKey: (_value: Array<AnswerKey>) => void;
    isGenerating: boolean;
    setIsGenerating: (_value: boolean) => void;
    saveHandler: () => void;
    isSaving: boolean;
    setIsSaving: (_value: boolean) => void;
    timeStamp: string | undefined;
    setTimeStamp: (_value: string | undefined) => void;
    showQuizDetails: boolean;
    setShowQuizDetails: (_value: boolean) => void;
    isDirty: boolean;
    setIsDirty: (_value: boolean) => void;
    questionsInEditMode: Array<string>;
    setQuestionsInEditMode: (_value: Array<string>) => void;
}>({
    title: "",
    setTitle: (_value) => {
        return;
    },
    multipleChoice: true,
    setMultipleChoice: (_value) => {
        return;
    },
    trueOrFalse: true,
    setTrueOrFalse: (_value) => {
        return;
    },
    numberOfQuestions: 1,
    setNumberOfQuestions: (_value) => {
        return;
    },
    questions: [],
    setQuestions: (_value) => {
        return;
    },
    answerKey: [],
    setAnswerKey: (_value) => {
        return;
    },
    isGenerating: false,
    setIsGenerating: (_value) => {
        return;
    },
    saveHandler: () => {
        return;
    },
    isSaving: false,
    setIsSaving: (_value) => {
        return;
    },
    timeStamp: "",
    setTimeStamp: (_value) => {
        return;
    },
    showQuizDetails: false,
    setShowQuizDetails: (_value) => {
        return;
    },
    isDirty: false,
    setIsDirty: (_value) => {
        return;
    },
    questionsInEditMode: [],
    setQuestionsInEditMode: (_value) => {
        return;
    },
});
