import { useState } from "react";
import {
    Checkbox,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
} from "@mui/material";

import { settings } from "./OrganicSearchFilters.module.scss";
import { CollectionFilters, ReadingLevel, Subject } from "types";
import { tracker } from "utils/analytics";

import LabeledSwitch from "components/InputFields/LabeledSwitch/LabeledSwitch";
import { PanelSection, PanelV2 } from "components/Panel/Panel";
import ReadingLevelFilter, {
    allReadingLevels,
    allReadingLevelsSelected,
} from "components/ReadingLevelFilter/ReadingLevelFilter";
import Translate from "components/Translate/Translate";

export const allSubjects: Array<{ id: Subject; name: string }> = [
    { id: "wz.biology", name: "Biology" },
    { id: "wz.chemistry", name: "Chemistry" },
    { id: "wz.english", name: "English" },
    { id: "wz.mathematics", name: "Mathematics" },
    { id: "wz.physics", name: "Physics" },
    { id: "wz.science", name: "Science" },
];

interface Props {
    collectionFilters: CollectionFilters;
    updateCollectionFilters: (filter: string, value: unknown) => void;
}

const OrganicSearchFilters = ({
    collectionFilters,
    updateCollectionFilters,
}: Props) => {
    const [showPagesTooltip, setShowPagesTooltip] = useState(false);
    const [showVideosTooltip, setShowVideosTooltip] = useState(false);
    const { contentType, readingLevel, embeddable, subjects } =
        collectionFilters;
    const updateReadingLevels = (level: ReadingLevel) => {
        if (allReadingLevelsSelected(readingLevel)) {
            tracker("Change reading level", { readingLevel: [level] });
            updateCollectionFilters("readingLevel", [level]);
        } else {
            const newReadingLevel = [...readingLevel];
            if (newReadingLevel.includes(level)) {
                newReadingLevel.splice(newReadingLevel.indexOf(level), 1);
            } else {
                newReadingLevel.push(level);
                newReadingLevel.sort();
            }
            if (newReadingLevel.length != 0) {
                tracker("Changed reading level", {
                    readingLevel: newReadingLevel,
                });
                updateCollectionFilters("readingLevel", newReadingLevel);
            } else {
                tracker("Changed reading level", {
                    readingLevel: ["1", "2", "3", "4", "5"],
                });
                updateCollectionFilters("readingLevel", allReadingLevels);
            }
        }
    };
    return (
        <PanelV2
            styleOverrides={{
                flex: 1,
                gap: "30px",
                overflowY: "auto",
            }}
        >
            <PanelSection title="Content type">
                <div className={settings}>
                    <LabeledSwitch
                        label={"Pages"}
                        keyname={"pages"}
                        value={contentType === "all" || contentType === "pages"}
                        updateHandler={() => {
                            if (contentType === "all") {
                                updateCollectionFilters(
                                    "contentType",
                                    "videos",
                                );
                            } else if (contentType === "videos") {
                                updateCollectionFilters("contentType", "all");
                            } else {
                                setShowPagesTooltip(true);
                            }
                        }}
                        switchTooltip={
                            "At least one content type must be selected."
                        }
                        showSwitchTooltip={showPagesTooltip}
                        hideSwitchTooltipHandler={() =>
                            setShowPagesTooltip(false)
                        }
                    />
                    <LabeledSwitch
                        label={"Videos"}
                        keyname={"videos"}
                        value={
                            contentType === "all" || contentType === "videos"
                        }
                        updateHandler={() => {
                            if (contentType === "all") {
                                updateCollectionFilters("contentType", "pages");
                            } else if (contentType === "pages") {
                                updateCollectionFilters("contentType", "all");
                            } else {
                                setShowPagesTooltip(true);
                            }
                        }}
                        switchTooltip={
                            "At least one content type must be selected."
                        }
                        showSwitchTooltip={showVideosTooltip}
                        hideSwitchTooltipHandler={() =>
                            setShowVideosTooltip(false)
                        }
                    />
                </div>
            </PanelSection>
            {(contentType === "all" || contentType === "pages") && (
                <PanelSection title="Filter texts by reading level">
                    <div className={settings}>
                        <ReadingLevelFilter
                            readingLevels={readingLevel}
                            updateReadingLevels={updateReadingLevels}
                        />
                    </div>
                </PanelSection>
            )}
            <PanelSection title="Curriculum filters">
                <div className={settings}>
                    <FormControl>
                        <InputLabel id="select-subject-label">
                            <Translate text="Select subject(s)" />
                        </InputLabel>
                        <Select
                            labelId="select-subject-label"
                            multiple
                            value={subjects}
                            onChange={(e) => {
                                const newSubjects = e.target.value.sort();
                                tracker("Changed subjects", {
                                    subjects: newSubjects,
                                });
                                updateCollectionFilters(
                                    "subjects",
                                    newSubjects,
                                );
                            }}
                            input={
                                <OutlinedInput
                                    label={<Translate text="Select subjects" />}
                                />
                            }
                            renderValue={(selected) =>
                                allSubjects
                                    .filter((subject) =>
                                        selected.includes(subject.id),
                                    )
                                    .map((subject) => subject.name)
                                    .join(", ")
                            }
                            MenuProps={{
                                style: { zIndex: 35001 },
                            }}
                        >
                            {allSubjects.map(
                                (el: { id: Subject; name: string }) => (
                                    <MenuItem key={el.id} value={el.id}>
                                        <Checkbox
                                            checked={
                                                subjects.indexOf(el.id) > -1
                                            }
                                        />
                                        {el.name}
                                    </MenuItem>
                                ),
                            )}
                        </Select>
                    </FormControl>
                </div>
            </PanelSection>
            <PanelSection title="Wizenoze viewer">
                <div className={settings}>
                    <LabeledSwitch
                        label={
                            "Only resources available in the Wizenoze viewer"
                        }
                        keyname={"embed"}
                        value={embeddable}
                        updateHandler={() =>
                            updateCollectionFilters("embeddable", !embeddable)
                        }
                        tooltip={{
                            text: "Enable this filter to see only resources that can be opened within the Wizenoze viewer.",
                            url: new URL(
                                "https://wizenoze.zendesk.com/hc/en-gb/articles/16170659663506-How-to-add-and-remove-content-to-a-collection-",
                            ),
                            placement: "bottom",
                        }}
                    />
                </div>
            </PanelSection>
        </PanelV2>
    );
};

export default OrganicSearchFilters;
