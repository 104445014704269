import { faFolder } from "@fortawesome/free-solid-svg-icons";

import useScreenSizes from "hooks/useScreenSizes";
import variables from "styles/_variables.module.scss";

import { PanelSection, PanelV2 } from "components/Panel/Panel";
import TreeExplorer from "components/TreeExplorer/TreeExplorer";

interface Props {
    items: React.ComponentProps<typeof TreeExplorer>["data"];
    selectedIds?: React.ComponentProps<typeof TreeExplorer>["selectedNodes"];
    panelTitle?: string;
    folderClickHandler: React.ComponentProps<
        typeof TreeExplorer
    >["folderClickHandler"];
    collectionClickHandler: React.ComponentProps<
        typeof TreeExplorer
    >["collectionClickHandler"];
    pathRoot?: React.ComponentProps<typeof TreeExplorer>["pathRoot"];
}

const CollectionsExplorerPanel = ({
    items,
    selectedIds,
    panelTitle,
    folderClickHandler,
    collectionClickHandler,
    pathRoot = "/my-collections",
}: Props) => {
    const { isDesktopScreen } = useScreenSizes();
    return (
        <PanelV2
            styleOverrides={{
                flex: 1,
                borderRadius: isDesktopScreen ? undefined : "0px !important",
                overflowY: "auto",
            }}
        >
            <PanelSection
                title={panelTitle ?? "My collections"}
                icon={faFolder}
                colorOverride={variables["text-colors-color-text-gray"]}
            >
                <TreeExplorer
                    data={items ?? []}
                    selectedNodes={selectedIds ?? []}
                    pathRoot={pathRoot}
                    folderClickHandler={folderClickHandler}
                    collectionClickHandler={collectionClickHandler}
                />
            </PanelSection>
        </PanelV2>
    );
};

export default CollectionsExplorerPanel;
