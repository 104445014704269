import { useRef, useEffect, Dispatch, SetStateAction } from "react";

import {
    suggestionsListItem,
    suggestionsListItemText,
    radioStyle,
} from "./ListItem.module.scss";
import { tracker } from "utils/analytics";

interface Props {
    name: string;
    id: number;
    isSelected: boolean;
    selectAction:
        | Dispatch<SetStateAction<number | undefined>>
        | ((arg0: number | undefined) => void);
}

const ListItem = ({ name, id, isSelected, selectAction }: Props) => {
    const listItemRef = useRef<HTMLLIElement>(null);

    useEffect(() => {
        isSelected &&
            listItemRef?.current?.scrollIntoView({
                block: "nearest",
                inline: "start",
            });
    }, [isSelected, listItemRef]);

    const handleClick = () => {
        tracker("ListItem clicked", { id });
        selectAction(id);
    };

    return (
        <li
            className={suggestionsListItem}
            data-selected={isSelected}
            onClick={handleClick}
            ref={listItemRef}
        >
            <input
                type="radio"
                name={name.toLowerCase()}
                className={radioStyle}
                checked={isSelected}
            />
            <span className={suggestionsListItemText}>{name}</span>
        </li>
    );
};

export default ListItem;
