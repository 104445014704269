import { useState, Fragment, ReactNode } from "react";
import { faFolderTree } from "@fortawesome/free-solid-svg-icons";

import * as styles from "./MobileCollectionsExplorer.module.scss";
import useScreenSizes from "hooks/useScreenSizes";
import variables from "styles/_variables.module.scss";
import { tracker } from "utils/analytics";

import CollectionsExplorerPanel from "./CollectionsExplorerPanel";
import MenuButton from "components/MenuButton/MenuButton";
import MenuContainer from "components/MenuContainer/MenuContainer";

const SubheaderContainer = ({ children }: { children: ReactNode }) => (
    <div className={styles.subheader}>
        <div className={styles.subheaderGroup}>{children}</div>
    </div>
);

const MobileCollectionsExplorer = ({
    useOwnSubheaderContainer,
    inDialogBox,
    ...props
}: React.ComponentProps<typeof CollectionsExplorerPanel> & {
    useOwnSubheaderContainer?: boolean;
    inDialogBox?: boolean;
}) => {
    const { isMobileScreen } = useScreenSizes();
    const [isCollectionPanelOpen, setIsCollectionPanelOpen] = useState(false);

    const ButtonContainer = useOwnSubheaderContainer
        ? SubheaderContainer
        : Fragment;
    return (
        <>
            <ButtonContainer>
                <MenuButton
                    faIcon={faFolderTree}
                    iconColor={variables["icon-colors-blue-100"]}
                    primaryColor={variables["icon-colors-blue-40"]}
                    secondaryColor={variables["icon-colors-blue-60"]}
                    isOpen={isCollectionPanelOpen}
                    styleOverrides={{
                        width: "30px",
                        minWidth: "30px",
                        height: "30px",
                        fontSize: "12px",
                    }}
                    onClick={() => {
                        tracker("Collections panel menu open");
                        setIsCollectionPanelOpen(true);
                    }}
                />
            </ButtonContainer>
            <MenuContainer
                isOpen={isCollectionPanelOpen}
                closeMenu={() => setIsCollectionPanelOpen(false)}
                containerRelativePosition={
                    isMobileScreen
                        ? "bottom-right"
                        : "left-full-height-half-width"
                }
                inDialogBox={inDialogBox}
                fullWidthBreakpoint="xl"
                fullHeightBreakpoint="xl"
            >
                <CollectionsExplorerPanel {...props} />
            </MenuContainer>
        </>
    );
};

export default MobileCollectionsExplorer;
