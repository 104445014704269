@use "../../styles/variables.module";

.suggestionsListItem {
    /* height: 55px; */
    width: 100%;
    padding: 15px 20px;
    // margin-bottom: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: white;
    border-radius: 15px;
    border: 1px solid #e8e7e9;
    scroll-margin: 20px;
    transition:
        color 0.25s,
        background 0.25s;
    user-select: none;
    box-sizing: border-box;

    color: #000;

    font-size: 1rem; /* $text-size-Small */
    font-style: normal;
    font-weight: 400;
    line-height: 25px; /* 156.25% */
}

.suggestionsListItem[data-selected="true"] {
    border: 1px solid variables.$border-colors-color-border-blue;
    background-color: variables.$background-colors-color-background-light-blue;
}

.suggestionsListItemText {
    word-break: break-word;
    word-wrap: break-word;
    text-align: left;
}

.radioStyle {
    margin: 0;
    padding: 0;
    margin-right: 10px;
    height: 20px;
    border-color: #e8e7e9;
    width: 20px;
}
