import { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";

import { useAppContext } from "contexts/AppContext";
import { useOrganicSearchInfiniteQuery } from "hooks/api/useSearchQuery";
import useScreenSizes from "hooks/useScreenSizes";
import { ReadingLevel, Subject } from "types";
import { tracker } from "utils/analytics";

import OrganicSearchFilters from "./OrganicSearchFilters/OrganicSearchFilters";
import OrganicSearchResults from "./OrganicSearchResults/OrganicSearchResults";
import Loading from "components/Loading/Loading";
import NewSearchInput from "features/Search/SearchInput/NewSearchInput";

const AddFromSearch = () => {
    const { isDesktopScreen, isMobileScreen } = useScreenSizes();
    const { config } = useAppContext();
    const { showDrafts } = config;
    const [displayTerm, setDisplayTerm] = useState("");
    const [queryTerm, setQueryTerm] = useState("");
    const [contentType, setContentType] = useState<"all" | "pages" | "videos">(
        "all",
    );
    const [readingLevel, setReadingLevel] = useState<ReadingLevel[]>([
        "1",
        "2",
        "3",
        "4",
        "5",
    ]);
    const [embeddable, setEmbeddable] = useState(config.embeddable);
    const [subjects, setSubjects] = useState<Subject[]>([]);
    const { data, fetchNextPage, hasNextPage, isFetching } =
        useOrganicSearchInfiniteQuery(
            queryTerm,
            contentType,
            readingLevel,
            embeddable,
            showDrafts,
            subjects,
        );
    const collectionFilters = {
        contentType: contentType,
        readingLevel: readingLevel,
        embeddable: embeddable,
        subjects: subjects,
    };
    const updateCollectionFilters: (key: string, value: unknown) => void = (
        key: string,
        value: unknown,
    ) => {
        switch (key) {
            case "contentType": {
                if (
                    value === "all" ||
                    value === "pages" ||
                    value === "videos"
                ) {
                    setContentType(value);
                }
                break;
            }
            case "readingLevel": {
                if (
                    Array.isArray(value) &&
                    value.every((item) =>
                        ["1", "2", "3", "4", "5"].includes(item),
                    )
                ) {
                    setReadingLevel(value);
                }
                break;
            }
            case "embeddable": {
                if (typeof value === "boolean") {
                    setEmbeddable(value);
                }
                break;
            }
            case "subjects": {
                if (
                    Array.isArray(value) &&
                    value.every((item) =>
                        [
                            "wz.science",
                            "wz.physics",
                            "wz.chemistry",
                            "wz.biology",
                            "wz.mathematics",
                            "wz.english",
                        ].includes(item),
                    )
                ) {
                    setSubjects(value);
                }
                break;
            }
        }
    };
    const changeHandler = (value: string) => {
        setDisplayTerm(value);
    };
    const searchHandler = (value: string) => {
        setDisplayTerm(value);
        setQueryTerm(value);
        tracker("Collection Editor Search", {
            searchTerm: queryTerm,
        });
    };
    const resetTerm = () => {
        setDisplayTerm("");
    };
    return (
        <div
            style={{
                padding: !isMobileScreen ? "20px" : "10px",
                width: "100%",
            }}
        >
            <Grid container spacing={"20px"}>
                <>
                    {isDesktopScreen && (
                        <Grid lg={3} style={{ marginTop: "60px" }}>
                            <OrganicSearchFilters
                                collectionFilters={collectionFilters}
                                updateCollectionFilters={
                                    updateCollectionFilters
                                }
                            />
                        </Grid>
                    )}
                    <Grid lg={6} xs={12}>
                        {/* This div with height 40 exists to prevent the autocomplete menu displace the filters and results */}
                        <div style={{ marginBottom: "20px", height: "40px" }}>
                            <NewSearchInput
                                displayTerm={displayTerm}
                                changeHandler={changeHandler}
                                submitHandler={searchHandler}
                                resetTerm={resetTerm}
                            />
                        </div>
                        {queryTerm &&
                            (data ? (
                                <OrganicSearchResults
                                    data={data}
                                    fetchNextPage={() => fetchNextPage()}
                                    hasNextPage={hasNextPage}
                                    isFetching={isFetching}
                                    contentType={contentType}
                                    collectionFilters={collectionFilters}
                                    updateCollectionFilters={
                                        updateCollectionFilters
                                    }
                                />
                            ) : (
                                <Loading />
                            ))}
                    </Grid>
                </>
            </Grid>
        </div>
    );
};

export default AddFromSearch;
