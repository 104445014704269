import { useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronRight,
    faHouseChimney,
} from "@fortawesome/free-solid-svg-icons";

import { useAppContext } from "contexts/AppContext";
import { EmbedLibrary } from "types";
import { tracker } from "utils/analytics";
import { isShareMode } from "utils/newtools";

export type Breadcrumb = {
    label: React.ReactNode;
    pathname?: string;
    customOnClick?: () => void;
};

export const Crumb = ({
    crumb,
    clickHandler,
    isFirstCrumb,
    isLastCrumb,
}: {
    crumb: Breadcrumb;
    clickHandler: (arg0: Breadcrumb) => void;
    isFirstCrumb: boolean;
    isLastCrumb: boolean;
}) => {
    return (
        <span
            style={{
                paddingRight: "10px",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                overflow: isFirstCrumb ? "visible" : "hidden",
            }}
        >
            <button
                style={{
                    padding: "0px",
                    pointerEvents:
                        crumb.label !== "Home" && isLastCrumb ? "none" : "auto",
                    border: 0,
                    cursor: "pointer",
                    textDecoration: isLastCrumb ? "none" : "underline",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    color: "inherit",
                    background: "transparent",
                }}
                onClick={() => {
                    tracker("Breadcrumb clicked", { crumb });
                    clickHandler(crumb);
                }}
            >
                {isFirstCrumb ? (
                    <FontAwesomeIcon icon={faHouseChimney} width={16} />
                ) : (
                    crumb.label
                )}
            </button>
            {isFirstCrumb ? (
                <FontAwesomeIcon
                    icon={faChevronRight}
                    width={8}
                    color="inherit"
                />
            ) : undefined}
            {!isFirstCrumb && !isLastCrumb ? (
                <span
                    style={{
                        color: "inherit",
                        margin: "0 5px",
                    }}
                >
                    /
                </span>
            ) : undefined}
        </span>
    );
};

const Breadcrumbs = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { embedDetails, libraryCurriculumId } = useAppContext();

    const clickHandler = (crumb: Breadcrumb, isLast: boolean) => {
        if (searchParams.get("searchTerm") && crumb.label !== "Home" && isLast)
            return;
        tracker("Breadcrumb clicked");
        if (isShareMode && crumb.label === "Home" && !crumb.pathname) {
            if (
                embedDetails &&
                !embedDetails?.topicId &&
                !embedDetails?.queryId
            ) {
                const libraryEmbedDetails = embedDetails as EmbedLibrary;
                const currentLibraryCurriculumId =
                    libraryCurriculumId ??
                    libraryEmbedDetails.details.curriculumId;
                navigate(
                    `/library/embed/curriculum/${currentLibraryCurriculumId}`,
                );
            }
        } else {
            if (crumb.customOnClick) {
                crumb.customOnClick();
            } else {
                navigate({ pathname: crumb?.pathname ?? "/" });
            }
        }
    };
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                overflow: "hidden",
            }}
        >
            {breadcrumbs.map((crumb, index) => (
                <Crumb
                    key={`${index}-${crumb.label?.toString()}`}
                    crumb={crumb}
                    clickHandler={(crumb) => {
                        clickHandler(crumb, index + 1 === breadcrumbs.length);
                    }}
                    isFirstCrumb={index === 0}
                    isLastCrumb={index + 1 === breadcrumbs.length}
                />
            ))}
        </div>
    );
};

export default Breadcrumbs;
