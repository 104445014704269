import { Button, Typography } from "@mui/material";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import startFlagSVG from "./assets/start.svg";

import { BackgroundPattern } from "./BackgroundPattern";
import Translate from "components/Translate/Translate";
import InputField from "components/InputField/InputField";

interface Props {
    buttonClickHandler: () => void;
    quizTitle: string;
    questionCount: number;
    userName: string;
    setUserName: (userName: string) => void;
}

const StartScreen = ({
    buttonClickHandler,
    quizTitle,
    questionCount,
    userName,
    setUserName,
}: Props) => {
    return (
        <div
            style={{
                padding: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                width: "100%",
            }}
        >
            <BackgroundPattern colourState="neutral" height="320px" />
            <div
                style={{
                    zIndex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 265,
                    marginLeft: 20,
                    marginRight: 20,
                    minWidth: 500,
                }}
            >
                <div>
                    <img src={startFlagSVG} style={{ width: 152 }} />
                </div>
                <div
                    style={{
                        marginBottom: 10,
                        marginTop: 40,
                        textAlign: "center",
                    }}
                >
                    <Typography
                        variant="text-size-Large"
                        color="text-colors-color-text-dark.main"
                    >
                        <Translate text="Welcome to the " />
                        {quizTitle}
                        <Translate text=" quiz." />
                    </Typography>
                </div>
                <div>
                    <Typography
                        variant="text-size-Base"
                        color="text-colors-color-text-gray.main"
                        style={{
                            textAlign: "center",
                        }}
                    >
                        <Translate text="This quiz consists of " />
                        {questionCount}
                        <Translate text=" questions." />
                    </Typography>
                </div>
                <div
                    style={{
                        marginBottom: 10,
                        textAlign: "center",
                    }}
                >
                    <Typography
                        variant="text-size-Base"
                        color="text-colors-color-text-gray.main"
                    >
                        <Translate text="You can take the quiz as many times as you want." />
                    </Typography>
                </div>
                <div
                    style={{
                        marginTop: 20,
                        marginBottom: 10,
                        textAlign: "center",
                    }}
                >
                    <Typography
                        variant="text-size-Base"
                        color="text-colors-color-text-gray.main"
                    >
                        <Translate text="Enter your name to start the quiz" />
                    </Typography>
                </div>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        gap: 10,
                        justifyContent: "center",
                        alignItems: "center",
                        flexWrap: "wrap",
                    }}
                >
                    <InputField
                        fullWidth
                        required
                        value={userName}
                        name="userName"
                        type="text"
                        handleChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                        ) => {
                            setUserName(e.target.value);
                        }}
                        containerStyle={{
                            flex: 1,
                            textAlign: "center",
                            display: "flex",
                            gap: 10,
                            justifyContent: "space-between",
                            flexBasis: 300,
                        }}
                    />

                    <Button
                        data-testid="start-quiz-button"
                        onClick={buttonClickHandler}
                        variant="outlined"
                        style={{
                            borderColor:
                                userName === "" ? "#6C757D" : "#9B1DE8",
                            background: userName === "" ? "#ADB5BD" : "#FFEFFE",
                            borderRadius: "5px",
                            gap: 10,
                            justifySelf: "right",
                        }}
                        disabled={userName === ""}
                    >
                        <FontAwesomeIcon
                            icon={faArrowRight}
                            color={userName === "" ? "#FFF" : "#9B1DE8"}
                        />
                        <Typography
                            variant="text-size-Base-bold"
                            color={userName === "" ? "#FFF" : "#9B1DE8"}
                        >
                            <Translate text="Start the quiz" />
                        </Typography>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default StartScreen;
