import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import * as styles from "./NoCollectionsMessage.module.scss";
import { useAllFoldersEnhancedQuery } from "hooks/api/folders";
import useScreenSizes from "hooks/useScreenSizes";
import { tracker } from "utils/analytics";

import Translate from "components/Translate/Translate";

const NoCollectionsMessage = () => {
    const { isTabletScreen, isMobileScreen } = useScreenSizes();
    const navigate = useNavigate();
    const { data: allRootFolders } = useAllFoldersEnhancedQuery();
    const userPrivateFolder = allRootFolders?.find(
        (folder) => folder.access === "PRIVATE"
    );
    const clickHandler = () => {
        tracker("Add your first collection button clicked");
        navigate("/create-collection", {
            state: { collectionsParams: [userPrivateFolder?.id] },
        });
    };
    return (
        <div
            className={styles.noCollections}
            style={isTabletScreen ? { height: "100%" } : {}}
        >
            <Typography variant="text-size-Base">
                <Translate text="You don't have any collections yet. Once you create a collection, it will appear here." />
            </Typography>
            {!isMobileScreen && (
                <Button
                    data-testid="add-collection-button"
                    variant="contained"
                    className={styles.addCollectionButton}
                    onClick={clickHandler}
                >
                    <FontAwesomeIcon icon={faPlus} />
                    <Translate text="Add your first collection" />
                </Button>
            )}
        </div>
    );
};

export default NoCollectionsMessage;
