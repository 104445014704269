@use "../../../styles/variables.module";

.introModalDialog {
    pointer-events: none;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: auto;
    height: 100%;
    /* margin: 1.75rem auto; */
}

.nextPrevButton {
    background-color: variables.$background-colors-color-background-blue !important;
    color: white;
    border-radius: 50% !important;
}

.nextPrevButton:disabled {
    background-color: variables.$background-colors-color-background-light-gray;
}

.introContent {
    padding: 0;
    margin: 0px;
    border: none;
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    background-color: variables.$background-colors-color-background-white;
    background-clip: padding-box;
    border-radius: 15px;
    outline: 0;
    gap: 20px;

    position: relative;
    width: 487px;
    height: 525px;
    overflow: hidden;
}

.introContent .textCarrousel {
    font-size: 1.2rem;
    font-weight: 500;
}

.introContent .enable {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.introContent .disable {
    display: none;
}

.introHeader {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.introTitle {
    margin-bottom: 0;
    line-height: 1.5;
    color: variables.$text-colors-color-text-white;
    font-size: 2rem;
    font-weight: 500;
    margin-top: 0.5rem;
}

.introBody {
    position: relative;
    flex: 1 1 auto;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    color: #dee2e6;
}

.introBody::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.introBody img {
    opacity: 1;
    width: 65% !important;
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    border-style: none;
}

.introBody img.wizeupLogo {
    width: 45% !important;
    margin-top: 1rem;
}

.introBody.introTour2 {
    padding-top: 6rem;
}

.introBody.introTour2::before {
    opacity: 0.15;
}

.introTour3Grid {
    display: grid;
    grid-template-columns: 40% 60%;
    grid-gap: 10px;
    padding: 10px;
    height: 100%;
}

.introTour3Text {
    display: flex;
    align-items: center;
}

.introBody.introTour3 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.introBody.introTour3::before {
    top: 0rem;
    left: 0rem;
    width: 95%;
    background: green;
    background-size: 400px;
}

.introBody.introTour4 {
    padding: 0.8rem;
    display: flex;
    height: 100%;
}

.introBody.introTour4::before {
    left: 2%;
    width: 100%;
    height: 110%;
    background: purple;
}

.introBody.introTour4 .cards {
    width: 70%;
}

.multiLineTextWrap {
    width: 246px;
    line-height: 24px;
}

.sliderControlWrapper {
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.modalProgressMarks svg {
    font-size: 0.8rem;
    color: #ced4da;
    margin: 3px;
    opacity: 0.5;
}

svg.progressActiveMark {
    opacity: 0.8;
}

.skipProgressWrapper {
    display: flex;
    align-items: center;
}

.introFooter {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between !important;
    padding: 0.75rem;
    border-top: 1px solid #dee2e6;
    border: none;
    z-index: 10;
}

.introFooter > * {
    margin: 0.25rem;
}

.introFooter.centerItem {
    justify-content: center !important;
}

.button {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.button.skipButton {
    color: variables.$text-colors-color-text-blue;
    text-decoration: underline;
    font-weight: 400;
}

.button.nextButton {
    font-weight: 700;
    color: #504691;
    background: variables.$background-colors-color-background-light
        linear-gradient(
            180deg,
            #f8f9fb,
            variables.$background-colors-color-background-light
        )
        repeat-x;
    border-color: variables.$background-colors-color-background-light;
    width: 4rem;
    height: 4rem;
    border-radius: 50% !important;
    padding: 0;
}

.button.endButton {
    width: 320px;
    height: 45px;
    background: variables.$background-colors-color-background-blue;
    border-radius: 5px;
    order: 3;
    flex-grow: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    color: white;
}

.button.endButton svg {
    padding-right: 6px;
    color: variables.$text-colors-color-text-light-gray;
}

img.searchLargeIcon {
    width: 35% !important;
}

@media (max-width: 575.98px) {
    .introContent {
        padding: 0;
        width: 100%;
        height: 90vh;
    }
    .introTour1 {
        margin-bottom: 4.3rem;
    }
    .introTour2 {
        margin-bottom: 6rem;
    }
    .introTour3 {
        padding: 0.7rem !important;
    }
    .introTour3::before,
    .introTour4::before {
        background: none !important;
    }
    .introHeader {
        border: none;
        padding-bottom: 0;
        text-align: center;
        padding: 1rem;
    }
    .introTitle {
        font-size: 1.4rem;
        text-align: center;
        margin: 0.5rem auto;
    }
    .introBody {
        padding: 0;
        padding-top: 2rem !important;
    }
    .popover {
        display: none;
    }
}
