import { CSSProperties } from "react";
import { Breakpoint, Theme, useMediaQuery } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

import {
    container,
    list,
    positionBottomRight,
    positionLeftFullHeightHalfWidth,
    inDialogBoxStyle,
    closeButtonContainer,
} from "./MenuContainer.module.scss";
import variables from "styles/_variables.module.scss";
import { isEmbedMode } from "utils/newtools";

interface Props {
    children: React.ReactElement | React.ReactElement[];
    isOpen: boolean;
    closeMenu: () => void;
    styleOverrides?: CSSProperties;
    containerRelativePosition?:
        | "top-left"
        | "top-right"
        | "bottom-left"
        | "bottom-right"
        | "left-full-height-half-width";
    inDialogBox?: boolean;
    fullWidthBreakpoint?: Breakpoint;
    fullHeightBreakpoint?: Breakpoint;
}

export const MenuContainer = ({
    children,
    isOpen,
    closeMenu,
    styleOverrides,
    containerRelativePosition = "bottom-left",
    inDialogBox,
    fullWidthBreakpoint = "md",
    fullHeightBreakpoint = "xs",
}: Props) => {
    const isfullWidthMode = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down(fullWidthBreakpoint)
    );
    const isfullHeightMode = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down(fullHeightBreakpoint)
    );
    const differentPosition =
        containerRelativePosition === "bottom-right"
            ? positionBottomRight
            : containerRelativePosition === "top-right"
            ? ""
            : containerRelativePosition === "top-left"
            ? ""
            : containerRelativePosition === "left-full-height-half-width"
            ? positionLeftFullHeightHalfWidth
            : "";
    const inDialogBoxClass = inDialogBox ? inDialogBoxStyle : "";
    if (!isOpen) return null;
    return (
        <>
            {!inDialogBox && (
                <div
                    className={container}
                    onClick={closeMenu}
                    style={{
                        background:
                            containerRelativePosition ===
                            "left-full-height-half-width"
                                ? "#343a4099"
                                : isfullWidthMode
                                ? "linear-gradient(#0000 0 71px, #343a4099 71px 100%)"
                                : undefined,
                        ...styleOverrides,
                    }}
                ></div>
            )}
            <div
                className={`${list} ${differentPosition} ${inDialogBoxClass}`}
                style={{
                    width:
                        containerRelativePosition ===
                        "left-full-height-half-width"
                            ? "50%"
                            : inDialogBox
                            ? undefined
                            : isfullWidthMode
                            ? "100%"
                            : 340,
                    top: isEmbedMode ? 0 : undefined,
                    borderRadius:
                        containerRelativePosition ===
                        "left-full-height-half-width"
                            ? "0px 10px 10px 0px"
                            : undefined,
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        overflow: "hidden",
                        height: isfullHeightMode ? "100%" : undefined,
                        maxHeight: "100%",
                    }}
                >
                    {isfullWidthMode && (
                        <div className={closeButtonContainer}>
                            <div
                                style={{ cursor: "pointer" }}
                                onClick={closeMenu}
                            >
                                <FontAwesomeIcon
                                    icon={faXmark}
                                    color={
                                        variables["text-colors-color-text-gray"]
                                    }
                                    fontSize={15}
                                />
                            </div>
                        </div>
                    )}
                    {children}
                </div>
            </div>
        </>
    );
};

export default MenuContainer;
