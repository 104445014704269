import { useState } from "react";
import { faEdit, faPlay, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Typography, useMediaQuery } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";

import { useMessagingContext } from "contexts/MessagingContext";
import useScreenSizes from "hooks/useScreenSizes";
import { useDeleteQuizMutation } from "hooks/api/quizzes";
import { Collection } from "types";
import { tracker } from "utils/analytics";

import { PanelV2 } from "components/Panel/Panel";
import Translate from "components/Translate/Translate";
import QuizTaker from "features/CollectionShareViewer/QuizTaker/QuizTaker";

const PreviewQuizButton = ({
    onClickHandler,
}: {
    onClickHandler: () => void;
}) => {
    const isAtleast1250Width = useMediaQuery("(min-width:1250px)");
    const { isDesktopScreen } = useScreenSizes();
    const showLabel = !isDesktopScreen || isAtleast1250Width;
    return (
        <Button
            style={{
                height: "30px",
                padding: "8px",
                alignItems: "center",
                borderRadius: "4px",
                background: "#F0F6FF",
                verticalAlign: "middle",
            }}
            onClick={onClickHandler}
        >
            {showLabel && (
                <Typography variant="text-size-Small-regular">
                    <Translate text="Preview the quiz" />
                </Typography>
            )}
            <FontAwesomeIcon
                icon={faPlay}
                style={
                    showLabel ? { marginLeft: "10px", marginBottom: -1 } : {}
                }
                fontSize={14}
            />
        </Button>
    );
};

const EditQuizButton = ({ onClickHandler }: { onClickHandler: () => void }) => (
    <Button
        aria-label="edit quiz"
        color="primary"
        variant="outlined"
        style={{ padding: 8 }}
        onClick={onClickHandler}
    >
        <FontAwesomeIcon icon={faEdit} color="primary" fontSize={14} />
    </Button>
);

const DeleteQuizButton = ({
    onClickHandler,
}: {
    onClickHandler: () => void;
}) => (
    <Button
        aria-label="delete quiz"
        color="error"
        style={{ background: "#FDEBED", padding: 8 }}
        onClick={onClickHandler}
    >
        <FontAwesomeIcon icon={faTrash} color="error" fontSize={14} />
    </Button>
);

const QuizPanel = ({
    collection,
    editClickHandler,
}: {
    collection: Collection;
    editClickHandler: () => void;
}) => {
    const { openMessageModal } = useMessagingContext();
    const queryClient = useQueryClient();
    const quizData = collection?.quiz;
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const deleteQuizMutation = useDeleteQuizMutation();

    if (!quizData || !quizData.questions || quizData.questions.length === 0)
        return <></>;

    const previewQuiz = () => {
        tracker("Preview quiz opened");
        setIsPreviewOpen(true);
    };
    const editQuiz = () => {
        tracker("Edit quiz opened");
        editClickHandler();
    };

    const deleteQuiz = () => {
        tracker("Delete quiz dialog opened");
        openMessageModal({
            isModalVisible: true,
            title: "Delete the quiz",
            body: "Are you sure you want to delete the quiz? You can’t undo this action.",
            type: "Delete",
            primaryActionHandler: () =>
                deleteQuizMutation.mutate(
                    { collectionId: collection.id },
                    {
                        onSuccess: () => tracker("Quiz deleted successfully"),
                        onSettled: () =>
                            queryClient.invalidateQueries([
                                "collection",
                                collection.id,
                            ]),
                    },
                ),
        });
    };

    const closeHandler = () => {
        tracker("Preview quiz closed");

        setIsPreviewOpen(false);
    };
    return (
        <>
            <PanelV2
                styleOverrides={{
                    // flex: 1,
                    gap: "20px",
                    overflowY: "auto",
                    overflowX: "hidden",
                    borderRadius: "15px",
                    border: "1px solid  #BA29B5",
                    background: "white",
                    boxShadow: "0px 5px 20px 0px rgba(108, 117, 125, 0.20)",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                    }}
                >
                    <Typography
                        variant="text-size-Medium"
                        color="$text-colors-color-text-dark.main"
                    >
                        {quizData.title}
                    </Typography>
                </div>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexFlow: "row wrap",
                        gap: "10px",
                    }}
                >
                    <PreviewQuizButton onClickHandler={previewQuiz} />
                    <div style={{ display: "flex", gap: "10px" }}>
                        <EditQuizButton onClickHandler={editQuiz} />
                        <DeleteQuizButton onClickHandler={deleteQuiz} />
                    </div>
                </div>
            </PanelV2>
            <QuizTaker
                collectionId={collection.id}
                quizData={quizData}
                isOpen={isPreviewOpen}
                closeHandler={closeHandler}
                isPreview
                withCloseButton
            />
        </>
    );
};

export default QuizPanel;
