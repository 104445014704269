import { useState } from "react";
import {
    faArrowRightToBracket,
    // faClone,
    faPen,
    faShareFromSquare,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";

import { Folder } from "types";
import { tracker } from "utils/analytics";

import ThreeDotContextMenu from "./ThreeDotContextMenu";
import EditFolderDialog from "./dialogs/EditFolderDialog";
import DeleteFolderDialog from "./dialogs/DeleteFolderDialog";
import MoveFolderDialog from "./dialogs/MoveFolderDialog";
import ShareFolderDialog from "./dialogs/ShareFolderDialog";

const FolderThreeDotContextMenu = ({
    folder,
    isHovered,
}: {
    folder: Folder;
    isHovered: boolean;
}) => {
    const [isDialogVisible, setIsDialogVisible] = useState(false);
    const [openDialogType, setOpenDialogType] = useState<
        | undefined
        | "move"
        // | "duplicate"
        | "delete"
        | "edit"
        | "share"
    >();
    const moveToHandler = () => {
        tracker("Move Folder Dialog Opened");
        setOpenDialogType("move");
        setIsDialogVisible(true);
    };
    // const duplicateHandler = () => {
    //     tracker("Duplicate Folder Dialog Opened");
    //     console.log("Duplicate ");
    // };
    const renameHandler = () => {
        tracker("Edit Folder Dialog Opened");
        setOpenDialogType("edit");
        setIsDialogVisible(true);
    };
    const shareHandler = () => {
        tracker("Share Folder Dialog Opened");
        setOpenDialogType("share");
        setIsDialogVisible(true);
    };
    const deleteHandler = () => {
        tracker("Delete Folder Dialog Opened");
        setOpenDialogType("delete");
        setIsDialogVisible(true);
    };
    return (
        <>
            <ThreeDotContextMenu
                options={[
                    {
                        label: "Move to",
                        icon: faArrowRightToBracket,
                        clickHandler: moveToHandler,
                    },
                    // {
                    //     label: "Duplicate",
                    //     icon: faClone,
                    //     clickHandler: duplicateHandler,
                    // },
                    {
                        label: "Rename",
                        icon: faPen,
                        clickHandler: renameHandler,
                    },
                    {
                        label: "Share",
                        icon: faShareFromSquare,
                        clickHandler: shareHandler,
                    },
                    {
                        label: "Delete",
                        icon: faTrash,
                        clickHandler: deleteHandler,
                    },
                ]}
                isHovered={isHovered}
            />
            {isDialogVisible && openDialogType === "move" && (
                <MoveFolderDialog
                    folder={folder}
                    isVisible={isDialogVisible && openDialogType === "move"}
                    setIsVisible={setIsDialogVisible}
                />
            )}
            {isDialogVisible && openDialogType === "edit" && (
                <EditFolderDialog
                    folder={folder}
                    isVisible={isDialogVisible && openDialogType === "edit"}
                    setIsVisible={setIsDialogVisible}
                />
            )}
            {isDialogVisible && openDialogType === "share" && (
                <ShareFolderDialog
                    folder={folder}
                    isVisible={isDialogVisible && openDialogType === "share"}
                    setIsVisible={setIsDialogVisible}
                />
            )}
            {isDialogVisible && openDialogType === "delete" && (
                <DeleteFolderDialog
                    folder={folder}
                    isVisible={isDialogVisible && openDialogType === "delete"}
                    setIsVisible={setIsDialogVisible}
                />
            )}
        </>
    );
};

export default FolderThreeDotContextMenu;
