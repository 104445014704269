import {
    container,
    title,
    text,
    supportTeamText,
} from "./NoResults.module.scss";
import { tracker } from "utils/analytics";

import Translate from "components/Translate/Translate";

const handleClickSupportTeam = () => {
    tracker("Support Team Clicked");
    const a = document.createElement("a");
    a.href = "mailto:support@wizenoze.com";
    a.click();
};

const NoResults = () => {
    return (
        <div className={container}>
            <div className={title}>
                <Translate text="We are sorry! It seems that no resources match your current filter combination." />
            </div>
            <div className={text}>
                <Translate text="Consider adjusting your filters to expand your search." />
            </div>
            <div className={text}>
                <Translate text="If you still encounter the same issue, our dedicated " />
                <span
                    className={supportTeamText}
                    onClick={handleClickSupportTeam}
                >
                    <Translate text="support team" />
                </span>
                <Translate text=" is here to assist you." />
            </div>
        </div>
    );
};

export default NoResults;
