@use "../../../styles/variables.module";

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.tooltipOn {
    text-decoration: dotted underline;
    cursor: help;
}
