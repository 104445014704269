import Grid from "@mui/material/Unstable_Grid2";
import useScreenSizes from "hooks/useScreenSizes";
import { tracker } from "utils/analytics";

import AddResources from "./AddResources/AddResources";

const MyResources = ({ closeHandler }: { closeHandler: () => void }) => {
    const { isMobileScreen } = useScreenSizes();
    return (
        <div
            style={{
                padding: !isMobileScreen ? "20px" : "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <Grid container spacing={2}>
                <Grid lg={12}>
                    <AddResources
                        hideForm={() => {
                            tracker('Closed "Add Resources Form" Manually');
                            closeHandler();
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default MyResources;
