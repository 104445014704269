@use "../../styles/variables.module";

.dialogTitle {
    color: variables.$text-colors-color-text-dark;
    text-align: center;
    font-size: 18px;
    // font-style: normal;
    // font-weight: 500;
    line-height: 25px; /* 138.889% */
    border-bottom: 1px solid variables.$border-colors-color-border-primary;
    background: variables.$background-colors-color-background-light-off;
    padding: 10px 20px;
}

.introModalOpen .backdrop {
    overflow-x: hidden;
    overflow-y: auto;
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #343a403c;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.backdrop.fade .introModalDialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
}

.backdrop.show .introModalDialog {
    transform: none;
}
