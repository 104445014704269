import { Skeleton } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

const FolderListViewSkeleton = () => {
    return (
        <Grid container spacing={"20px"}>
            <Grid xs={12}>
                <Skeleton variant="rectangular" height={200} />
            </Grid>
        </Grid>
    );
};

export default FolderListViewSkeleton;
