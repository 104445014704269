import React from "react";
import { TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleMinus } from "@fortawesome/free-solid-svg-icons";

import { urlFieldContainer } from "./URLInputField.module.scss";
import variables from "styles/_variables.module.scss";
import { translate } from "components/Translate/Translate";
import { useAppContext } from "contexts/AppContext";

const URLInputField = ({
    clearHandler,
    error,
    ...props
}: React.ComponentProps<typeof TextField> & {
    clearHandler?: () => void;
    error?: boolean;
}) => {
    const {
        config: { language },
    } = useAppContext();
    return (
        <div className={urlFieldContainer}>
            <TextField
                variant="outlined"
                size="small"
                placeholder="https://"
                style={{ flex: 1 }}
                error={error}
                helperText={
                    error &&
                    translate(
                        language,
                        "We can't reach the provided URL. The URL is incorrect or it has restricted access.",
                    )
                }
                {...props}
            />
            <FontAwesomeIcon
                icon={faCircleMinus}
                color={
                    clearHandler
                        ? variables["message-colors-color-message-danger"]
                        : variables["text-colors-color-text-light-gray"]
                }
                fontSize={20}
                onClick={clearHandler}
                style={error ? { marginTop: -20 } : undefined}
            />
        </div>
    );
};

export default URLInputField;
