import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { axiosAPI } from "./axiosAPI";

import { useAppContext } from "contexts/AppContext";
import { UserBase } from "types";
import { identifySignedInUser, tracker } from "utils/analytics";

type UserLoginDetails = { email: string; password: string };

export const useLoginMutation = () => {
    const queryClient = useQueryClient();
    const { config } = useAppContext();
    return useMutation(
        async (loginDetails: UserLoginDetails) => {
            return await axiosAPI
                .getInstance()
                ?.put("/signin", { ...loginDetails, application: "WIZEUP" });
        },
        {
            onSuccess: async ({ data }) => {
                if (data && data.authenticationToken) {
                    tracker("Log in successful");
                    await axiosAPI.initialise(config, {
                        me: data,
                    });
                    queryClient.setQueryData(["user-me"], { me: data });
                    identifySignedInUser(data);
                }
            },
        },
    );
};

export const useLogoutMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(async () => {
        const response = await axiosAPI.getInstance()?.post("/signout");
        if (response?.data) {
            queryClient.setQueryData(["user-me"], null);
        }
    });
};

type SignupUser = UserLoginDetails & {
    email: string;
    password: string;
    passwordConfirmation: string;
    languageId: number;
    companyName: string | undefined;
    agreement: true;
    region: string;
    marketingOptIn: boolean;
    application: "WIZEUP";
};

export const useSignupMutation = () => {
    return useMutation<UserBase, AxiosError>(
        async ({ user }: { user: SignupUser }) => {
            const { data } = await axiosAPI
                .getInstance()
                ?.post("/signup", user);
            return data;
        },
    );
};

export const usePasswordResetMutation = () => {
    return useMutation(async (email: { email: string }) => {
        await axiosAPI.getInstance()?.post("/signin/password/reset", {
            ...email,
            application: "WIZEUP",
        });
    });
};

type PasswordUpdateDetails = {
    token: string;
    password: string;
    passwordConfirmation: string;
};

export const usePasswordUpdateMutation = () => {
    return useMutation(async (passwordUpdateDetails: PasswordUpdateDetails) => {
        await axiosAPI
            .getInstance()
            ?.put(`/signin/password/${passwordUpdateDetails.token}`, {
                password: passwordUpdateDetails.password,
                passwordConfirmation:
                    passwordUpdateDetails.passwordConfirmation,
            });
    });
};

export const useVerifyEmailMutation = () => {
    return useMutation(async (verificationToken: string) => {
        return await axiosAPI
            .getInstance()
            ?.put(`/signup/confirm/${verificationToken}`);
    });
};

type SendConfirmationAgainDetails = {
    email: string;
    application: "WIZEUP";
};

export const useSendConfirmationAgainMutation = () => {
    return useMutation(
        async (sendConfirmationAgainDetails: SendConfirmationAgainDetails) => {
            await axiosAPI
                .getInstance()
                ?.post("/signup/verify/resend", sendConfirmationAgainDetails);
        },
    );
};
