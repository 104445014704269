import { useContext, useEffect, useState } from "react";

import { QuizContext } from "./state/QuizContext";
import { useMessagingContext } from "contexts/MessagingContext";
import { CollectionContext } from "features/CollectionEditor/state/CollectionContext";
import { useCollectionQuery } from "hooks/api/collections";
import {
    useCreateQuizMutation,
    useGenerateQuestionsQuery,
} from "hooks/api/quizzes";
import useCollectionsParams from "hooks/useCollectionsParams";
import useScreenSizes from "hooks/useScreenSizes";
import { tracker } from "utils/analytics";

import QuizDetails from "./components/QuizDetails/QuizDetails";
import QuizQuestions from "./components/QuizQuestions/QuizQuestions";
import QuizSteps from "./components/QuizSteps/QuizSteps";
import DialogBox from "components/DialogBox/DialogBox";

interface Props {
    isEditorOpen: boolean;
    setIsEditorOpen: (value: boolean) => void;
}

const QuizEditor = ({ isEditorOpen, setIsEditorOpen }: Props) => {
    const { isDesktopScreen } = useScreenSizes();
    const { collection } = useContext(CollectionContext);
    const collectionParams = useCollectionsParams();
    const { data: fullCollection, status: fullCollectionStatus } =
        useCollectionQuery(collection.id || collectionParams.at(-1));
    const { openMessageModal } = useMessagingContext();
    const savedQuizData = fullCollection?.quiz;
    const [title, setTitle] = useState(
        savedQuizData?.title ?? fullCollection?.title,
    );
    const [multipleChoice, setMultipleChoice] = useState(true);
    const [trueOrFalse, setTrueOrFalse] = useState(true);
    const [numberOfQuestions, setNumberOfQuestions] = useState(5);
    const [questions, setQuestions] = useState(savedQuizData?.questions ?? []);
    const [answerKey, setAnswerKey] = useState(savedQuizData?.answerKey ?? {});
    const [isGenerating, setIsGenerating] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [timeStamp, setTimeStamp] = useState<string | undefined>(undefined);
    const [showQuizDetails, setShowQuizDetails] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [questionsInEditMode, setQuestionsInEditMode] = useState<
        Array<string>
    >([]);
    const { data: generateQuestionsData, fetchStatus } =
        useGenerateQuestionsQuery(fullCollection?.id, numberOfQuestions, {
            timeStamp,
        });
    useEffect(() => {
        if (
            isGenerating &&
            fetchStatus !== "fetching" &&
            generateQuestionsData
        ) {
            setQuestions([...questions, ...generateQuestionsData.questions]);
            setIsGenerating(false);
        }
    }, [
        fetchStatus,
        generateQuestionsData,
        isGenerating,
        questions,
        setIsGenerating,
        setQuestions,
    ]);
    const [isEditorInitialised, setIsEditorInitialised] = useState(false);
    useEffect(() => {
        if (fullCollectionStatus === "success" && !isEditorInitialised) {
            if (fullCollection?.quiz) {
                setTitle(fullCollection.quiz.title);
                setQuestions(fullCollection.quiz.questions);
            }
            setIsEditorInitialised(true);
        }
    }, [fullCollection?.quiz, fullCollectionStatus, isEditorInitialised]);
    const createQuizMutation = useCreateQuizMutation();
    const saveHandler = async () => {
        tracker("Quiz Save Button Clicked");
        if (questionsInEditMode.length > 0) {
            openMessageModal({
                isModalVisible: true,
                title: "Questions are not saved",
                body: "Save all individual questions first.",
                type: "OK",
                primaryActionHandler: () => undefined,
            });
        } else {
            setIsSaving(true);
            await createQuizMutation.mutateAsync({
                collectionId: fullCollection?.id,
                quiz: {
                    title: title,
                    questions: questions,
                    answerKey: answerKey,
                },
            });
            setIsSaving(false);
            setIsDirty(false);
        }
    };
    const closeEditor = () => {
        tracker("Quiz Editor Closed");
        if (isDirty) {
            openMessageModal({
                isModalVisible: true,
                title: "Quiz is not saved",
                body: "Your work will be lost if you navigate away from this page. Are you sure you want to discard the changes?",
                type: "Yes, leave the page",
                primaryActionHandler: resetEditor,
            });
        } else {
            setIsEditorOpen(false);
        }
    };
    const resetEditor = () => {
        setTitle(savedQuizData?.title ?? fullCollection?.title);
        setQuestions(savedQuizData?.questions ?? []);
        setAnswerKey(savedQuizData?.answerKey ?? {});
        setIsDirty(false);
        setIsEditorOpen(false);
    };

    return (
        <QuizContext.Provider
            value={{
                title,
                setTitle,
                multipleChoice,
                setMultipleChoice,
                trueOrFalse,
                setTrueOrFalse,
                numberOfQuestions,
                setNumberOfQuestions,
                questions,
                setQuestions,
                answerKey,
                setAnswerKey,
                isGenerating,
                setIsGenerating,
                saveHandler,
                isSaving,
                setIsSaving,
                timeStamp,
                setTimeStamp,
                showQuizDetails,
                setShowQuizDetails,
                isDirty,
                setIsDirty,
                questionsInEditMode,
                setQuestionsInEditMode,
            }}
        >
            <DialogBox
                isOpen={isEditorOpen}
                closeHandler={closeEditor}
                title="Quiz (Beta)"
                withCloseButton
            >
                <div style={{ width: "100%", height: "100%", display: "flex" }}>
                    {isDesktopScreen && (
                        <div style={{ width: "420px" }}>
                            <QuizDetails />
                        </div>
                    )}
                    <div style={{ width: "100%" }}>
                        {!isDesktopScreen && showQuizDetails ? (
                            <QuizDetails />
                        ) : questions?.length === 0 && !isGenerating ? (
                            <QuizSteps />
                        ) : (
                            <QuizQuestions />
                        )}
                    </div>
                </div>
            </DialogBox>
        </QuizContext.Provider>
    );
};

export default QuizEditor;
