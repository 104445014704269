import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
    card,
    container,
    visualContainer,
    imageContainer,
    imageCrop,
    img,
    videoIconContainer,
    videoIcon,
    textContainer,
    feedbackFooter,
} from "./ResultCard.module.scss";
import { useAppContext } from "contexts/AppContext";
import useUserQuery from "hooks/api/useUserQuery";
import wizenozePlaceholder from "images/wizenoze-placeholder.webp";
import { Result } from "types";
import { getImageWithSize, setBlankImage } from "utils/helpers";

import Feedback from "./Feedback/Feedback";
import PremiumIcon from "./PremiumIcon/PremiumIcon";
import ResultCardBody from "./ResultCardBody/ResultCardBody";
import ResultCardFooter from "./ResultCardFooter/ResultCardFooter";
import TeacherApproved from "./TeacherApproved/TeacherApproved";
import ResultLink from "features/ResultLink/ResultLink";

export interface ResultThumbnails {
    articleImageThumbnail: string;
    openGraphImageThumbnail: string;
    thumbnail: string;
    // Add other properties here if needed
}

export const getImgUrl = ({
    articleImageThumbnail,
    openGraphImageThumbnail,
    thumbnail,
}: ResultThumbnails) =>
    getImageWithSize({
        imgUrl: articleImageThumbnail || openGraphImageThumbnail,
        length: 400,
        height: 400,
        fallbacks: [thumbnail, wizenozePlaceholder],
    });

interface OptionsFooterProps {
    showFeedback: boolean;
    children?: React.ReactElement | React.ReactElement[];
}

const OptionsFooter = ({ showFeedback, children }: OptionsFooterProps) => {
    if (showFeedback) {
        return <div className={feedbackFooter}>{children}</div>;
    } else {
        return <></>;
    }
};

interface Props {
    results: Result[];
    resultIndex: number;
    type: string;
    stripSummaryText: boolean;
    isSearchResult: boolean;
}

const ResultCard = ({
    results,
    resultIndex,
    type,
    stripSummaryText,
    isSearchResult,
    ...props
}: Props) => {
    const result = results[resultIndex];
    const { data: userData } = useUserQuery();
    const userIsVerifier = userData?.me?.roles?.includes("VERIFIER");
    const showFeedback = !isSearchResult && userIsVerifier ? true : false;

    return (
        <div className={card}>
            <div className={container}>
                <div className={visualContainer}>
                    <ResultLink
                        results={results}
                        initialIndex={resultIndex}
                        styles={imageContainer}
                    >
                        <div className={imageCrop}>
                            <img
                                onError={setBlankImage}
                                src={getImgUrl(result) || wizenozePlaceholder}
                                alt={result.title}
                                className={img}
                            />
                        </div>
                        {result?.documentTypes?.includes("video") && (
                            <div className={videoIconContainer}>
                                <FontAwesomeIcon
                                    icon={faPlayCircle}
                                    className={videoIcon}
                                    title="video-icon"
                                />
                            </div>
                        )}
                    </ResultLink>
                </div>
                <div className={textContainer}>
                    <ResultCardBody
                        results={results}
                        resultIndex={resultIndex}
                        resourceType={type}
                        stripSummaryText={stripSummaryText}
                        {...props}
                    />
                    <ResultCardFooter
                        results={results}
                        resultIndex={resultIndex}
                    />
                </div>
                <PremiumIcon
                    collectionAccessType={result?.collectionAccessType}
                />
                <TeacherApproved
                    collectionAccessType={result?.collectionAccessType}
                    isTeacherApproved={result.teacherApproved}
                    isCurriculumBasics={result?.isCurriculumBasics}
                />
            </div>
            <OptionsFooter showFeedback={showFeedback}>
                <Feedback
                    {...{
                        result,
                        isSearchResult,
                    }}
                />
            </OptionsFooter>
        </div>
    );
};

export default ResultCard;
