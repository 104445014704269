import React, { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Button, Divider, TextField, Typography } from "@mui/material";

import * as styles from "./Login.module.scss";
import { useAppContext } from "contexts/AppContext";
import { useLoginMutation } from "hooks/api/authentication";
import { tracker } from "utils/analytics";

import PublicPageContainer from "../components/PublicPageContainer/PublicPageContainer";
import PublicPageTitle from "../components/PublicPageTitle/PublicPageTitle";
import Translate, { translate } from "components/Translate/Translate";

const Login = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { config } = useAppContext();
    const language = config.language;
    const [error, setError] = useState<string>();
    const loginMutation = useLoginMutation();
    const [formData, setFormData] = useState<{
        email: string;
        password: string;
    }>({ email: "", password: "" });

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (loginMutation.isLoading) return;
        const { email, password } = e.currentTarget;
        if (!(email.value && password.value)) {
            tracker("Log in failed - missing credentials");
            throw new Error(translate(language, "Missing login credentials"));
        }
        loginMutation.mutate(
            { email: email.value, password: password.value },
            {
                onSuccess: async ({ data }) => {
                    if (data && data.authenticationToken) {
                        navigate("/");
                    }
                },
                onError: (_error) => {
                    tracker("Log in failed - wrong credentials");
                    setError(
                        translate(
                            language,
                            "Sorry, we don't recognise this email and password. Please ensure the email address you entered is correct or verified by following the instructions in the confirmation email.",
                        ),
                    );
                },
            },
        );
    };
    return (
        <PublicPageContainer page="login">
            <div className={styles.loginContainer}>
                <div className={styles.title}>
                    <PublicPageTitle title="Login" />
                </div>
                {error && <div className={styles.formError}>{error}</div>}
                <form className={styles.formContainer} onSubmit={submitHandler}>
                    <TextField
                        name="email"
                        type="email"
                        label={translate(language, "Email")}
                        placeholder={translate(language, "Enter your email")}
                        value={formData.email}
                        fullWidth
                        required
                        InputLabelProps={{ required: false, shrink: true }}
                        onChange={changeHandler}
                    />
                    <TextField
                        name="password"
                        type="password"
                        label={translate(language, "Password")}
                        placeholder={translate(language, "Enter your password")}
                        value={formData.password}
                        fullWidth
                        required
                        InputLabelProps={{ required: false, shrink: true }}
                        onChange={changeHandler}
                    />
                    <Button
                        variant="contained"
                        type="submit"
                        className={styles.formSubmitButton}
                    >
                        <Typography variant="text-size-Base-bold">
                            <Translate text="Login" />
                        </Typography>
                    </Button>
                </form>
                <div className={styles.forgotPassword}>
                    <Link to={"/forgot-password"}>
                        <Typography variant="text-size-Small-regular">
                            <Translate text="Forgot password?" />
                        </Typography>
                    </Link>
                </div>
                <Divider style={{ margin: "12px 0px" }}>
                    <Typography
                        variant="text-size-xSmall-regular"
                        color="$text-colors-color-text-gray.main"
                    >
                        <Translate text="OR" />
                    </Typography>
                </Divider>
                <Button
                    variant="outlined"
                    className={styles.createAccountButton}
                    onClick={() =>
                        navigate(
                            {
                                pathname: "/signup",
                                search: searchParams.toString(),
                            },
                            { relative: "path" },
                        )
                    }
                >
                    <Typography variant="text-size-Base-bold">
                        <Translate text="Create account" />
                    </Typography>
                </Button>
            </div>
        </PublicPageContainer>
    );
};

export default Login;
