@use "../../../../../../styles/variables.module";

.card {
    box-shadow: 0 5px 20px rgba(108, 117, 125, 0.2);
    border-radius: 15px;
    border: 1px solid variables.$border-colors-color-border-light;
    background-color: white;
    /* min-height: 455px; */
    max-width: 100%;
    /* min-width: 280px; */
    position: relative;
    overflow: hidden;
    display: flex;
    height: 100%;
}

.container {
    padding: 10px;
    width: 100%;
    height: 475;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
}

.resultGridContainer {
    padding: 20px 40px;
}

.visualContainer {
    display: flex;
    justify-content: center;
    position: relative;
    height: 150px;
}

.imageContainer {
    max-height: 150px;
    min-height: 150px;
    overflow: hidden;
    display: flex;
    place-items: center;
    justify-content: center;
    border-radius: 5px;
}

/* The first child of the featured card is the image */
div[data-numberofresults="5"]
    .container:first-child[data-large-screen="true"][data-mobile-screen="false"]
    .imageContainer {
    grid-column-start: 2;
    grid-row: 1 / -1;
    max-height: 500px;
    min-width: 275px;
    max-width: 420px;
    justify-self: end;
}

.container:first-child[data-large-screen="true"] .imageCrop {
    max-height: 500px;
}

.img {
    border-radius: 5px;
    object-fit: cover;
    max-width: 100%;
    cursor: pointer;
    padding: 20px;
    margin: 0;
}

.videoIconContainer {
    position: absolute;
    bottom: 40px;
    right: 55px;
    cursor: pointer;
}

.videoIcon {
    position: absolute;
    left: 16px;
    color: variables.$message-colors-color-message-danger;
    font-size: 3.8rem;
    background-color: white;
    border-radius: 50%;
    border: 2px solid variables.$icon-colors-green-100;
}

/* Override font awesome */
.videoIconContainer svg.videoIcon {
    width: 30px;
    height: 30px;
}

.textContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    flex-grow: 1;
}

@media (max-width: variables.$bp-md) {
    .resultGridContainer {
        padding: 15px 20px;
    }
}
