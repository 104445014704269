import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";

import * as styles from "./Subheader.module.scss";
import { useSelectedFoldersAndCollections } from "hooks/api/folders";
import useCollectionsParams from "hooks/useCollectionsParams";
import useScreenSizes from "hooks/useScreenSizes";
import variables from "styles/_variables.module.scss";

import MenuButton from "components/MenuButton/MenuButton";
import Translate from "components/Translate/Translate";

const EditButton = () => {
    const { isMobileScreen } = useScreenSizes();
    const navigate = useNavigate();
    const collectionsParams = useCollectionsParams();
    const selectedFoldersAndCollections = useSelectedFoldersAndCollections();
    const selectedFolderOrCollection =
        selectedFoldersAndCollections[selectedFoldersAndCollections.length - 1];
    const clickHandler = () => {
        navigate(`/edit-collection/${selectedFolderOrCollection.id}`, {
            state: {
                collectionsParams: collectionsParams,
            },
        });
    };
    return isMobileScreen ? (
        <MenuButton
            faIcon={faPenToSquare}
            iconColor={variables["icon-colors-blue-100"]}
            primaryColor={variables["icon-colors-blue-40"]}
            secondaryColor={variables["icon-colors-blue-60"]}
            isOpen={false}
            styleOverrides={{
                width: "30px",
                minWidth: "30px",
                height: "30px",
                fontSize: "12px",
            }}
            onClick={clickHandler}
        />
    ) : (
        <Button
            variant="contained"
            className={styles.editButton}
            onClick={clickHandler}
        >
            <FontAwesomeIcon icon={faPenToSquare} fontSize={12} />
            <Typography variant="text-size-xSmall-bold">
                <Translate text="Edit" />
            </Typography>
        </Button>
    );
};

export default EditButton;
