@use "../../styles/variables.module";

.cardContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 445px;
    background: white;
    border-radius: 15px;
    box-shadow: 0px 5px 20px rgba(108, 117, 125, 0.2);
    overflow: visible;
    cursor: pointer;
}

.innerCard {
    display: flex;
    flex-direction: column;
    border-radius: 15px 15px 0 0;
}

.cardImageWrapper {
    height: 80px;
    width: 100%;
    overflow: hidden;
    border-radius: 15px 15px 0 0;
    display: inline-block;
    padding: 20px 0px;
    background: #dededf;
    box-sizing: content-box;
}

.cardBody {
    border-radius: 0 0 16px 16px;
    padding: 20px 20px;
}

.cardTitle {
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    display: flex;
    justify-content: space-evenly;
    color: variables.$text-colors-color-text-dark;
    cursor: pointer;
}

.cardList {
    padding: 0;
    margin: 0;
    list-style: none;
}

.cardListItem {
    font-size: 14px;
    line-height: 20px;
    color: variables.$text-colors-color-text-gray;
    margin-top: 10px;
    min-height: 24px;
    cursor: pointer;
}

.cardListItemText {
    font-size: 14px;
    line-height: 20px;
    color: variables.$text-colors-color-text-gray;
    margin-left: 1ch;
    word-break: break-word;
}

.cardListItemText:hover {
    color: variables.$text-colors-color-text-blue;
}

.bulletIcon {
    color: variables.$icon-colors-blue-100;
}

.cardBodyFooter {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0px 20px 15px 20px;
    font-size: 14px;
    cursor: pointer;
}

.cardBodyFooter span {
    border: none;
    color: variables.$text-colors-color-text-blue;
}

.cardBodyFooter svg {
    color: variables.$text-colors-color-text-blue;
}

.pathLabel {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: variables.$text-colors-color-text-light-gray;
    flex: none;
    order: 1;
    flex-grow: 1;
}

[data-card-expanded="true"] .cardImageWrapper {
    display: none;
}

[data-card-expanded="true"] .innerCard {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: calc(100% - 45px);
    overflow: auto;
}

[data-cardbackground] {
    background: #dededf !important;
}

[data-cardbackground*="biology"] {
    background: #d6ead2 !important;
}

[data-cardbackground*="chemistry"] {
    background: #f1d4b2 !important;
}

[data-cardbackground*="physics"] {
    background: #d3edfc !important;
}

[data-cardbackground*="mathematics"] {
    background: #d1d1d1 !important;
}

[data-cardbackground*="english"] {
    background: #b8e3e6 !important;
}

[data-cardbackground*="computing"] {
    background: #ddeeea !important;
}

[data-cardbackground*="science"] {
    background: #f0edf5 !important;
}

[data-cardbackground*="geometry"] {
    background: #38ada9 !important;
}

[data-cardbackground*="geometry"][data-curriculum="CCSS"] {
    background: #dededf !important;
}

[data-cardbackground*="design and technology"] {
    background: #e2e2ef !important;
}

[data-cardbackground*="numbers concept"] {
    background: #dededf !important;
}

[data-cardbackground*="geometry concept"] {
    background: #dededf !important;
}

[data-cardbackground*="algebra"],
[data-cardbackground*="algebra concept"] {
    background: #dededf !important;
}

[data-cardbackground*="measurement concept"] {
    background: #dededf !important;
}

[data-cardbackground*="statistics and probability"],
[data-cardbackground*="statistics concept"] {
    background: #dededf !important;
}

[data-cardbackground*="numeracy and mathematics"],
[data-cardbackground*="numbers and quantities"],
[data-cardbackground*="functions"] {
    background: #d1d1d1 !important;
}

[data-cardbackground*="earth and space science"] {
    background: #c9d1db !important;
}

[data-cardbackground*="earth and space science"] h1 {
    color: white !important;
}

[data-cardbackground*="physical science"] {
    background: #eef9fe !important;
}

[data-cardbackground*="life science"] {
    background: #d6ead2 !important;
}

[data-cardbackground*="computing"],
[data-cardbackground*="network and the internet"],
[data-cardbackground*="networks and the internet"],
[data-cardbackground*="data and analysis"],
[data-cardbackground*="impacts of computing"],
[data-cardbackground*="algorithms and programming"],
[data-cardbackground*="informatics"],
[data-cardbackground*="computer science"] {
    background: #ddeeea !important;
}

[data-cardbackground*=" administration and support"],
[data-cardbackground*="business management"],
[data-cardbackground*="customer service and sales"],
[data-cardbackground*="enterprise and entrepreneurship"],
[data-cardbackground*="event management"],
[data-cardbackground*="finance and accounting"],
[data-cardbackground*="human resources"],
[data-cardbackground*="marketing"],
[data-cardbackground*="project management"] {
    background: #e5e5e5 !important;
}

[data-cardbackground*="anatomy and physiology"],
[data-cardbackground*="coaching"],
[data-cardbackground*="health, wellbeing and sport"],
[data-cardbackground*="nutrition and sport"],
[data-cardbackground*="outdoor and adventurous activities"],
[data-cardbackground*="research methods"],
[data-cardbackground*="sports psychology"],
[data-cardbackground*="working in the sports industry"]
    [data-cardbackground*="health, wellbeing and exercise"],
[data-cardbackground*="fitness testing and training"],
[data-cardbackground*="nutrition for sports and performance"] {
    background: #eff4ff !important;
}

[data-cardbackground*="mens en natuur"] {
    background: #f8f6da !important;
}

[data-cardbackground*="mens en maatschappij"] {
    background: #f0eae8 !important;
}

[data-cardbackground*="burgerschap"] {
    background: #fff4f9 !important;
}

[data-cardbackground*="kunst en cultuur"] {
    background: #eae2e8 !important;
}

[data-cardbackground*="historische tijd - geschiedenis"] {
    background: #ffeaec !important;
}

[data-cardbackground*="wetenschap & techniek - m&n"] {
    background: #eae6f6 !important;
}

[data-cardbackground*="plantyn"],
[data-cardbackground*="environmental sciences"] {
    background: #edebf4 !important;
}

[data-cardbackground*="life and earth sciences"] {
    background: #d6ead2 !important;
}

[data-cardbackground*="social studies"],
[data-cardbackground*="social science"],
[data-cardbackground*="history"] {
    background: #d4cfe3 !important;
}

[data-cardbackground*="geography"] {
    background: #eff2e6 !important;
}

[data-cardbackground*="civics"],
[data-cardbackground*="political science"] {
    background: #e5ece6 !important;
}

[data-cardbackground*="learning skills"] {
    background: #e7fbfa !important;
}

[data-cardbackground*="life skills"] {
    background: #e9f6fa !important;
}

[data-cardbackground*="literacy skills"] {
    background: #dff3f1 !important;
}

[data-cardbackground*="kanjerkrant"] {
    background: #f0edf5 !important;
}

[data-cardbackground*="scoodle"] {
    background: #f0edf5 !important;
}

[data-cardbackground*="aardrijkskunde"] {
    background: #e5ece6 !important;
}

[data-cardbackground*="electro mechanical engineering"] {
    background: #edebf4 !important;
}

[data-cardbackground*="aarde en heelal"] {
    background: #c9d1db !important;
}

[data-cardbackground*="cultuur en godsdienst"] {
    background: #f0eae8 !important;
}

[data-cardbackground*="dieren"] {
    background: #d6ead2 !important;
}

[data-cardbackground*="eten en drinken"] {
    background: #f7edf3 !important;
}

[data-cardbackground*="geschiedenis"] {
    background: #ffeaec !important;
}

[data-cardbackground*="kunst en creatief"] {
    background: #eae2e8 !important;
}

[data-cardbackground*="lichaam en gevoel"] {
    background: #f0eae8 !important;
}

[data-cardbackground*="mens en samenleving"] {
    background: #f5e1ed !important;
}

[data-cardbackground*="natuur"] {
    background: #d6ead2 !important;
}

[data-cardbackground*="school"] {
    background: #d3edfc !important;
}

[data-cardbackground*="sport en vrije tijd"] {
    background: #d3edfc !important;
}

[data-cardbackground*="wetenschap en techniek"] {
    background: #f1d4b2 !important;
}

[data-cardbackground*="wereld"] {
    background: #e5ece6 !important;
}

[data-cardbackground*="general science"] {
    background: #f0edf5 !important;
}

[data-cardbackground*="physical science"] {
    background: #d3edfc !important;
}
