import { badgeIconContainer } from "../PremiumIcon/PremiumIcon.module.scss";
import teacherApprovedSVG from "./teacher_aproved_ribbon.svg";
import { Result } from "types";
import { isTeacherApprovedContent } from "utils/helpers/isTeacherApproved";
import { collectionAccessTypes } from "utils/constants";

interface Props {
    isTeacherApproved: boolean;
    collectionAccessType: Result["collectionAccessType"];
    isCurriculumBasics: boolean;
}

const TeacherApproved = ({
    isTeacherApproved,
    collectionAccessType,
    isCurriculumBasics,
}: Props) => {
    const isBadgeVisible = isTeacherApprovedContent({
        isTeacherApproved,
        collectionAccessType,
        collectionAccessTypes,
        isCurriculumBasics,
    });

    if (!isBadgeVisible) return <></>;

    return (
        <div className={badgeIconContainer}>
            <img src={teacherApprovedSVG} alt="teacher approved badge" />
        </div>
    );
};

export default TeacherApproved;
