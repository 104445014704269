import {
    formFooter,
    submitButton,
    cancelButton,
} from "./FormFooter.module.scss";
import { tracker } from "utils/analytics";

interface Props {
    setIsFormShowing: (arg0: boolean) => void;
    isSubmitButtonEnabled: boolean;
}

export const FormFooter = ({
    setIsFormShowing,
    isSubmitButtonEnabled,
}: Props) => (
    <div className={formFooter}>
        <button
            className={cancelButton}
            onClick={() => {
                tracker("Feedback cancelled");
                setIsFormShowing(false);
            }}
        >
            Cancel
        </button>
        <button
            className={submitButton}
            disabled={!isSubmitButtonEnabled}
            name="submit"
        >
            Submit
        </button>
    </div>
);

export default FormFooter;
