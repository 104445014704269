import { useContext } from "react";
import { Button, Dialog, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

import * as styles from "./MessageModal.module.scss";
import MessagingContext from "contexts/MessagingContext";
import { tracker } from "utils/analytics";

import Translate, { TranslatableText } from "components/Translate/Translate";

export interface MessageModalProps {
    isModalVisible: boolean;
    title?: TranslatableText;
    body?: TranslatableText | JSX.Element;
    type: "Save" | "Delete" | "OK" | "Yes, leave the page";
    primaryActionHandler: () => void;
    secondaryButtonTextOverride?: TranslatableText;
    secondaryActionHandler?: () => void;
}

const MessageModal = ({
    isModalVisible,
    title,
    body,
    type,
    primaryActionHandler,
    secondaryButtonTextOverride,
    secondaryActionHandler,
}: MessageModalProps) => {
    const { resetMessageModal } = useContext(MessagingContext);
    if (!title || !body) return <></>;
    const closeHander = () => {
        tracker("Message modal closed");
        secondaryActionHandler && secondaryActionHandler();
        resetMessageModal();
    };
    return (
        <Dialog
            open={isModalVisible}
            onClose={closeHander}
            classes={{ paper: styles.container }}
        >
            <div className={styles.header}>
                <Typography
                    variant="text-size-Medium"
                    color="$text-colors-color-text-dark.main"
                >
                    <Translate text={title} />
                </Typography>
                <FontAwesomeIcon
                    icon={faXmark}
                    style={{ cursor: "pointer" }}
                    onClick={closeHander}
                />
            </div>
            <div className={styles.body}>
                <Typography
                    variant="text-size-Base"
                    color="$text-colors-color-text-dark.main"
                >
                    {typeof body === "string" ? (
                        <Translate text={body} />
                    ) : (
                        body
                    )}
                </Typography>
            </div>
            <div
                className={styles.footer}
                style={
                    type === "OK"
                        ? { justifyContent: "flex-end" }
                        : { justifyContent: "center" }
                }
            >
                {(type === "Save" ||
                    type === "Delete" ||
                    secondaryActionHandler) && (
                    <Button
                        style={{ height: "100%", padding: "16px" }}
                        onClick={closeHander}
                    >
                        <Translate
                            text={secondaryButtonTextOverride ?? "Cancel"}
                        />
                    </Button>
                )}
                <Button
                    color={
                        type === "Delete"
                            ? "$message-colors-color-message-danger"
                            : "primary"
                    }
                    variant="contained"
                    style={{ height: "100%", padding: "16px 30px" }}
                    onClick={() => {
                        tracker(`Message modal ${type} clicked`);
                        primaryActionHandler();
                        resetMessageModal();
                    }}
                >
                    <Translate text={type} />
                </Button>
            </div>
        </Dialog>
    );
};

export default MessageModal;
