import { useEffect } from "react";
import mixpanel from "mixpanel-browser";
import useHotjar from "react-use-hotjar";

import { isAppMode, isShareMode } from "./newtools";
import { getDeviceID } from "hooks/api/axiosAPI";
import { UserMe } from "types";

// Support webpack/parcel and Vite (Storybook)
const mixpanelKey: string | undefined = process.env.REACT_APP_SDK_MIXPANEL_KEY;

export const trackPageView = (pageName: string) => {
    if (process.env.NODE_ENV !== "production")
        console.info("Viewed Page: " + pageName);
    if (mixpanelKey) {
        mixpanel.track_pageview({ page: pageName });
    }
};

export const tracker = (
    eventName: string,
    eventDetails?: Record<string, unknown>
) => {
    if (process.env.NODE_ENV !== "production") console.info(eventName);
    if (mixpanelKey) {
        mixpanel.track(eventName, eventDetails);
    }
};

export const identifySignedInUser = (user: UserMe["me"]) => {
    if (!user || !user.id) {
        console.error("Tried to identify but no data available");
        return;
    }

    if (!mixpanelKey) {
        console.error("Tried to identify but no API key");
        return;
    }

    const { email, accountId, companyName, region, regionName, roles, id } =
        user;

    mixpanel.identify(id.toString());
    mixpanel.people.set({
        $email: email,
        accountId: accountId,
        schoolName: companyName,
        signupCountryCode: region,
        signupCountryName: regionName,
        roles: roles,
        appMode: "App",
    });

    if (process.env.NODE_ENV !== "production")
        console.info("Idenitfying App User:", { user });
};

const identifyShareUser = () => {
    if (process.env.NODE_ENV !== "production")
        console.info("Identifying Share User");
    const hasBeenIdentified = mixpanel.persistence.props.appMode != undefined;
    if (!hasBeenIdentified) {
        const wizeupDeviceUUID = getDeviceID();
        mixpanel.identify(wizeupDeviceUUID?.toString());
        mixpanel.people.union({
            wizeupDeviceUUID,
            appMode: "Share",
        });
    }
};
const identifyLtiUser = () => {
    if (process.env.NODE_ENV !== "production")
        console.info("Identifying Lti User");
    const hasBeenIdentified = mixpanel.persistence.props.appMode != undefined;
    if (!hasBeenIdentified) {
        const wizeupDeviceUUID = getDeviceID();
        mixpanel.identify(wizeupDeviceUUID?.toString());
        mixpanel.people.union({
            wizeupDeviceUUID,
            appMode: "LTI",
        });
    }
};

export const idenitfyUser = (user?: UserMe) => {
    if (!mixpanelKey) {
        console.warn("No Mixpanel API Key Present");
        return;
    }

    if (isAppMode) {
        if (!user) {
            console.error("Identify Error: APP without USER");
            return;
        }
        identifySignedInUser(user.me);
    } else if (isShareMode) {
        identifyShareUser();
    } else {
        identifyLtiUser();
    }
};

// const myCustomLogger = console.info;
export const HotjarProvider = ({ children }: { children: JSX.Element }) => {
    const { initHotjar } = useHotjar();

    useEffect(() => {
        if (!isShareMode && process.env.REACT_APP_HOTJAR_KEY) {
            console.info("Initializing Hotjar");
            initHotjar(parseInt(process.env.REACT_APP_HOTJAR_KEY), 6); //, false, myCustomLogger);
        }
    }, [initHotjar]);

    return children;
};
