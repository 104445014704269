@use "../../../../../../styles/variables.module";

.title {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    overflow: hidden;
}

@media (max-width: variables.$bp-md) {
    .title {
        text-wrap: nowrap;
    }
}
