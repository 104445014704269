@use "../../../styles/variables.module";

.loginContainer {
    width: 100%;
}

.title {
    margin-bottom: 20px;
    @media (max-width: variables.$bp-lg) {
        margin-bottom: 15px;
    }
}

.formError {
    padding: 16px;
    line-height: 24px;
    color: #2a244b;
    background: #dcdae9 linear-gradient(180deg, #e1dfec, #dcdae9) repeat-x;
    border-radius: 8px;
    border-color: #cecbe0;
}

.formContainer {
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (max-width: variables.$bp-lg) {
        padding: 15px 0px;
    }
}

.formSubmitButton {
    margin-top: 20px;
    padding: 16px 30px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: variables.$bp-lg) {
        margin-top: 15px;
    }
}

.forgotPassword {
    text-align: right;
    text-decoration: underline;
    color: variables.$text-colors-color-text-blue;
}

.createAccountButton {
    padding: 16px 30px;
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}
