import { useContext } from "react";

import { CurriculumContext } from "features/CurriculumNavigator/state/CurriculumContext";
import { Result } from "types";

import ResultCard from "components/ResultCard/ResultCard";

interface Props {
    result: Result;
    results?: Result[];
}

const QueryViewResultCard = ({ result, results }: Props) => {
    const { selectedResultsArray, setSelectedResultsArray } =
        useContext(CurriculumContext);
    const isSelectedResult = selectedResultsArray.includes(result.id);
    const selectResultHandler = (resultId: string) => {
        if (selectedResultsArray.includes(resultId)) {
            setSelectedResultsArray(
                selectedResultsArray.filter((result) => result !== resultId)
            );
        } else {
            setSelectedResultsArray([...selectedResultsArray, resultId]);
        }
    };
    return (
        <ResultCard
            result={result}
            results={results}
            resultIndex={results?.indexOf(result)}
            isSelectedResult={isSelectedResult}
            selectResultHandler={selectResultHandler}
        />
    );
};

export default QueryViewResultCard;
