import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import {
    subjectCard,
    imageClass,
    subjectTitle,
} from "./SubjectCard.module.scss";
import wizenozePlaceholder from "images/wizenoze-placeholder.webp";
import { Grade, Subject } from "types";
import { tracker } from "utils/analytics";
import { setBlankImage } from "utils/helpers";
import { getSubjectClass } from "utils/strings";

const SubjectCard = ({ item }: { item: Subject | Grade }) => {
    const navigate = useNavigate();
    const handleClick = () => {
        tracker("Subject clicked", item);
        navigate(`./${item?.qualifier?.toLowerCase()}/${item.id}`, {
            relative: "path",
        });
    };
    return (
        <div
            className={[getSubjectClass(item.name), subjectCard].join(" ")}
            onClick={handleClick}
        >
            <img
                onError={setBlankImage}
                src={item?.logoUrl || wizenozePlaceholder}
                alt={item?.name}
                className={imageClass}
            />
            <Typography
                variant="text-size-Medium"
                color="$text-colors-color-text-dark.main"
                className={subjectTitle}
            >
                {item?.name}
            </Typography>
        </div>
    );
};

export default SubjectCard;
