@use "../../styles/variables.module";

.headerContainer {
    margin-bottom: 20px;
    display: flex;
    padding: 10px 40px;
    align-items: center;
    background: variables.$subject-colors-color-subject-biology;
}

@media (max-width: variables.$bp-md) {
    .headerContainer {
        padding: 15px 20px;
    }
}
