@use "../../../styles/variables.module";

.imageStyle {
    width: 100%;
    border-radius: 15px;
}

.textContainer {
    padding: 0px 20px;
}
