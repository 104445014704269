@use "../../../../../../../../styles/variables.module";

.container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    background-color: variables.$background-colors-color-background-white;
    border-radius: 15px;
    border: 1px solid variables.$border-colors-color-border-primary;
}

.actionsContainer {
    border-top: 1px solid variables.$border-colors-color-border-primary;
    margin-left: -20;
    margin-right: -20;
    padding-left: 20;
    padding-right: 20;
    padding-top: 10;
    margin-bottom: -10;
    margin-top: 20;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}
