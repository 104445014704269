import { Dialog } from "@mui/material";

import { useFullFolderQuery } from "hooks/api/folders";
import { Folder } from "types";

import Loading from "components/Loading/Loading";
import ShareModal from "components/ShareButton/ShareModal/ShareModal";

const ShareFolderDialog = ({
    folder,
    isVisible,
    setIsVisible,
}: {
    folder: Folder;
    isVisible: boolean;
    setIsVisible: (arg0: boolean) => void;
}) => {
    const { data: fullFolder, status: fullFolderStatus } = useFullFolderQuery(
        folder.id,
        isVisible
    );
    const isLoading =
        fullFolderStatus === "loading" || !fullFolder || !fullFolder.embedLink;

    return (
        <Dialog
            open={isVisible}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
            onClose={() => {
                setIsVisible(false);
            }}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        maxWidth: "100%", // Set your width here
                        margin: 0,
                        borderRadius: "15px",
                    },
                },
            }}
        >
            {isLoading ? (
                <div
                    style={{
                        width: 900,
                        height: 300,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Loading />
                </div>
            ) : (
                <ShareModal
                    embedUrl={fullFolder.embedLink as string}
                    linkUrl={fullFolder.shareLink}
                    closeHandler={() => {
                        setIsVisible(false);
                    }}
                    inCollectionEditor={true}
                />
            )}
        </Dialog>
    );
};

export default ShareFolderDialog;
