import { Button, Typography } from "@mui/material";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Translate from "components/Translate/Translate";

const DeleteButton = ({
    deleteHandler,
    isVisible,
}: {
    deleteHandler: () => void;
    isVisible?: boolean;
}) => {
    if (!isVisible) return null;
    return (
        <Button
            variant="outlined"
            color="$message-colors-color-message-danger"
            onClick={deleteHandler}
            style={{
                padding: "10px 20px",
                width: "100%",
            }}
        >
            <FontAwesomeIcon
                icon={faTrash}
                style={{ marginRight: 10, fontSize: 12 }}
            />
            <Typography variant="button-text-size-xSmall-bold">
                <Translate text="Delete" />
            </Typography>
        </Button>
    );
};
export default DeleteButton;
