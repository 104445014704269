import { CSSProperties } from "react";

import * as styles from "./ResultCardBody.module.scss";
import variables from "styles/_variables.module.scss";
import { Result } from "types";

import ResultCardTitle from "./ResultCardTitle/ResultCardTitle";

const snippetTextStyle: CSSProperties = {
    color: variables["text-colors-color-text-gray"],
    fontSize: "16px",
    lineHeight: "26px",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 5,
    overflow: "hidden",
    padding: 0,
    margin: 0,
};

interface Props {
    results: Result[];
    resultIndex: number;
    resourceType: string;
    stripSummaryText?: boolean;
}

const ResultCardBody = ({
    results,
    resultIndex,
    resourceType,
    stripSummaryText = true,
}: Props) => {
    const result = results[resultIndex];
    if (!result) return <></>;
    return (
        <div
            className={styles.queryCardBodyContent}
            key={result.id + result.fullUrl}
        >
            <ResultCardTitle results={results} resultIndex={resultIndex} />
            {result?.missingQueryTerms?.length > 0 && (
                <small className={styles.missingQueryTerms}>
                    Missing Terms:
                    {result.missingQueryTerms.map((term: string) => (
                        <span key={term}>{term}</span>
                    ))}
                </small>
            )}
            <div
                style={snippetTextStyle}
                dangerouslySetInnerHTML={{
                    __html: stripSummaryText
                        ? result.summaryText.replace(/(<([^>]+)>)/gi, "")
                        : result.summaryText,
                }}
            ></div>
        </div>
    );
};
export default ResultCardBody;
