import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Typography } from "@mui/material";

import * as styles from "../ForgotPassword.module.scss";
import { tracker } from "utils/analytics";

import Translate from "components/Translate/Translate";

const SecondEmailSentMessage = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const contactSupportClickHandler = () => {
        tracker("Support Team Clicked");
        window.location.href = "mailto:support@wizenoze.com";
    };
    return (
        <>
            <Typography variant="text-size-Base">
                <Translate text="The link has been resent to you. If you not find it in your inbox, please check your spam folder or reach out to our" />
                <Typography
                    variant="text-size-Base"
                    color="$text-colors-color-text-blue.main"
                    onClick={contactSupportClickHandler}
                >
                    <Translate text=" support " />
                </Typography>
                <Translate text="for further assistance." />
            </Typography>
            <Button
                variant="outlined"
                className={styles.buttonStyle}
                onClick={() =>
                    navigate(
                        {
                            pathname: "/login",
                            search: searchParams.toString(),
                        },
                        { relative: "path" }
                    )
                }
            >
                <Typography variant="text-size-Base-bold">
                    <Translate text="Back to login" />
                </Typography>
            </Button>
        </>
    );
};

export default SecondEmailSentMessage;
