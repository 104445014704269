@use "../../../styles/variables.module";

.profileContainer {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.mainHeading {
    color: variables.$text-colors-color-text-dark;
    /* Tablet/$text-size-tablet-Medium */
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 150% */
    border-bottom: 1px solid variables.$border-colors-color-border-light;
    text-transform: none;
}

.profileFormGroup {
    max-width: 325px;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.changeButton {
    color: variables.$text-colors-color-text-blue;
    /* $text-size-xSmall */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
    display: block;
    margin-top: -10px;
    margin-bottom: 20px;
}

.formContainer {
    max-width: 500px;
    margin-top: 0.625rem;
}

.supportText {
    color: variables.$text-colors-color-text-dark;
    /* $text-size-xSmall */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 2.5rem !important;
}

.errorAlert {
    color: variables.$message-colors-color-message-danger;
    /* $text-size-xSmall */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: -10px;
    margin-bottom: 20px;
}

.successAlert {
    color: variables.$message-colors-color-message-success;
    /* $text-size-xSmall */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: -10px;
    margin-bottom: 20px;
}

.supportText span {
    color: variables.$message-colors-color-message-danger;
    font-weight: 700;
}

.supportText a {
    color: variables.$text-colors-color-text-blue;
    text-decoration: none;
    font-weight: 700;
}
