import { useOrganicAutocompleteQuery } from "hooks/api/useSearchQuery";
import { useUserCurrentLayerQuery } from "hooks/api/useUserSettingsQuery";
import { tracker } from "utils/analytics";

import AutoCompleteList from "./AutoCompleteList";

const Autocomplete = ({
    searchTerm,
    updateSearchTerm,
    resetAutoCompleteDisplay,
}: {
    searchTerm: string;
    updateSearchTerm: (arg0: string) => void;
    resetAutoCompleteDisplay: () => void;
}) => {
    const { data: userCurrentLayer } = useUserCurrentLayerQuery();
    const curriculumId = userCurrentLayer?.curriculumId;
    const { data: autocompleteData } = useOrganicAutocompleteQuery(
        curriculumId?.toString(),
        searchTerm,
        true,
        { q: searchTerm }
    );

    const setTerm = (index: number) => {
        const completion = autocompleteData?.[index];
        if (!completion) return;
        tracker("Collection Autocomplete Completion Clicked");
        updateSearchTerm(completion.label);
        resetAutoCompleteDisplay();
    };

    if (
        !autocompleteData ||
        (autocompleteData?.length ? "" : Boolean(searchTerm))
    )
        return <></>;
    return (
        <AutoCompleteList
            autocompleteList={autocompleteData}
            completionClickHandler={setTerm}
        />
    );
};

export default Autocomplete;
