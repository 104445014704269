import { ReactNode } from "react";

import { useResultModalContext } from "contexts/ResultModalContext";
import { Result } from "types";
import { tracker } from "utils/analytics";

const ResultLink = ({
    children,
    results,
    initialIndex,
    styles,
}: {
    children: ReactNode;
    results: Result[];
    initialIndex: number;
    styles: string;
}) => {
    const { openResultModal, isResultModalEnabled } = useResultModalContext();
    const localResult = results[initialIndex];
    const { sourceUrl, clickUrl } = localResult;

    if (isResultModalEnabled) {
        const openIframe = () => {
            openResultModal({ results, initialIndex });
            tracker("Link feature clicked", {
                fullUrl: localResult.clickUrl,
                inIFrame: true,
            });
        };

        return (
            <a
                onClick={openIframe}
                className={styles}
                title={sourceUrl}
                data-testid={"openIframeLink"}
            >
                {children}
            </a>
        );
    }

    const handleExternalLinkClick = (_e: React.MouseEvent) => {
        tracker("Link feature clicked", {
            fullUrl: localResult.clickUrl, // fullurl needs keeping because of legacy mixpanel data
            inIFrame: false,
        });
    };

    return (
        <a
            href={clickUrl}
            onClick={handleExternalLinkClick}
            className={styles}
            title={sourceUrl}
            target="_blank"
            rel="noreferrer"
            data-testid={"openInModalLink"}
        >
            {children}
        </a>
    );
};

export default ResultLink;
