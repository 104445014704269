import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronDown,
    faChevronUp,
    faCheck,
} from "@fortawesome/free-solid-svg-icons";

import {
    button,
    container,
    list,
    title,
    item,
} from "./SelectableDropdown.module.scss";
import { capitaliseFirstLetter } from "utils/helpers";

import Translate, { TranslatableText } from "components/Translate/Translate";

interface Props {
    name: TranslatableText;
    items: Array<{ id: number; name: string }>;
    selectedId: number;
    trackerCall: (showDropdown: boolean) => void;
    handleChange: (id: number) => void;
}

const SelectableDropdown = ({
    name,
    items,
    selectedId,
    trackerCall,
    handleChange,
}: Props) => {
    const height = window.innerHeight;
    const itemsRef = useRef(document.createElement("div"));
    const [distanceTop, setDistanceTop] = useState(0);
    const paddingBot = 10;
    const maxHeight = height - distanceTop - paddingBot;
    const [isOpen, setIsOpen] = useState(false);
    const handleClick = () => {
        trackerCall(!isOpen);
        setIsOpen(!isOpen);
    };
    useEffect(() => {
        if (isOpen) {
            const y = itemsRef.current.getBoundingClientRect().y;
            setDistanceTop(y);
        }
    }, [isOpen, itemsRef]);
    return (
        <>
            <div
                className={button}
                onClick={handleClick}
                style={{ display: isOpen ? "none" : "flex" }}
            >
                <Translate text={name} />
                <FontAwesomeIcon icon={faChevronDown} />
            </div>
            {isOpen && (
                <>
                    <div className={container} onClick={handleClick}></div>
                    <div className={list}>
                        <div className={title} onClick={handleClick}>
                            <Translate text={name} />
                            <FontAwesomeIcon icon={faChevronUp} />
                        </div>
                        <div
                            ref={itemsRef}
                            style={{
                                maxHeight: maxHeight,
                                overflowY: "auto",
                                overscrollBehavior: "contain",
                            }}
                        >
                            {items.map(({ id, name }) => (
                                <div
                                    key={id}
                                    className={item}
                                    onClick={() => {
                                        handleChange(id);
                                        setIsOpen(false);
                                    }}
                                >
                                    {capitaliseFirstLetter(name)}
                                    {id == selectedId && (
                                        <FontAwesomeIcon icon={faCheck} />
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default SelectableDropdown;
