import { loadingContainer, spinner, dot1, dot2 } from "./Loading.module.scss";

interface Props {
    isCentered?: boolean;
}

const Loading = ({ isCentered }: Props) => (
    <div className={isCentered ? loadingContainer : undefined} title="Loading">
        <div className={spinner}>
            <div className={dot1} />
            <div className={dot2} />
        </div>
    </div>
);

export default Loading;
