import {
    DraggableAttributes,
    DraggableSyntheticListeners,
} from "@dnd-kit/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";

import {
    card,
    container,
    visualContainer,
    imageContainer,
    imageCrop,
    img,
    videoIconContainer,
    videoIcon,
    textContainer,
} from "./CollectionGridResultCard.module.scss";
import { useResultModalContext } from "contexts/ResultModalContext";
import wizenozePlaceholder from "images/wizenoze-placeholder.webp";
import variables from "styles/_variables.module.scss";
import { Result } from "types";
import { tracker } from "utils/analytics";
import { setBlankImage } from "utils/helpers";
import { isAppMode } from "utils/newtools";

import DragHandle from "components/ResultCard/DragHandle";
import Header from "components/ResultCard/Header";
import MetaDataFooter from "components/ResultCard/MetaDataFooter";
import ResultLink from "features/ResultLink/ResultLink";
import ResultCardBody from "features/QueryResults/ResultsGrid/ResultCard/ResultCardBody/ResultCardBody";

interface Props {
    results: Result[];
    resultIndex: number;
    deleteItemHandler?: () => void;
    stripSummaryText?: boolean;
    isHighlighted?: boolean;
    draggable?: {
        attributes: DraggableAttributes;
        listeners: DraggableSyntheticListeners;
    };
}

const CollectionGridResultCard = ({
    results,
    resultIndex,
    deleteItemHandler,
    stripSummaryText,
    isHighlighted,
    draggable,
}: Props) => {
    const { openResultModal, isResultModalEnabled } = useResultModalContext();
    const result = results[resultIndex];
    const [page] = result.audiences;
    const readingLevel = page?.level;
    const isResultGone = result.isUserProvided && result.pageState === "GONE";
    const showMetadataFooter =
        result.collectionAccessType === "paidCollection" ||
        result.isUserProvided ||
        result.teacherApproved ||
        readingLevel ||
        (isAppMode && result.options?.embeddable) ||
        (isAppMode && result.videoMetadata?.caption) ||
        (isAppMode && result.videoMetadata?.duration);
    const clickHandler = () => {
        if (isResultModalEnabled) {
            openResultModal({
                results: results,
                initialIndex: resultIndex,
            });
        } else {
            window.open(result.fullUrl);
        }
        tracker("Link feature clicked", {
            fullUrl: result.fullUrl,
            inIFrame: isResultModalEnabled,
        });
    };
    return (
        <div
            className={card}
            style={{
                borderColor: isHighlighted
                    ? variables["border-colors-color-border-blue"]
                    : variables["border-colors-color-border-light"],
            }}
        >
            {draggable && <DragHandle draggable={draggable} />}
            <div className={container}>
                <Header
                    clickHandler={clickHandler}
                    result={result}
                    deleteItemHandler={deleteItemHandler}
                    isResultGone={isResultGone}
                />
                {result?.thumbnail && (
                    <div className={visualContainer}>
                        <ResultLink
                            results={results}
                            initialIndex={resultIndex}
                            styles={imageContainer}
                        >
                            <div className={imageCrop}>
                                <img
                                    onError={setBlankImage}
                                    src={
                                        result.thumbnail || wizenozePlaceholder
                                    }
                                    alt={result?.title + " thumbnail"}
                                    className={img}
                                />
                            </div>
                            {result?.documentTypes?.includes("video") && (
                                <div className={videoIconContainer}>
                                    <FontAwesomeIcon
                                        icon={faPlayCircle}
                                        className={videoIcon}
                                        title="video-icon"
                                    />
                                </div>
                            )}
                        </ResultLink>
                    </div>
                )}
                <div className={textContainer}>
                    <ResultCardBody
                        results={results}
                        resultIndex={resultIndex}
                        resourceType={result.documentTypes[0]}
                        stripSummaryText={stripSummaryText}
                    />
                </div>
                {showMetadataFooter && (
                    <MetaDataFooter
                        readingLevel={readingLevel}
                        result={result}
                    />
                )}
            </div>
        </div>
    );
};

export default CollectionGridResultCard;
