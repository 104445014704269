import { Typography } from "@mui/material";

import * as styles from "./LanguageControl.module.scss";
import { useAppContext } from "contexts/AppContext";

const LanguageControl = () => {
    const { config, updateConfig } = useAppContext();
    const language = config.language;
    const clickHandler = () => {
        updateConfig("language", language === "en" ? "nl" : "en");
    };
    return (
        <div className={styles.languageContainer} onClick={clickHandler}>
            <div
                data-selected={language === "en"}
                className={styles.languageButton}
            >
                <Typography variant="text-size-xxSmall-regular">EN</Typography>
            </div>
            <div
                data-selected={language === "nl"}
                className={styles.languageButton}
            >
                <Typography variant="text-size-xxSmall-regular">NL</Typography>
            </div>
        </div>
    );
};

export default LanguageControl;
