@use "../../styles/variables.module";

.inputLabel {
    color: variables.$text-colors-color-text-gray;
    /* $text-size-xxSmall-regular */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
