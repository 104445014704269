@use "../../styles/variables.module";

.container {
    height: 100%;
    background-color: white;
    padding: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image {
    width: 270px;
}

.text {
    margin-top: 20px;
    display: flex;
    align-items: center;
    text-align: center;
}

.smallText {
    display: flex;
    align-items: center;
    text-align: center;
}
.headerContainer {
    display: flex;
    padding: 10px 0px 10px 40px;
    align-items: center;
    background: variables.$subject-colors-color-subject-biology;
}

@media (min-width: variables.$bp-md) {
    .smallText {
        font-size: 20px;
    }
}
