@use "../../../../styles/variables.module";

.item {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    background: variables.$background-colors-color-background-white;
    box-shadow: 0px -1px 0px 0px variables.$border-colors-color-border-primary inset;
    cursor: pointer;
}

.icon {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.iconColorBlue {
    color: variables.$icon-colors-blue-100;
    background: variables.$icon-colors-blue-40;
    border: 1px solid variables.$icon-colors-blue-60;
}

.iconColorGreen {
    color: variables.$icon-colors-green-100;
    background: variables.$icon-colors-green-40;
    border: 1px solid variables.$icon-colors-green-60;
}
