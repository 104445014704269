import { useContext } from "react";
import { Alert, Snackbar, Typography } from "@mui/material";

import {
    faCheckCircle,
    faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import MessagingContext from "contexts/MessagingContext";

import Translate, { TranslatableText } from "components/Translate/Translate";

export interface AlertMessageProps {
    message?: TranslatableText;
    type: React.ComponentProps<typeof Alert>["severity"];
    open: boolean;
}

const AlertMessage = ({ message, type, open }: AlertMessageProps) => {
    const { resetAlertMessage } = useContext(MessagingContext);
    if (!message) return <></>;
    return (
        <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={resetAlertMessage}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
            <Alert
                severity={type}
                iconMapping={{
                    success: <FontAwesomeIcon icon={faCheckCircle} />,
                    error: <FontAwesomeIcon icon={faTimesCircle} />,
                }}
                onClose={resetAlertMessage}
            >
                <Typography
                    variant="alert-text-size-xSmall"
                    color="$text-colors-color-text-white.main"
                >
                    <Translate text={message} />
                </Typography>
            </Alert>
        </Snackbar>
    );
};

export default AlertMessage;
