import { Button, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

import * as styles from "./Subheader.module.scss";
import useScreenSizes from "hooks/useScreenSizes";
import { tracker } from "utils/analytics";

import ContextMenu from "components/ContextMenu/ContextMenu";
import Translate from "components/Translate/Translate";
import { Folder } from "types";

const ViewAsContextMenu = ({
    viewAs,
    updateViewAs,
    isVisible,
    setIsVisible,
    isDisabled,
}: {
    viewAs: Folder["view"];
    updateViewAs: (viewAs: Folder["view"]) => void;
    isVisible: boolean;
    setIsVisible: (open: boolean) => void;
    isDisabled?: boolean;
}) => {
    const { isMobileScreen } = useScreenSizes();
    const viewAsLabel = viewAs.toLowerCase();
    return (
        <ContextMenu
            options={[
                {
                    label: "Grid",
                    clickHandler: () => {
                        tracker("View as grid clicked");
                        updateViewAs("GRID");
                    },
                    disabled: isDisabled,
                },
                {
                    label: "List",
                    clickHandler: () => {
                        tracker("View as list clicked");
                        updateViewAs("LIST");
                    },
                    disabled: isDisabled,
                },
            ]}
            onClose={() => setIsVisible(false)}
            styleOverrides={{ width: isMobileScreen ? "100%" : undefined }}
        >
            <Button
                variant={isMobileScreen ? "outlined" : "text"}
                className={styles.mobileButton}
                onClick={() => setIsVisible(true)}
            >
                <Typography variant="text-size-xSmall-bold">
                    <Translate text={`View as ${viewAsLabel}`} />
                </Typography>
                <FontAwesomeIcon
                    icon={isVisible ? faChevronUp : faChevronDown}
                    fontSize={12}
                />
            </Button>
        </ContextMenu>
    );
};

export default ViewAsContextMenu;
