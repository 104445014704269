import { CSSProperties } from "react";
import { Theme, Typography, useMediaQuery } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";

import * as styles from "./TabTitle.module.scss";
import variables from "styles/_variables.module.scss";

import Tooltip from "components/Tooltip/Tooltip";
import Translate, { TranslatableText } from "components/Translate/Translate";

interface Props {
    label: TranslatableText;
    icon: IconDefinition;
    isTabSelected: boolean;
    styleOverrides?: CSSProperties;
    iconStyleOverrides?: CSSProperties;
}

const TabTitle = ({
    label,
    icon,
    isTabSelected,
    styleOverrides,
    iconStyleOverrides,
}: Props) => {
    const isMobileScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("md")
    );
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
            }}
        >
            <Tooltip text={label} disabled={!isMobileScreen}>
                <FontAwesomeIcon
                    icon={icon}
                    color={
                        isTabSelected
                            ? variables["text-colors-color-text-blue"]
                            : variables["text-colors-color-text-light-gray"]
                    }
                    style={iconStyleOverrides}
                />
            </Tooltip>
            <Typography
                variant="text-size-Base-bold"
                color={
                    isTabSelected
                        ? "$text-colors-color-text-dark.main"
                        : "$text-colors-color-text-gray.main"
                }
                className={styles.label}
                style={styleOverrides}
            >
                <Translate text={label} />
            </Typography>
        </div>
    );
};

export default TabTitle;
