import { useState } from "react";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";

import * as styles from "./QuestionCard.module.scss";
import variables from "styles/_variables.module.scss";
import { Question } from "types";
import { tracker } from "utils/analytics";

import Answer from "./Answer";
import Translate from "components/Translate/Translate";
import EditableTitle from "features/CollectionEditor/EditableTitle/EditableTitle";

interface Props {
    question: Question;
    setNewQuestion: (question: Question) => void;
    type: "multipleChoice" | "trueOrFalse" | "fillInTheBlank" | undefined;
}

const CreateQuestionCard = ({ question, setNewQuestion, type }: Props) => {
    const [initialiseTitleSelected, setInitialiseTitleSelected] =
        useState(false);
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );
    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            const answersIds = question.answers.map((answer) => answer.id);
            const localAnswers = arrayMove(
                question.answers,
                answersIds.indexOf(active.id),
                answersIds.indexOf(over.id),
            );
            setNewQuestion({
                ...question,
                answers: localAnswers,
            });
            tracker("Options Reordered");
        }
    };
    const addOptionHandler = () => {
        setInitialiseTitleSelected(true);
        setNewQuestion({
            ...question,
            answers: [
                ...question.answers,
                { id: Date.now().toString(), text: "" },
            ],
        });
        tracker("Add option button clicked");
    };
    return (
        <>
            <div className={styles.header}>
                <div className={styles.headerDragAndQuestion}>
                    <EditableTitle
                        title={question.question}
                        titleChangeHandler={(title: string) =>
                            setNewQuestion({
                                ...question,
                                question: title,
                            })
                        }
                        typographyVariant="text-size-Base-bold"
                        initialiseTitleSelected
                        styleOverrides={{
                            margin: "unset",
                            display: "flex",
                            alignItems: "center",
                            borderBottom: "unset",
                        }}
                    />
                </div>
            </div>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
            >
                <div className={styles.answers}>
                    <SortableContext
                        items={question.answers}
                        strategy={verticalListSortingStrategy}
                    >
                        {question.answers.map((answer) => (
                            <Answer
                                key={answer.id}
                                answer={answer}
                                localQuestion={question}
                                setLocalQuestion={setNewQuestion}
                                inEditMode={true}
                                initialiseTitleSelected={
                                    initialiseTitleSelected
                                }
                                hideDelete={type === "trueOrFalse"}
                            />
                        ))}
                    </SortableContext>
                    {type !== "trueOrFalse" && question.answers.length < 4 && (
                        <div
                            className={styles.answer}
                            style={{ cursor: "pointer" }}
                            onClick={addOptionHandler}
                        >
                            <FontAwesomeIcon
                                icon={faCirclePlus}
                                color={variables["icon-colors-blue-100"]}
                                fontSize={17}
                            />
                            <Typography
                                variant="text-size-Small-medium"
                                color="$text-colors-color-text-blue.main"
                            >
                                <Translate text="Add option" />
                            </Typography>
                        </div>
                    )}
                </div>
            </DndContext>
        </>
    );
};

export default CreateQuestionCard;
