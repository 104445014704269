import { useSearchParams } from "react-router-dom";
import _ from "lodash";

import { filterContainer } from "./Filter.module.scss";
import { getValue } from "./Filters.helpers";
import { useAppContext } from "contexts/AppContext";
import useCurriculumQuery from "hooks/api/useCurriculumQuery";
import useCurriculumSearchQuery from "hooks/api/useSearchQuery";
import { tracker } from "utils/analytics";

import FilterInput from "./FilterInput";
import { translate } from "components/Translate/Translate";

const Filters = () => {
    const { data: searchResponse, status: searchResponseStatus } =
        useCurriculumSearchQuery();

    const [searchParams, setSearchParams] = useSearchParams();

    const { config } = useAppContext();
    const { data: curriculumData } = useCurriculumQuery();
    if (searchResponseStatus !== "success") return null;
    const structure = curriculumData?.structure;
    const { facets, totalResults, appliedFilters } = searchResponse;

    const clearSubjectFilter = () => {
        tracker("Subject filters cleared");
        applySubjectFilter([]);
    };

    const applySubjectFilter = (subjectFilters: Array<string>) => {
        const allParams = Object.fromEntries([...searchParams]);
        const queryParams = searchParams.get("queryParams");
        const newQueryParams = {
            ...(queryParams
                ? { ...JSON.parse(queryParams), startPage: 1 }
                : { startPage: 1 }),
        };

        const updatedParams = {
            ...allParams,
            queryParams: JSON.stringify(newQueryParams),
            subjectFilters: JSON.stringify(subjectFilters),
        };
        _.isEmpty(subjectFilters);

        setSearchParams(updatedParams);
    };

    const appliedSubjectFilter = appliedFilters?.subjectFilters || [];
    return (
        <div
            className={filterContainer}
            // data-container-width={dataContainerWidth}
        >
            <FilterInput
                label={"Subject"}
                value={
                    _.isEmpty(appliedSubjectFilter)
                        ? [
                              {
                                  key: translate(
                                      config.language,
                                      "Select Subjects",
                                  ),
                              },
                          ]
                        : getValue(facets, "subjects")
                }
                options={getValue(facets, "subjects")}
                isVisible={structure?.includes("SUBJECT")}
                clearFilter={clearSubjectFilter}
                appliedFilters={appliedSubjectFilter}
                applySubjectFilter={applySubjectFilter}
                totalResults={totalResults}
            />
        </div>
    );
};

export default Filters;
