import {
    reasonList,
    mandatory,
    listItemCheckbox,
    labelCheckbox,
    checkbox,
} from "./ReasonList.module.scss";

interface ReasonCheckboxProps {
    reason: Reason;
    isSelected: boolean;
    changeHandler: (arg0: Reason) => void;
}

const ReasonCheckbox = ({
    reason,
    isSelected,
    changeHandler,
}: ReasonCheckboxProps) => (
    <li className={listItemCheckbox}>
        <label className={labelCheckbox}>
            <input
                type="checkbox"
                value={reason.id}
                name="reasons[]"
                onChange={() => changeHandler(reason)}
                id={reason.id.toString()}
                checked={isSelected}
                className={checkbox}
            />
            <span>{reason.name}</span>
        </label>
    </li>
);

type Reason = { id: number; name: string };
interface Props {
    value: Array<Reason>;
    optionsValues: Array<Reason>;
    changeHandler: (arg0: Reason) => void;
    isRequired: boolean | undefined;
}

const ReasonList = ({
    value,
    optionsValues,
    changeHandler,
    isRequired,
}: Props) => {
    if (!optionsValues || !optionsValues.length) return <></>;
    const orderOptionsValues = optionsValues.sort((a, b) =>
        a.id > b.id ? 1 : -1
    );
    return (
        <div>
            <label>
                Provide a Reason
                {isRequired && <span className={mandatory}>*</span>}
            </label>
            <ul className={reasonList}>
                {orderOptionsValues.map((reason) => (
                    <ReasonCheckbox
                        key={reason.id}
                        reason={reason}
                        isSelected={value.some((el) => el.id === reason.id)}
                        changeHandler={changeHandler}
                    />
                ))}
            </ul>
        </div>
    );
};

export default ReasonList;
