import { ClickAwayListener, Tooltip, Typography } from "@mui/material";

import { tracker } from "utils/analytics";

import InputBase from "../InputBase/InputBase";
import Switch from "components/Switch/Switch";
import Translate, { TranslatableText } from "components/Translate/Translate";

type Props = Omit<React.ComponentProps<typeof InputBase>, "children"> & {
    value: boolean;
    updateHandler: (key: string, value: boolean) => void;
    switchTooltip?: TranslatableText;
    showSwitchTooltip?: boolean;
    hideSwitchTooltipHandler?: () => void;
};

const LabeledSwitch = ({
    label,
    keyname,
    value,
    updateHandler,
    disabled,
    isHidden,
    tooltip,
    switchTooltip,
    showSwitchTooltip,
    hideSwitchTooltipHandler,
}: Props) => {
    if (isHidden) return null;
    return (
        <InputBase
            label={label}
            keyname={keyname}
            disabled={disabled}
            isHidden={isHidden}
            tooltip={tooltip}
        >
            <Tooltip
                title={
                    switchTooltip && (
                        <ClickAwayListener
                            onClickAway={hideSwitchTooltipHandler}
                        >
                            <Typography variant="text-size-xxSmall-regular">
                                <Translate text={switchTooltip} />
                            </Typography>
                        </ClickAwayListener>
                    )
                }
                placement="bottom"
                arrow
                open={showSwitchTooltip}
            >
                <div>
                    <Switch
                        checked={value}
                        onChange={() => {
                            tracker("Changed " + keyname, {
                                [keyname]: !value,
                            });
                            updateHandler(keyname, !value);
                        }}
                        disabled={disabled}
                    />
                </div>
            </Tooltip>
        </InputBase>
    );
};

export default LabeledSwitch;
