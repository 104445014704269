@use "../../styles/variables.module";

.title {
    padding: 10px 20px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    word-break: break-word;
    border-bottom: 1px solid variables.$border-colors-color-border-primary;
    background: variables.$background-colors-color-background-light-off;
    @media (max-width: variables.$bp-md) {
        padding: 10px;
    }
}

.closeButton {
    color: variables.$text-colors-color-text-gray;
    font-size: 16px;
    font-style: normal;
    cursor: pointer;
}
