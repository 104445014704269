@use "../../styles/variables.module";

.container {
    position: relative;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 15px;
    border: 1px solid variables.$border-colors-color-border-light;
    background: linear-gradient(180deg, #fff 45.77%, #f3f3f6 89.77%);
    box-shadow: 0px 5px 20px 0px rgba(108, 117, 125, 0.2);
}

.image {
    height: 32px;
}

.title {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    overflow: hidden;
    cursor: pointer;
    text-align: center;
}
