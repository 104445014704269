import { useState } from "react";

import { numberOfResultsStyle } from "./LabeledNumber.module.scss";
import { tracker } from "utils/analytics";

import InputBase from "../InputBase/InputBase";

type Props = Omit<React.ComponentProps<typeof InputBase>, "children"> & {
    value: number;
    minValue: number;
    maxValue: number;
    updateHandler: (key: string, value: number) => void;
};

const LabeledNumber = ({
    label,
    keyname,
    value,
    minValue,
    maxValue,
    updateHandler,
    disabled,
    isHidden,
    tooltip,
}: Props) => {
    const [currentValue, setCurrentValue] = useState(value);
    if (isHidden) return null;
    return (
        <InputBase
            label={label}
            keyname={keyname}
            disabled={disabled}
            isHidden={isHidden}
            tooltip={tooltip}
        >
            <input
                className={numberOfResultsStyle}
                type="number"
                onChange={(e) => {
                    const number = Math.max(
                        minValue,
                        Math.min(maxValue, parseFloat(e.target.value))
                    );
                    if (isNaN(number)) {
                        setCurrentValue(number);
                    } else {
                        setCurrentValue(number);
                        tracker("Changed " + keyname, {
                            [keyname]: number,
                        });
                        updateHandler(keyname, number);
                    }
                }}
                onBlur={() => {
                    setCurrentValue(value);
                }}
                min={minValue}
                max={maxValue}
                value={disabled ? NaN : currentValue}
                disabled={disabled}
            />
        </InputBase>
    );
};

export default LabeledNumber;
