import { Outlet } from "react-router-dom";

import { isShareMode } from "utils/newtools";

import AppLayout from "components/AppLayout/AppLayout";
import Onboarder from "features/Onboarder/Onboarder";

const App = () => {
    return (
        <AppLayout>
            <Outlet />
            {!isShareMode ? <Onboarder /> : <></>}
        </AppLayout>
    );
};

export default App;
