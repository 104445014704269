import { AxiosError } from "axios";
import { QueryClient, useQuery, useQueryClient } from "@tanstack/react-query";

import { axiosAPI } from "./axiosAPI";
import { useAppContext } from "contexts/AppContext";
import { Query, Topic, Unit } from "types";
import { isShareMode } from "utils/newtools";

const calculateNextTopic = (
    topics: Array<Topic>,
    index: number,
    topicIndex: number,
    units: Array<Unit>,
) => {
    if (topics[index + 1]) {
        return {
            node: topics[index + 1],
            isLast: false,
        };
    } else if (units && units[topicIndex + 1]) {
        return {
            node: units[topicIndex + 1]?.children[0],
            isLast: Boolean(units[topicIndex + 1]?.children.length === 1),
        };
    } else {
        return {
            node: {},
            isLast: true,
        };
    }
};

const updateTheNextTopic = (
    queryClient: QueryClient,
    showDrafts: boolean,
    topics: Array<Topic>,
    units: Array<Unit>,
    topicIndex: number,
) => {
    topics.map((topic, index) => {
        const data = calculateNextTopic(topics, index, topicIndex, units);
        queryClient.setQueryData(["topicnext", topic?.id, showDrafts], data);
        queryClient.setQueryData(
            ["node", data?.node?.id, "QUERY", showDrafts],
            data?.node,
        );
    });
};

export const useNodeLayersQuery = (
    nodeId: string | null | undefined,
    layersString: string | undefined,
) => {
    const { config } = useAppContext();
    const { showDrafts } = config;
    const queryClient = useQueryClient();
    return useQuery<Topic | Unit | Query, AxiosError>(
        ["node", nodeId, layersString, showDrafts],
        async () => {
            const { data } = await axiosAPI
                .getInstance()
                .get(`/curriculum/node/${nodeId}/layers/${layersString}`, {
                    params: {
                        allPublicationStatus: showDrafts,
                    },
                });
            if (data?.childrenQualifier === "UNIT") {
                data.children.map((item: Unit, index: number) => {
                    updateTheNextTopic(
                        queryClient,
                        showDrafts,
                        item?.children,
                        data?.children,
                        index,
                    );
                });
            }
            return data;
        },
        { enabled: !!nodeId && !!layersString, staleTime: 1000 * 60 * 60 * 12 },
    );
};

const useNodeNextQuery = <T>(nodeId: string | number | null | undefined) => {
    const { config } = useAppContext();
    const params = new URLSearchParams(document.location.search);
    const showDrafts =
        isShareMode && typeof params.get("showDrafts") === "string"
            ? params.get("showDrafts")
            : config["showDrafts"];
    return useQuery<T, AxiosError>(
        ["nodenext", nodeId, showDrafts],
        async () => {
            const { data } = await axiosAPI
                .getInstance()
                .get(`/curriculum/node/${nodeId}/layers/NEXT`, {
                    params: {
                        allPublicationStatus: showDrafts,
                    },
                });
            return data;
        },
        { enabled: !!nodeId },
    );
};

export default useNodeNextQuery;
