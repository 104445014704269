import { CSSProperties, useLayoutEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Typography, TypographyOwnProps, useTheme } from "@mui/material";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as styles from "./EditableTitle.module.scss";

interface Props {
    title: string;
    titleChangeHandler: (arg0: string) => void;
    typographyVariant?: TypographyOwnProps["variant"];
    editDisabled?: boolean;
    initialiseTitleSelected?: boolean;
    styleOverrides?: CSSProperties;
}

const EditableTitle = ({
    title,
    titleChangeHandler,
    typographyVariant,
    editDisabled,
    initialiseTitleSelected,
    styleOverrides,
}: Props) => {
    const theme = useTheme();
    const { collectionId } = useParams();
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [localTitle, setLocalTitle] = useState(title);
    const [inputWidth, setInputWidth] = useState("auto");
    const inputRef = useRef<HTMLInputElement>(null);
    const iconRef = useRef<SVGSVGElement>(null);
    const spanRef = useRef<HTMLSpanElement>(null);
    const [initTitleSelected, setInitTitleSelected] = useState(
        !collectionId || initialiseTitleSelected,
    );

    useLayoutEffect(() => {
        if (inputRef.current) {
            if (initTitleSelected) {
                inputRef.current?.focus();
                inputRef.current?.select();
                setIsEditingTitle(true);
                setInitTitleSelected(false);
            }
            if (spanRef.current) {
                spanRef.current.textContent = localTitle;
                setInputWidth(
                    `${
                        spanRef.current.offsetWidth +
                        1 +
                        (iconRef.current?.clientWidth ?? 0)
                    }px`,
                );
                if (isEditingTitle) {
                    inputRef.current?.focus();
                }
            }
        }
    }, [initTitleSelected, isEditingTitle, localTitle]);

    const switchToEditMode = () => {
        if (!editDisabled) {
            setIsEditingTitle(true);
            inputRef.current?.select();
        }
    };

    const finishEditing = () => {
        if (localTitle !== title) {
            titleChangeHandler(localTitle);
        } else {
            setLocalTitle(title);
        }
        setIsEditingTitle(false);
    };

    return (
        <div className={styles.container} style={styleOverrides}>
            <Typography
                variant={typographyVariant ?? "text-size-Medium"}
                onClick={switchToEditMode}
                style={
                    isEditingTitle
                        ? {
                              display: "none",
                          }
                        : {
                              marginRight: "18.25px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                          }
                }
            >
                {title}
            </Typography>
            <input
                className={styles.titleInputField}
                value={localTitle}
                onChange={(e) => setLocalTitle(e.target.value)}
                onBlur={(e) => {
                    if (isEditingTitle) finishEditing();
                }}
                onKeyDown={(e) => e.key === "Enter" && finishEditing()}
                ref={inputRef}
                style={{
                    width: inputWidth,
                    ...(!isEditingTitle ? { display: "none" } : undefined),
                    ...(typographyVariant
                        ? theme.typography[typographyVariant]
                        : theme.typography["text-size-Medium"]),
                    fontFamily: "inherit",
                }}
            />
            <span
                ref={spanRef}
                className={styles.titleInputField}
                style={{
                    position: "absolute",
                    top: "-9999px",
                    left: "-9999px",
                    visibility: "hidden",
                    ...(typographyVariant
                        ? theme.typography[typographyVariant]
                        : theme.typography["text-size-Medium"]),
                    fontFamily: "inherit",
                }}
            />
            <FontAwesomeIcon
                ref={iconRef}
                icon={faPenToSquare}
                className={styles.editIcon}
                onClick={switchToEditMode}
            />
        </div>
    );
};

export default EditableTitle;
