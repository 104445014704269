import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Typography } from "@mui/material";

import * as styles from "./VerifyEmail.module.scss";
import emailConfirmation from "./assets/img-email-confirmation.webp";
import emailConfirmationFailed from "./assets/img-email-confirmation-failed.webp";
import emailConfirmationSuccess from "./assets/img-email-confirmation-succes.webp";
import { useVerifyEmailMutation } from "hooks/api/authentication";
import { identifySignedInUser, tracker } from "utils/analytics";

import ConfirmOrCompleteMessage from "./components/ConfirmOrCompleteMessage";
import NotVerifiedMessage from "./components/NotVerifiedMessage";
import SendConfirmationAgainForm from "./components/SendConfirmationAgainForm";
import PublicPageContainer from "../components/PublicPageContainer/PublicPageContainer";
import PublicPageTitle from "../components/PublicPageTitle/PublicPageTitle";
import Translate from "components/Translate/Translate";

const VerifyEmail = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const [title, setTitle] = useState("Confirm your email");
    const [image, setImage] = useState(emailConfirmation);
    const [message, setMessage] = useState(
        "Please check your inbox and follow the link in the email to confirm your email address. Don't forget to check your spam folder if you don't see the email in your inbox."
    );
    const [isVerified, setIsVerified] = useState(false);
    const [showSendConfirmationAgainForm, setShowSendConfirmationAgainForm] =
        useState(false);
    const verifyEmailMutation = useVerifyEmailMutation();
    const resetDefaultValues = () => {
        searchParams.delete("token");
        setTitle("Confirm your email");
        setImage(emailConfirmation);
        setMessage(
            "Please check your inbox and follow the link in the email to confirm your email address. Don't forget to check your spam folder if you don't see the email in your inbox."
        );
        setIsVerified(false);
        setShowSendConfirmationAgainForm(false);
    };
    useEffect(() => {
        function submitToken() {
            if (token) {
                verifyEmailMutation.mutate(token, {
                    onSuccess: (response) => {
                        tracker("Email verification successful");
                        identifySignedInUser(response.data);
                        setTitle("Your registration is complete!");
                        setImage(emailConfirmationSuccess);
                        setMessage(
                            "You can now log in to Wizenoze Learning and start exploring."
                        );
                        setIsVerified(true);
                    },
                    onError: () => {
                        setTitle("Email address is not verified!");
                        setImage(emailConfirmationFailed);
                        setMessage(
                            "We could not verify your email address. Please try again or contact our support for further assistance."
                        );
                    },
                });
            }
        }
        submitToken();
    }, [token]);
    if (verifyEmailMutation.isLoading) return null;
    return (
        <PublicPageContainer page="verifyEmail">
            <div className={styles.verifyEmailContainer}>
                <PublicPageTitle title={title} />
                {!showSendConfirmationAgainForm && (
                    <>
                        <img
                            src={image}
                            className={styles.imageStyle}
                            alt="confirmationEmailImage"
                        />
                        <Typography variant="text-size-Base">
                            <Translate text={message} />
                        </Typography>
                    </>
                )}
                {showSendConfirmationAgainForm ? (
                    <SendConfirmationAgainForm
                        resetDefaultValues={resetDefaultValues}
                    />
                ) : token && !isVerified ? (
                    <NotVerifiedMessage
                        setTitle={setTitle}
                        setShowSendConfirmationAgainForm={
                            setShowSendConfirmationAgainForm
                        }
                    />
                ) : (
                    <ConfirmOrCompleteMessage />
                )}
            </div>
        </PublicPageContainer>
    );
};

export default VerifyEmail;
