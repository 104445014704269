import { InfiniteData } from "@tanstack/react-query";
import { floor } from "lodash";
import { Typography } from "@mui/material";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as styles from "./OrganicSearchResults.module.scss";
import { OrganicSearchResultsType } from "hooks/api/useSearchQuery";
import useIntersectionObserver from "hooks/useIntersectionObserver";
import useScreenSizes from "hooks/useScreenSizes";
import { CollectionFilters } from "types";

import OrganicSearchResultsCard from "./components/OrganicSearchResultsCard";
import MobileOrganicSearchFilters from "../OrganicSearchFilters/MobileOrganicSearchFilters";
import Translate from "components/Translate/Translate";

interface Props {
    data: InfiniteData<OrganicSearchResultsType>;
    fetchNextPage: () => void;
    hasNextPage: boolean | undefined;
    isFetching: boolean;
    contentType: "all" | "pages" | "videos";
    collectionFilters: CollectionFilters;
    updateCollectionFilters: (filter: string, value: unknown) => void;
}

const OrganicSearchResults = ({
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    contentType,
    collectionFilters,
    updateCollectionFilters,
}: Props) => {
    const { isDesktopScreen } = useScreenSizes();
    const handleIntersection: IntersectionObserverCallback = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting && !isFetching) {
                fetchNextPage();
            }
        });
    };
    const footerRef = useIntersectionObserver(handleIntersection, {
        root: null,
        rootMargin: "0px",
        threshold: 0.5,
    });
    return (
        <div className={styles.container}>
            <div className={styles.mobileFilterAndMessage}>
                {!isDesktopScreen && (
                    <MobileOrganicSearchFilters
                        collectionFilters={collectionFilters}
                        updateCollectionFilters={updateCollectionFilters}
                    />
                )}
                <Typography variant="text-size-xSmall-regular">
                    <>
                        <Translate text="We found " />
                        {data?.pages[0].pagination.totalResults < 1000
                            ? data?.pages[0].pagination.totalResults
                            : floor(
                                  data?.pages[0].pagination.totalResults,
                                  -3
                              ) + "+"}
                        <Translate
                            text={
                                contentType === "pages"
                                    ? " texts for "
                                    : contentType === "videos"
                                    ? " videos for "
                                    : " texts and videos for "
                            }
                        />
                        <Typography variant="text-size-xSmall-bold">
                            {data?.pages[0].query.terms}
                        </Typography>
                    </>
                </Typography>
            </div>
            <div className={styles.resultsList}>
                {data?.pages.map((group) =>
                    group?.results.map((result) => (
                        <OrganicSearchResultsCard
                            key={result.id}
                            result={result}
                        />
                    ))
                )}
                {data?.pages[0].pagination.totalResults != 0 && hasNextPage && (
                    <div
                        ref={footerRef}
                        style={{
                            height: "20px",
                            alignSelf: "center",
                            position: "relative",
                        }}
                    >
                        {isFetching && (
                            <div
                                style={{ position: "absolute", left: "-30px" }}
                            >
                                <FontAwesomeIcon
                                    icon={faSpinner}
                                    spin
                                    fontSize={15}
                                />
                            </div>
                        )}
                        <Typography
                            variant="text-size-Small-regular"
                            color="$text-colors-color-text-blue.main"
                        >
                            <Translate text="Load more" />
                        </Typography>
                    </div>
                )}
            </div>
        </div>
    );
};

export default OrganicSearchResults;
