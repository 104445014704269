import { useEffect } from "react";

import { autocompleteMenu } from "./Autocomplete.module.scss";

import SuggestionButton from "./SuggestionButton";

const AutoCompleteList = ({
    autocompleteList,
    completionClickHandler,
}: {
    autocompleteList: { id: string; label: string }[];
    completionClickHandler: (arg0: number) => void;
}) => {
    useEffect(() => {
        const handleArrowEvent = (
            e: KeyboardEvent,
            autocompleteList: { id: string; label: string }[]
            // updateTermIndex,
            // suggestionSearch
        ) => {
            if (!autocompleteList.length) {
                return;
            }

            if (e.code === "ArrowDown" || e.code === "ArrowUp") {
                // updateTermIndex(e.code);
            }

            if (e.code === "Enter") {
                e.preventDefault();
                // suggestionSearch(e, searchSuggestionIndex);
            }
        };
        const handleArrowEventWrapper = (e: KeyboardEvent) =>
            handleArrowEvent(
                e,
                autocompleteList
                // updateTermIndex,
                // suggestionSearch
            );
        window.addEventListener("keyup", handleArrowEventWrapper);

        return () => {
            window.removeEventListener("keyup", handleArrowEventWrapper);
        };
    }, [
        autocompleteList,
        // searchSuggestionIndex,
        // suggestionSearch,
        // updateTermIndex,
    ]);

    if (!autocompleteList?.length) return <></>;

    return (
        <div className={autocompleteMenu}>
            {autocompleteList.map((completion, index) => (
                <SuggestionButton
                    key={"autocomplete" + completion.id}
                    label={completion?.label}
                    index={index}
                    clickHandler={completionClickHandler}
                />
            ))}
        </div>
    );
};

export default AutoCompleteList;
