import Grid from "@mui/material/Unstable_Grid2";

import { Result } from "types";

import CollectionGridResultCard from "./components/CollectionGridResultCard/CollectionGridResultCard";

interface Props {
    results: Result[];
}

const CollectionGridView = ({ results }: Props) => {
    return (
        <Grid container spacing={"20px"}>
            {results.map((result, index) => (
                <Grid xl={4} md={6} xs={12} key={result.id}>
                    <CollectionGridResultCard
                        results={results}
                        resultIndex={index}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default CollectionGridView;
