import { ClickAwayListener } from "@mui/material";

import { container, text, url } from "./TooltipContent.module.scss";
import { tracker } from "utils/analytics";

import Translate, { TranslatableText } from "components/Translate/Translate";

interface Props {
    tooltip: { text: TranslatableText; url: URL };
    closeHandler: () => void;
}

const TooltipContent = ({ tooltip, closeHandler }: Props) => (
    <ClickAwayListener onClickAway={closeHandler}>
        <div className={container}>
            <span className={text}>
                <Translate text={tooltip.text} />
            </span>
            <span
                className={url}
                onClick={() => {
                    window?.open(tooltip.url)?.focus();
                    tracker("Tooltip read more clicked");
                }}
            >
                <Translate text="Read more" />
            </span>
        </div>
    </ClickAwayListener>
);

export default TooltipContent;
