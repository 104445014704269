import { useNavigate } from "react-router-dom";
import { Theme, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import { useMyLatestCollectionsQuery } from "hooks/api/collections";
import { useAllFoldersEnhancedQuery } from "hooks/api/folders";
import { Collection, Folder } from "types";
import { tracker } from "utils/analytics";

import MyCollectionsPanelSkeleton from "./components/MyCollectionsPanelSkeleton/MyCollectionsPanelSkeleton";
import NoCollectionsMessage from "./components/NoCollectionsMessage/NoCollectionsMessage";
import CollectionCard from "components/CollectionCard/CollectionCard";
import Panel from "components/Panel/Panel";

const findPathForID = (arr: Array<Collection | Folder>, targetId: number) => {
    // Helper function to perform the recursive search
    const search = (
        array: Array<Collection | Folder>,
        targetId: number,
        path: string
    ): string | null => {
        for (const node of array) {
            // Add the current node's id to the path
            const currentPath = path ? `${path}/${node.id}` : `${node.id}`;
            // Check if the current node is the target
            if (node.id === targetId) {
                return currentPath;
            }
            // If the node has children, search recursively
            if (
                node.type === "FOLDER" &&
                node.children &&
                node.children.length > 0
            ) {
                const result = search(node.children, targetId, currentPath);
                if (result) {
                    return result;
                }
            }
        }
        // If not found, return null
        return null;
    };
    // Start the search from the root array
    return search(arr, targetId, "");
};

const MyCollectionsPanel = () => {
    const isTabletScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.between("md", "lg")
    );
    const navigate = useNavigate();
    const { data: myCollectionsData, isFetched: isFetchedMyCollections } =
        useMyLatestCollectionsQuery();
    const noCollections = !myCollectionsData?.results?.length;
    const { data: allFolders } = useAllFoldersEnhancedQuery();
    const clickHandler = () => {
        tracker("My collections panel button clicked");
        navigate("/my-collections");
    };
    const collectionClickHandler = (collection: Collection) => {
        const privateCollections = allFolders.find(
            (el) => el.access === "PRIVATE"
        );
        if (!privateCollections) return;
        const navigatePath = findPathForID(
            privateCollections.children,
            collection.id
        );
        if (!navigatePath) return;
        tracker("Collection clicked", collection);
        navigate(`/my-collections/${privateCollections.id}/${navigatePath}`);
    };
    return (
        <Panel
            title={"My collections"}
            footer={
                noCollections
                    ? undefined
                    : {
                          text: "View all collections",
                          clickHandler: clickHandler,
                      }
            }
            styleOverrides={{ height: "100%" }}
        >
            {!isFetchedMyCollections ? (
                <Grid
                    container
                    spacing={"20px"}
                    style={{ alignSelf: "stretch" }}
                >
                    <MyCollectionsPanelSkeleton />
                </Grid>
            ) : noCollections ? (
                <NoCollectionsMessage />
            ) : (
                <Grid
                    container
                    spacing={"20px"}
                    style={{ alignSelf: "stretch" }}
                >
                    {myCollectionsData?.results
                        .slice(0, isTabletScreen ? 1 : 3)
                        .map((collection) => (
                            <Grid
                                key={"collection" + collection.id}
                                lg={4}
                                xs={12}
                            >
                                <CollectionCard
                                    collection={collection}
                                    clickHandler={() =>
                                        collectionClickHandler(collection)
                                    }
                                    disableContextMenu
                                />
                            </Grid>
                        ))}
                </Grid>
            )}
        </Panel>
    );
};

export default MyCollectionsPanel;
