import { useState, useRef, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";

import * as styles from "./NodeCard.module.scss";
import useContainerSize from "hooks/useContainerSize";
import { Topic, Unit } from "types";
import { tracker } from "utils/analytics";

import ShareButton from "components/ShareButton/ShareButton";
import CardFooter from "components/TopicCard/components/CardFooter";
import CardImage from "components/TopicCard/components/CardImage";
import CardList from "components/TopicCard/components/CardList";

interface Props {
    node: Unit | Topic;
    subjectName: string;
}

const NodeCard = ({ node, subjectName }: Props) => {
    const navigate = useNavigate();
    const { width } = useContainerSize();
    const [isCardExpanded, setIsCardExpanded] = useState(false);

    const ref = useRef<HTMLDivElement | null>(null);
    const [preExpansionHeight, setPreExpansionHeight] = useState<number>();
    useLayoutEffect(() => {
        if (!preExpansionHeight && ref.current?.clientHeight)
            setPreExpansionHeight(ref.current?.clientHeight);
    }, [preExpansionHeight]);

    const expandCollapseCard = () => {
        setIsCardExpanded((current) => !current);
        tracker("Card Show/Hide more");
    };

    const children = node.children ? node.children : node.queries;
    const nodeClickHandler = () => {
        tracker("Node clicked", node);
        navigate("./" + node.qualifier.toLowerCase() + "/" + node.id, {
            relative: "path",
        });
    };
    const childrenClickHandler = (index: number) => {
        tracker("Node clicked", node);
        navigate(
            "./" +
                node.qualifier.toLowerCase() +
                "/" +
                node.id +
                "/" +
                node.childrenQualifier.toLowerCase() +
                "/" +
                index,
            { relative: "path" }
        );
    };
    return (
        <div
            className={styles.cardContainer}
            data-card-expanded={isCardExpanded}
            ref={ref}
            style={{
                minWidth: width > 470 ? undefined : "95%",
                maxWidth: width > 470 ? undefined : "95%",
                height: isCardExpanded ? preExpansionHeight : undefined,
            }}
        >
            {(node?.embedUrl || node?.linkUrl) && (
                <ShareButton
                    embedUrl={node.embedUrl}
                    linkUrl={node.linkUrl}
                    hasText={false}
                    analyticsOptions={{ topicId: node.id }}
                    styleOverrides={{
                        position: "absolute",
                        right: "15px",
                        top: "15px",
                    }}
                />
            )}
            <div className={styles.innerCard} onClick={nodeClickHandler}>
                <CardImage
                    cardImage={node.logoUrl}
                    cardHeading={node.name}
                    subjectTitle={subjectName}
                />
                <div className={styles.cardBody}>
                    <Typography
                        variant="text-size-Base-bold"
                        color="$text-colors-color-text-dark.main"
                        className={styles.cardTitle}
                    >
                        {node.name}
                    </Typography>
                    <CardList
                        listItems={children}
                        handleClick={childrenClickHandler}
                        isCardExpanded={isCardExpanded}
                    />
                </div>
            </div>
            {children !== undefined && (
                <CardFooter
                    listItemsTotal={children?.length}
                    expandCollapseCard={expandCollapseCard}
                    isCardExpanded={isCardExpanded}
                />
            )}
        </div>
    );
};

export default NodeCard;
