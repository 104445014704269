@use "../../../../../../../styles/variables.module";

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid variables.$border-colors-color-border-light;
    background: variables.$background-colors-color-background-white;
}

.back {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    gap: 5px;
    background: variables.$background-colors-color-background-light-off;
    box-shadow: 0px -1px 0px 0px #cbcbcc inset;
}

.quizName {
    padding: 30px 40px;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: 5px;
    border-bottom: 1px solid variables.$border-colors-color-border-primary;
    background: variables.$background-colors-color-background-light;
}

.logoAndTitle {
    display: flex;
    align-items: center;
    gap: 10px;
}

.quizOptions {
    padding: 15px 40px;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: 20px;
}

.questionTypes {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: 20px;
}

.numberOfQuestions {
    margin-top: 10px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: 10px;
}

.numberButtons {
    display: flex;
    border-radius: 5px;
    background: variables.$background-colors-color-background-light-blue;
}

.generateButton {
    margin-top: 10px;
    padding: 16px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 5px;
}
