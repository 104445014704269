@use "../../../../../../../../styles/variables.module";

.checkbox {
    width: 20px;
    height: 20px;
}

.reasonList {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 15px 0px;
    gap: 5px;
    padding: 0;
}

.mandatory {
    color: variables.$message-colors-color-message-danger;
}

.listItemCheckbox {
    padding: 0;
    margin: 0;
    list-style: none;
}
.labelCheckbox {
    display: flex;
    align-items: center;
    gap: 10px;
}
