/**
 * Only use this if you need to filter a list of objects. 
 * For ints or strings, use a Set https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set
 * 
 * This filters the array based on the objects in the list
 * 
 * @param {array} arr The list that should be filtered
 * @param {string} prop the object key
 * @returns array
 */
export const getUniqueList = function (arr, prop) {
    if (!(Array.isArray(arr) && typeof prop === "string" && prop)) {
        throw new Error("Invalid input provided to unique function");
    }

    if (arr.length < 2) return arr;

    const uniqueList = new Set();
    return arr.filter(item => !uniqueList.has(item[prop]) && uniqueList.add(item[prop]));
}