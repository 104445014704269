import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";
import { axiosAPI } from "./axiosAPI";

const parseCookie = (cookieString: string) =>
    cookieString
        .split(";")
        .map((v) => v.split("="))
        .reduce((acc, v) => {
            acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(
                v[1].trim()
            );
            return acc;
        }, {});

const addSubmitDeepLinkHeader = (headers: object) => {
    if (!document.cookie) return headers;

    const cookieData = parseCookie(document.cookie);

    if (!cookieData) return headers;
    const authValue =
        cookieData?.id_token ||
        cookieData?.APP_WIZEUP_SSO_COOKIE ||
        cookieData?.DEV_APP_WIZEUP_SSO_COOKIE;
    return {
        ...headers,
        authorization: authValue,
    };
};

const useLTIDeepLinkSubmit = (submitData: unknown) => {
    const headers = addSubmitDeepLinkHeader({
        "Content-Type": "application/json",
    });

    return useQuery<SubmitDeepLink, AxiosError>(
        ["submitDeepLink", submitData], // keys need the params setting here too, otherwise it'll not update
        async () => {
            const { data } = await axiosAPI
                .getInstance()
                .post(`/curriculum/lti/v13/submit_deeplink`, submitData, {
                    headers,
                });
            return data;
        },
        { enabled: !!submitData }
    );
};

type SubmitDeepLink = string;

export default useLTIDeepLinkSubmit;
