@use "../../styles/variables.module";

.container {
    display: flex;
    align-items: center;
    gap: 40px;
}

.readingLevels {
    display: flex;
    gap: 10px;
}

@media (max-width: 1110px) {
    .container {
        gap: 10px;
    }
}

@media (max-width: variables.$bp-lg) {
    .container {
        gap: 40px;
    }
}
