interface Props {
    isDisabled?: boolean;
}

const WizenozeCopyRight = ({ isDisabled }: Props) => {
    if (isDisabled) return null;
    return <div>{`Wizenoze © 2013 - ${new Date().getFullYear()}`}</div>;
};

export default WizenozeCopyRight;
