import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import * as styles from "./MyCollections.module.scss";
import { useAppContext } from "contexts/AppContext";
import { translate } from "components/Translate/Translate";
import {
    useCollectionQuery,
    useItemsInCollectionQuery,
} from "hooks/api/collections";
import {
    useAllFoldersEnhancedQuery,
    useEditFolderMutation,
    useSelectedFoldersAndCollections,
} from "hooks/api/folders";
import useCollectionsParams from "hooks/useCollectionsParams";
import useScreenSizes from "hooks/useScreenSizes";
import { Collection, Folder } from "types";
import { tracker } from "utils/analytics";

import Subheader from "./components/Subheader/Subheader";
import CollectionsExplorerPanel from "components/CollectionsExplorerPanel/CollectionsExplorerPanel";
import CollectionViewer from "components/CollectionViewer/CollectionViewer";
import FolderViewer from "components/FolderViewer/FolderViewer";
import CollectionNavBar from "components/NavBars/CollectionNavBar";
import QuizPanel from "features/CollectionEditor/QuizPanel/QuizPanel";

const MyCollections = () => {
    const { isDesktopScreen, isMobileScreen } = useScreenSizes();
    const navigate = useNavigate();
    const {
        config: { language },
    } = useAppContext();
    const editFolderMuatation = useEditFolderMutation();
    const collectionsParams = useCollectionsParams();
    const { data: allRootFolders } = useAllFoldersEnhancedQuery();
    const selectedFoldersAndCollections = useSelectedFoldersAndCollections();
    const selectedFirstChild = selectedFoldersAndCollections[1];
    const selectedFolderOrCollection = selectedFoldersAndCollections.at(-1) as
        | Folder
        | Collection
        | undefined;
    const { data: fullCollection } = useCollectionQuery(
        selectedFolderOrCollection?.id,
    );
    const pageTitle = selectedFolderOrCollection?.title
        ? selectedFolderOrCollection.type === "FOLDER" &&
          selectedFolderOrCollection?.isRootFolder
            ? translate(language, selectedFolderOrCollection?.title)
            : selectedFolderOrCollection.title
        : translate(language, "My collections");

    // Folder items
    const folderItems = selectedFolderOrCollection?.children
        ? selectedFolderOrCollection?.children
        : allRootFolders;
    const sortedFolderItems = selectedFoldersAndCollections[0]
        ? selectedFolderOrCollection?.sortBy === "TITLE"
            ? [...folderItems].sort((a, b) =>
                  a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1,
              )
            : [...folderItems].sort((a, b) =>
                  a.lastModifiedDate < b.lastModifiedDate ? 1 : -1,
              )
        : [...folderItems];

    // Collection items
    const { data: collectionItems, isFetched: collectionItemsIsFetched } =
        useItemsInCollectionQuery(
            selectedFolderOrCollection &&
                selectedFolderOrCollection.type === "COLLECTION"
                ? selectedFolderOrCollection.id
                : undefined,
        );

    const folderClickHandler = (folder: Folder, pathString?: string) => {
        if (!pathString) return;
        tracker("Folder clicked", folder);
        navigate(pathString);
    };
    const folderViewClickHandler = (folder: Folder) => {
        folderClickHandler(folder, location.pathname + "/" + folder.id);
    };
    const collectionClickHandler = (
        collection: Collection,
        pathString?: string,
    ) => {
        if (!pathString) return;
        tracker("Collection clicked", collection);
        navigate(pathString);
    };
    const collectionViewClickHandler = (collection: Collection) => {
        collectionClickHandler(
            collection,
            location.pathname + "/" + collection.id,
        );
    };
    const openQuizEditorHandler = () => {
        navigate(`/edit-collection/${selectedFolderOrCollection?.id}`, {
            state: {
                collectionsParams: collectionsParams,
                openQuizEditor: true,
            },
        });
    };
    return (
        <>
            <CollectionNavBar
                previousCrumbs={[
                    {
                        label: translate(language, "My collections"),
                        pathname: "/my-collections",
                    },
                ]}
            />
            <Subheader
                viewAs={selectedFolderOrCollection?.view ?? "GRID"}
                setViewAs={(newValue: Folder["view"]) =>
                    selectedFolderOrCollection &&
                    selectedFolderOrCollection.type === "FOLDER" &&
                    editFolderMuatation.mutate({
                        ...selectedFolderOrCollection,
                        view: newValue,
                    })
                }
                sortBy={
                    selectedFolderOrCollection?.sortBy ?? "LAST_MODIFIED_DATE"
                }
                setSortBy={(newValue: Folder["sortBy"]) =>
                    selectedFolderOrCollection &&
                    selectedFolderOrCollection.type === "FOLDER" &&
                    editFolderMuatation.mutate({
                        ...selectedFolderOrCollection,
                        sortBy: newValue,
                    })
                }
                openQuizEditorHandler={openQuizEditorHandler}
            />
            <div
                style={{
                    padding: isMobileScreen ? "20px 10px" : "20px 40px",
                    marginBottom: 10,
                    width: "100%",
                }}
            >
                <Grid container spacing={"20px"}>
                    {isDesktopScreen && (
                        <Grid lg={3} xs={0}>
                            <CollectionsExplorerPanel
                                items={allRootFolders}
                                selectedIds={collectionsParams}
                                folderClickHandler={folderClickHandler}
                                collectionClickHandler={collectionClickHandler}
                            />
                        </Grid>
                    )}
                    <Grid lg={9} xs={12}>
                        <div className={styles.title}>
                            <Typography
                                variant="text-size-Medium"
                                color="$text-colors-color-text-dark.main"
                            >
                                {pageTitle}
                            </Typography>
                        </div>
                        {selectedFolderOrCollection &&
                        selectedFolderOrCollection.type === "COLLECTION" ? (
                            <div>
                                <Grid container spacing={"20px"} columns={9}>
                                    {!isDesktopScreen && fullCollection && (
                                        <Grid xs={9}>
                                            <QuizPanel
                                                collection={fullCollection}
                                                editClickHandler={
                                                    openQuizEditorHandler
                                                }
                                            />
                                        </Grid>
                                    )}
                                    <Grid lg={6} xs={9}>
                                        <CollectionViewer
                                            items={collectionItems ?? []}
                                            isFetched={collectionItemsIsFetched}
                                            viewAs={
                                                fullCollection?.view ?? "LIST"
                                            }
                                            collectionResultsModalEnabled={
                                                !!fullCollection?.resultModal
                                            }
                                        />
                                    </Grid>
                                    {isDesktopScreen && (
                                        <Grid lg={3}>
                                            <QuizPanel
                                                collection={fullCollection}
                                                editClickHandler={
                                                    openQuizEditorHandler
                                                }
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </div>
                        ) : (
                            <FolderViewer
                                items={sortedFolderItems}
                                isFetched={
                                    selectedFirstChild?.hasBeenFetched ===
                                    undefined
                                        ? true
                                        : selectedFirstChild?.hasBeenFetched
                                }
                                viewAs={
                                    selectedFolderOrCollection?.view ?? "GRID"
                                }
                                sortBy={
                                    selectedFolderOrCollection?.sortBy ??
                                    "LAST_MODIFIED_DATE"
                                }
                                folderClickHandler={folderViewClickHandler}
                                collectionClickHandler={
                                    collectionViewClickHandler
                                }
                            />
                        )}
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default MyCollections;
