@use "../../../styles/variables.module";

.shadedBackground {
    position: fixed;
    inset: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: variables.$zindex-result-modal;
}

.iframeContainer {
    position: fixed;
    inset: 60px;
    display: flex;
    flex-direction: column;
    background-color: white;
    overflow: hidden;
    border-radius: 5px;
    background: variables.$background-colors-color-background-white;
    box-shadow: 0px 20px 30px 0px
        variables.$background-colors-color-background-overlay;
}

.headerStyle {
    width: 100%;
    height: 60px;
    display: flex;
    gap: 20px;
    padding: 10px 40px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid variables.$border-colors-color-border-primary;
    background: variables.$background-colors-color-background-light-off;
}

.headerTitle {
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
}

.headerButtons {
    display: flex;
    align-items: center;
    gap: 30px;
}

.nextPreviousContainer {
    display: flex;
    align-items: center;
    gap: 10px;
}

.nextPreviousButton {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: variables.$icon-colors-blue-40;
}

.nextPreviousButton:hover {
    background-color: variables.$icon-colors-blue-40;
}

.nextPreviousButton:disabled {
    background-color: variables.$icon-colors-blue-40;
    opacity: 0.3;
}

.closeButton {
    padding: 10px;
    color: variables.$text-colors-color-text-gray;
    cursor: pointer;
}

.iframe {
    border: none;
    width: 100%;
    height: calc(100% - 60px);
    background-color: white;
}

.activeUrlLink {
    height: 100%;
    display: flex;
    flex-direction: column;
    place-items: center;
    font-size: 32px;
    overflow-y: auto;
    padding: 7% 5%;
}

.wrapImageStyle {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
}

.wrapImageStyle img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    object-fit: contain;
}

.wrapTextStyle {
    margin-top: 30px;
}

.textStyle {
    text-align: center;
    color: variables.$text-colors-color-text-light-gray;
    font-size: 16px;
    margin: 0px;
    line-height: 25px;
}

.textStyle > a {
    text-decoration: none;
    color: variables.$text-colors-color-text-blue;
}

.activeUrlLink > a {
    background-color: variables.$background-colors-color-background-blue;
    border-radius: 5px;
    padding: 10px 61px;
    color: white;
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    margin-top: 30px;
    text-decoration: none;
}

.loadingIframe {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: white;
    width: 90%;
    height: calc(90% - 60px);
}

@media (min-width: variables.$bp-xl) {
    .wrapImageStyle {
        height: 40%;
    }
    .textStyle {
        font-size: 24px;
        line-height: 35px;
    }
}

@media (max-width: variables.$bp-lg) {
    .iframeContainer {
        inset: 40px;
    }
}

@media (max-width: variables.$bp-md) {
    .iframeContainer {
        inset: 0px;
    }
    .headerStyle {
        padding: 10px 20px;
    }
    .headerButtons {
        width: 100%;
    }
    .closeButton {
        padding: 0px;
    }
}

@media (max-width: 640px) {
    .textStyle {
        line-height: 30px;
    }
    .loadingIframe {
        width: 100%;
        left: 0%;
    }
}

@media screen and (max-height: 640px) and (orientation: landscape) {
    .headerStyle[data-video="true"] {
        display: none;
    }

    .iframe[data-video="true"] {
        height: 100%;
    }

    .loadingIframe {
        height: 90%;
    }
}

@media (max-width: 400px) {
    .pages {
        margin: 0 5px;
    }
}
