import {
    searchResultsAmount,
    infoTextStyle,
    resultAmountContainer,
} from "../SearchResult.module.scss";
import { Pagination } from "components/PaginationControls/PaginationControls";
import { OrganicSearchResultsType } from "hooks/api/useSearchQuery";
import { capitaliseFirstLetter } from "utils/helpers";

import Translate from "components/Translate/Translate";

interface Props {
    searchResults: Pagination;
    query: OrganicSearchResultsType["query"];
    infoText: string;
}

const SearchTotalResultDisplay = ({
    searchResults,
    query,
    infoText,
}: Props) => {
    if (!query) {
        return null;
    }

    const { totalResults, totalPages, pageSize } = searchResults;
    const amountResults = pageSize * totalPages;
    const hasMoreResults = amountResults >= 5000 ? "+ " : "";

    return (
        <div className={resultAmountContainer}>
            {Boolean(infoText) && <p className={infoTextStyle}>{infoText}</p>}
            <p className={searchResultsAmount} tabIndex={0}>
                {hasMoreResults}
                {totalResults + " "}
                <Translate text="results for" />
                {` "${capitaliseFirstLetter(query?.terms)}"`}
            </p>
        </div>
    );
};

export default SearchTotalResultDisplay;
