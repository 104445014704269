@use "../../../../styles/variables.module";

.header {
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid variables.$border-colors-color-border-light;
}

.nodeLogo {
    height: 55px;
}

.titleAndMetadata {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.metadataTags {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
}
