@use "../../../styles/variables.module";

.container {
    padding: 10px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: variables.$background-colors-color-background-white;
    border-bottom: 1px solid variables.$border-colors-color-border-light;
    @media (max-width: variables.$bp-md) {
        padding: 10px;
    }
}

.editAndMobileExplorer {
    display: flex;
    align-items: center;
    gap: 20px;
}

.editButton {
    height: 30px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    @media (max-width: variables.$bp-md) {
        width: 100%;
    }
}

.mobileContainer {
    padding: 20px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    background: variables.$background-colors-color-background-white;
}
