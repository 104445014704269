import React from "react";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { persistQueryClient } from "@tanstack/react-query-persist-client";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";

import AppContextProvider from "./contexts/AppContext";
import { MessagingContextProvider } from "./contexts/MessagingContext";
import { ResultModalContextProvider } from "./contexts/ResultModalContext";
import ZendeskContextProvider from "./contexts/ZendeskContext";
import theme from "./styles/theme";
import { sanitiseURL } from "./utils/legacySupport";
import { checkLocalStoragePermission, isAppMode } from "./utils/newtools";

import AxiosInitialiser from "./AxiosInitialiser";
import AppRouter from "./AppRouter";
import { HotjarProvider } from "utils/analytics";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: 1000 * 60 * 60 * 24 * 7, // 24 hours
        },
    },
});
const reactRender = async () => {
    const container = document.getElementById("root");
    if (!container) {
        console.error(`Can't find root`);
        return;
    }

    sanitiseURL(); // For handling old share/widget urls and such that we need to support for older customers, but cleans them up for standardised interpretation in the app

    if (isAppMode && checkLocalStoragePermission()) {
        // App user who can use persistant storage for queries, so configure:
        const localStoragePersister = createSyncStoragePersister({
            storage: window.localStorage,
            throttleTime: 1000,
        });
        persistQueryClient({
            queryClient,
            persister: localStoragePersister,
        });
    }

    const root = createRoot(container);

    root.render(
        <React.StrictMode>
            <QueryClientProvider client={queryClient}>
                <AppContextProvider>
                    <ZendeskContextProvider>
                        <AxiosInitialiser>
                            <HotjarProvider>
                                <ThemeProvider theme={theme}>
                                    <CssBaseline />
                                    <StyledEngineProvider injectFirst>
                                        <ResultModalContextProvider>
                                            <MessagingContextProvider>
                                                <AppRouter />
                                            </MessagingContextProvider>
                                        </ResultModalContextProvider>
                                    </StyledEngineProvider>
                                </ThemeProvider>
                            </HotjarProvider>
                        </AxiosInitialiser>
                    </ZendeskContextProvider>
                </AppContextProvider>
                <ReactQueryDevtools
                    initialIsOpen={false}
                    position="bottom-right"
                />
            </QueryClientProvider>
        </React.StrictMode>,
    );
};

export default reactRender;
