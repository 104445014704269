import {
    createTheme,
    PaletteColorOptions,
    SimplePaletteColorOptions,
} from "@mui/material/styles";

declare module "@mui/material/Button" {
    interface ButtonPropsVariantOverrides {
        "ai-disabled-button": true;
        "ai-button": true;
    }
}
declare module "@mui/material/styles" {
    interface TypographyVariants {
        "text-size-xxLarge": React.CSSProperties;
        "text-size-xxLarge-regular": React.CSSProperties;
        "text-size-xxLarge-medium": React.CSSProperties;
        "text-size-xxLarge-bold": React.CSSProperties;
        "text-size-xLarge": React.CSSProperties;
        "text-size-xLarge-regular": React.CSSProperties;
        "text-size-xLarge-medium": React.CSSProperties;
        "text-size-xLarge-bold": React.CSSProperties;
        "text-size-Large": React.CSSProperties;
        "text-size-Large-regular": React.CSSProperties;
        "text-size-Large-medium": React.CSSProperties;
        "text-size-Large-bold": React.CSSProperties;
        "text-size-xMedium": React.CSSProperties;
        "text-size-xMedium-regular": React.CSSProperties;
        "text-size-xMedium-medium": React.CSSProperties;
        "text-size-xMedium-bold": React.CSSProperties;
        "text-size-Medium": React.CSSProperties;
        "text-size-Medium-regular": React.CSSProperties;
        "text-size-Medium-medium": React.CSSProperties;
        "text-size-Medium-bold": React.CSSProperties;
        "text-size-Base": React.CSSProperties;
        "text-size-Base-regular": React.CSSProperties;
        "text-size-Base-medium": React.CSSProperties;
        "text-size-Base-bold": React.CSSProperties;
        "text-size-Small": React.CSSProperties;
        "text-size-Small-regular": React.CSSProperties;
        "text-size-Small-medium": React.CSSProperties;
        "text-size-Small-bold": React.CSSProperties;
        "text-size-xSmall": React.CSSProperties;
        "text-size-xSmall-regular": React.CSSProperties;
        "text-size-xSmall-medium": React.CSSProperties;
        "text-size-xSmall-bold": React.CSSProperties;
        "text-size-xxSmall": React.CSSProperties;
        "text-size-xxSmall-regular": React.CSSProperties;
        "text-size-xxSmall-medium": React.CSSProperties;
        "text-size-xxSmall-bold": React.CSSProperties;
        "button-text-size-xSmall-bold": React.CSSProperties;
        "alert-text-size-xSmall": React.CSSProperties;
    }

    interface Palette {
        "$wizenoze-brand-color-wizenoze-darkgreen": PaletteColorOptions;
        "$wizenoze-brand-color-wizenoze-green": PaletteColorOptions;
        "$wizenoze-brand-color-wizenoze-lightgreen": PaletteColorOptions;
        "$text-colors-color-text-dark": PaletteColorOptions;
        "$text-colors-color-text-gray": PaletteColorOptions;
        "$text-colors-color-text-light-gray": PaletteColorOptions;
        "$text-colors-color-text-blue": PaletteColorOptions;
        "$text-colors-color-text-white": PaletteColorOptions;
        "$text-colors-color-text-orange": PaletteColorOptions;
        "$border-colors-color-border-primary": PaletteColorOptions;
        "$border-colors-color-border-light": PaletteColorOptions;
        "$border-colors-color-border-blue": PaletteColorOptions;
        "$border-colors-color-border-white": PaletteColorOptions;
        "$icon-colors-orange-100": PaletteColorOptions;
        "$icon-colors-orange-60": PaletteColorOptions;
        "$icon-colors-orange-40": PaletteColorOptions;
        "$icon-colors-blue-100": PaletteColorOptions;
        "$icon-colors-blue-60": PaletteColorOptions;
        "$icon-colors-blue-40": PaletteColorOptions;
        "$icon-colors-green-100": PaletteColorOptions;
        "$icon-colors-green-60": PaletteColorOptions;
        "$icon-colors-green-40": PaletteColorOptions;
        "$reading-levels-color-level-unknown": PaletteColorOptions;
        "$reading-levels-color-level-selected": PaletteColorOptions;
        "$reading-levels-color-level-1": PaletteColorOptions;
        "$reading-levels-color-level-2": PaletteColorOptions;
        "$reading-levels-color-level-3": PaletteColorOptions;
        "$reading-levels-color-level-4": PaletteColorOptions;
        "$reading-levels-color-level-5": PaletteColorOptions;
        "$message-colors-color-message-info": SimplePaletteColorOptions;
        "$message-colors-color-message-warning": SimplePaletteColorOptions;
        "$message-colors-color-message-danger": SimplePaletteColorOptions;
        "$message-colors-color-message-success": SimplePaletteColorOptions;
        "$background-colors-color-background-light": SimplePaletteColorOptions;
        "$background-colors-color-background-light-blue": SimplePaletteColorOptions;
    }

    interface TypographyVariantsOptions {
        "text-size-xxLarge"?: React.CSSProperties;
        "text-size-xxLarge-regular"?: React.CSSProperties;
        "text-size-xxLarge-medium"?: React.CSSProperties;
        "text-size-xxLarge-bold"?: React.CSSProperties;
        "text-size-xLarge"?: React.CSSProperties;
        "text-size-xLarge-regular"?: React.CSSProperties;
        "text-size-xLarge-medium"?: React.CSSProperties;
        "text-size-xLarge-bold"?: React.CSSProperties;
        "text-size-Large"?: React.CSSProperties;
        "text-size-Large-regular"?: React.CSSProperties;
        "text-size-Large-medium"?: React.CSSProperties;
        "text-size-Large-bold"?: React.CSSProperties;
        "text-size-xMedium"?: React.CSSProperties;
        "text-size-xMedium-regular"?: React.CSSProperties;
        "text-size-xMedium-medium"?: React.CSSProperties;
        "text-size-xMedium-bold"?: React.CSSProperties;
        "text-size-Medium"?: React.CSSProperties;
        "text-size-Medium-regular"?: React.CSSProperties;
        "text-size-Medium-medium"?: React.CSSProperties;
        "text-size-Medium-bold"?: React.CSSProperties;
        "text-size-Base"?: React.CSSProperties;
        "text-size-Base-regular"?: React.CSSProperties;
        "text-size-Base-medium"?: React.CSSProperties;
        "text-size-Base-bold"?: React.CSSProperties;
        "text-size-Small"?: React.CSSProperties;
        "text-size-Small-regular"?: React.CSSProperties;
        "text-size-Small-medium"?: React.CSSProperties;
        "text-size-Small-bold"?: React.CSSProperties;
        "text-size-xSmall"?: React.CSSProperties;
        "text-size-xSmall-regular"?: React.CSSProperties;
        "text-size-xSmall-medium"?: React.CSSProperties;
        "text-size-xSmall-bold"?: React.CSSProperties;
        "text-size-xxSmall"?: React.CSSProperties;
        "text-size-xxSmall-regular"?: React.CSSProperties;
        "text-size-xxSmall-medium"?: React.CSSProperties;
        "text-size-xxSmall-bold"?: React.CSSProperties;
        "button-text-size-xSmall-bold"?: React.CSSProperties;
        "alert-text-size-xSmall"?: React.CSSProperties;
    }

    interface PaletteOptions {
        "$wizenoze-brand-color-wizenoze-darkgreen"?: PaletteColorOptions;
        "$wizenoze-brand-color-wizenoze-green"?: PaletteColorOptions;
        "$wizenoze-brand-color-wizenoze-lightgreen"?: PaletteColorOptions;
        "$text-colors-color-text-dark"?: PaletteColorOptions;
        "$text-colors-color-text-gray"?: PaletteColorOptions;
        "$text-colors-color-text-light-gray"?: PaletteColorOptions;
        "$text-colors-color-text-blue"?: PaletteColorOptions;
        "$text-colors-color-text-white"?: PaletteColorOptions;
        "$text-colors-color-text-orange"?: PaletteColorOptions;
        "$border-colors-color-border-primary"?: PaletteColorOptions;
        "$border-colors-color-border-light": PaletteColorOptions;
        "$border-colors-color-border-blue": PaletteColorOptions;
        "$border-colors-color-border-white": PaletteColorOptions;
        "$icon-colors-orange-100"?: PaletteColorOptions;
        "$icon-colors-orange-60"?: PaletteColorOptions;
        "$icon-colors-orange-40"?: PaletteColorOptions;
        "$icon-colors-blue-100"?: PaletteColorOptions;
        "$icon-colors-blue-60"?: PaletteColorOptions;
        "$icon-colors-blue-40"?: PaletteColorOptions;
        "$icon-colors-green-100"?: PaletteColorOptions;
        "$icon-colors-green-60"?: PaletteColorOptions;
        "$icon-colors-green-40"?: PaletteColorOptions;
        "$reading-levels-color-level-unknown"?: PaletteColorOptions;
        "$reading-levels-color-level-selected"?: PaletteColorOptions;
        "$reading-levels-color-level-1"?: PaletteColorOptions;
        "$reading-levels-color-level-2"?: PaletteColorOptions;
        "$reading-levels-color-level-3"?: PaletteColorOptions;
        "$reading-levels-color-level-4"?: PaletteColorOptions;
        "$reading-levels-color-level-5"?: PaletteColorOptions;
        "$message-colors-color-message-info"?: SimplePaletteColorOptions;
        "$message-colors-color-message-warning"?: SimplePaletteColorOptions;
        "$message-colors-color-message-danger"?: SimplePaletteColorOptions;
        "$message-colors-color-message-success"?: SimplePaletteColorOptions;
        "$background-colors-color-background-light"?: SimplePaletteColorOptions;
        "$background-colors-color-background-light-blue"?: SimplePaletteColorOptions;
    }
}

declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        "text-size-xxLarge": true;
        "text-size-xxLarge-regular": true;
        "text-size-xxLarge-medium": true;
        "text-size-xxLarge-bold": true;
        "text-size-xLarge": true;
        "text-size-xLarge-regular": true;
        "text-size-xLarge-medium": true;
        "text-size-xLarge-bold": true;
        "text-size-Large": true;
        "text-size-Large-regular": true;
        "text-size-Large-medium": true;
        "text-size-Large-bold": true;
        "text-size-xMedium": true;
        "text-size-xMedium-regular": true;
        "text-size-xMedium-medium": true;
        "text-size-xMedium-bold": true;
        "text-size-Medium": true;
        "text-size-Medium-regular": true;
        "text-size-Medium-medium": true;
        "text-size-Medium-bold": true;
        "text-size-Base": true;
        "text-size-Base-regular": true;
        "text-size-Base-medium": true;
        "text-size-Base-bold": true;
        "text-size-Small": true;
        "text-size-Small-regular": true;
        "text-size-Small-medium": true;
        "text-size-Small-bold": true;
        "text-size-xSmall": true;
        "text-size-xSmall-regular": true;
        "text-size-xSmall-medium": true;
        "text-size-xSmall-bold": true;
        "text-size-xxSmall": true;
        "text-size-xxSmall-regular": true;
        "text-size-xxSmall-medium": true;
        "text-size-xxSmall-bold": true;
        "button-text-size-xSmall-bold": true;
        "alert-text-size-xSmall": true;
    }
}

declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        "$wizenoze-brand-color-wizenoze-darkgreen": true;
        "$wizenoze-brand-color-wizenoze-green": true;
        "$wizenoze-brand-color-wizenoze-lightgreen": true;
        "$text-colors-color-text-dark": true;
        "$text-colors-color-text-gray": true;
        "$text-colors-color-text-light-gray": true;
        "$text-colors-color-text-blue": true;
        "$text-colors-color-text-white": true;
        "$text-colors-color-text-orange": true;
        "$border-colors-color-border-primary": true;
        "$border-colors-color-border-light": true;
        "$border-colors-color-border-blue": true;
        "$border-colors-color-border-white": true;
        "$reading-levels-color-level-unknown": true;
        "$reading-levels-color-level-selected": true;
        "$reading-levels-color-level-1": true;
        "$reading-levels-color-level-2": true;
        "$reading-levels-color-level-3": true;
        "$reading-levels-color-level-4": true;
        "$reading-levels-color-level-5": true;
        "$message-colors-color-message-info": true;
        "$message-colors-color-message-warning": true;
        "$message-colors-color-message-danger": true;
        "$message-colors-color-message-success": true;
        "$background-colors-color-background-light": true;
    }
}

declare module "@mui/material/Chip" {
    interface ChipPropsColorOverrides {
        "$wizenoze-brand-color-wizenoze-darkgreen": true;
        "$wizenoze-brand-color-wizenoze-green": true;
        "$wizenoze-brand-color-wizenoze-lightgreen": true;
        "$text-colors-color-text-dark": true;
        "$text-colors-color-text-gray": true;
        "$text-colors-color-text-light-gray": true;
        "$text-colors-color-text-blue": true;
        "$text-colors-color-text-white": true;
        "$text-colors-color-text-orange": true;
        "$icon-colors-orange-100": true;
        "$icon-colors-orange-60": true;
        "$icon-colors-orange-40": true;
        "$icon-colors-blue-100": true;
        "$icon-colors-blue-60": true;
        "$icon-colors-blue-40": true;
        "$icon-colors-green-100": true;
        "$icon-colors-green-60": true;
        "$icon-colors-green-40": true;
        "$reading-levels-color-level-unknown": true;
        "$reading-levels-color-level-selected": true;
        "$reading-levels-color-level-1": true;
        "$reading-levels-color-level-2": true;
        "$reading-levels-color-level-3": true;
        "$reading-levels-color-level-4": true;
        "$reading-levels-color-level-5": true;
        "$message-colors-color-message-success": true;
        "$message-colors-color-message-danger": true;
        "$background-colors-color-background-light": true;
    }
}

declare module "@mui/material/CircularProgress" {
    interface CircularProgressPropsColorOverrides {
        "$text-colors-color-text-gray": true;
    }
}

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 414,
            md: 768,
            lg: 992,
            xl: 1281,
        },
    },
    typography: {
        fontFamily: [
            "system-ui",
            "Segoe UI",
            "Roboto",
            "Helvetica",
            "Arial",
            "sans-serif",
            "Apple Color Emoji",
            "Segoe UI Emoji",
            "Segoe UI Symbol",
        ].join(","),
        "text-size-xxLarge": {
            fontSize: "2rem",
            fontWeight: 500,
            lineHeight: "2.8125rem",
        },
        "text-size-xxLarge-regular": {
            fontSize: "2rem",
            fontWeight: 400,
            lineHeight: "2.8125rem",
        },
        "text-size-xxLarge-medium": {
            fontSize: "2rem",
            fontWeight: 500,
            lineHeight: "2.8125rem",
        },
        "text-size-xxLarge-bold": {
            fontSize: "2rem",
            fontWeight: 700,
            lineHeight: "2.8125rem",
        },
        "text-size-xLarge": {
            fontSize: "1.75rem",
            fontWeight: 500,
            lineHeight: "2.5rem",
        },
        "text-size-xLarge-regular": {
            fontSize: "1.75rem",
            fontWeight: 400,
            lineHeight: "2.5rem",
        },
        "text-size-xLarge-medium": {
            fontSize: "1.75rem",
            fontWeight: 500,
            lineHeight: "2.5rem",
        },
        "text-size-xLarge-bold": {
            fontSize: "1.75rem",
            fontWeight: 700,
            lineHeight: "2.5rem",
        },
        "text-size-Large": {
            fontSize: "1.5rem",
            fontWeight: 500,
            lineHeight: "2.1875rem",
        },
        "text-size-Large-regular": {
            fontSize: "1.5rem",
            fontWeight: 400,
            lineHeight: "2.1875rem",
        },
        "text-size-Large-medium": {
            fontSize: "1.5rem",
            fontWeight: 500,
            lineHeight: "2.1875rem",
        },
        "text-size-Large-bold": {
            fontSize: "1.5rem",
            fontWeight: 700,
            lineHeight: "2.1875rem",
        },
        "text-size-xMedium": {
            fontSize: "1.25rem",
            fontWeight: 500,
            lineHeight: "1.875rem",
        },
        "text-size-xMedium-regular": {
            fontSize: "1.25rem",
            fontWeight: 400,
            lineHeight: "1.875rem",
        },
        "text-size-xMedium-medium": {
            fontSize: "1.25rem",
            fontWeight: 500,
            lineHeight: "1.875rem",
        },
        "text-size-xMedium-bold": {
            fontSize: "1.25rem",
            fontWeight: 700,
            lineHeight: "1.875rem",
        },
        "text-size-Medium": {
            fontSize: "1.125rem",
            fontWeight: 500,
            lineHeight: "1.5625rem",
        },
        "text-size-Medium-regular": {
            fontSize: "1.125rem",
            fontWeight: 400,
            lineHeight: "1.5625rem",
        },
        "text-size-Medium-medium": {
            fontSize: "1.125rem",
            fontWeight: 500,
            lineHeight: "1.5625rem",
        },
        "text-size-Medium-bold": {
            fontSize: "1.125rem",
            fontWeight: 700,
            lineHeight: "1.5625rem",
        },
        "text-size-Base": {
            fontSize: "1rem",
            fontWeight: 400,
            lineHeight: "1.5625rem",
        },
        "text-size-Base-regular": {
            fontSize: "1rem",
            fontWeight: 400,
            lineHeight: "1.5625rem",
        },
        "text-size-Base-medium": {
            fontSize: "1rem",
            fontWeight: 500,
            lineHeight: "1.5625rem",
        },
        "text-size-Base-bold": {
            fontSize: "1rem",
            fontWeight: 700,
            lineHeight: "1.5625rem",
        },
        "text-size-Small": {
            fontSize: "0.875rem",
            fontWeight: 400,
            lineHeight: "1.25rem",
        },
        "text-size-Small-regular": {
            fontSize: "0.875rem",
            fontWeight: 400,
            lineHeight: "1.25rem",
        },
        "text-size-Small-medium": {
            fontSize: "0.875rem",
            fontWeight: 500,
            lineHeight: "1.25rem",
        },
        "text-size-Small-bold": {
            fontSize: "0.875rem",
            fontWeight: 700,
            lineHeight: "1.25rem",
        },
        "text-size-xSmall": {
            fontSize: "0.75rem",
            fontWeight: 400,
            lineHeight: "1.25rem",
        },
        "text-size-xSmall-regular": {
            fontSize: "0.75rem",
            fontWeight: 400,
            lineHeight: "1.25rem",
        },
        "text-size-xSmall-medium": {
            fontSize: "0.75rem",
            fontWeight: 500,
            lineHeight: "1.25rem",
        },
        "text-size-xSmall-bold": {
            fontSize: "0.75rem",
            fontWeight: 700,
            lineHeight: "1.25rem",
        },
        "button-text-size-xSmall-bold": {
            fontSize: "0.75rem",
            fontWeight: 700,
            lineHeight: "0.75rem",
        },
        "alert-text-size-xSmall": {
            fontSize: "0.625rem",
            fontWeight: 700,
            lineHeight: "0.875rem",
        },
        "text-size-xxSmall": {
            fontSize: "0.625rem",
            fontWeight: 400,
            lineHeight: "1.25rem",
        },
        "text-size-xxSmall-regular": {
            fontSize: "0.625rem",
            fontWeight: 400,
            lineHeight: "1.25rem",
        },
        "text-size-xxSmall-medium": {
            fontSize: "0.625rem",
            fontWeight: 500,
            lineHeight: "1.25rem",
        },
        "text-size-xxSmall-bold": {
            fontSize: "0.625rem",
            fontWeight: 700,
            lineHeight: "1.25rem",
        },
    },
    palette: {
        primary: { main: "#2b7ff2" },
        error: { main: "#e30613" },
        "$wizenoze-brand-color-wizenoze-darkgreen": { main: "#03392a" },
        "$wizenoze-brand-color-wizenoze-green": {
            main: "#69bfac",
            contrastText: "#fff",
        },
        "$wizenoze-brand-color-wizenoze-lightgreen": { main: "#cbe6dd" },
        "$text-colors-color-text-dark": { main: "#343a40" },
        "$text-colors-color-text-gray": { main: "#6c757d" },
        "$text-colors-color-text-light-gray": { main: "#adb5bd" },
        "$text-colors-color-text-blue": { main: "#2b7ff2" },
        "$text-colors-color-text-white": {
            main: "#fff",
            contrastText: "#000",
        },
        "$text-colors-color-text-orange": { main: "#f29103" },
        "$border-colors-color-border-primary": { main: "#cbcbcc" },
        "$border-colors-color-border-light": { main: "#e8e7e9" },
        "$border-colors-color-border-blue": { main: "#2b7ff2" },
        "$border-colors-color-border-white": { main: "#fff" },
        "$icon-colors-orange-100": { main: "#f29103", contrastText: "#fff" },
        "$icon-colors-orange-60": { main: "#f7bf6d", contrastText: "#fff" },
        "$icon-colors-orange-40": { main: "#fbdeb3", contrastText: "#fff" },
        "$icon-colors-blue-100": { main: "#2b7ff2", contrastText: "#fff" },
        "$icon-colors-blue-60": { main: "#84b5f7", contrastText: "#fff" },
        "$icon-colors-blue-40": { main: "#bfd9fb", contrastText: "#fff" },
        "$icon-colors-green-100": { main: "#03392a", contrastText: "#fff" },
        "$icon-colors-green-60": { main: "#819c94", contrastText: "#fff" },
        "$icon-colors-green-40": { main: "#b3c4bf", contrastText: "#fff" },
        "$reading-levels-color-level-unknown": {
            main: "#c0c0c0",
            contrastText: "#6c757d",
        },
        "$reading-levels-color-level-selected": { main: "#6c757d" },
        "$reading-levels-color-level-1": {
            main: "#ffcf00",
            contrastText: "#fff",
        },
        "$reading-levels-color-level-2": {
            main: "#ff7119",
            contrastText: "#fff",
        },
        "$reading-levels-color-level-3": {
            main: "#5fc3ad",
            contrastText: "#fff",
        },
        "$reading-levels-color-level-4": {
            main: "#2b7ff2",
            contrastText: "#fff",
        },
        "$reading-levels-color-level-5": {
            main: "#161c1f",
            contrastText: "#fff",
        },
        "$message-colors-color-message-info": {
            main: "#6c757d",
            contrastText: "#fff",
        },
        "$message-colors-color-message-warning": {
            main: "#ff7119",
            contrastText: "#fff",
        },
        "$message-colors-color-message-danger": {
            main: "#e30613",
            contrastText: "#fff",
        },
        "$message-colors-color-message-success": {
            main: "#5fc3ad",
            contrastText: "#fff",
        },
        "$background-colors-color-background-light": { main: "#f8f9fa" },
        "$background-colors-color-background-light-blue": { main: "#F0F6FF" },
    },
    components: {
        MuiAutocomplete: {
            styleOverrides: {
                popper: {
                    zIndex: 35001,
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    boxShadow: "0px 20px 30px 0px rgba(52, 58, 64, 0.60)",
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    borderBottom: "none",
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    borderRadius: "9px",
                    borderTop: "#E8E7E9",
                    background: "white",
                    borderColor: "#5FC3AD",
                    borderWidth: "3px",
                    borderStyle: "solid",
                    color: "#343A40",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "16px",
                    // overflow: "hidden",
                    "&:before": {
                        marginTop: "-8px",
                    },
                },
                popper: {
                    //     '&[x-placement*="bottom"] $arrow': {
                    //         marginTop: "-17px",
                    //     },
                    //     '&[x-placement*="top"] $arrow': {
                    //         marginBottom: "-0.71em",
                    //     },
                    //     '&[x-placement*="right"] $arrow': {
                    //         marginLeft: "-0.71em",
                    //     },
                    //     '&[x-placement*="left"] $arrow': {
                    //         marginRight: "-0.71em",
                    //     },
                    '&[data-popper-placement*="bottom"]': {
                        // styles when placement is bottom
                        ".MuiTooltip-arrow": {
                            // styles for the tooltip arrow element
                            marginTop: "-17px",
                        },
                    },
                    '&[data-popper-placement*="top"]': {
                        // styles when placement is bottom
                        ".MuiTooltip-arrow": {
                            // styles for the tooltip arrow element
                            marginBottom: "-17px",
                        },
                    },
                    // '&[data-popper-placement*="right"]': {
                    //     // styles when placement is bottom
                    //     ".MuiTooltip-arrow": {
                    //         // styles for the tooltip arrow element
                    //         marginLeft: "-17px",
                    //     },
                    // },
                    // '&[data-popper-placement*="left"]': {
                    //     // styles when placement is bottom
                    //     ".MuiTooltip-arrow": {
                    //         // styles for the tooltip arrow element
                    //         marginRight: "-17px",
                    //     },
                    // },
                },
                arrow: {
                    "&:before": {
                        background: "white",
                        border: "3px solid #5FC3AD",
                        borderRadius: "4px",
                    },
                    width: "24px",
                    height: "17px",
                },
            },
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    padding: "10px",
                    minWidth: "unset",
                    fontWeight: "700",
                    lineHeight: "25px",
                    textTransform: "none",
                    textWrap: "nowrap",
                    borderRadius: "5px",
                },
            },
            variants: [
                {
                    props: { variant: "ai-button" },
                    style: {
                        padding: "10px 20px",
                        background:
                            "linear-gradient(180deg, #BA29B5 0%, #9B1DE8 100%)",
                    },
                },
                {
                    props: { variant: "ai-disabled-button" },
                    style: {
                        padding: "10px 20px",
                        background: "#ADB5BD", // background-colors-color-background-light-gray
                        border: "1px solid #6C757D", // border-colors-color-border-dark
                    },
                },
            ],
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    height: "30px",
                    alignSelf: "center",
                    borderColor: "#cbcbcc",
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    display: "flex",
                    height: "20px",
                    padding: "5px 8px",
                    alignItems: "center",
                    gap: "5px",
                    fontSize: "0.625rem",
                    fontWeight: "500",
                    lineHeight: "1.25rem",
                    borderRadius: "15px",
                    justifyContent: "space-between",
                    border: "1px solid #819c94",
                },
                icon: {
                    color: "#819c94",
                    opacity: 0.6,
                    margin: 0,
                },
                deleteIcon: {
                    color: "#fff",
                    opacity: 0.6,
                    margin: 0,
                },
                label: {
                    padding: "0px",
                },
                filled: {
                    background: "#819c94",
                    color: "#fff",
                },
                outlined: {
                    backgroundColor: "#fff",
                    color: "#819c94",
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                // Override Alert severity styles here
                root: {
                    justifyContent: "space-between",
                    padding: 10,
                    "& .MuiAlert-icon": {
                        fontSize: 20,
                        color: "#ffffff",
                        padding: 0,
                    },
                    "& .MuiAlert-message": {
                        flexGrow: 1,
                        padding: 0,
                        overflow: "hidden",
                    },
                    "& .MuiAlert-action": {
                        position: "absolute",
                        right: 10,
                        top: 10,
                        alignSelf: "flex-start",
                        padding: 0,
                        margin: 0,
                        "& .MuiSvgIcon-root": {
                            height: 12,
                            width: 12,
                            opacity: 0.6,
                            fontWeight: 900,
                        },
                        "& .MuiIconButton-root": {
                            padding: 0,
                            fontWeight: 900,
                        },
                    },
                    color: "#ffffff",
                    background: "inherit",
                    height: "100%",
                    width: "100%",
                    borderRadius: "4px",
                    minWidth: "266px",
                    minHeight: "36px",
                },
                standardSuccess: ({ theme }) => ({
                    backgroundColor:
                        theme.palette["$message-colors-color-message-success"]
                            .main,
                    border: "1px solid #619388",
                }),
                standardInfo: ({ theme }) => ({
                    backgroundColor: "#A7ACB1",
                    border: `1px solid ${theme.palette["$message-colors-color-message-info"].main}`,
                }),
                standardWarning: ({ theme }) => ({
                    backgroundColor: "#FFAA75",
                    border: `1px solid ${theme.palette["$message-colors-color-message-warning"].main}`,
                }),
                standardError: ({ theme }) => ({
                    backgroundColor: "#EE6A71",
                    border: `1px solid ${theme.palette["$message-colors-color-message-danger"].main}`,
                }),
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    background: "#FFF",
                    boxShadow: "0px 5px 20px 0px rgba(108, 117, 125, 0.20)",
                },
                indicator: {
                    height: "4px",
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    maxWidth: "300px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                    textTransform: "none",
                    /* $text-size-Base-bold */
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "25px",
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: "5px",
                },
            },
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    textDecoration: "none",
                },
            },
        },
    },
});

export default theme;
