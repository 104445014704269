@use "../../../../../../styles/variables.module";

.languageContainer {
    height: 30px;
    padding: 4px;
    display: flex;
    align-items: center;
    gap: 4px;
    border-radius: 30px;
    border: 1px solid #2371dd;
    background: variables.$background-colors-color-background-blue;
}

.languageButton {
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
}

.languageButton[data-selected="true"] {
    background: variables.$background-colors-color-background-white;
}

.languageButton > span {
    color: variables.$text-colors-color-text-white;
}

.languageButton[data-selected="true"] > span {
    color: variables.$text-colors-color-text-blue;
}
