import { AppConfig, useAppContext } from "contexts/AppContext";
import { translations } from "./translations";

export type TranslatableText = (typeof translations)[number]["en"];

const _getTranslation = (
    lang: AppConfig["language"],
    key: TranslatableText,
) => {
    const translationFound = translations.find((t) => t.en === key);
    // if (!translationFound) console.warn("Translation not found: ", key);
    return translationFound ? translationFound[lang] : key;
};

export const translate = (
    language: AppConfig["language"],
    text: TranslatableText,
) => {
    if (!language || language === "en") return text;
    return _getTranslation(language, text);
};
const Translate = ({ text }: { text: TranslatableText }) => {
    const { config } = useAppContext();
    if (!config.language || config.language === "en") return <>{text}</>;
    return <>{_getTranslation(config.language, text)}</>;
};

export default Translate;
