import { Dispatch, SetStateAction } from "react";

import { listContainer } from "./SelectableList.module.scss";

import ListItem from "./ListItem";

type Item = {
    id: number;
    name: string;
};
interface Props {
    list: Array<Item>;
    selectedId: Item["id"] | undefined;
    setSelectedId:
        | Dispatch<SetStateAction<Item["id"] | undefined>>
        | ((arg0: Item["id"] | undefined) => void);
}

const SelectableList = ({ list, selectedId, setSelectedId }: Props) => (
    <ul className={listContainer}>
        {list?.map(({ name, id }) => (
            <ListItem
                key={id}
                name={name}
                id={id}
                selectAction={setSelectedId}
                isSelected={id === selectedId}
            />
        ))}
    </ul>
);

export default SelectableList;
