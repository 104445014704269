import { useNavigate } from "react-router-dom";
import { Chip, Typography } from "@mui/material";

import * as styles from "./MyCurriculumPanel.module.scss";
import useCurriculumQuery from "hooks/api/useCurriculumQuery";
import useGradesQuery from "hooks/api/useGradeQuery";
import { useUserCurrentLayerQuery } from "hooks/api/useUserSettingsQuery";
import { tracker } from "utils/analytics";

import CurriculumLogo from "components/CurriculumLogo/CurriculumLogo";
import Panel from "components/Panel/Panel";

const MyCurriculumPanel = () => {
    const navigate = useNavigate();
    const { data: userCurrentLayer } = useUserCurrentLayerQuery();
    const { data: curriculumData } = useCurriculumQuery();
    const { data: gradesData } = useGradesQuery();
    const gradeId = userCurrentLayer?.gradeId;
    const selectedGrade = gradeId
        ? gradesData?.find((el) => el.id === parseInt(gradeId))
        : undefined;
    const clickHandler = () => {
        tracker("My curriculum panel button clicked");
        selectedGrade
            ? navigate(
                  `/curriculum/${curriculumData?.id}/grade/${selectedGrade?.id}`
              )
            : navigate(`/curriculum/${curriculumData?.id}`);
    };
    return (
        <Panel
            title={"My curriculum"}
            footer={{
                text: "View curriculum",
                clickHandler: clickHandler,
            }}
            styleOverrides={{ height: "100%" }}
        >
            <div className={styles.container} onClick={clickHandler}>
                <CurriculumLogo
                    name={curriculumData?.name}
                    logoUrl={curriculumData?.logoUrl}
                />
                <div className={styles.curiculumAndGrade}>
                    <Typography
                        variant="text-size-Medium"
                        color="$text-colors-color-text-white.main"
                        className={styles.curriculum}
                    >
                        {curriculumData?.name}
                    </Typography>
                    {selectedGrade && (
                        <Chip
                            variant="outlined"
                            label={selectedGrade?.name}
                            style={{ width: "fit-content" }}
                        />
                    )}
                </div>
            </div>
        </Panel>
    );
};

export default MyCurriculumPanel;
