import {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useContext,
    useState,
} from "react";

import MessageModal, {
    MessageModalProps,
} from "components/MessageModal/MessageModal";
import AlertMessage, {
    AlertMessageProps,
} from "components/AlertMessage/AlertMessage";

export type MessagingContextType = {
    messageModal?: MessageModalProps;
    alertMessage?: AlertMessageProps;
    openMessageModal: Dispatch<SetStateAction<MessageModalProps>>;
    openAlertMessage: Dispatch<SetStateAction<AlertMessageProps>>;
    resetMessageModal: () => void;
    resetAlertMessage: () => void;
};

export const messageModalDefaults: MessageModalProps = {
    isModalVisible: false,
    title: undefined,
    body: undefined,
    type: "Save",
    primaryActionHandler: () => false,
};

export const alertMessageDefaults: AlertMessageProps = {
    type: "success",
    message: undefined,
    open: false,
};

const MessagingContext = createContext<MessagingContextType>({
    messageModal: undefined,
    alertMessage: undefined,
    openMessageModal: () => undefined,
    openAlertMessage: () => undefined,
    resetMessageModal: () => undefined,
    resetAlertMessage: () => undefined,
});

export const useMessagingContext = () => {
    const context = useContext(MessagingContext);
    if (!context) {
        throw new Error(
            "useMessagingContext must be used within a MessagingContextProvider"
        );
    }
    return context;
};

export const MessagingContextProvider = ({
    initialMessageModal,
    initialAlertMessage,
    children,
}: {
    initialMessageModal?: MessageModalProps;
    initialAlertMessage?: AlertMessageProps;
    children: ReactNode;
}) => {
    const [messageModal, setMessageModal] = useState<MessageModalProps>(
        initialMessageModal ?? messageModalDefaults
    );
    const [alertMessage, setAlertMessage] = useState<AlertMessageProps>(
        initialAlertMessage ?? alertMessageDefaults
    );
    const resetMessageModal = () =>
        setMessageModal({ ...messageModal, isModalVisible: false });
    const resetAlertMessage = () =>
        setAlertMessage({ ...alertMessage, open: false });

    const contextValue = {
        messageModal,
        alertMessage,
        openMessageModal: setMessageModal,
        openAlertMessage: setAlertMessage,
        resetMessageModal,
        resetAlertMessage,
    };

    return (
        <MessagingContext.Provider value={contextValue}>
            {children}
            <MessageModal {...messageModal} />
            <AlertMessage {...alertMessage} />
        </MessagingContext.Provider>
    );
};

export default MessagingContext;
