import {
    DraggableAttributes,
    DraggableSyntheticListeners,
} from "@dnd-kit/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";

import * as styles from "./ResultCard.module.scss";

interface Props {
    draggable: {
        attributes: DraggableAttributes;
        listeners: DraggableSyntheticListeners;
    };
}

const DragHandle = ({ draggable }: Props) => {
    return (
        <div
            className={styles.dragHandle}
            {...draggable.attributes}
            {...draggable.listeners}
        >
            <FontAwesomeIcon icon={faEllipsisVertical} color="white" />
            <FontAwesomeIcon icon={faEllipsisVertical} color="white" />
        </div>
    );
};

export default DragHandle;
