import { Theme, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import { container } from "./QueryResults.module.scss";
import { useAppContext } from "contexts/AppContext";
import { useNodeLayersQuery } from "hooks/api/useNodeNextQuery";
import useQueryResultsQuery from "hooks/api/useQueryResultsQuery";
import useResourcesQuery from "hooks/api/useResourcesQuery";
import useCurriculumParams from "hooks/useCurriculumParams";

import QueryHeader from "./QueryHeader/QueryResultsHeader";
import ResultsGrid from "./ResultsGrid/ResultsGrid";
import Loading from "components/Loading/Loading";
import QueryResultsNavBar from "components/NavBars/QueryResultsNavBar";

const QueryResults = () => {
    const { topicId } = useCurriculumParams();
    const isMobileScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("md"),
    );
    const { config, embedDetails } = useAppContext();

    const { data: queryData, status: queryStatus } = useQueryResultsQuery(
        embedDetails?.queryId
            ? embedDetails?.queryId
            : embedDetails?.metadata?.queryId,
        {
            numberOfResults: config.numberOfResults,
            pinnedOnly: config.pinnedOnly,
            embeddable: config.embeddable,
        },
    );
    const { data: resourcesData, status: resourcesStatus } = useResourcesQuery(
        embedDetails?.resourceRequestType,
        embedDetails?.ids,
    );
    const queryResults =
        queryStatus === "success" || resourcesStatus === "success"
            ? embedDetails?.ids
                ? resourcesData
                : queryData?.results
            : undefined;
    const { data: currentTopic } = useNodeLayersQuery(
        queryData?.query?.parentId,
        "QUERY",
    );

    return (
        <>
            {topicId && <QueryResultsNavBar />}
            <div className={container}>
                <QueryHeader
                    query={queryData}
                    currentTopic={currentTopic}
                    nodeData={currentTopic}
                />
                <Grid
                    container
                    spacing={2}
                    style={{
                        padding: isMobileScreen ? "20px 10px" : "20px 40px",
                        marginBottom: 10,
                    }}
                >
                    <Grid xs={12}>
                        {queryResults ? (
                            <ResultsGrid
                                queryResult={queryResults}
                                totalData={queryData}
                            />
                        ) : (
                            <Loading />
                        )}
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default QueryResults;
