@forward "./zindexes.module";

$font-family: system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

// Wizenoze brand colors
$wizenoze-brand-color-wizenoze-darkgreen: #03392a;
$wizenoze-brand-color-wizenoze-green: #69bfac;
$wizenoze-brand-color-wizenoze-lightgreen: #cbe6dd;

// Backgrounds colors
$background-colors-color-background-light: #f8f9fa;
$background-colors-color-background-light-off: #f3f3f6;
$background-colors-color-background-white: #fff;
$background-colors-color-background-gray: #6c757d;
$background-colors-color-background-light-gray: #adb5bd;
$background-colors-color-background-light-blue: #f0f6ff;
$background-colors-color-background-blue: #2b7ff2;
$background-colors-color-background-overlay: rgba(52, 58, 64, 0.6);

// Text colors
$text-colors-color-text-dark: #343a40;
$text-colors-color-text-gray: #6c757d;
$text-colors-color-text-light-gray: #adb5bd;
$text-colors-color-text-blue: #2b7ff2;
$text-colors-color-text-white: #fff;
$text-colors-color-text-orange: #f29103;

// Border colors
$border-colors-color-border-primary: #cbcbcc;
$border-colors-color-border-light: #e8e7e9;
$border-colors-color-border-blue: #2b7ff2;
$border-colors-color-border-white: #fff;

// Reading levels colors
$reading-levels-color-level-unknown: #c0c0c0;
$reading-levels-color-level-selected: #6c757d;
$reading-levels-color-level-1: #ffcf00;
$reading-levels-color-level-2: #ff7119;
$reading-levels-color-level-3: #5fc3ad;
$reading-levels-color-level-4: #2b7ff2;
$reading-levels-color-level-5: #161c1f;

// Message colors
$message-colors-color-message-info: #6c757d;
$message-colors-color-message-warning: #ff7119;
$message-colors-color-message-danger: #e30613;
$message-colors-color-message-success: #5fc3ad;

// Icon colors
$icon-colors-orange-100: #f29103;
$icon-colors-orange-60: #f7bf6d;
$icon-colors-orange-40: #fbdeb3;
$icon-colors-blue-100: #2b7ff2;
$icon-colors-blue-60: #84b5f7;
$icon-colors-blue-40: #bfd9fb;
$icon-colors-green-100: #03392a;
$icon-colors-green-60: #819c94;
$icon-colors-green-40: #b3c4bf;

// Subject colors
$subject-colors-color-subject-biology: #d6ead2;
// $subject-colors-color-subject-physics: #d3edfc;
// $subject-colors-color-subject-chemistry: #f1d4b2;
// $subject-colors-color-subject-mathematics: #d1d1d1;
// $subject-colors-color-subject-computing: #ddeeea;
// $subject-colors-color-subject-informatics: #ddeeea;
// $subject-colors-color-subject-science: #f0edf5;
// $subject-colors-color-subject-history: #d4cfe3;
// $subject-colors-color-subject-geography: #eff2e6;
// $subject-colors-color-subject-music: #f1edef;
// $subject-colors-color-subject-religion: #d8ded9;
// $subject-colors-color-subject-geometry: #d4f2f1;
// $subject-colors-color-subject-design-and-technology: #e2e2ef;
// $subject-colors-color-subject-earth-and-space: #c9d1db;
// $subject-colors-color-subject-physical-science: #eef9fe;
// $subject-colors-color-subject-earth-space-science: #c9d1db;
// $subject-colors-color-subject-default: #dededf;

//Shadows
$card-box-shadow: 0px 5px 20px 0px rgba(108, 117, 125, 0.2);
$box-shadow-dropdown: 0px 10px 10px 0px rgba(108, 117, 125, 0.5);

@function get-bp($bp) {
    @if $bp {
        $bp: map-get($breakpoints, $bp);
    } @else {
        @error "Parameter #{$bp} is unknown or empty.";
    }

    @return $bp;
}

$breakpoints: (
    xs: 0px,
    sm: 413px,
    md: 767px,
    lg: 991px,
    xl: 1280px,
    max: 1440px,
);

// variables settings with function call
// AFTER FUNCTION DECLARATION
$bp-xs: get-bp("xs");
$bp-sm: get-bp("sm");
$bp-md: get-bp("md");
$bp-lg: get-bp("lg");
$bp-xl: get-bp("xl");
$bp-max: get-bp("max");

:export {
    // Wizenoze brand colors
    wizenoze-brand-color-wizenoze-darkgreen: $wizenoze-brand-color-wizenoze-darkgreen;
    wizenoze-brand-color-wizenoze-green: $wizenoze-brand-color-wizenoze-green;
    wizenoze-brand-color-wizenoze-lightgreen: $wizenoze-brand-color-wizenoze-lightgreen;

    // Backgrounds colors
    background-colors-color-background-light: $background-colors-color-background-light;
    background-colors-color-background-light-off: $background-colors-color-background-light-off;
    background-colors-color-background-white: $background-colors-color-background-white;
    background-colors-color-background-gray: $background-colors-color-background-gray;
    background-colors-color-background-light-gray: $background-colors-color-background-light-gray;
    background-colors-color-background-light-blue: $background-colors-color-background-light-blue;
    background-colors-color-background-blue: $background-colors-color-background-blue;
    background-colors-color-background-overlay: $background-colors-color-background-overlay;

    // Text colors
    text-colors-color-text-dark: $text-colors-color-text-dark;
    text-colors-color-text-gray: $text-colors-color-text-gray;
    text-colors-color-text-light-gray: $text-colors-color-text-light-gray;
    text-colors-color-text-blue: $text-colors-color-text-blue;
    text-colors-color-text-white: $text-colors-color-text-white;
    text-colors-color-text-orange: $text-colors-color-text-orange;

    // Border colors
    border-colors-color-border-primary: $border-colors-color-border-primary;
    border-colors-color-border-light: $border-colors-color-border-light;
    border-colors-color-border-blue: $border-colors-color-border-blue;
    border-colors-color-border-white: $border-colors-color-border-white;

    // Reading levels colors
    reading-levels-color-level-unkown: $reading-levels-color-level-unknown;
    reading-levels-color-level-selected: $reading-levels-color-level-selected;
    reading-levels-color-level-1: $reading-levels-color-level-1;
    reading-levels-color-level-2: $reading-levels-color-level-2;
    reading-levels-color-level-3: $reading-levels-color-level-3;
    reading-levels-color-level-4: $reading-levels-color-level-4;
    reading-levels-color-level-5: $reading-levels-color-level-5;

    // Message colors
    message-colors-color-message-info: $message-colors-color-message-info;
    message-colors-color-message-warning: $message-colors-color-message-warning;
    message-colors-color-message-danger: $message-colors-color-message-danger;
    message-colors-color-message-success: $message-colors-color-message-success;

    // Icon colors
    icon-colors-orange-100: $icon-colors-orange-100;
    icon-colors-orange-60: $icon-colors-orange-60;
    icon-colors-orange-40: $icon-colors-orange-40;
    icon-colors-blue-100: $icon-colors-blue-100;
    icon-colors-blue-60: $icon-colors-blue-60;
    icon-colors-blue-40: $icon-colors-blue-40;
    icon-colors-green-100: $icon-colors-green-100;
    icon-colors-green-60: $icon-colors-green-60;
    icon-colors-green-40: $icon-colors-green-40;

    // Subject colors
    subject-colors-color-subject-biology: $subject-colors-color-subject-biology;

    // Card shadow
    card-box-shadow: $card-box-shadow;
    box-shadow-dropdown: $box-shadow-dropdown;

    // Breakpoints
    bp-xs: $bp-xs;
    bp-sm: $bp-sm;
    bp-md: $bp-md;
    bp-lg: $bp-lg;
    bp-xl: $bp-xl;
    bp-max: $bp-max;
}
