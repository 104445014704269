import { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Theme, useMediaQuery } from "@mui/material";

import * as styles from "./CurriculumNavigator.module.scss";
import { CurriculumContext } from "./state/CurriculumContext";
import { useResultModalContext } from "contexts/ResultModalContext";
import useCurriculumParams from "hooks/useCurriculumParams";
import useCurriculumQuery from "hooks/api/useCurriculumQuery";
import {
    isGradeAndUnitOnlyStructure,
    isGradeOnlyStructure,
    isUnitOnlyStructure,
} from "utils/newtools";

import CurriculumPanel from "./components/CurriculumPanel/CurriculumPanel";
import CurriculumNavBar from "components/NavBars/CurriculumNavBar";
import NodeView from "./components/NodeView/NodeView";
import PageHeader from "./components/PageHeader/PageHeader";
import QueryView from "./components/QueryView/QueryView";
import SubjectsView from "./components/SubjectsView/SubjectsView";

const CurriculumNavigator = () => {
    const { resetIsResultModalEnabled } = useResultModalContext();
    resetIsResultModalEnabled();
    const [selectedResultsArray, setSelectedResultsArray] = useState<string[]>(
        []
    );
    const [embedUrl, setEmbedUrl] = useState<string>("");
    const [linkUrl, setLinkUrl] = useState<string>("");
    const { curriculumId, subjectId, unitId, topicId, queryId } =
        useCurriculumParams();
    const { data: curriculumData } = useCurriculumQuery(curriculumId);
    const isDesktopScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.up("lg")
    );
    return (
        <CurriculumContext.Provider
            value={{
                selectedResultsArray,
                setSelectedResultsArray,
                embedUrl,
                setEmbedUrl,
                linkUrl,
                setLinkUrl,
            }}
        >
            <div className={styles.navigation}>
                <CurriculumNavBar />
            </div>
            <PageHeader curriculum={curriculumData} />
            <div className={styles.container}>
                <Grid container spacing={2} style={{ height: "100%" }}>
                    {isDesktopScreen && (
                        <Grid lg={3} xs={0}>
                            <CurriculumPanel />
                        </Grid>
                    )}
                    <Grid lg={9} xs={12}>
                        {queryId ? (
                            <QueryView />
                        ) : topicId ||
                          unitId ||
                          subjectId ||
                          isGradeOnlyStructure(curriculumData?.structure) ||
                          isUnitOnlyStructure(curriculumData?.structure) ||
                          isGradeAndUnitOnlyStructure(
                              curriculumData?.structure
                          ) ? (
                            <NodeView />
                        ) : (
                            <SubjectsView />
                        )}
                    </Grid>
                </Grid>
            </div>
        </CurriculumContext.Provider>
    );
};

export default CurriculumNavigator;
