import {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useContext,
    useState,
} from "react";

import ResultModal, {
    ResultModalProps,
} from "features/ResultLink/ResultModal/ResultModal";
import { useZendeskContext } from "contexts/ZendeskContext";
import { useAppContext } from "./AppContext";
import { isShareMode } from "utils/newtools";

type ResultModalContextType = {
    resultModal?: ResultModalProps;
    openResultModal: (results: ResultModalProps) => void;
    resetResultModal: () => void;
    isResultModalEnabled: boolean;
    setIsResultModalEnabled: Dispatch<SetStateAction<boolean>>;
    resetIsResultModalEnabled: () => void;
} | null;

const resultModalDefaults: ResultModalProps = {
    results: [],
    initialIndex: 0,
};

const ResultModalContext = createContext<ResultModalContextType>(null);

export const useResultModalContext = () => {
    const context = useContext(ResultModalContext);
    if (!context) {
        throw new Error(
            "useResultModalContext must be used within a ReactModalContextProvider",
        );
    }
    return context;
};

export const ResultModalContextProvider = ({
    initialResultModal,
    children,
}: {
    initialResultModal?: ResultModalProps;
    children: ReactNode;
}) => {
    const params = new URLSearchParams(document.location.search);
    const shareResultModalEnabled =
        isShareMode && Boolean(params.get("resultModal") === "true");

    const {
        config: { resultModal: configResultModalEnabled },
    } = useAppContext();

    const initialResultModalEnabled =
        shareResultModalEnabled || configResultModalEnabled;

    const [isResultModalEnabled, setIsResultModalEnabled] = useState(
        initialResultModalEnabled,
    );
    const resetIsResultModalEnabled = () => {
        setIsResultModalEnabled(initialResultModalEnabled);
    };

    const [resultModalData, setResultModalData] = useState<ResultModalProps>(
        initialResultModal ?? resultModalDefaults,
    );
    const { showZendesk, hideZendesk } = useZendeskContext();
    const resetResultModal = () => {
        showZendesk();
        setResultModalData(resultModalDefaults);
    };
    const contextValue = {
        resultModal: resultModalData,
        openResultModal: (results: ResultModalProps) => {
            hideZendesk();
            setResultModalData(results);
        },
        isResultModalEnabled,
        setIsResultModalEnabled,
        resetResultModal,
        resetIsResultModalEnabled,
    };
    return (
        <ResultModalContext.Provider value={contextValue}>
            {children}
            {resultModalData.results.length !== 0 && (
                <ResultModal {...resultModalData} />
            )}
        </ResultModalContext.Provider>
    );
};

export default ResultModalContext;
