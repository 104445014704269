import Grid from "@mui/material/Unstable_Grid2";

import { useAppContext } from "contexts/AppContext";
import useCurriculumParams from "hooks/useCurriculumParams";
import { useCurriculumLayersQuery } from "hooks/api/useCurriculumQuery";
import { Subject } from "types";
import { isEmbedLibrary } from "types/guards";

import SubjectCard from "../SubjectCard/SubjectCard";
import Loading from "components/Loading/Loading";

const SubjectsView = () => {
    const { embedDetails } = useAppContext();
    const isEmbedLibraryResult = isEmbedLibrary(embedDetails);
    const curriculumParams = useCurriculumParams();
    const curriculumId =
        isEmbedLibraryResult && typeof embedDetails?.curriculumId === "number"
            ? embedDetails.curriculumId
            : curriculumParams?.curriculumId;
    const gradeId = isEmbedLibraryResult
        ? embedDetails?.gradeId
        : curriculumParams?.gradeId;

    const { data: layerData, status: layerStatus } = useCurriculumLayersQuery(
        curriculumId,
        "ALL",
    );

    const sourceData = gradeId
        ? layerData?.find((item) => item.id.toString() === gradeId.toString())
              ?.children
        : layerData;
    return (
        <>
            {layerStatus !== "success" ? (
                <Loading isCentered={true} />
            ) : (
                <Grid container spacing={"20px"}>
                    {sourceData?.map((subject: Subject) => (
                        <Grid md={3} xs={6} key={subject.id}>
                            <SubjectCard item={subject} />
                        </Grid>
                    ))}
                </Grid>
            )}
        </>
    );
};

export default SubjectsView;
