import { useContext, useLayoutEffect, useRef, useState } from "react";
import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chip, Typography, useTheme } from "@mui/material";

import { Tag } from "types";
import { tracker } from "utils/analytics";

import Translate from "components/Translate/Translate";
import { CollectionContext } from "features/CollectionEditor/state/CollectionContext";

const TagChip = ({
    tag,
    deleteHandler,
}: {
    tag: Tag;
    deleteHandler: (arg0: string) => void;
}) => (
    <Chip
        label={tag.value}
        deleteIcon={<FontAwesomeIcon icon={faXmark} style={{ fontSize: 8 }} />}
        onDelete={() => deleteHandler(tag.value)}
    />
);

const EditChip = ({
    saveHandler,
    cancelHandler,
}: {
    saveHandler: (arg0: string) => void;
    cancelHandler: () => void;
}) => {
    const [value, setValue] = useState("");
    const editTagRef = useRef<HTMLInputElement>(null);

    useLayoutEffect(() => {
        if (editTagRef.current) {
            editTagRef.current.select();
        }
    }, [editTagRef]);
    const theme = useTheme();
    return (
        <Chip
            label={
                <input
                    ref={editTagRef}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    onBlur={() => saveHandler(value)}
                    onKeyDown={(e) => e.key === "Enter" && saveHandler(value)}
                    style={{
                        ...theme.typography["text-size-xxSmall-medium"],
                        background: "transparent",
                        color: "white",
                        outline: "none",
                        width: 50,
                        border: 0,
                        appearance: "none",
                    }}
                />
            }
            deleteIcon={
                <FontAwesomeIcon icon={faXmark} style={{ fontSize: 8 }} />
            }
            onDelete={cancelHandler}
        />
    );
};

const AddChip = ({ addHandler }: { addHandler: () => void }) => {
    return (
        <Chip
            label={<Translate text="Add" />}
            variant="outlined"
            icon={<FontAwesomeIcon icon={faPlus} style={{ fontSize: 8 }} />}
            onClick={addHandler}
        />
    );
};
const TagList = () => {
    const { collection, addTag, removeTag } = useContext(CollectionContext);
    const [isCreatingTag, setIsCreatingTag] = useState(false);
    const createTagHandler = () => {
        tracker("Create New Tag");
        setIsCreatingTag(true);
    };
    const cancelCreateTagHandler = () => {
        tracker("Canceled Create New Tag");
        setIsCreatingTag(false);
    };
    const deleteTagHandler = (value: string) => {
        removeTag(value);
    };

    return (
        <div>
            <Typography
                variant="text-size-xSmall-regular"
                color="$text-colors-color-text-gray.main"
            >
                <Translate text="Use labels (tags) to categorise and organise your collections." />
            </Typography>
            <div
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginTop: 10,
                    gap: "10px",
                }}
            >
                {collection.tags.map((tag) => (
                    <TagChip
                        key={tag.id + tag.value}
                        tag={tag}
                        deleteHandler={deleteTagHandler}
                    />
                ))}
                {isCreatingTag && (
                    <EditChip
                        saveHandler={(value) => {
                            addTag(value);
                            setIsCreatingTag(false);
                        }}
                        cancelHandler={cancelCreateTagHandler}
                    />
                )}
                <AddChip addHandler={createTagHandler} />
            </div>
        </div>
    );
};
export default TagList;
