@use "../../../../../styles/variables.module";

.querySelector {
    display: flex;
    justify-self: flex-end;
    align-self: center;
    align-items: center;
}

.selectWrapper {
    /* width: 100%; */
    margin-left: 20px;
    margin-right: 20px;
}

.selectWrapper select {
    /* styling */
    border: none;
    background: variables.$background-colors-color-background-blue;
    border-radius: 45px;
    display: inline-block;
    font-size: 16px;
    line-height: 25px;
    color: white;
    padding: 0.5em 3.5em 0.5em 1em;
    overflow: hidden;

    -webkit-appearance: none;
    -moz-appearance: none;
}

.selectWrapper select.selectStyle {
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.50195 6.2168C4.76562 6.48047 5.20508 6.48047 5.46875 6.2168L9.45312 2.23242C9.74609 1.93945 9.74609 1.5 9.45312 1.23633L8.80859 0.5625C8.51562 0.298828 8.07617 0.298828 7.8125 0.5625L4.9707 3.4043L2.1582 0.5625C1.89453 0.298828 1.45508 0.298828 1.16211 0.5625L0.517578 1.23633C0.224609 1.5 0.224609 1.93945 0.517578 2.23242L4.50195 6.2168Z' fill='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat, repeat;
    background-position: right 1.3em top 50%, 0 0;
    background-size: 0.65em auto, 100%;
    outline: 0;
    /* width: 100%; */
    font-size: 16px;
    cursor: pointer;
}

.topicTitleStyle {
    display: block;
    font-size: 14px;
    line-height: 20px;
}

.queryTitleStyle {
    display: flex;
    justify-content: flex-start;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
}

@media (max-width: variables.$bp-md) {
    .topicTitleStyle {
        font-size: 12px;
    }
    .queryTitleStyle {
        justify-content: space-between;
        font-size: 16px;
        line-height: 20px;
    }
}
