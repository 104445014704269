import { createContext, ReactNode, useContext, useState } from "react";

import { axiosAPI } from "hooks/api/axiosAPI";
import useUserQuery from "hooks/api/useUserQuery";
import {
    useUserSettingsMutation,
    useUserSettingsQuery,
} from "hooks/api/useUserSettingsQuery";
import { EmbedType } from "types";
import { getAppMode } from "utils/newtools";
import { getEmbedDetails } from "utils/legacySupport";

export type Tours = Array<"WELCOME">;

export type AppConfig = {
    language: "en" | "nl";
    resultModal: boolean;
    numberOfResults: number;
    embeddable: boolean;
    pinnedOnly: boolean;
    showDrafts: boolean;
    completedTours: Tours;
};

export type AppContextType = {
    config: AppConfig; // Config is modifiable version of settings which works as the state of the app
    updateConfig: (key: keyof AppConfig, value: unknown) => void;
    updateAllConfig: (config: AppConfig) => void;
    embedDetails: EmbedType | undefined;
    libraryCurriculumId: number;
    setLibraryCurriculumId: (id: number) => void;
};

const configDefaults: AppConfig = {
    language: "en",
    resultModal: false,
    numberOfResults: 5,
    embeddable: false,
    pinnedOnly: true,
    showDrafts: false,
    completedTours: [],
};

const AppContext = createContext<AppContextType | null>(null);

export const useAppContext = () => {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error(
            "useAppContext must be used within an AppContextProvider",
        );
    }
    return context;
};

export function configFromURL(
    embedDetails: EmbedType | undefined,
    searchParams: URLSearchParams,
) {
    const paramsSource =
        embedDetails?.shareMode === "link"
            ? searchParams
            : new URLSearchParams(document.location.search);
    const urlNumOfResultsString = paramsSource.get("numberOfResults");
    const urlNumOfResults = urlNumOfResultsString
        ? parseFloat(urlNumOfResultsString)
        : 5;
    const urlLanguage = paramsSource.get("language") || "en";
    const urlResultModal = Boolean(paramsSource.get("resultModal") === "true");
    const urlPinnedOnly = Boolean(paramsSource.get("pinnedOnly") === "true");
    const urlEmbeddable = Boolean(paramsSource.get("embeddable") === "true");

    return {
        numberOfResults: urlNumOfResults,
        language: urlLanguage as AppConfig["language"],
        resultModal: urlResultModal,
        pinnedOnly: urlPinnedOnly,
        embeddable: urlEmbeddable,
        showDrafts: false,
        completedTours: [],
    };
}

const AppContextProvider = ({ children }: { children: ReactNode }) => {
    const isShareMode = getAppMode() === "share";
    const searchParams = new URLSearchParams(document.location.search);
    const langParam = searchParams.get("lang");
    const lang = langParam === "en" || langParam === "nl" ? langParam : "en";

    const { data: user } = useUserQuery();
    const { data: userSettings, status: userSettingsStatus } =
        useUserSettingsQuery(!user);

    const userSettingsMutation = useUserSettingsMutation();

    const embedDetails = getEmbedDetails();

    // Wont be getting API settings, so use url params
    const shareConfig = isShareMode
        ? configFromURL(embedDetails, searchParams)
        : userSettings;
    const [localConfig, setLocalConfig] = useState({});
    const updateConfig = async (
        key: keyof AppConfig,
        value: AppConfig[typeof key],
    ) => {
        if (isShareMode && value) {
            setLocalConfig({ ...localConfig, [key]: value });
        } else {
            const resetEmbeddable = // turning off resultmodal turns off embeddable
                key === "resultModal" && value === false
                    ? { embeddable: false }
                    : null;
            const newAppConfig = {
                ...userSettings,
                [key]: value,
                ...resetEmbeddable,
            } as AppConfig;

            await axiosAPI.initialise(newAppConfig, user);
            userSettingsMutation.mutate(newAppConfig);
        }
    };

    const updateAllConfig = async (newConfig: AppConfig) => {
        if (isShareMode) return; // maybe not needed
        const newAppConfig = { ...userSettings, ...newConfig };
        await axiosAPI.initialise(newAppConfig, user);
        userSettingsMutation.mutate(newAppConfig);
    };

    const finalConfig: AppConfig = {
        ...configDefaults,
        language: lang,
        ...shareConfig,
        ...(isShareMode ? localConfig : {}),
    };
    const [libraryCurriculumId, setLibraryCurriculumId] = useState<
        number | undefined
    >();

    const contextValue = {
        config: finalConfig,
        updateConfig,
        updateAllConfig,
        embedDetails,
        libraryCurriculumId,
        setLibraryCurriculumId,
    };

    if (isShareMode || userSettingsStatus === "success")
        axiosAPI.initialise(finalConfig, user);

    return (
        <AppContext.Provider value={contextValue}>
            {children}
        </AppContext.Provider>
    );
};

export default AppContextProvider;
