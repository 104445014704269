import {
    ClickAwayListener,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";

import variables from "styles/_variables.module.scss";
import { ReadingLevel } from "types";

import Translate, { TranslatableText } from "components/Translate/Translate";

const createRowData = (
    level: ReadingLevel,
    grade: TranslatableText,
    keyStage: TranslatableText,
    cefr: string
) => ({ level, grade, keyStage, cefr });

const rows = [
    createRowData("1", "Kindergarten - Grade 1", "Key stage 1", "A1"),
    createRowData("2", "Grade 2 - 3", "Key stage 2 low", "A2"),
    createRowData("3", "Grade 4 - 5", "Key stage 2 high", "B1"),
    createRowData("4", "Grade 6 - 8", "Key stage 3", "B2"),
    createRowData("5", "Grade 9 - 12", "Key stage 4/GCSE", "C1-C2"),
];
interface Props {
    closeHandler: () => void;
}

const ReadingLevelTooltip = ({ closeHandler }: Props) => {
    return (
        <ClickAwayListener onClickAway={closeHandler}>
            <div>
                <Typography
                    variant="text-size-xxSmall-bold"
                    color="$text-colors-color-text-dark.main"
                >
                    <Translate
                        text={"Filter search results by a reading level"}
                    />
                </Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>
                                    <Typography
                                        variant="text-size-xxSmall-regular"
                                        color="$text-colors-color-text-gray.main"
                                    >
                                        <Translate text="Grades (US)" />
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        variant="text-size-xxSmall-regular"
                                        color="$text-colors-color-text-gray.main"
                                    >
                                        <Translate text="Key stages (UK)" />
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        variant="text-size-xxSmall-regular"
                                        color="$text-colors-color-text-gray.main"
                                    >
                                        <Translate text="CEFR" />
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.level}
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell>
                                        <div
                                            style={{
                                                width: "15px",
                                                height: "15px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                backgroundColor:
                                                    variables[
                                                        `reading-levels-color-level-${row.level}`
                                                    ],
                                                borderRadius: "15px",
                                            }}
                                        >
                                            <Typography
                                                variant="text-size-xSmall-bold"
                                                color="$text-colors-color-text-white.main"
                                            >
                                                {row.level}
                                            </Typography>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            variant="text-size-xxSmall-regular"
                                            color="$text-colors-color-text-dark.main"
                                        >
                                            <Translate text={row.grade} />
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            variant="text-size-xxSmall-regular"
                                            color="$text-colors-color-text-dark.main"
                                        >
                                            <Translate text={row.keyStage} />
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            variant="text-size-xxSmall-regular"
                                            color="$text-colors-color-text-dark.main"
                                        >
                                            {row.cefr}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </ClickAwayListener>
    );
};

export default ReadingLevelTooltip;
