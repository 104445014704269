import { Link, Typography } from "@mui/material";

import AddResourcesForm from "./components/AddResourceForm";
import Translate from "components/Translate/Translate";

const AddResources = ({ hideForm }: { hideForm: () => void }) => {
    return (
        <div>
            <div style={{ marginBottom: 20 }}>
                <Typography
                    variant="text-size-Base"
                    color="$text-colors-color-text-dark.main"
                    style={{ lineHeight: "1rem" }}
                >
                    <Translate text="Add your resources to the collection." />
                </Typography>
            </div>
            <AddResourcesForm hideForm={hideForm} />
            <div style={{ marginTop: 10 }}>
                <Typography
                    variant="text-size-Small-bold"
                    color="$text-colors-color-text-dark.main"
                >
                    <Translate text="Disclaimer: User-uploaded content" />
                </Typography>
            </div>
            <div>
                <Typography
                    variant="text-size-Small"
                    color="$text-colors-color-text-dark.main"
                >
                    <Translate text="Wizenoze does not endorse, verify, or assume responsibility for the accuracy, legality, or appropriateness of user-uploaded content." />{" "}
                    <Link href="https://wizenoze.zendesk.com/hc/en-gb/articles/15871814444306">
                        <Translate text="Read more" />
                    </Link>
                </Typography>
            </div>
        </div>
    );
};

export default AddResources;
