@use "../../../../../styles/variables.module";

.noResultsContainer {
    font-style: normal;
}

.helperList {
    font-size: 15px;
    line-height: 25px;
    font-weight: 400;
    color: variables.$text-colors-color-text-dark;
    padding: 20px 0;
}

.helperList ul {
    margin: 0;
    padding-left: 25px;
}

.helperList span {
    color: variables.$text-colors-color-text-blue;
    text-decoration: underline;
    padding-left: 5px;
    cursor: pointer;
}

.imageWrapper {
    text-align: center;
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    color: variables.$text-colors-color-text-light-gray;
}

@media (min-width: 770px) {
    .helperList {
        font-size: 15px;
    }
}
