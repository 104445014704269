import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { axiosAPI } from "./axiosAPI";
import useCurriculumQuery from "./useCurriculumQuery";
import { useAppContext } from "contexts/AppContext";
import { ReadingLevel, Result, Subject } from "types";
import useCurriculumParams from "hooks/useCurriculumParams";

export const useOrganicSearchQuery = () => {
    const { curriculumId } = useCurriculumParams();
    const [searchParams] = useSearchParams();
    const searchTerm = searchParams.get("searchTerm");
    const queryParams = searchParams.get("queryParams");
    const { config } = useAppContext();
    const { embeddable, showDrafts } = config;

    const paramsObject = {
        q: searchTerm,
        size: 6,
        ...(queryParams ? JSON.parse(queryParams) : null),
        allPublicationStatus: showDrafts,
        embeddable: embeddable,
    };

    return useQuery<OrganicSearchResultsType, AxiosError>(
        ["organic-search", curriculumId, searchTerm, paramsObject], // keys need the params setting here too, otherwise it'll not update
        async () => {
            const { data } = await axiosAPI
                .getInstance()
                .get(`/curriculum/${curriculumId}/documents/search`, {
                    params: paramsObject,
                });
            return data;
        },
        { enabled: !!searchTerm, staleTime: 1000 * 60 * 60 * 12 },
    );
};

export const useOrganicSearchInfiniteQuery = (
    term: string,
    contentType: "all" | "pages" | "videos",
    readingLevel: ReadingLevel[],
    embeddable: boolean,
    showDrafts: boolean,
    subjects: Subject[],
) => {
    const { curriculumId } = useCurriculumParams();
    const fetchOrganicSearch = async ({ pageParam = 1 }) => {
        const { data } = await axiosAPI
            .getInstance()
            .get(
                `/curriculum/${curriculumId}/documents/search?startPage=` +
                    (pageParam ? pageParam.toString() : 1),
                {
                    params: {
                        q: term,
                        size: 6,
                        resultType: contentType,
                        readabilityLevel: readingLevel.join(","),
                        allPublicationStatus: showDrafts,
                        embeddable: embeddable,
                        curriculumSubjectsFilter: subjects.length
                            ? subjects.join(",")
                            : undefined,
                    },
                },
            );
        return data;
    };
    return useInfiniteQuery({
        queryKey: [
            "organic-search-infinite",
            term,
            contentType,
            readingLevel,
            embeddable,
            subjects.sort(),
        ],
        queryFn: fetchOrganicSearch,
        getNextPageParam: (lastPage, _pages) =>
            lastPage.pagination.startPage
                ? lastPage.pagination.startPage !=
                  lastPage.pagination.totalPages
                    ? lastPage.pagination.startPage + 1
                    : undefined
                : 1,
        enabled: !!term,
    });
};

export const useOrganicAutocompleteQuery = (
    curriculumId: string | undefined | null,
    searchTerm: string | undefined,
    delayPassed: boolean,
    paramsObject?: object,
) => {
    return useQuery<{ id: string; label: string }[], AxiosError>(
        ["organic-autocomplete", curriculumId, searchTerm, paramsObject], // keys need the params setting here too, otherwise it'll not update
        async () => {
            const { data }: { data: OrganicAutocompleteResultsType } =
                await axiosAPI
                    .getInstance()
                    .get(`/curriculum/${curriculumId}/documents/autocomplete`, {
                        params: paramsObject,
                    });
            const results = data?.results;
            const returnvalue = results[0].completions?.map((label) => ({
                id: label,
                label,
            }));
            return returnvalue;
        },
        { enabled: !!curriculumId && !!searchTerm && delayPassed },
    );
};

const useCurriculumAutocompleteQuery = (
    curriculumId: string | undefined | null,
    searchTerm: string,
    paramsObject: object,
    filter: object,
    delayPassed: boolean,
) => {
    return useQuery<
        Array<
            {
                id: string;
                label: string;
                facets: unknown;
            } & CurriculumSearchResult
        >,
        AxiosError
    >(
        [
            "curriculumAutocompleteResults",
            curriculumId,
            searchTerm,
            paramsObject,
            filter,
        ], // keys need the params setting here too, otherwise it'll not update
        async () => {
            const { data } = await axiosAPI
                .getInstance()
                .post(`/curriculum/${curriculumId}/autocomplete`, filter, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: paramsObject,
                });

            const results = data?.results;
            const facets = data?.facets;
            return results?.map((item: CurriculumSearchResult) => ({
                id: item.name,
                label: item.name,
                facets,
                ...item,
            }));
        },
        {
            enabled: !!curriculumId && !!searchTerm && delayPassed,
        },
    );
};

export const useSearchAutocompleteQuery = (searchTerm: string) => {
    const [delayPassed, setDelayPassed] = useState(false);
    useEffect(() => {
        // Rob: This isn't perfect as it does trigger additional api calls still, but it is better than without
        setDelayPassed(false);
        const timer = setTimeout(() => {
            searchTerm && setDelayPassed(true);
        }, 400);
        return () => clearTimeout(timer);
    }, [searchTerm]);

    const { curriculumId } = useCurriculumParams();
    const [searchParams] = useSearchParams();
    const queryParamsString = searchParams.get("queryParams");
    const queryParams = queryParamsString
        ? JSON.parse(queryParamsString)
        : null;
    const { config } = useAppContext();
    const { showDrafts } = config;
    const paramsObject = {
        q: searchTerm,
        size: 6,
        ...(queryParams ? queryParams : null),
        allPublicationStatus: showDrafts,
    };

    const { data: curriculum } = useCurriculumQuery();
    const filters = {
        curriculumFilters: [curriculum?.name?.toLowerCase()],
        layerFilters: ["QUERY"],
    };

    const resultType = queryParams?.resultType;

    const organicAutocompleteQuery = useOrganicAutocompleteQuery(
        curriculumId?.toString(),
        searchTerm,
        delayPassed,
        paramsObject,
    );

    const curriculumAutocompleteQuery = useCurriculumAutocompleteQuery(
        curriculumId?.toString(),
        searchTerm,
        paramsObject,
        filters,
        delayPassed,
    );
    if (resultType === "pages" || resultType === "videos") {
        return organicAutocompleteQuery;
    }
    return curriculumAutocompleteQuery;
};

const useCurriculumSearchQuery = () => {
    const { curriculumId } = useCurriculumParams();
    const [searchParams] = useSearchParams();
    const searchTerm = searchParams.get("searchTerm");
    const queryParamsString = searchParams.get("queryParams");
    const queryParams = queryParamsString
        ? JSON.parse(queryParamsString)
        : null;
    const subjectFilterString = searchParams.get("subjectFilters");
    const subjectFilter = subjectFilterString
        ? JSON.parse(subjectFilterString)
        : [];
    const { config } = useAppContext();
    const { showDrafts } = config;
    const paramsObject = {
        q: searchTerm,
        size: 6,
        resultType: "topics",
        allPublicationStatus: showDrafts,
        ...(queryParams ? queryParams : null),
    };

    const { data: curriculum } = useCurriculumQuery();

    const filters = {
        curriculumFilters: [curriculum?.name?.toLowerCase()],
        subjectFilters:
            subjectFilter && subjectFilter?.length ? [...subjectFilter] : null,
        layerFilters: ["QUERY"],
    };
    return useQuery<CurriculumSearchResultsType, AxiosError>(
        [
            "curriculum-search",
            curriculumId,
            searchTerm,
            paramsObject,
            subjectFilter,
        ], // keys need the params setting here too, otherwise it'll not update
        async () => {
            const { data } = await axiosAPI
                .getInstance()
                .post(`/curriculum/${curriculumId}/search`, filters, {
                    params: paramsObject,
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
            return { ...data, appliedFilters: filters };
        },
        { enabled: !!searchTerm },
    );
};

export default useCurriculumSearchQuery;

export type OrganicAutocompleteResultsType = {
    status: "ok" | string;
    errorMessage: string | null;
    resultSetId: string | null;
    query: {
        terms: string;
        originalTerms: string;
        pagination: {
            startPage: number;
            pageSize: number;
        };
        readingLevel: null;
        age: null;
        minSuitableAge: null;
        maxSuitableAge: null;
        readabilityFilter: "none";
        suitabilityFilter: "none";
        maxSuggestions: number;
        maxCandidates: number;
        minOccurences: number;
        maxNumTokens: number;
        minSourceOccurences: number;
        occurencesDecayFactor: number;
        prefixOnly: boolean;
        numBenchmarkIterations: number;
    };
    didYouMean: string | null;
    pagination: {
        startPage: number | null;
        pageSize: number | null;
        totalPages: number | null;
        totalResults: number | null;
        returnedResults: number;
    };
    results: [
        {
            completions: string[];
            tookTime: number;
            queryTime: number;
            analyzeTime: number;
            totalTime: number;
        },
    ];
    facets: string | null;
};

export type OrganicSearchResultsType = {
    status: "ok" | string;
    errorMessage: null | string;
    resultSetId: string;
    query: {
        terms: string;
        originalTerms: string;
        pagination: {
            startPage: number;
            pageSize: number;
        };
        readingLevel: number | null;
        age: number | null;
        minSuitableAge: number | null;
        maxSuitableAge: number | null;
        readabilityFilter: string;
        suitabilityFilter: string;
        pageQualityThreshold: number;
        maxQueryTerms: number;
        minDocFreq: number;
        fullTextMustClause: boolean;
        moreLikeThisMustClause: boolean;
    };
    didYouMean: string;
    pagination: {
        startPage: number;
        pageSize: number;
        totalPages: number;
        totalResults: number;
        returnedResults: number;
    };
    results: Result[];
};

type CurriculumSearchResult = {
    type: "QUERY";
    qualifier: "QUERY";
    curriculumId: number;
    nodeId: number;
    queryId: number;
    name: string;
    path: string;
    curriculumMetadata: {
        CURRICULUM: {
            name: string;
            id: number;
        };
        UNIT: {
            name: string;
            id: number;
        };
        QUERY: {
            name: string;
            id: number;
        };
        GRADE: {
            name: string;
            id: number;
        };
        SUBJECT: {
            name: string;
            id: number;
        };
        TOPIC: {
            name: string;
            id: number;
            logoUrl: string | null;
        };
    };
    logoUrl: string | null;
};

export type CurriculumSearchResultsType = {
    totalPages: number;
    count: number;
    pageSize: number;
    startPage: number;
    totalResults: number;
    results: Array<CurriculumSearchResult>;
    facets: {
        curriculums: [
            {
                key: string;
                count: number;
            },
        ];
        subjects: Array<{
            key: string;
            count: number;
        }>;
        grades: [
            {
                key: string;
                count: number;
            },
        ];
        layers: ["QUERY"];
    };
};
