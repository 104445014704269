@use "../../../../styles/variables.module";

.type {
    margin-bottom: 20px;
    padding-bottom: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid variables.$border-colors-color-border-light;
}
