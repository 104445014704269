import { Dialog } from "@mui/material";

import { useCollectionQuery } from "hooks/api/collections";
import { Collection } from "types";

import Loading from "components/Loading/Loading";
import ShareModal from "components/ShareButton/ShareModal/ShareModal";

const ShareCollectionDialog = ({
    collection,
    isVisible,
    setIsVisible,
}: {
    collection: Collection;
    isVisible: boolean;
    setIsVisible: (arg0: boolean) => void;
}) => {
    const { data: collectionData, status: collectionStatus } =
        useCollectionQuery(collection.id);
    const isLoading =
        collectionStatus === "loading" ||
        !collectionData ||
        !collectionData.embedLink;
    return (
        <Dialog
            open={isVisible}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
            onClose={() => {
                setIsVisible(false);
            }}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        maxWidth: "100%", // Set your width here
                        margin: 0,
                        borderRadius: "15px",
                    },
                },
            }}
        >
            {isLoading ? (
                <div
                    style={{
                        width: 900,
                        height: 300,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Loading />
                </div>
            ) : (
                <ShareModal
                    embedUrl={collectionData.embedLink as string}
                    linkUrl={collectionData.shareLink}
                    closeHandler={() => {
                        setIsVisible(false);
                    }}
                    inCollectionEditor={true}
                />
            )}
        </Dialog>
    );
};

export default ShareCollectionDialog;
