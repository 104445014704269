import { useContext } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { rectSortingStrategy, SortableContext } from "@dnd-kit/sortable";

import { CollectionContext } from "features/CollectionEditor/state/CollectionContext";
import { Result } from "types";
import { convertItemTypeToResultType } from "utils/typeConvertors";

import AddResourcePanel from "../AddResourcesPanel/AddResourcePanel";
import SortableGridResult from "./components/SortableGridResult";

const ResourcesContainerGridView = () => {
    const { selectedItems, highlightedItems, removeItem } =
        useContext(CollectionContext);
    const results: Result[] = selectedItems.map((item) =>
        convertItemTypeToResultType(item)
    );
    return (
        <>
            <AddResourcePanel />
            {selectedItems.length > 0 ? (
                <SortableContext
                    items={selectedItems}
                    strategy={rectSortingStrategy}
                >
                    <Grid
                        container
                        spacing={"20px"}
                        style={{ overflow: "hidden" }}
                    >
                        {selectedItems.map((item, index) => (
                            <Grid key={item.url} xl={4} md={6} xs={12}>
                                <SortableGridResult
                                    id={item.id}
                                    results={results}
                                    resultIndex={index}
                                    deleteItemHandler={() => removeItem(item)}
                                    isHighlighted={highlightedItems.includes(
                                        item.id
                                    )}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </SortableContext>
            ) : (
                <></>
            )}
        </>
    );
};

export default ResourcesContainerGridView;
