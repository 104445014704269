import { useContext } from "react";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";

import { CollectionContext } from "features/CollectionEditor/state/CollectionContext";
import useScreenSizes from "hooks/useScreenSizes";
import { tracker } from "utils/analytics";

import ResourcesContainerGridView from "./components/ResourcesContainerGridView/ResourcesContainerGridView";
import ResourcesContainerListView from "./components/ResourcesContainerListView/ResourcesContainerListView";

const ResourcesContainer = () => {
    const { isMobileScreen } = useScreenSizes();
    const { collection, selectedItems, reorderItems } =
        useContext(CollectionContext);
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );
    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            const selectedItemsIds = selectedItems.map((item) => item.id);
            const localItems = arrayMove(
                selectedItems,
                selectedItemsIds.indexOf(active.id),
                selectedItemsIds.indexOf(over.id)
            );
            reorderItems(localItems);
            tracker("Collection Items Reordered");
        }
    };
    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
        >
            {isMobileScreen || collection.view === "GRID" ? (
                <ResourcesContainerGridView />
            ) : (
                <ResourcesContainerListView />
            )}
        </DndContext>
    );
};

export default ResourcesContainer;
