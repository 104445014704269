import { CSSProperties, useState, useEffect } from "react";
import { Dialog } from "@mui/material";

import { useAppContext } from "contexts/AppContext";
import { DeepLink } from "types";
import { tracker } from "utils/analytics";
import { getAppMode } from "utils/newtools";

import ShareModal, { shareSettingsToUrl } from "./ShareModal/ShareModal";
import LTIRender from "../LTIFormRedirector/LTIRender";
import ShareButtonUI from "./ShareButtonUI/ShareButtonUI";
import { useZendeskContext } from "contexts/ZendeskContext";

interface Props {
    embedUrl?: string;
    linkUrl?: string;
    hasText: boolean;
    analyticsOptions?: Record<string, unknown>;
    styleOverrides?: CSSProperties;
    deepLink?: DeepLink;
    disabled?: boolean;
    inCollectionEditor?: boolean;
    isNewDesign?: boolean;
}

const ShareButton = ({
    embedUrl,
    linkUrl,
    hasText,
    analyticsOptions,
    styleOverrides,
    deepLink,
    disabled,
    inCollectionEditor,
    isNewDesign,
}: Props) => {
    const { config } = useAppContext();
    const { showZendesk, hideZendesk } = useZendeskContext();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isInitialised, setIsInitialised] = useState(false);
    const [displayLTI, setLTIVisible] = useState(false);
    const shareSettingsUrlParams = shareSettingsToUrl(
        config,
        deepLink?.url,
        inCollectionEditor,
    );
    const appMode = getAppMode();
    useEffect(() => {
        if (
            (!isInitialised && inCollectionEditor) ||
            (appMode !== "share" &&
                !(appMode === "lti" || !deepLink) &&
                (embedUrl || linkUrl))
        ) {
            setIsInitialised(true);
        }
    }, [
        isInitialised,
        embedUrl,
        linkUrl,
        deepLink,
        inCollectionEditor,
        appMode,
    ]);

    if (
        !isInitialised &&
        (appMode === "share" ||
            (!embedUrl && !linkUrl) ||
            (appMode === "lti" && !deepLink))
    ) {
        return <></>;
    }

    const ltiButtonClick = () => {
        setLTIVisible(true);
        tracker("LTI button clicked", analyticsOptions);
    };
    return (
        <>
            <ShareButtonUI
                clickHandler={(e) => {
                    e.stopPropagation();
                    if (appMode === "lti") return ltiButtonClick();
                    setIsModalVisible(true);
                    if (linkUrl) hideZendesk();
                    tracker("Share button clicked", analyticsOptions);
                }}
                hasText={hasText}
                styleOverrides={styleOverrides}
                disabled={disabled}
                isNewDesign={isNewDesign}
            />
            {displayLTI && deepLink && (
                <LTIRender
                    deeplink={{
                        ...deepLink,
                        url: deepLink?.url + shareSettingsUrlParams,
                    }}
                />
            )}
            {embedUrl && (
                <Dialog
                    open={isModalVisible}
                    onClose={() => {
                        setIsModalVisible(false);
                        if (appMode !== "share" && linkUrl) showZendesk();
                        tracker("Share modal closed");
                    }}
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                maxWidth: "100%", // Set your width here
                                margin: 0,
                                borderRadius: "15px",
                            },
                        },
                    }}
                >
                    <ShareModal
                        embedUrl={embedUrl}
                        linkUrl={linkUrl}
                        closeHandler={(e) => {
                            setIsModalVisible(false);
                            if (linkUrl) showZendesk();
                            tracker("Share modal closed");
                            e.stopPropagation();
                        }}
                        analyticsOptions={analyticsOptions}
                        inCollectionEditor={inCollectionEditor}
                    />
                </Dialog>
            )}
        </>
    );
};

export default ShareButton;
