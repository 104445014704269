@use "../../styles/variables.module";

.title {
    margin-bottom: 20px;
    padding-bottom: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    word-break: break-all;
    border-bottom: 1px solid variables.$border-colors-color-border-primary;
    overflow: hidden;
}
