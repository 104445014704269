import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import { faArrowLeft, faToolbox } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as styles from "./Subheader.module.scss";
import { CollectionContext } from "../state/CollectionContext";
import MessagingContext from "contexts/MessagingContext";
import useScreenSizes from "hooks/useScreenSizes";
import variables from "styles/_variables.module.scss";
import { tracker } from "utils/analytics";

import CollectionShareButtonWrapper from "./CollectionShareButtonWrapper/CollectionShareButtonWrapper";
import { GenerateCollectionQuizButton } from "./GenerateQuizButton/GenerateQuizButton";
import MobileCollectionDetailsPanel from "../DetailsPanel/MobileCollectionDetailsPanel";
import { AlertMessageProps } from "components/AlertMessage/AlertMessage";
import DeleteButton from "components/DeleteButton/DeleteButton";
import MenuButton from "components/MenuButton/MenuButton";
import MenuContainer from "components/MenuContainer/MenuContainer";
import { MessageModalProps } from "components/MessageModal/MessageModal";
import SaveButton from "components/SaveButton/SaveButton";
import Translate from "components/Translate/Translate";

const Subheader = ({
    setIsQuizEditorOpen,
}: {
    setIsQuizEditorOpen: (value: boolean) => void;
}) => {
    const { isDesktopScreen, isMobileScreen } = useScreenSizes();
    const navigate = useNavigate();
    const { openMessageModal, openAlertMessage } = useContext(MessagingContext);
    const { collection, changeQueue, saveHandler, isSaving, deleteHandler } =
        useContext(CollectionContext);
    const [isToolboxPanelOpen, setIsToolboxPanelOpen] = useState(false);

    const deleteAlertMessage: AlertMessageProps = {
        message: "Collection has been deleted.",
        type: "error",
        open: true,
    };
    const deleteMessageModal: MessageModalProps = {
        isModalVisible: true,
        title: "Delete the collection",
        body: "Are you sure you want to delete the collection? You can't undo this action.",
        type: "Delete",
        primaryActionHandler: () => {
            deleteHandler();
            openAlertMessage(deleteAlertMessage);
        },
    };
    const openQuizEditor = () => {
        tracker("Generate Quiz Button Clicked");
        setIsToolboxPanelOpen(false);
        setIsQuizEditorOpen(true);
    };

    return (
        <div className={styles.container}>
            <div className={styles.editAndMobileExplorer}>
                {!isMobileScreen && (
                    <Button
                        variant="outlined"
                        className={styles.editButton}
                        onClick={() => navigate(-1)}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} fontSize={12} />
                        <Typography variant="text-size-xSmall-bold">
                            <Translate text="Exit Editor" />
                        </Typography>
                    </Button>
                )}
                {!isDesktopScreen && <MobileCollectionDetailsPanel />}
            </div>
            {isMobileScreen && (
                <>
                    <MenuButton
                        faIcon={faToolbox}
                        iconColor={variables["icon-colors-blue-100"]}
                        primaryColor={variables["icon-colors-blue-40"]}
                        secondaryColor={variables["icon-colors-blue-60"]}
                        isOpen={isToolboxPanelOpen}
                        styleOverrides={{
                            width: "30px",
                            minWidth: "30px",
                            height: "30px",
                            fontSize: "12px",
                        }}
                        onClick={() => {
                            setIsToolboxPanelOpen(true);
                        }}
                    />
                    <MenuContainer
                        isOpen={isToolboxPanelOpen}
                        closeMenu={() => setIsToolboxPanelOpen(false)}
                        containerRelativePosition="bottom-right"
                        fullWidthBreakpoint="xl"
                        fullHeightBreakpoint="xl"
                    >
                        <div className={styles.mobileContainer}>
                            <Button
                                variant="outlined"
                                className={styles.editButton}
                                onClick={() => navigate(-1)}
                            >
                                <FontAwesomeIcon
                                    icon={faArrowLeft}
                                    fontSize={12}
                                />
                                <Typography variant="text-size-xSmall-bold">
                                    <Translate text="Exit Editor" />
                                </Typography>
                            </Button>
                            <GenerateCollectionQuizButton
                                clickHandler={openQuizEditor}
                                isFullWidth
                            />
                            <SaveButton
                                isDisabled={changeQueue.length === 0}
                                isSaving={isSaving}
                                saveHandler={saveHandler}
                                isFullWidth
                            />
                            <DeleteButton
                                isVisible={Boolean(collection.id)}
                                deleteHandler={() =>
                                    openMessageModal(deleteMessageModal)
                                }
                            />
                        </div>
                    </MenuContainer>
                </>
            )}
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 20,
                }}
            >
                {!isMobileScreen && (
                    <>
                        <GenerateCollectionQuizButton
                            clickHandler={openQuizEditor}
                        />
                        <DeleteButton
                            isVisible={Boolean(collection.id)}
                            deleteHandler={() =>
                                openMessageModal(deleteMessageModal)
                            }
                        />
                        <SaveButton
                            isDisabled={changeQueue.length === 0}
                            isSaving={isSaving}
                            saveHandler={saveHandler}
                        />
                    </>
                )}
                <CollectionShareButtonWrapper />
            </div>
        </div>
    );
};
export default Subheader;
