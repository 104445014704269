@use "../../../styles/variables.module";

.container {
    margin: 10px 40px 0px 40px;
    display: inline-block;
    word-break: break-all;
    border-bottom: 1px solid variables.$border-colors-color-border-light;
    @media (max-width: variables.$bp-md) {
        margin: 10px;
    }
}

.titleInputField {
    padding: 0px;
    color: variables.$text-colors-color-text-dark;
    font-family: variables.$font-family;
    background: transparent;
    border: none;
    outline: none;
}

.editIcon {
    color: variables.$text-colors-color-text-gray;
    font-family: Font Awesome 5 Free;
    font-size: 15px;
    font-style: normal;
    font-weight: 900;
    line-height: 20px; /* 133.333% */
    cursor: pointer;
    width: 17px;
}
