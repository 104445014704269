import { useEffect } from "react";
import { Typography } from "@mui/material";

import { useResultModalContext } from "contexts/ResultModalContext";
import { Collection, Item, Result } from "types";
import { convertItemTypeToResultType } from "utils/typeConvertors";

import CollectionGridView from "./components/CollectionGridView/CollectionGridView";
import CollectionListView from "./components/CollectionListView/CollectionListView";
import Loading from "components/Loading/Loading";
import Translate from "components/Translate/Translate";

interface Props {
    items: Item[];
    isFetched?: boolean;
    viewAs: Collection["view"];
    selectedItems?: Item[];
    addItemHandler?: (item: Item) => void;
    removeItemHandler?: (item: Item) => void;
    collectionResultsModalEnabled: boolean;
}

const CollectionViewer = ({
    items,
    isFetched,
    viewAs,
    selectedItems,
    addItemHandler,
    removeItemHandler,
    collectionResultsModalEnabled,
}: Props) => {
    const { setIsResultModalEnabled, resetIsResultModalEnabled } =
        useResultModalContext();
    const results: Result[] = items.map((item) =>
        convertItemTypeToResultType(item)
    );
    useEffect(() => {
        setIsResultModalEnabled(collectionResultsModalEnabled);
        return () => {
            resetIsResultModalEnabled();
        };
    }, [
        collectionResultsModalEnabled,
        resetIsResultModalEnabled,
        setIsResultModalEnabled,
    ]);
    return !isFetched ? (
        <Loading />
    ) : items && items.length === 0 ? (
        <Typography variant="text-size-Base">
            <Translate text="The collection is currently empty." />
        </Typography>
    ) : viewAs === "LIST" ? (
        <CollectionListView
            items={items}
            selectedItems={selectedItems}
            addItemHandler={addItemHandler}
            removeItemHandler={removeItemHandler}
        />
    ) : (
        <CollectionGridView results={results} />
    );
};

export default CollectionViewer;
