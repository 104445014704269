@use "../../../../../../../styles/variables.module";

.settings {
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    /* $text-size-Small */
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: variables.$text-colors-color-text-dark;
}
