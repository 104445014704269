import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    FormControl,
    Select,
    MenuItem,
    SelectChangeEvent,
} from "@mui/material";

import { inputLabel, dropdownIcon } from "./RegionSelect.module.scss";
import { regions } from "data/regions";
import variables from "styles/_variables.module.scss";

import Translate from "components/Translate/Translate";

interface Props {
    value: string;
    handleChange: (event: SelectChangeEvent<string>) => void;
    disabled?: boolean;
}

const RegionSelect = ({ value, handleChange, disabled }: Props) => {
    return (
        <FormControl fullWidth>
            <label className={inputLabel}>
                <Translate text="Country" />
            </label>
            <Select
                value={value || ""}
                disabled={disabled}
                data-testid="region"
                IconComponent={(props) => (
                    <FontAwesomeIcon
                        {...props}
                        className={dropdownIcon}
                        icon={faAngleDown}
                    />
                )}
                sx={{
                    maxHeight: 40,
                    background: disabled
                        ? variables["border-colors-color-border-light"]
                        : variables["background-colors-color-background-white"],
                    "& .MuiSelect-select": {
                        paddingLeft: "20px",
                    },
                }}
                onChange={handleChange}
                required
                name="region"
            >
                {Object.entries(regions).map((region) => (
                    <MenuItem key={region[0]} value={region[0]}>
                        {region[1]}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default RegionSelect;
