import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    faGear,
    faGraduationCap,
    faListUl,
    faQuestion,
    faUser,
} from "@fortawesome/free-solid-svg-icons";

import { useUserCurrentLayerQuery } from "hooks/api/useUserSettingsQuery";
import variables from "styles/_variables.module.scss";
import { tracker } from "utils/analytics";

import FooterItem from "./FooterItem/FooterItem";
import LogoutItem from "./LogoutItem/LogoutItem";
import MenuItem from "./MenuItem/MenuItem";
import UserItem from "./UserItem/UserItem";
import MenuButton from "components/MenuButton/MenuButton";
import MenuContainer from "components/MenuContainer/MenuContainer";

const UserMenu = () => {
    const navigate = useNavigate();
    const { data: userCurrentLayer } = useUserCurrentLayerQuery();
    const curriculumId = userCurrentLayer?.curriculumId;
    const gradeId = userCurrentLayer?.gradeId;
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const closeMenu = () => {
        tracker("User menu closed");
        setIsMenuOpen(false);
    };
    return (
        <div style={{ position: "relative" }}>
            <MenuButton
                dataTestId={"userMenuButton"}
                faIcon={faUser}
                iconColor={variables["icon-colors-green-100"]}
                primaryColor={variables["icon-colors-green-40"]}
                secondaryColor={variables["icon-colors-green-60"]}
                isOpen={isMenuOpen}
                onClick={() => {
                    tracker("User menu open");
                    setIsMenuOpen(true);
                }}
            />
            <MenuContainer isOpen={isMenuOpen} closeMenu={closeMenu}>
                <>
                    <UserItem closeMenu={closeMenu} />
                    <MenuItem
                        faIcon={faGraduationCap}
                        iconColor={"Blue"}
                        text="Curriculum"
                        clickHandler={() => {
                            tracker("Curriculum button clicked");
                            navigate(
                                `/curriculum/${
                                    curriculumId +
                                    (gradeId ? "/grade/" + gradeId : "")
                                }`
                            );
                            closeMenu();
                        }}
                    />
                    <MenuItem
                        faIcon={faListUl}
                        iconColor={"Blue"}
                        text="My collections"
                        clickHandler={() => {
                            tracker("My collections button clicked");
                            navigate("/my-collections");
                            closeMenu();
                        }}
                    />
                    <MenuItem
                        faIcon={faGear}
                        iconColor={"Green"}
                        text="Settings"
                        clickHandler={() => {
                            tracker("Settings button clicked");
                            navigate("/settings");
                            closeMenu();
                        }}
                    />
                    <MenuItem
                        faIcon={faQuestion}
                        iconColor={"Green"}
                        text="FAQ"
                        clickHandler={() => {
                            tracker("FAQ button clicked");
                            window.open(
                                "https://wizenoze.zendesk.com/hc/en-gb",
                                "_blank"
                            );
                            closeMenu();
                        }}
                    />
                    <LogoutItem />
                    <FooterItem />
                </>
            </MenuContainer>
        </div>
    );
};

export default UserMenu;
