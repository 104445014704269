import { AxiosError } from "axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { axiosAPI } from "./axiosAPI";
import { AppConfig } from "contexts/AppContext";
import { StructureNode } from "types";
import useUserQuery from "./useUserQuery";
import { isShareMode } from "utils/newtools";
import {
    convertAppConfigTypeToUserSettingsType,
    convertUserSettingsTypeToAppConfigType,
} from "utils/typeConvertors";

export type NavState = {
    curriculumId: string;
    gradeId?: string;
    topicListId?: string;
};

export const useUserCurrentLayerMutation = () => {
    return useMutation(async (newData: NavState) => {
        const nodesArray = [];
        if (newData.gradeId) {
            nodesArray.push(newData.gradeId);
        }
        const transformedData = {
            curriculumId: newData.curriculumId,
            nodes: nodesArray,
        };
        return axiosAPI
            .getInstance()
            ?.put(`/user/currentLayers`, transformedData);
    });
};

type Node = {
    nodeId: number;
    name: string;
    qualifier: StructureNode;
};

export const userCurrentLayersURL = `/user/currentLayers`;
export const useUserCurrentLayerQuery = (isDisabled?: boolean) => {
    const { data: userData } = useUserQuery();
    return useQuery<NavState, AxiosError>(
        ["user-current-layers", userData?.me.id],
        async () => {
            const { data } = await axiosAPI
                .getInstance()
                .get(userCurrentLayersURL);
            if (Object.keys(data).length === 0) return data; // no data, new user
            const gradeId = data.nodes.find(
                (el: Node) => el.qualifier === "GRADE",
            )?.nodeId;
            const topicListId = data.nodes.find(
                (el: Node) => el.qualifier !== "GRADE",
            )?.nodeId; // This will have to change if we store more than curriculum and grade (and 1,0,0 structure topicListId)
            const cleanedData = {
                curriculumId: data.curriculumId,
                ...(gradeId ? { gradeId } : null),
                ...(topicListId ? { topicListId } : null),
            };
            return cleanedData;
        },
        {
            enabled: !isShareMode && !isDisabled,
        },
    );
};

export const userSettingsURL = "/user/settings";
export const useUserSettingsQuery = (isDisabled?: boolean) => {
    const { data: userData } = useUserQuery();
    return useQuery<AppConfig, AxiosError>(
        ["user-settings", userData?.me.id],
        async () => {
            const { data } = await axiosAPI.getInstance().get(userSettingsURL);
            return convertUserSettingsTypeToAppConfigType(data);
        },
        {
            enabled: !isDisabled && !isShareMode,
            staleTime: 20000,
            // cacheTime: 20000,
        },
    );
};

export const useUserSettingsMutation = () => {
    const queryClient = useQueryClient();
    const { data: userData } = useUserQuery();
    return useMutation(
        async (newAppConfig: AppConfig) => {
            return axiosAPI
                .getInstance()
                ?.put(
                    userSettingsURL,
                    convertAppConfigTypeToUserSettingsType(newAppConfig),
                );
        },
        {
            onMutate: (newValue: AppConfig) => {
                queryClient.setQueryData(
                    ["user-settings", userData?.me.id],
                    newValue,
                );
            },
        },
    );
};
