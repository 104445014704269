import { premiumIconFooterContainer } from "./PremiumIcon.module.scss";
import premiumRibbonSVG from "./premium_icon_32x32.svg";
import { Result } from "types";

const PremiumIcon = ({
    collectionAccessType,
}: {
    collectionAccessType: Result["collectionAccessType"];
}) => {
    if (collectionAccessType !== "paidCollection") return <></>;

    return (
        <div className={premiumIconFooterContainer}>
            <img src={premiumRibbonSVG} alt="premium ribbon" />
        </div>
    );
};

export default PremiumIcon;
