import { useSearchParams } from "react-router-dom";

import { resultContainer } from "./TopicSearchResults.module.scss";

import TopicResultList from "./TopicResultList";

const SingleTopicResult = () => {
    const [searchParams] = useSearchParams();

    const searchResult = searchParams.get("queryResult");

    if (!searchResult) return <></>;

    return (
        <div className={resultContainer}>
            <TopicResultList
                results={[JSON.parse(searchResult)]}
                // dataContainerWidth={props.dataContainerWidth}
            />
        </div>
    );
};

export default SingleTopicResult;
