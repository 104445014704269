@use "../../../../../../styles/variables.module";

.noCollections {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
}

.addCollectionButton {
    padding: 10px 20px;
    height: 30px;
    gap: 10px;
    font-size: 0.75rem;
}
