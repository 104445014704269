import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { QueryResults } from "types";
import { axiosAPI } from "./axiosAPI";
import { useAppContext } from "contexts/AppContext";
import useCurriculumParams from "hooks/useCurriculumParams";

type LocalFilters = {
    numberOfResults: number;
    pinnedOnly: boolean;
    embeddable: boolean;
};

const useQueryResultsQuery = (
    targetQueryId?: string | number,
    localFilters?: LocalFilters,
) => {
    const curriculumParams = useCurriculumParams();
    const { config } = useAppContext();
    const queryId = targetQueryId ?? curriculumParams.queryId;

    const { showDrafts } = config;
    const { numberOfResults, pinnedOnly, embeddable } = localFilters
        ? localFilters
        : config;

    return useQuery<QueryResults, AxiosError>(
        [
            "query-results",
            queryId,
            { pinnedOnly, embeddable, numberOfResults, showDrafts },
        ],
        async () => {
            const { data } = await axiosAPI
                .getInstance()
                .get(`/curriculum/node/query/${queryId}/results`, {
                    params: {
                        pinnedOnly,
                        embeddable,
                        ...(showDrafts
                            ? { allPublicationStatus: showDrafts }
                            : null),
                    },
                });
            if (numberOfResults < data.results.length)
                data.results.splice(numberOfResults);
            return data;
        },
        { enabled: !!queryId, staleTime: 1000 * 60 * 60 * 12 },
    );
};

export default useQueryResultsQuery;
