export const getDefaultCurriculumId = function (curriculumId) {
    if (!curriculumId) {
        return;
    }

    if (typeof curriculumId === "number") {
        return curriculumId;
    }

    if (curriculumId?.length > 0) {
        return curriculumId[0];
    }
};

export default getDefaultCurriculumId;
