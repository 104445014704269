@use "../../styles/variables.module";

.title {
    margin-bottom: 20px;
    padding-bottom: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid variables.$border-colors-color-border-primary;
}

.emptyCollectionMessage {
    padding: 20px 40px;
}

@media (max-width: variables.$bp-md) {
    .emptyCollectionMessage {
        padding: 20px;
    }
}
