@use "../../../styles/variables.module";

.searchResultsContainer {
    margin: 0;
    background-color: variables.$background-colors-color-background-light;
    box-sizing: border-box;
    z-index: 5;
    height: 100%;
}

.faIcon {
    margin-right: 0.7rem;
    opacity: 0.6;
    font-size: 1.7rem;
    vertical-align: middle;
}

.searchResultsDisplay {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    padding: 20px 20px 0 20px;
    align-items: center;
}
.searchResultsDisplay[data-result-type="pages"],
.searchResultsDisplay[data-result-type="videos"] {
    padding-bottom: 20px;
}

p.searchResultsAmount {
    color: variables.$text-colors-color-text-dark;
    font-weight: 500;
    font-size: 28px;
    line-height: 40px;
    word-break: break-all;
    hyphens: auto;
    flex-basis: 50%;
}

p.infoTextStyle {
    /* $text-size-Small */
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    /* identical to box height, or 156% */

    /* Text colors/$color-text-gray */

    color: variables.$text-colors-color-text-gray;
}

.resultAmountContainer {
    display: flex;
    flex-direction: column;
}

@media (min-width: 640px) {
    .searchResultsDisplay {
        text-align: left;
    }
}

@media (min-width: 1024px) {
    .searchResultsDisplay[data-result-type="topics"] {
        padding-left: 40px;
    }
}
