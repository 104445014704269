import { Button, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCheck } from "@fortawesome/free-solid-svg-icons";

import * as styles from "../ShareModal.module.scss";
import { tracker } from "utils/analytics";

import Translate from "components/Translate/Translate";

interface Props {
    linkUrlSettings: string;
    copyLink: any;
    setCopyEmbed: any;
    setCopyLink: any;
    analyticsOptions?: Record<string, unknown>;
}

const LinkTabContent = ({
    linkUrlSettings,
    copyLink,
    setCopyEmbed,
    setCopyLink,
    analyticsOptions,
}: Props) => {
    return (
        <>
            <div className={styles.tabsContainer}>
                <div className={styles.textContainer}>
                    <span className={styles.text}>{linkUrlSettings}</span>
                </div>
                <Button
                    variant="text"
                    className={styles.previewButton}
                    onClick={() => {
                        window?.open(linkUrlSettings)?.focus();
                        tracker("Preview Link Clicked", analyticsOptions);
                    }}
                >
                    <Translate text="Preview in a new tab" />
                </Button>
            </div>
            <div className={styles.copyContainer}>
                {copyLink ? (
                    <div className={styles.copyCorrect}>
                        <FontAwesomeIcon icon={faSquareCheck} />
                        <Typography variant="text-size-Small-bold">
                            <Translate text="Link copied to clipboard" />
                        </Typography>
                    </div>
                ) : (
                    <div />
                )}
                <Button
                    variant="contained"
                    className={styles.copyButton}
                    onClick={() => {
                        navigator.clipboard.writeText(linkUrlSettings);
                        setCopyEmbed(false);
                        setCopyLink(true);
                        tracker("Copy Link Clicked", analyticsOptions);
                    }}
                >
                    <Translate text="Copy link" />
                </Button>
            </div>
        </>
    );
};

export default LinkTabContent;
