import { useMemo } from "react";
import {
    QueryClient,
    useMutation,
    useQueries,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import { axiosAPI } from "./axiosAPI";
import { AppConfig } from "contexts/AppContext";
import useCollectionsParams from "hooks/useCollectionsParams";
import { Collection, Folder } from "types";
import { updateArrayItem } from "utils/newtools";

const changeResultIntoChildren = (
    data: Folder | Collection,
    node: Folder,
    changeType: "add" | "edit" | "delete",
) => {
    if (node.id === data.parentId) {
        if (!node.children) {
            node.children = [];
        }
        switch (changeType) {
            case "add":
                node.children.push(data);
                break;
            case "edit":
                node.children = [
                    ...node.children.filter((el) => el.id !== data.id),
                    data,
                ];
                break;
            case "delete":
                node.children = [
                    ...node.children.filter((el) => el.id !== data.id),
                ];
                break;
        }
        node.children.push(data);
    } else if (node.children && node.children.length > 0) {
        node.children.forEach((child) => {
            if (child.type === "FOLDER") {
                changeResultIntoChildren(data, child, changeType);
            }
        });
    }
};

export const updateFolderCache = (
    data: Folder | Collection,
    collectionsParams: number[],
    queryClient: QueryClient,
    updateType: "add" | "edit" | "move" | "delete",
    oldData?: Folder | Collection,
) => {
    if (!data) {
        console.error("No data to update folder cache");
    }
    if (collectionsParams.length > 1) {
        const rootId = collectionsParams[1];
        if (!rootId) return;
        const currentRoot = queryClient.getQueryData<Folder>([
            "folder",
            rootId,
        ]);
        if (!currentRoot) return;
        const currentRootCopy = { ...currentRoot };

        changeResultIntoChildren(
            data,
            currentRootCopy,
            updateType === "move" ? "add" : updateType,
        );
        if (updateType === "move" && oldData) {
            changeResultIntoChildren(oldData, currentRootCopy, "delete");
        }
        queryClient.setQueryData(["folder", rootId], currentRootCopy);
    } else {
        const rootId = collectionsParams[0];
        if (!rootId) return;
        const allFolders = queryClient.getQueryData<Folder[]>(["all-folders"]);
        if (!allFolders) return;
        const foundRoot = allFolders.find((el) => el.id === rootId);
        if (!foundRoot) return;
        const currentRootCopy = { ...foundRoot };
        if (updateType === "move" && oldData) {
            changeResultIntoChildren(oldData, currentRootCopy, "delete");
        }
        queryClient.setQueryData(["folder", rootId], currentRootCopy);
        const newAllFolders = allFolders.map((el) =>
            el.id === rootId ? currentRootCopy : el,
        );
        queryClient.setQueryData(["all-folder"], newAllFolders);
    }
};
export const invalidateFolderCache = (
    collectionsParams: number[],
    queryClient: QueryClient,
) => {
    if (collectionsParams && collectionsParams.length > 1) {
        queryClient.invalidateQueries({
            queryKey: ["folder", collectionsParams[1]],
        });
    } else {
        queryClient.invalidateQueries({
            queryKey: ["all-folders"],
        });
    }
};

export const useCreateFolderMutation = () => {
    const queryClient = useQueryClient();
    const collectionsParams = useCollectionsParams();
    return useMutation(
        async ({
            parentId,
            title,
            description,
            imageUrl,
            language,
            resultModal,
            color,
        }: {
            parentId: number | string;
            title: string;
            description: string;
            imageUrl: string;
            language?: AppConfig["language"];
            resultModal?: boolean;
            color?: string;
        }) => {
            return await axiosAPI
                .getInstance()
                .post(`/content/folder/${parentId}/subfolder`, {
                    title,
                    description,
                    imageUrl,
                    language,
                    resultModal,
                    color,
                });
        },
        {
            onSuccess: ({ data }) =>
                updateFolderCache(data, collectionsParams, queryClient, "add"),
            onSettled: () =>
                invalidateFolderCache(collectionsParams, queryClient),
        },
    );
};

export const useEditFolderMutation = () => {
    const queryClient = useQueryClient();
    const collectionsParams = useCollectionsParams();
    return useMutation(
        async (folder: Folder) => {
            return await axiosAPI
                .getInstance()
                .put(`/content/folder/${folder.id}`, folder);
        },
        {
            onMutate: (folder) => {
                queryClient.setQueryData(["folder", folder.id], folder);
                updateRootFoldersCache(queryClient, folder);
            },
            // onSuccess: ({ data }) =>
            //     updateFolderCache(data, collectionsParams, queryClient, "edit"),
            onSettled: () =>
                invalidateFolderCache(collectionsParams, queryClient),
        },
    );
};

export const useDeleteFolderMutation = () => {
    const queryClient = useQueryClient();
    const collectionsParams = useCollectionsParams();
    return useMutation(
        async (folder: Folder) => {
            return await axiosAPI
                .getInstance()
                .delete(`/content/folder/${folder.id}`);
        },
        {
            onMutate: (folder) => {
                queryClient.removeQueries(["folder", folder.id]);
            },
            onSuccess: ({ data }) =>
                updateFolderCache(
                    data,
                    collectionsParams,
                    queryClient,
                    "delete",
                ),
            onSettled: () =>
                invalidateFolderCache(collectionsParams, queryClient),
        },
    );
};

export const useMoveFolderMutation = () => {
    const queryClient = useQueryClient();
    const collectionsParams = useCollectionsParams();
    return useMutation(
        async ({
            folder,
            newParentId,
        }: {
            folder: Folder;
            newParentId: string | number;
            newInvalidationIds?: number[];
        }) => {
            return await axiosAPI
                .getInstance()
                .put(
                    `/content/folder/move/${folder.id}?parentId=${newParentId}`,
                );
        },
        {
            onSuccess: ({ data }, { folder }) =>
                updateFolderCache(
                    data,
                    collectionsParams,
                    queryClient,
                    "move",
                    folder,
                ),
            onSettled: (_, __, { newInvalidationIds }) => {
                if (newInvalidationIds) {
                    // If moving from different roots or (first child) folders, we need to invalidate both
                    invalidateFolderCache(newInvalidationIds, queryClient);
                }
                invalidateFolderCache(collectionsParams, queryClient);
            },
        },
    );
};

// Folders from the tree matching the url IDs
export const useSelectedFolders = () => {
    const collectionsParams = useCollectionsParams();
    const { data: allFolders } = useAllFoldersEnhancedQuery();
    if (!allFolders || !collectionsParams || collectionsParams.length === 0)
        return [];
    const findItemsWithIds = (data: Folder[], nodeIds: number[]) => {
        const foundItems: Folder[] = [];
        const findItemsRecursive = (node: Folder) => {
            if (nodeIds.includes(node.id)) {
                foundItems.push(node);
                if (node.children && node.children.length > 0) {
                    node.children.forEach(
                        (el) => el.type === "FOLDER" && findItemsRecursive(el),
                    );
                }
            }
        };
        data.forEach((item) => findItemsRecursive(item));
        return foundItems;
    };
    const selectedFolders = findItemsWithIds(allFolders, collectionsParams);
    return selectedFolders;
};

export const findItemsWithIds = (
    data: Array<Folder | Collection>,
    nodeIds: number[],
) => {
    const foundItems: Array<Folder | Collection> = [];
    const findItemsRecursive = (node: Folder | Collection) => {
        if (nodeIds.includes(node.id)) {
            foundItems.push(node);
            if (
                node.type === "FOLDER" &&
                node.children &&
                node.children.length > 0
            ) {
                node.children.forEach((el) => findItemsRecursive(el));
            }
        }
    };
    data.forEach((item) => findItemsRecursive(item));
    return foundItems;
};

// Folders from the tree matching the url IDs
export const useSelectedFoldersAndCollections = () => {
    const collectionsParams = useCollectionsParams();
    const { data: allFolders } = useAllFoldersEnhancedQuery();
    if (!allFolders || !collectionsParams || collectionsParams.length === 0)
        return [];

    const selectedFoldersAndCollections = findItemsWithIds(
        allFolders,
        collectionsParams,
    );
    return selectedFoldersAndCollections;
};

// Basic Roots Fetch
const useAllRootFoldersQuery = () => {
    return useQuery<Array<Folder>, AxiosError>(["all-folders"], async () => {
        const { data } = await axiosAPI
            .getInstance()
            .get(`/content/folder/all/folders`);
        return data.map((el: Folder) => ({
            ...el,
            isRootFolder: true,
        }));
    });
};

export const useFullFolderQuery = (
    folderId: number | string,
    isEnabled?: boolean,
) => {
    return useQuery<Folder, AxiosError>(
        ["full-folder", folderId],
        async () => {
            const { data } = await axiosAPI
                .getInstance()
                .get(`/content/folder/${folderId}`, {
                    params: {
                        includeChildren: false,
                    },
                });
            return data;
        },
        { enabled: isEnabled },
    );
};

export const useFolderTreeQuery = (folderId?: number | string) => {
    return useQuery<Folder, AxiosError>(
        ["folder", folderId],
        async () => {
            const { data } = await axiosAPI
                .getInstance()
                .get(`/content/folder/${folderId}`, {
                    params: {
                        includeChildren: true,
                    },
                });
            return data;
        },
        { enabled: !!folderId },
    );
};

export const useAllFoldersEnhancedQuery = () => {
    const { data: allRootFolders, status: allRootFoldersStatus } =
        useAllRootFoldersQuery();
    // const allRootFolderIds = allRootFolders?.map((el) => el.id);
    const queryClient = useQueryClient();
    const availableChildren = queryClient.getQueriesData<Folder>(["folder"]);
    const allFetchedFolderIds = availableChildren
        ?.map((el) => {
            if (el && el.length > 1) {
                return el[1]?.id;
            }
            return false;
        })
        .filter((el) => el);
    const privateCollectionFirstFoldersIds =
        allRootFolders
            ?.find((el) => el.access === "PRIVATE")
            ?.children.filter((el) => el.type === "FOLDER")
            ?.map((el) => el.id) ?? [];

    const collectionsParams = useCollectionsParams();
    const allNeededFolderIds =
        collectionsParams.length > 1
            ? [
                  ...allFetchedFolderIds.filter(
                      (id) => id !== collectionsParams[1],
                  ),
                  collectionsParams[1],
                  ...privateCollectionFirstFoldersIds,
              ]
            : [...allFetchedFolderIds, ...privateCollectionFirstFoldersIds];
    const allFetchedFolders = useQueries({
        queries: allNeededFolderIds
            .filter((el) => !!el) // || allRootFolderIds?.includes(el)) not sure what this was doing
            .map((id) => ({
                queryKey: ["folder", id],
                queryFn: () =>
                    axiosAPI
                        .getInstance()
                        .get(`/content/folder/${id}/subfolders`, {
                            params: {
                                includeChildren: true,
                            },
                        }),
                staleTime: Infinity,
            })),
    });

    const enhancedFolders = useMemo(() => {
        if (!allRootFolders || !allFetchedFolders) {
            return [];
        }
        const updatedFolders = allRootFolders.map((folder) => {
            const newChildren = folder.children.map((child) => {
                const matchingChild = allFetchedFolders.find(
                    (fetchedFolder) => {
                        const flattenedFetchedFolder =
                            fetchedFolder?.data?.data || fetchedFolder?.data;
                        return flattenedFetchedFolder?.id === child.id;
                    },
                );
                if (matchingChild) {
                    const flattenedMatchedChild =
                        matchingChild?.data?.data || matchingChild?.data;
                    return {
                        ...flattenedMatchedChild,
                        hasBeenFetched: true,
                    };
                }
                return { ...child, hasBeenFetched: false };
            });
            folder.children = newChildren;
            return folder;
        });

        return updatedFolders;
    }, [allFetchedFolders, allRootFolders]);

    return { data: enhancedFolders, status: allRootFoldersStatus };
};

const updateRootFoldersCache = (queryClient: QueryClient, folder: Folder) => {
    const allRootFolders: Folder[] =
        queryClient.getQueryData(["all-folders"]) ?? [];
    const rootFolderIndex = allRootFolders.findIndex(
        (el) => el.id === folder.id,
    );
    if (rootFolderIndex !== -1) {
        const newRoots = updateArrayItem(
            allRootFolders,
            folder,
            rootFolderIndex,
        );
        queryClient.setQueryData(["all-folders"], newRoots);
    }
};
