import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
    Button,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from "@mui/material";

import * as styles from "./Signup.module.scss";
import { useAppContext } from "contexts/AppContext";
import { regions } from "data/regions";
import { useSignupMutation } from "hooks/api/authentication";
import { identifySignedInUser, tracker } from "utils/analytics";

import PublicPageContainer from "../components/PublicPageContainer/PublicPageContainer";
import PublicPageTitle from "../components/PublicPageTitle/PublicPageTitle";
import Translate, { translate } from "components/Translate/Translate";

type SignUpFormData = {
    email: string;
    password: string;
    companyName: string;
    region: string;
    phoneNumber: string;
    referral?: string;
    newsletter: boolean;
};

const trackedKeys: Set<keyof SignUpFormData> = new Set();

const Signup = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { config } = useAppContext();
    const language = config.language;
    const signupMutation = useSignupMutation();
    const [error, setError] = useState<string>();
    const [formData, setFormData] = useState<SignUpFormData>({
        email: "",
        password: "",
        companyName: "",
        region: "",
        phoneNumber: "",
        referral: "",
        newsletter: false,
    });
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const formKeys = Object.keys(formData);
        if (!formKeys.includes(name)) {
            return;
        }
        const key = name as keyof SignUpFormData;
        if (!trackedKeys.has(key)) {
            tracker(`Sign Up Form Value Added: ${key}`);
            trackedKeys.add(key);
        }
        setFormData({ ...formData, [key]: value });
    };
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (signupMutation.isLoading) return;
        const { email, password, companyName, region, newsletter } =
            e.currentTarget;
        if (!(email.value && password.value)) {
            const errMsg = translate(
                language,
                "Please specify an email address and password",
            );
            tracker("Sign up failed - missing email and/or password");
            setError(errMsg);
            throw new Error(errMsg);
        }
        const signupData = {
            email: email.value.toLowerCase().trim(),
            password: password.value,
            passwordConfirmation: password.value,
            languageId: language === "en" ? 29 : 90,
            companyName: companyName?.value,
            agreement: true,
            region: region.value,
            phoneNumber: formData.phoneNumber,
            marketingOptIn: newsletter.checked,
            application: "WIZEUP",
        };

        signupMutation.mutate(
            { user: signupData },
            {
                onSuccess: (res) => {
                    identifySignedInUser(res);
                    tracker("Sign up successful");
                    tracker("Sign up referral", {
                        referral: formData?.referral ?? "none",
                    });
                    navigate(
                        {
                            pathname: "/verifyemail",
                            search: searchParams.toString(),
                        },
                        { relative: "path" },
                    );
                },
                onError: (error) => {
                    tracker("Sign up failed - server rejection", {
                        error: error,
                    });
                    console.error({ error });
                    const firstError = error?.response?.data?.errors?.[0]; // TS error here. I need to look into customising AxiosError based on server response
                    const message =
                        firstError.code === "AssertEmailAvailable"
                            ? translate(
                                  language,
                                  "That email address is in use. If you've registered for another Wizenoze product, then please try logging in with that password.",
                              )
                            : firstError.message;
                    setError(message);
                },
            },
        );
    };

    return (
        <PublicPageContainer page="signup">
            <div className={styles.signupContainer}>
                <div className={styles.title}>
                    <PublicPageTitle title="Create a free account" />
                </div>
                {error && <div className={styles.formError}>{error}</div>}
                <form
                    className={styles.formContainer}
                    onSubmit={handleSubmit}
                    autoComplete="off"
                >
                    <TextField
                        name="email"
                        type="email"
                        label={translate(language, "Email")}
                        placeholder={translate(language, "Enter your email")}
                        value={formData.email}
                        fullWidth
                        required
                        InputLabelProps={{ required: false }}
                        onChange={handleChange}
                        autoComplete="new-email"
                    />
                    <TextField
                        name="password"
                        type="password"
                        label={translate(language, "Password")}
                        placeholder={translate(language, "Enter your password")}
                        value={formData.password}
                        fullWidth
                        required
                        InputLabelProps={{ required: false }}
                        onChange={handleChange}
                    />
                    <TextField
                        name="companyName"
                        type="text"
                        label={translate(language, "School name")}
                        placeholder={translate(
                            language,
                            "Enter your education institution or school name",
                        )}
                        value={formData.companyName}
                        fullWidth
                        onChange={handleChange}
                    />
                    <FormControl fullWidth>
                        <InputLabel>
                            {translate(language, "Choose your country")}
                        </InputLabel>
                        <Select
                            name="region"
                            value={formData.region}
                            label={translate(language, "Choose your country")}
                            onChange={(e: SelectChangeEvent) => {
                                const { name: key, value } = e.target;
                                // Handles the duplicate entries at the top of the list, which need unique keys in the list
                                const trimmedValue =
                                    value.length === 3 && value[2] === "2"
                                        ? value.slice(0, 2)
                                        : value;
                                setFormData({
                                    ...formData,
                                    [key]: trimmedValue,
                                });
                            }}
                        >
                            {Object.entries(regions).map((region) =>
                                region[1] !== "-" ? (
                                    <MenuItem key={region[0]} value={region[0]}>
                                        {region[1]}
                                    </MenuItem>
                                ) : (
                                    <Divider
                                        key={region[0]}
                                        color="border-colors-color-border-light.main"
                                        style={{
                                            padding: 0,
                                            margin: "16px",
                                            height: 1,
                                        }}
                                    />
                                ),
                            )}
                        </Select>
                    </FormControl>
                    {formData.region === "IN" && (
                        <TextField
                            name="phoneNumber"
                            type="tel"
                            label={translate(
                                language,
                                "Enter your mobile phone number",
                            )}
                            value={formData.phoneNumber}
                            fullWidth
                            onChange={handleChange}
                        />
                    )}
                    <TextField
                        name="referral"
                        type="text"
                        label={translate(
                            language,
                            "How did you hear about us?",
                        )}
                        placeholder={translate(
                            language,
                            "Enter your education institution or school name",
                        )}
                        value={formData.referral}
                        fullWidth
                        onChange={handleChange}
                        helperText={translate(
                            language,
                            "Please tell us how you heard about Wizenoze",
                        )}
                    />
                    <Button
                        variant="contained"
                        type="submit"
                        className={styles.formSubmitButton}
                    >
                        <Typography variant="text-size-Base-bold">
                            <Translate text="Create free account" />
                        </Typography>
                    </Button>
                    <div className={styles.disclaimers}>
                        <div>
                            <Typography variant="text-size-Small">
                                <Translate text="By creating an account you agree to the Wizenoze" />{" "}
                                <a
                                    href="https://www.wizenoze.com/cookies/"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <Translate text="Privacy Policy" />
                                </a>
                            </Typography>{" "}
                        </div>
                        <div>
                            <Typography variant="text-size-Small">
                                <Translate text="The Wizenoze collection includes videos collected from Youtube. By creating an account you are agreeing to be bound by the" />{" "}
                                <a
                                    href="https://www.youtube.com/static?template=terms"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <Translate text="YouTube Terms of Service" />
                                </a>
                                {"."}
                            </Typography>
                        </div>
                        <div className={styles.newsletter}>
                            <input
                                type="checkbox"
                                name="newsletter"
                                onChange={handleChange}
                                className={styles.checkbox}
                            />
                            <Translate text="I want to receive the Wizenoze newsletter" />
                        </div>
                    </div>
                </form>
                <Divider style={{ margin: "12px 0px" }}>
                    <Typography
                        variant="text-size-xSmall-regular"
                        color="$text-colors-color-text-gray.main"
                    >
                        <Translate text="OR" />
                    </Typography>
                </Divider>
                <Button
                    variant="outlined"
                    className={styles.loginButton}
                    onClick={() =>
                        navigate(
                            {
                                pathname: "/login",
                                search: searchParams.toString(),
                            },
                            { relative: "path" },
                        )
                    }
                >
                    <Typography variant="text-size-Base-bold">
                        <Translate text="Login" />
                    </Typography>
                </Button>
            </div>
        </PublicPageContainer>
    );
};

export default Signup;
