@use "../../../../styles/variables.module";

.container {
    padding: 20px 0px;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.selectAll {
    display: flex;
    align-items: center;
    gap: 10px;
}

@media (max-width: variables.$bp-lg) {
    .container {
        max-width: unset;
    }
}
