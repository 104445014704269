import { AppConfig } from "contexts/AppContext";
import { Item, Result, UserSettings } from "types";
import { formatYoutubeEmbedUrl, isSrcYouTube } from "utils/helpers/youtube";

export const convertAppConfigTypeToUserSettingsType = (
    appConfig: AppConfig,
) => {
    return {
        resultTarget: appConfig.resultModal ? "EMBED" : "BLANK",
        resultSize: appConfig.numberOfResults,
        embeddable: appConfig.embeddable,
        teacherApproved: appConfig.pinnedOnly,
        languageId: appConfig.language === "nl" ? 90 : 29,
        displayDrafts: appConfig.showDrafts,
        completedTours: appConfig.completedTours,
    };
};

export const convertUserSettingsTypeToAppConfigType = (
    userSettings: UserSettings,
) => {
    return {
        resultModal: userSettings.resultTarget === "EMBED",
        numberOfResults: userSettings.resultSize,
        embeddable: userSettings.embeddable,
        pinnedOnly: userSettings.teacherApproved,
        language: userSettings.language,
        showDrafts: userSettings.displayDrafts,
        completedTours: userSettings.completedTours,
    };
};

export const convertResultTypeToItemType = (
    result: Result,
    order: number,
): Item & { clickUrl: string; embedUrl: string | null } => {
    return {
        id: order,
        indexId: result.id,
        title: result.title,
        description: result.summaryText,
        url: result.fullUrl,
        sourceUrl: result.sourceUrl,
        thumbnail: result.thumbnail,
        audiences: result.audiences,
        collectionAccessType: result.collectionAccessType,
        item_order: order,
        documentTypes: result.documentTypes,
        metadata: "",
        options: result.options,
        sourceLogo: result.sourceLogo,
        teacherApproved: result.teacherApproved,
        isCurriculumBasics: result.isCurriculumBasics,
        isUserProvided: result.isUserProvided,
        lastVerified: null,
        pageState: "",
        redirectedTo: null,
        clickUrl: result.clickUrl,
        embedUrl: result.embedUrl,
    };
};

export const convertItemTypeToResultType = (item: Item): Result => {
    const embedUrl = !!item.embedUrl
        ? item.embedUrl
        : isSrcYouTube(item.url)
          ? formatYoutubeEmbedUrl(item.url)
          : item.url;
    return {
        id: item.indexId,
        title: item.title,
        summaryText: item.description,
        audiences: item.audiences,
        documentTypes: item.documentTypes,
        thumbnail: item.thumbnail,
        sourceLogo: item.sourceLogo,
        sourceLogoThumbnail: item.thumbnail,
        sourceUrl: item.sourceUrl,
        fullUrl: item.url,
        clickUrl: item.url,
        embedUrl: embedUrl,
        collectionAccessType: item.collectionAccessType,
        isCurriculumBasics: item.isCurriculumBasics,
        options: item.options,
        teacherApproved: item.teacherApproved,
        isUserProvided: item.isUserProvided,
        lastVerified: item.lastVerified,
        pageState: item.pageState,
        redirectedTo: item.redirectedTo,
    };
};
