import React, { useState } from "react";
import { Tabs, Tab, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faCode, faLink } from "@fortawesome/free-solid-svg-icons";

import { mainContainer, headerContainer } from "./ShareModal.module.scss";
import { AppConfig, useAppContext } from "contexts/AppContext";
import { tracker } from "utils/analytics";

import EmbedTabContent from "./components/EmbedTabContent";
import LinkTabContent from "./components/LinkTabContent";
import TabTitle from "components/Tabs/TabTitle/TabTitle";
import Translate from "components/Translate/Translate";

export const shareSettingsToUrl = (
    config: AppConfig,
    url?: string,
    inCollectionEditor?: boolean,
) => {
    if (inCollectionEditor || !Object.keys(config).length) return "";

    const embedStr = Object.entries(config)
        .filter((elem) => elem[1])
        .map((elem) => {
            return `${elem[0]}=${elem[1]}`;
        });
    return `${url?.includes("?") ? "&" : "?"}${embedStr.join("&")}`;
};

interface Props {
    embedUrl: string;
    linkUrl?: string;
    closeHandler: (e: React.MouseEvent) => void;
    analyticsOptions?: Record<string, unknown>;
    inCollectionEditor?: boolean;
}

const ShareModal = ({
    embedUrl,
    linkUrl,
    closeHandler,
    analyticsOptions,
    inCollectionEditor,
}: Props) => {
    const { config } = useAppContext();
    const [tabKey, setTabKey] = useState<"link" | "embed">(
        linkUrl ? "link" : "embed",
    );
    const [copyEmbed, setCopyEmbed] = useState(false);
    const [copyLink, setCopyLink] = useState(false);
    const [height, setHeight] = useState<number>();
    const [width, setWidth] = useState<number>();
    const shareSettingsUrlParams = shareSettingsToUrl(
        config,
        embedUrl,
        inCollectionEditor,
    );
    const changeTabHandler = (
        _event: React.SyntheticEvent,
        newTab: "link" | "embed",
    ) => {
        tracker("Share Modal Tab Changed", {
            newTab: newTab,
        });
        setTabKey(newTab);
    };

    return (
        <div className={mainContainer}>
            <div className={headerContainer}>
                <Typography
                    variant="text-size-Medium"
                    color="$text-colors-color-text-dark.main"
                >
                    <Translate text="Share" />
                </Typography>
                <FontAwesomeIcon
                    icon={faXmark}
                    style={{ cursor: "pointer" }}
                    onClick={closeHandler}
                />
            </div>
            <Tabs
                value={tabKey}
                onChange={changeTabHandler}
                variant="fullWidth"
                centered
                style={{ padding: "0px 40px" }}
            >
                {linkUrl && (
                    <Tab
                        label={
                            <TabTitle
                                label="Share a link"
                                icon={faLink}
                                isTabSelected={tabKey === "link"}
                            />
                        }
                        value="link"
                        style={{ maxWidth: "unset" }}
                    />
                )}
                {embedUrl && (
                    <Tab
                        label={
                            <TabTitle
                                label="Embed"
                                icon={faCode}
                                isTabSelected={tabKey === "embed"}
                            />
                        }
                        value="embed"
                        style={{ maxWidth: "unset" }}
                    />
                )}
            </Tabs>
            <div hidden={tabKey !== "link" || !linkUrl}>
                <LinkTabContent
                    linkUrlSettings={linkUrl + shareSettingsUrlParams}
                    copyLink={copyLink}
                    setCopyLink={setCopyLink}
                    setCopyEmbed={setCopyEmbed}
                    analyticsOptions={analyticsOptions}
                />
            </div>
            <div hidden={tabKey !== "embed" || !embedUrl}>
                <EmbedTabContent
                    embedUrlSettings={embedUrl + shareSettingsUrlParams}
                    copyEmbed={copyEmbed}
                    setCopyLink={setCopyLink}
                    setCopyEmbed={setCopyEmbed}
                    width={width}
                    setWidth={setWidth}
                    height={height}
                    setHeight={setHeight}
                    analyticsOptions={analyticsOptions}
                />
            </div>
        </div>
    );
};

export default ShareModal;
