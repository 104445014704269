import { Button, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";

import Translate, { TranslatableText } from "components/Translate/Translate";

interface Props {
    faIcon: IconDefinition;
    text: TranslatableText;
    clickHandler: () => void;
}

const HeaderButton = ({ faIcon, text, clickHandler }: Props) => {
    return (
        <Button
            variant="outlined"
            style={{
                padding: "10px 20px",
                height: "30px",
                gap: "10px",
            }}
            onClick={clickHandler}
        >
            <FontAwesomeIcon icon={faIcon} fontSize={12} />
            <Typography variant="text-size-xSmall-bold">
                <Translate text={text} />
            </Typography>
        </Button>
    );
};

export default HeaderButton;
