@use "../../styles/variables.module";

.container {
    display: flex;
    width: 400px;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    background: variables.$background-colors-color-background-light;
    box-shadow: 0px 20px 30px 0px
        variables.$background-colors-color-background-overlay;
    z-index: variables.$zindex-message-modal;
}

.header {
    display: flex;
    padding: 10px 20px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    color: variables.$text-colors-color-text-gray;
    border-bottom: 1px solid variables.$border-colors-color-border-primary;
    background: variables.$background-colors-color-background-light-off;
}

.body {
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
}

.footer {
    height: 63px;
    display: flex;
    padding: 10px 20px;
    align-items: center;
    gap: 20px;
    align-self: stretch;
    border-top: 1px solid variables.$border-colors-color-border-primary;
}
