import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";

import useScreenSizes from "hooks/useScreenSizes";
import variables from "styles/_variables.module.scss";
import { Collection, Element, Folder } from "types";

import FolderListViewSkeleton from "./components/FolderListViewSkeleton";
import ListItem from "./components/ListItem/ListItem";
import EmptyFolderMessage from "components/EmptyFolderMessage/EmptyFolderMessage";
import Translate from "components/Translate/Translate";

interface Props {
    items: (Collection | Folder)[];
    isFetched: boolean;
    sortBy: Folder["sortBy"];
    folderClickHandler: (folder: Folder) => void;
    collectionClickHandler: (collection: Collection) => void;
    disableContextMenu?: boolean;
}

const FolderListView = ({
    items,
    isFetched,
    sortBy,
    folderClickHandler,
    collectionClickHandler,
    disableContextMenu,
}: Props) => {
    const { isMobileScreen } = useScreenSizes();
    const isEmptyFolder = items && items.length === 0;
    return !isFetched ? (
        <FolderListViewSkeleton />
    ) : isEmptyFolder ? (
        <EmptyFolderMessage />
    ) : (
        <TableContainer sx={{ overflowX: isMobileScreen ? "auto" : "hidden" }}>
            <Table
                sx={{
                    ".MuiTableCell-root": {
                        padding: "7px 10px",
                        borderColor:
                            variables["border-colors-color-border-light"],
                    },
                }}
            >
                <TableHead
                    sx={{ ".MuiTableCell-root": { borderBottomWidth: "3px" } }}
                >
                    <TableRow>
                        <TableCell align="center" sx={{ width: "40px" }} />
                        <TableCell>
                            <Typography
                                variant="text-size-Small"
                                color="$text-colors-color-text-gray.main"
                            >
                                <Translate text="Title" />
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                variant="text-size-Small"
                                color="$text-colors-color-text-gray.main"
                            >
                                <Translate text="Type" />
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                variant="text-size-Small"
                                color="$text-colors-color-text-gray.main"
                            >
                                <Translate text="Date" />
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortBy === "TYPE" ? (
                        <>
                            {items
                                .filter(
                                    (element: Element): element is Folder =>
                                        element.type === "FOLDER"
                                )
                                .map((folder: Folder) => (
                                    <ListItem
                                        key={folder.id}
                                        element={folder}
                                        folderClickHandler={folderClickHandler}
                                        collectionClickHandler={
                                            collectionClickHandler
                                        }
                                        disableContextMenu={disableContextMenu}
                                    />
                                ))}
                            {items
                                .filter(
                                    (element: Element): element is Collection =>
                                        element.type === "COLLECTION"
                                )
                                .map((collection: Collection) => (
                                    <ListItem
                                        key={collection.id}
                                        element={collection}
                                        folderClickHandler={folderClickHandler}
                                        collectionClickHandler={
                                            collectionClickHandler
                                        }
                                        disableContextMenu={disableContextMenu}
                                    />
                                ))}
                        </>
                    ) : (
                        items.map((row: Collection | Folder) => (
                            <ListItem
                                key={row.id}
                                element={row}
                                folderClickHandler={folderClickHandler}
                                collectionClickHandler={collectionClickHandler}
                                disableContextMenu={disableContextMenu}
                            />
                        ))
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default FolderListView;
