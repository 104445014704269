import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Typography } from "@mui/material";

import * as styles from "../VerifyEmail.module.scss";

import Translate from "components/Translate/Translate";

const ConfirmOrCompleteMessage = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    return (
        <Button
            variant="contained"
            className={styles.buttonStyle}
            onClick={() =>
                navigate(
                    {
                        pathname: "/login",
                        search: searchParams.toString(),
                    },
                    { relative: "path" }
                )
            }
        >
            <Typography variant="text-size-Base-bold">
                <Translate text="Go to login" />
            </Typography>
        </Button>
    );
};

export default ConfirmOrCompleteMessage;
