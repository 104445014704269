import { Tooltip, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCirclePlus,
    faCircleXmark,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";

import * as styles from "./ResultCard.module.scss";
import wizenozePlaceholder from "images/wizenoze-placeholder.webp";
import variables from "styles/_variables.module.scss";
import { Result } from "types";
import { setBlankImage } from "utils/helpers";

import NotificationIcon from "components/NotificationIcon/NotificationIcon";
import Translate from "components/Translate/Translate";

const Header = ({
    clickHandler,
    result,
    deleteItemHandler,
    isResultGone,
    isSelected,
    addResourceHandler,
    removeResourceHandler,
}: {
    clickHandler: () => void;
    result: Result;
    deleteItemHandler?: () => void;
    isResultGone: boolean;
    isSelected?: boolean;
    addResourceHandler?: (() => void) | undefined;
    removeResourceHandler?: (() => void) | undefined;
}) => {
    const displayAddButton = !isSelected && addResourceHandler;
    const displayRemoveButton = isSelected && removeResourceHandler;
    return (
        <div className={styles.header}>
            <div className={styles.source} onClick={clickHandler}>
                {result?.sourceLogo && (
                    <img
                        className={styles.sourceLogo}
                        onError={setBlankImage}
                        src={result.sourceLogo || wizenozePlaceholder}
                        alt={result.sourceUrl + " logo"}
                    />
                )}
                <div className={styles.sourceUrl}>
                    <Typography variant="text-size-xSmall-regular">
                        {result.sourceUrl}
                    </Typography>
                </div>
            </div>
            {isResultGone && (
                <NotificationIcon
                    tooltip={{
                        title: "Missing resource",
                        description:
                            "Currently, we can't reach the resource on this URL. The resource has been removed from your shared collection.",
                    }}
                />
            )}
            {displayAddButton && (
                <FontAwesomeIcon
                    icon={faCirclePlus}
                    color={variables["wizenoze-brand-color-wizenoze-green"]}
                    fontSize={20}
                    onClick={addResourceHandler}
                />
            )}
            {displayRemoveButton && (
                <Tooltip
                    title={
                        <Typography variant="text-size-xxSmall-regular">
                            <Translate text="Deselect the resource." />
                        </Typography>
                    }
                    disableFocusListener
                    enterDelay={500}
                    placement="bottom"
                    arrow
                >
                    <FontAwesomeIcon
                        icon={faCircleXmark}
                        color={
                            variables["message-colors-color-message-warning"]
                        }
                        fontSize={20}
                        onClick={removeResourceHandler}
                    />
                </Tooltip>
            )}
            {deleteItemHandler && (
                <FontAwesomeIcon
                    icon={faTrash}
                    className={styles.deleteButton}
                    fontSize={14}
                    onClick={deleteItemHandler}
                />
            )}
        </div>
    );
};

export default Header;
