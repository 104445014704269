@use "../../../../styles/variables.module";

.item {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    background: variables.$background-colors-color-background-white;
    box-shadow: 0px -1px 0px 0px variables.$border-colors-color-border-primary inset;
    cursor: pointer;
}
