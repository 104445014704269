@use "../../../styles/variables.module";

.forgotPasswordContainer {
    width: 100%;
}

.title {
    margin-bottom: 40px;
    @media (max-width: variables.$bp-lg) {
        margin-bottom: 30px;
    }
}

.formError {
    margin-bottom: 20px;
    padding: 16px;
    line-height: 24px;
    color: #2a244b;
    background: #dcdae9 linear-gradient(180deg, #e1dfec, #dcdae9) repeat-x;
    border-radius: 8px;
    border-color: #cecbe0;
}

.formContainer {
    display: flex;
    flex-direction: column;
    gap: 40px;
    @media (max-width: variables.$bp-lg) {
        gap: 30px;
    }
}

.messageAndButtonContainer {
    display: flex;
    flex-direction: column;
    gap: 40px;
    @media (max-width: variables.$bp-lg) {
        gap: 30px;
    }
}

.buttonStyle {
    padding: 16px 30px;
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}
