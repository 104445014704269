import Grid from "@mui/material/Unstable_Grid2";

import * as styles from "./CollectionListView.module.scss";
import { Item, Result } from "types";
import { convertItemTypeToResultType } from "utils/typeConvertors";

import CollectionListResultCard from "./components/CollectionListResultCard";

interface Props {
    items: Item[];
    selectedItems?: Item[];
    addItemHandler?: (result: Item) => void;
    removeItemHandler?: (result: Item) => void;
}
const CollectionListView = ({
    items,
    selectedItems,
    addItemHandler,
    removeItemHandler,
}: Props) => {
    const results: Result[] = items.map((item) =>
        convertItemTypeToResultType(item),
    );
    return (
        <div className={styles.listContainer}>
            {items.map((item, index) => (
                <CollectionListResultCard
                    key={item.url}
                    result={convertItemTypeToResultType(item)}
                    results={results}
                    resultIndex={index}
                    isSelectedResult={selectedItems?.some(
                        (el) => el.id === item.id,
                    )}
                    addResourceHandler={
                        addItemHandler ? () => addItemHandler(item) : undefined
                    }
                    removeResourceHandler={
                        removeItemHandler
                            ? () => removeItemHandler(item)
                            : undefined
                    }
                />
            ))}
        </div>
    );
};

export default CollectionListView;
