import { useCurriculumLayersQuery } from "./api/useCurriculumQuery";
import useCurriculumParams from "./useCurriculumParams";

type Node = {
    id: number;
    name: string;
    children?: Node[];
    queries?: { id: number }[];
    logoUrl?: string;
};

const findItemsWithIds = (
    data: Node[],
    nodeIds: number[],
    queryId?: number
) => {
    const foundItems: Node[] = [];

    const findItemsRecursive = (node: Node) => {
        if (nodeIds.includes(node.id)) {
            foundItems.push(node);
            if (node.children && node.children.length > 0) {
                node.children.forEach((el) => findItemsRecursive(el));
            } else if (queryId && node.queries && node.queries.length > 0) {
                const foundQuery = node.queries.find(
                    (query) => queryId === query.id
                );
                if (foundQuery) {
                    foundItems.push(foundQuery);
                }
            }
        }
    };

    data.forEach((item) => findItemsRecursive(item));

    return foundItems;
};

const useCurriculumSelectedNodes = () => {
    const { curriculumId, orderedNodeIds, queryId } = useCurriculumParams();
    const { data: curriculumLayersData } = useCurriculumLayersQuery(
        curriculumId,
        "ALL"
    );
    if (!curriculumLayersData) return [];
    return findItemsWithIds(
        curriculumLayersData,
        orderedNodeIds.map((el) => el.value),
        queryId
    );
};

export default useCurriculumSelectedNodes;
