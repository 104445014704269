@use "../../../../../../../styles/variables.module";

.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (max-width: variables.$bp-lg) {
        gap: 10px;
    }
}

.mobileFilterAndMessage {
    display: flex;
    align-items: center;
    gap: 10px;
}

.resultsList {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
