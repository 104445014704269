import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Theme, useMediaQuery } from "@mui/material";
import { isObject } from "lodash";

import { useAppContext } from "contexts/AppContext";
import useCurriculumParams from "hooks/useCurriculumParams";
import useCurriculumQuery from "hooks/api/useCurriculumQuery";
import { useNodeLayersQuery } from "hooks/api/useNodeNextQuery";
import { QueryResults, Topic } from "types";
import { tracker } from "utils/analytics";

import HeaderImage from "./components/HeaderImage/HeaderImage";
import HeaderTitle from "./components/HeaderTitle/HeaderTitle";
import NextTopicButton from "./components/NextTopicButton/NextTopicButton";
import QuerySelector from "./components/QuerySelector/QuerySelector";

export const handleChange = (changeQuery, nodeData) => (newId: number) => {
    const newQuery = nodeData?.queries?.find(
        ({ id }: { id: number }) => id === Number(newId),
    );
    tracker("Changed query via selector", {
        selectedQuery: newId,
    });
    newQuery && changeQuery(newQuery);
};

interface Props {
    query: QueryResults;
    nodeData: Topic;
}

const QueryResultsHeader = ({ query, nodeData, ...props }: Props) => {
    const isDesktopScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.up("lg"),
    );
    const isMobileScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("md"),
    );
    const navigate = useNavigate();
    const { curriculumId, gradeId, subjectId, unitId } = useCurriculumParams();
    const { embedDetails } = useAppContext();
    const { data: curriculumData } = useCurriculumQuery();
    const topicListId = unitId
        ? unitId.toString()
        : subjectId
          ? subjectId?.toString()
          : gradeId
            ? gradeId?.toString()
            : curriculumId?.toString();
    const willDisplayUnit =
        curriculumData?.structure.includes("UNIT") &&
        curriculumData?.structure.includes("SUBJECT");
    const layersString = willDisplayUnit ? "UNIT/TOPIC/QUERY" : "TOPIC/QUERY";
    const { data: topicListData } = useNodeLayersQuery(
        topicListId,
        layersString,
    );
    const subjectTitle = topicListData?.name.toLowerCase();
    const changeQuery = async (query) => {
        navigate("../" + query?.id, {
            relative: "path",
        });
    };

    const [localQuery, setLocalQuery] = useState(query);
    const [localNodeData, setNodeData] = useState(nodeData);
    useEffect(() => {
        if (query) setLocalQuery(query);
        if (isObject(nodeData) && Object.keys(nodeData)?.length)
            setNodeData(nodeData);
    }, [localNodeData, nodeData, query]);

    return (
        <div
            data-cardbackground={subjectTitle}
            style={{
                padding: isMobileScreen ? "0px 10px" : "0px 40px",
                display: "flex",
                flexDirection: isDesktopScreen ? "row" : "column",
                justifyContent: "space-between",
                gap: "10px",
            }}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                }}
            >
                {!isMobileScreen && (
                    <HeaderImage
                        logoUrl={localNodeData?.logoUrl}
                        name={localNodeData?.name}
                    />
                )}
                <HeaderTitle
                    query={localQuery}
                    topicTitle={localNodeData?.name}
                />
            </div>
            {(embedDetails?.topicId ||
                (!embedDetails?.queryId && !embedDetails?.ids)) &&
                (localNodeData?.queries?.length > 1 ? (
                    <QuerySelector
                        {...{
                            query: localQuery.query,
                            nodeData: localNodeData,
                            handleChange: handleChange(
                                changeQuery,
                                localNodeData,
                            ),
                        }}
                        {...props}
                    />
                ) : (
                    <NextTopicButton
                        topicId={localNodeData?.id}
                        unitId={localNodeData?.parentId}
                    />
                ))}
        </div>
    );
};

export default QueryResultsHeader;
