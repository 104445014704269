import { Typography } from "@mui/material";

import useScreenSizes from "hooks/useScreenSizes";

import Translate, { TranslatableText } from "components/Translate/Translate";

interface Props {
    title: TranslatableText;
}

const PublicPageTitle = ({ title }: Props) => {
    const { isDesktopScreen, isTabletScreen } = useScreenSizes();
    return (
        <Typography
            variant={
                isDesktopScreen
                    ? "text-size-xxLarge"
                    : isTabletScreen
                    ? "text-size-xLarge"
                    : "text-size-Large"
            }
        >
            <Translate text={title} />
        </Typography>
    );
};

export default PublicPageTitle;
