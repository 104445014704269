@use "../../styles/variables.module";

.logoContainer {
    padding: 10px;
    min-width: 62px;
    max-width: 62px;
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: variables.$background-colors-color-background-white;
    border: 1px solid variables.$border-colors-color-border-primary;
    border-radius: 100%;
}

.curriculumLogo {
    max-width: 40px;
    max-height: 40px;
}

.curriculumMissingLogo {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: variables.$wizenoze-brand-color-wizenoze-green;
    border-radius: 100%;
}
