/**
 * Check if array is valid and
 * has enough contents
 * @param {*} arr
 * @param {*} options
 * @returns
 */
export const isFilledArray = (arr, options) => {
    if (options?.minLength) {
        return Array.isArray(arr) && arr.length > options.minLength;
    }
    return Array.isArray(arr) && arr.length;
};
