import { Typography } from "@mui/material";

import * as styles from "./Slide.module.scss";

import Translate, { TranslatableText } from "components/Translate/Translate";

export type SlideType = {
    image: string;
    title: TranslatableText;
    text?: TranslatableText;
    bulletPoints?: TranslatableText[];
};
interface Props {
    slide: SlideType;
}

const Slide = ({ slide }: Props) => {
    return (
        <>
            <img
                src={slide.image}
                className={styles.imageStyle}
                alt="slideImage"
            />
            <div className={styles.textContainer}>
                <div style={{ padding: "20px 0px", textAlign: "center" }}>
                    <Typography
                        variant="text-size-Base-bold"
                        color="$text-colors-color-text-dark.main"
                    >
                        <Translate text={slide.title} />
                    </Typography>
                </div>
                {slide.text && (
                    <Typography
                        variant="text-size-Base"
                        color="$text-colors-color-text-dark.main"
                    >
                        <Translate text={slide.text} />
                    </Typography>
                )}
                {slide.bulletPoints && (
                    <ul style={{ marginTop: "0px" }}>
                        {slide.bulletPoints.map((item, index) => {
                            return (
                                <li key={index}>
                                    <Typography
                                        variant="text-size-Base"
                                        color="$text-colors-color-text-dark.main"
                                    >
                                        <Translate text={item} />
                                    </Typography>
                                </li>
                            );
                        })}
                    </ul>
                )}
            </div>
        </>
    );
};

export default Slide;
