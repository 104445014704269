
/**
 * Check if the query has teacher approved badge
 * @param {Object} param0
 * @returns 
 */

export const isTeacherApprovedContent = ({
    isTeacherApproved,
    collectionAccessType,
    collectionAccessTypes,
    isCurriculumBasics,
}) => {
    if(collectionAccessType !== collectionAccessTypes?.paidCollection){
        if(isCurriculumBasics || isTeacherApproved) return true
    }
}