import { Theme, useMediaQuery } from "@mui/material";

import { Query, Topic } from "types";
import { tracker } from "utils/analytics";
import { capitaliseFirstLetter } from "utils/helpers";

import SelectableDropdown from "components/SelectableDropdown/SelectableDropdown";

interface Props {
    query: Query;
    nodeData: Topic;
    handleChange: (arg0: unknown) => void;
}

const QuerySelector = ({ query, nodeData, handleChange }: Props) => {
    const isDesktopScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.up("lg")
    );
    const queries = nodeData?.queries;
    if (!queries) return null;

    const trackerCall = (showDropdown: boolean) => {
        tracker("Learning objectives toggle dropdown", { showDropdown });
    };
    if (queries?.length < 2) {
        const [first] = queries;
        return <div>{capitaliseFirstLetter(first.name)}</div>;
    }
    return (
        <div
            style={{
                paddingTop: isDesktopScreen ? "15px" : "0px",
                position: "relative",
                height: isDesktopScreen ? "" : "55px",
                flex: isDesktopScreen ? "0 0 325px" : "",
            }}
        >
            <SelectableDropdown
                name={"Learning objectives"}
                items={queries}
                selectedId={query.id}
                trackerCall={trackerCall}
                handleChange={handleChange}
            />
        </div>
    );
};

export default QuerySelector;
