import { Button, Typography } from "@mui/material";
import {
    faArrowUpFromBracket,
    faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Translate from "components/Translate/Translate";

const SaveButton = ({
    saveHandler,
    isSaving,
    isDisabled,
    isFullWidth,
}: {
    saveHandler: () => void;
    isSaving: boolean;
    isDisabled?: boolean;
    isFullWidth?: boolean;
}) => {
    return (
        <Button
            variant="contained"
            onClick={saveHandler}
            style={{
                padding: "10px 20px",
                width: isFullWidth ? "100%" : undefined,
            }}
            disabled={isDisabled}
        >
            <FontAwesomeIcon
                icon={isSaving ? faSpinner : faArrowUpFromBracket}
                spin={isSaving}
                style={{ marginRight: 10, fontSize: 12, width: 12 }}
            />
            <Typography variant="button-text-size-xSmall-bold">
                <Translate text="Save" />
            </Typography>
        </Button>
    );
};

export default SaveButton;
