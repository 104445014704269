@use "../../../../../../../styles/variables.module";

.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.background {
    padding: 40px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: variables.$background-colors-color-background-light-off;
}

.stepsContainer {
    max-width: 480px;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.mainTitle {
    padding: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    border: 1px solid #770db8;
    background: variables.$background-colors-color-background-white;
}

.logo {
    padding: 16px 12px;
    border-radius: 4px;
    background: #ffeffe;
}

.section {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.steps {
    display: flex;
    flex-direction: column;
}

.footer {
    padding: 16px 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background: variables.$background-colors-color-background-blue;
}
