@use "../../../../../../../styles/variables.module";

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.questions {
    padding: 30px 40px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-right: 1px solid variables.$border-colors-color-border-light;
    background: variables.$background-colors-color-background-light-off;
    overflow: auto;
    @media (max-width: variables.$bp-md) {
        padding: 20px;
    }
}

.footer {
    padding: 10px 20px;
    height: 50px;
    display: flex;
    align-items: center;
    border-top: 1px solid variables.$border-colors-color-border-light;
    background: variables.$background-colors-color-background-white;
}

.numberAndSave {
    display: flex;
    align-items: center;
    gap: 20px;
}
