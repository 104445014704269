import { CSSProperties, ReactNode, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAnglesUp,
    faAngleDoubleDown,
    faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import * as styles from "./Panel.module.scss";
import variables from "styles/_variables.module.scss";

import Translate, { TranslatableText } from "components/Translate/Translate";

export const PanelSection = ({
    title,
    icon,
    isCollapsible,
    colorOverride,
    triggerCollapse,
    children,
}: {
    title: string;
    icon?: IconProp;
    isCollapsible?: boolean;
    colorOverride?: string;
    triggerCollapse?: boolean;
    children: ReactNode | ReactNode[];
}) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [collapseTriggered, setCollapseTriggered] = useState(false);
    useEffect(() => {
        if (triggerCollapse && !collapseTriggered) {
            setCollapseTriggered(true);
            setIsCollapsed(true);
        } else if (!triggerCollapse) {
            setCollapseTriggered(false);
            setIsCollapsed(false);
        }
    }, [collapseTriggered, isCollapsed, triggerCollapse]);
    return (
        <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
            <div className={styles.sectionTitle}>
                {icon && (
                    <FontAwesomeIcon
                        icon={icon}
                        color={variables["text-colors-color-text-gray"]}
                    />
                )}
                <Typography
                    variant="text-size-Base-bold"
                    color={colorOverride ?? "$text-colors-color-text-dark.main"}
                    style={{ flexGrow: 1 }}
                >
                    <Translate text={title} />
                </Typography>
                {isCollapsible && (
                    <FontAwesomeIcon
                        icon={!isCollapsed ? faAnglesUp : faAngleDoubleDown}
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        style={{
                            // color: variables["text-colors-color-text-gray"],
                            textAlign: "center",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 900,
                            lineHeight: "20px",
                        }}
                        color={colorOverride}
                    />
                )}
            </div>
            {!isCollapsed && children}
        </div>
    );
};

export const PanelV2 = ({
    children,
    styleOverrides,
}: {
    children: ReactNode | ReactNode[];
    styleOverrides?: CSSProperties;
}) => (
    <div className={styles.containerV2} style={styleOverrides}>
        {children}
    </div>
);

interface Props {
    children: React.ReactElement | React.ReactElement[];
    title: TranslatableText;
    footer?: {
        text: TranslatableText;
        clickHandler: () => void;
    };
    styleOverrides?: CSSProperties;
}

const Panel = ({ children, title, footer, styleOverrides }: Props) => {
    return (
        <div className={styles.container} style={styleOverrides}>
            <div className={styles.title}>
                <Typography
                    variant="text-size-Base-bold"
                    color="$text-colors-color-text-dark.main"
                >
                    <Translate text={title} />
                </Typography>
            </div>
            {children}
            {footer && (
                <div className={styles.footerContainer}>
                    <div
                        className={styles.footer}
                        onClick={footer.clickHandler}
                    >
                        <FontAwesomeIcon
                            icon={faArrowRight}
                            fontSize={12}
                            style={{ padding: "4px 0px" }}
                        />
                        <Typography variant="text-size-xSmall-bold">
                            <Translate text={footer.text} />
                        </Typography>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Panel;
