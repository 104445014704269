import { useContext, useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2";

import { CurriculumContext } from "features/CurriculumNavigator/state/CurriculumContext";
import useCurriculumQuery, {
    useCurriculumLayersQuery,
} from "hooks/api/useCurriculumQuery";
import useCurriculumSelectedNodes from "hooks/useCurriculumSelectedNodes";
import { Query, Topic, Unit } from "types";

import LogoAndTitle from "../LogoAndTitle/LogoAndTitle";
import NodeCard from "./components/NodeCard/NodeCard";
import QueryCard from "./components/QueryCard/QueryCard";

const NodeView = () => {
    const { data: curriculumData } = useCurriculumQuery();
    const { data: layerData } = useCurriculumLayersQuery(
        curriculumData?.id,
        "ALL"
    );
    const { setEmbedUrl, setLinkUrl } = useContext(CurriculumContext);
    const selectedNodes = useCurriculumSelectedNodes();
    const nodeData = selectedNodes[selectedNodes.length - 1] ?? {
        ...curriculumData,
        children: layerData,
    };
    const subjectName = nodeData?.name;

    useEffect(() => {
        if (nodeData?.qualifier === "TOPIC") {
            setEmbedUrl(nodeData?.embedUrl);
            setLinkUrl(nodeData?.linkUrl);
        }
    }, [
        nodeData?.embedUrl,
        nodeData?.linkUrl,
        nodeData?.qualifier,
        setEmbedUrl,
        setLinkUrl,
    ]);
    return (
        <>
            <LogoAndTitle />
            <Grid container spacing={"20px"} style={{ paddingTop: "20px" }}>
                {nodeData?.children
                    ? nodeData?.children.map((node: Unit | Topic) => (
                          <Grid key={node.id} lg={4} md={6} xs={12}>
                              <NodeCard node={node} subjectName={subjectName} />
                          </Grid>
                      ))
                    : nodeData?.queries?.map((query: Query) => (
                          <Grid key={query.id} lg={4} md={6} xs={12}>
                              <QueryCard
                                  query={query}
                                  subjectName={subjectName}
                              />
                          </Grid>
                      ))}
            </Grid>
        </>
    );
};

export default NodeView;
