import { useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Tab, Tabs } from "@mui/material";
import { faGear, faUserCog } from "@fortawesome/free-solid-svg-icons";

import styles from "./Settings.module.scss";
import MessagingContext from "contexts/MessagingContext";
import useScreenSizes from "hooks/useScreenSizes";

import ProfileTab from "./ProfileTab/ProfileTab";
import SettingsTab from "./SettingsTab/SettingsTab";
import CollectionNavBar from "components/NavBars/CollectionNavBar";
import TabTitle from "components/Tabs/TabTitle/TabTitle";

const Settings = () => {
    const { isMobileScreen } = useScreenSizes();
    const [searchParams] = useSearchParams();
    const initialTab = searchParams.get("tab");

    const { openMessageModal } = useContext(MessagingContext);
    const [tabKey, setTabKey] = useState(initialTab || "settings");
    const [hasUnsavedChanges, setUnsavedChanges] = useState(false);

    const changeTab = (
        _event: React.SyntheticEvent,
        newTabKey: "profile" | "settings",
    ) => {
        if (hasUnsavedChanges)
            return openMessageModal({
                isModalVisible: true,
                title: "Changes are not saved",
                body: "The changes you made will be lost if you navigate away from this page. Are you sure you want to discard the changes?",
                type: "Yes, leave the page",
                primaryActionHandler: () => {
                    setTabKey(newTabKey);
                    setUnsavedChanges(false);
                },
                secondaryActionHandler: () => {
                    // do nothing
                },
            });
        setTabKey(newTabKey);
        setUnsavedChanges(false);
    };

    return (
        <>
            <CollectionNavBar pageTitle="Settings" />
            <div style={{ padding: isMobileScreen ? "0px 10px" : "0px 40px" }}>
                <Tabs
                    value={tabKey}
                    variant="fullWidth"
                    onChange={changeTab}
                    className={styles.tabs}
                >
                    <Tab
                        label={
                            <TabTitle
                                label="Settings"
                                icon={faGear}
                                isTabSelected={tabKey === "settings"}
                            />
                        }
                        value="settings"
                    />
                    <Tab
                        label={
                            <TabTitle
                                label="My profile"
                                icon={faUserCog}
                                isTabSelected={tabKey === "profile"}
                            />
                        }
                        value="profile"
                    />
                </Tabs>
                {tabKey == "settings" && (
                    <SettingsTab
                        setUnsavedChanges={setUnsavedChanges}
                        hasUnsavedChanges={hasUnsavedChanges}
                    />
                )}
                {tabKey == "profile" && (
                    <ProfileTab
                        setUnsavedChanges={setUnsavedChanges}
                        hasUnsavedChanges={hasUnsavedChanges}
                    />
                )}
            </div>
        </>
    );
};

export default Settings;
